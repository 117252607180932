import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Blockstar } from '@shared-data';
import React from 'react';
import { calculateProgress } from 'src/utils/utils';
import { AbilityBar } from '../ability-bar';
import { ProgressBar } from '../progress-bar';
import { SkillEntryType } from '../types';
import { Skill } from './skill';
import {
  CurrentAbility,
  PotentialAbility,
  SkillAbilityContainer,
  SkillEntryContainer,
  XpBar,
  XpInfo,
  XpText,
} from './style';

export interface ISkillEntryProps {
  type: SkillEntryType;
  title: string;
  locked?: boolean;
  currentLevel: number;
  targetLevel?: number;
  minPotential: number;
  maxPotential: number;
  currentExp: number;
  targetExp: number;
  maxed?: boolean;
  blockstar?: Blockstar;
  nameOnly?: boolean;
}

export const SkillEntry = (props: ISkillEntryProps) => {
  const paddingRight = props.locked ? 24 : 0;
  const entry = (
    <SkillEntryContainer style={{ paddingRight: paddingRight }}>
      <Skill
        type={props.type}
        title={props.title}
        locked={props.locked}
        blockstar={props.blockstar}
        notFulfilled={props.currentLevel === 0 && props.maxPotential === 0}
      />
      {!props.nameOnly && (
        <div style={{ display: 'flex', flex: 1 }}>
          {props.locked ? (
            <div style={{ display: 'flex', flex: 1 }} />
          ) : (
            <AbilityBar
              musical={props.type === SkillEntryType.MUSICAL}
              currentLevel={props.currentLevel}
              targetLevel={props.targetLevel}
              minPotential={props.minPotential}
              maxPotential={props.maxPotential}
            />
          )}
          {props.locked ? (
            <SkillAbilityContainer>
              <LockIcon fontSize={'inherit'} />
            </SkillAbilityContainer>
          ) : (
            <CurrentAbility>{props.currentLevel}</CurrentAbility>
          )}
          {props.locked ? (
            <SkillAbilityContainer style={{ marginLeft: 24 }}>
              <LockIcon fontSize={'inherit'} />
            </SkillAbilityContainer>
          ) : (
            <PotentialAbility>{`${props.minPotential} - ${props.maxPotential}`}</PotentialAbility>
          )}
        </div>
      )}
    </SkillEntryContainer>
  );

  const progress = calculateProgress(props.currentExp, props.targetExp, true);

  return props.locked || props.nameOnly ? (
    entry
  ) : (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor={'#BEBEBE'} />}>
        {entry}
      </AccordionSummary>
      <AccordionDetails
        style={{ marginBottom: 16, display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: 270 }} />
        <XpInfo>
          <XpText>
            <span>To next ability</span>
            <span>
              {props.maxed
                ? 'Full potential reached!'
                : `${Math.floor(
                    props.currentExp,
                  ).toLocaleString()}/${Math.floor(
                    props.targetExp,
                  ).toLocaleString()} XP`}
            </span>
          </XpText>
          <XpBar>
            <ProgressBar
              currentLevel={props.maxed ? 100 : progress}
              color={'#FF68FF'}
            />
          </XpBar>
        </XpInfo>
        <div style={{ width: 208 }} />
      </AccordionDetails>
    </Accordion>
  );
};
