import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  IconButton,
  InputAdornment,
  Paper,
  useTheme,
} from '@mui/material';
import {
  ActionTargetType,
  Band,
  BandNameChangeRequest,
  Blockstar,
  blockstarNameRegex,
  nameChangeTimeLimit,
  PrepType,
  rolToPayForSignature,
  ThemeType,
} from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import rest, {
  BandRequestPath,
  BlockstarsRequests,
} from 'src/actions/network/rest';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import analytics from 'src/utils/analytics';
import { disableUnityInput } from 'src/utils/unity-helper';
import {
  getBandCollegeColumnRowCount,
  getBlockstarName,
} from 'src/utils/utils';
import { bandTxnPrep } from '../bands/others/helper';
import { ButtonRolPayText } from '../library/button-rol-pay-text';
import {
  CloseButtonRow,
  CloseContainer,
  CollageFrame,
  CollageImage,
} from '../library/style';
import {
  BodyContainer,
  ErrorMessage,
  Message,
  NameChangeDialogContainer,
  NameChangeForm,
  NameTextField,
  SubmitButton,
  TextFieldContainer,
  Title,
  TitleContainer,
  TooltipContainer,
} from './style';

const NameChangeDialog = (props: {
  open: boolean;
  target?: Blockstar | Band;
  nameChangeType: ActionTargetType;
  doNotUpdateToServer?: boolean;
  updateTarget: (newName: string) => void;
  onClose: () => void;
}) => {
  if (!props.target) {
    return <div></div>;
  }
  const { publicKey, signTransaction } = useWallet();
  const { connection } = useConnection();
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);
  const theme = useTheme() as ThemeType;

  const [inProgress, setInProgress] = useState(false);
  const { register, handleSubmit, reset, formState, setError } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (props.open) {
      analytics.logEvent('ViewScreenNameChange', {
        screen: `edit`,
      });
    }
  }, [props.open]);

  const onClose = () => {
    reset();
    props.onClose();
  };

  const handleClearClick = () => {
    formState;
  };

  const onNameSubmit = async (data: any) => {
    if (props.doNotUpdateToServer) {
      props.updateTarget(data.newName);
      onClose();
      return;
    }

    if (!publicKey) {
      console.error('Connect wallet before changing name.');
      return;
    }

    setInProgress(true);
    if (data.newName.length > 0) {
      let target: Blockstar | Band = props.target as Blockstar;
      let url = `${process.env.WORKER_URL}/${BlockstarsRequests.NameChange}`;
      let body = {};
      switch (props.nameChangeType) {
        case ActionTargetType.Blockstar:
          body = {
            blockstarId: target.number,
            name: data.newName,
            walletId: publicKey.toString(),
          };
          break;
        case ActionTargetType.Band:
          if (!publicKey || !signTransaction) {
            const notReady =
              'Tried to start band action with no wallet ready...';
            console.error(notReady);
            toast.error(notReady);
            return;
          }

          target = props.target as Band;

          try {
            const prepType = PrepType.NAME_CHANGE;
            const bandNameChangeRequest: BandNameChangeRequest = {
              id: target.id,
              name: data.newName,
            };
            const prepBody = {
              prepType: prepType,
              saveRequest: {
                id: target.id,
                name: data.newName,
              },
            };
            const txnResult = await bandTxnPrep(
              BandRequestPath.PrepEdit,
              prepBody,
              connection,
              publicKey,
              rolBalance,
              rolToPayForSignature,
              signTransaction,
              prepType,
              target.id,
            );

            mutateBalance();

            url = `${process.env.WORKER_URL}/${BandRequestPath.NameChange}`;
            body = {
              ...bandNameChangeRequest,
              signature: txnResult.clientSignature,
              blockhash: txnResult.signedTxn.recentBlockhash,
            };
          } catch (e) {
            setInProgress(false);
            setError('newName', {
              type: 'manual',
              message: 'Name change failed! Please use another name.',
            });
            return;
          }
          break;
        default:
          break;
      }

      try {
        const response: any = await rest.post(url, body);

        analytics.logEvent('ViewScreenNameChange', {
          screen: `confirmation`,
        });
        mutateBalance();
        setInProgress(false);
        toast.dismiss();
        toast.success(
          `Transaction valid! ${rolToPayForSignature} $ROL deducted; Name change confirmed!`,
        );
        onClose();
        props.updateTarget(data.newName);
      } catch (e: any) {
        setInProgress(false);
        setError('newName', {
          type: 'manual',
          message: 'Name change failed! Please use another name.',
        });
      }
    }
  };

  let titleText = ``;
  const messages = [];
  let canModifyName = true;
  if (props.target.customName) {
    const nameInfo = props.target.customName;
    const nextAvailTime = new Date(nameInfo.timestamp + nameChangeTimeLimit);
    if (Date.now() < nextAvailTime.getTime()) {
      canModifyName = false;
    }
  }

  const closeButton = (
    <CloseButtonRow>
      <CloseContainer>
        <IconButton color={'inherit'} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </CloseContainer>
    </CloseButtonRow>
  );

  const nameChangeDisabled =
    inProgress ||
    !formState.isDirty ||
    formState.errors?.newName?.message != undefined;
  const submitButton = (
    <SubmitButton
      loading={inProgress}
      disabled={nameChangeDisabled}
      disableElevation
      variant={'contained'}
      type={'submit'}>
      {props.nameChangeType === ActionTargetType.Band &&
      !props.doNotUpdateToServer ? (
        <ButtonRolPayText
          title={'Submit'}
          rolAmount={rolToPayForSignature}
          disabled={nameChangeDisabled}
        />
      ) : (
        'Submit'
      )}
    </SubmitButton>
  );

  const tooltipComponent = (
    <Paper>
      <TooltipContainer>
        <Message>You can only change the name once every 24 hours.</Message>
      </TooltipContainer>
    </Paper>
  );

  let image = <div />;
  switch (props.nameChangeType) {
    case ActionTargetType.Blockstar:
      const imgSrc = `${process.env.ASSET_BASE_URL || ''}/${
        (props.target as Blockstar).number
      }.png`;
      image = (
        <img
          src={imgSrc}
          width={90}
          height={90}
          style={{ objectFit: 'cover', borderRadius: 8 }}
        />
      );
      break;
    case ActionTargetType.Band:
      const band = props.target as Band;
      const collageColumnRowCount = getBandCollegeColumnRowCount(
        band.memberIds.length,
      );
      image = (
        <CollageFrame
          style={{ width: '90px', height: '90px' }}
          collageColumnRowCount={collageColumnRowCount}>
          {band.memberIds.map((memberId, index) => {
            return (
              <CollageImage
                key={index}
                src={`${process.env.ASSET_BASE_URL}/${memberId}.png`}
              />
            );
          })}
        </CollageFrame>
      );
      break;
    default:
      break;
  }

  let title = <Title>{titleText}</Title>;
  let body = <div></div>;
  if (canModifyName) {
    title = (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {image}
      </div>
    );
    let originalName = getBlockstarName(props.target as Blockstar);
    body = (
      <NameChangeForm onSubmit={handleSubmit(onNameSubmit)}>
        <TextFieldContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
            }}>
            <NameTextField
              variant='filled'
              placeholder='Enter Name'
              {...register('newName', {
                required: 'Please enter a name.',
                pattern: {
                  value: blockstarNameRegex,
                  message:
                    'Only letters, numbers, spaces, and periods are allowed.',
                },
                minLength: {
                  value: 1,
                  message: 'Min 1 characters.',
                },
                maxLength: {
                  value: 17,
                  message: 'Max 17 characters.',
                },
                validate: {
                  diffName: (v) =>
                    v !== originalName || 'Cannot match current name.',
                },
              })}
              onFocus={() => {
                disableUnityInput();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    variant='outlined'
                    sx={{ color: 'white', marginTop: '15px' }}>
                    <IconButton
                      sx={{
                        visibility: formState.isDirty ? 'visible' : 'hidden',
                      }}
                      onClick={() => {
                        reset();
                      }}
                      edge='end'>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoFocus
            />
          </div>
          <ErrorMessage>
            {formState.errors?.newName?.message ?? ``}
          </ErrorMessage>
        </TextFieldContainer>
        {submitButton}
      </NameChangeForm>
    );
  }

  return (
    <Dialog
      scroll={'body'}
      open={props.open}
      onClose={onClose}
      fullWidth={false}>
      <NameChangeDialogContainer>
        {closeButton}
        <TitleContainer>{title}</TitleContainer>
        <BodyContainer>{body}</BodyContainer>
      </NameChangeDialogContainer>
    </Dialog>
  );
};

export default NameChangeDialog;
