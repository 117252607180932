import { CharacterAnimationType } from '@shared-data';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import * as confetti from 'src/assets/lottie/confetti.json';
import { useWindowSize } from 'src/hooks/window-size';
import {
  hideUnityElement,
  styleUnityElement,
  unityMethods,
  unitySendMessage,
} from 'src/utils/unity-helper';

interface IProps {
  blockstarId: number;
  confetti?: boolean;
}

export const BlockstarRender = (props: IProps) => {
  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const windowSize = useWindowSize();

  useEffect(() => {
    // prep for 3d models
    const unityLoadCharacterData = {
      id: props.blockstarId.toString(),
      animation: CharacterAnimationType.BASIC_IDLE,
      source: 'results',
    };
    unitySendMessage(unityMethods.cleanSingleCharacter);
    unitySendMessage(
      unityMethods.loadSingleCharacter,
      JSON.stringify(unityLoadCharacterData),
    );

    return () => {
      unitySendMessage(unityMethods.cleanSingleCharacter);
      hideUnityElement();
    };
  }, []);

  useEffect(() => {
    // Need to make sure containerRef is set before opening the unity.
    // If the position for unity is not set, unity would throw error.
    if (containerRef && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      styleUnityElement({
        width: `${containerRect.width}px`,
        height: `${(containerRect.width * 3) / 4}px`,
        left: `${containerRect.left}px`,
        top: `${containerRect.top + 80}px`,
      });
    }
  }, [windowSize]);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
      ref={containerRef}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: -120,
        }}>
        {props.confetti && (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: confetti,
            }}
          />
        )}
      </div>
    </div>
  );
};
