import { LocationInfo } from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React from 'react';
import { Dropdown } from 'src/components/library/dropdown';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import {
  DropdownCost,
  DropdownItem,
  DropdownLocImg,
  DropdownLocInfo,
} from './style';

const LocationDropdown = (props: {
  disabled: boolean;
  error: boolean;
  locations: LocationInfo[];
  selectedLocationId: number;
  duration: number;
  durationCost: number;
  onLocationSelected: (locationId: number) => void;
}) => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { rolBalance } = useRolBalance(connection, publicKey);

  const onChange = (index: number) => {
    props.onLocationSelected(index);
  };

  return (
    <Dropdown
      disabled={props.disabled}
      value={props.locations.findIndex(
        (l) => l.id === props.selectedLocationId,
      )}
      error={props.error}
      onChange={onChange}
      style={{ width: '85%' }}
      components={props.locations.map((val, idx) => {
        const thisLocationCost =
          val.deposit + (props.duration * val.dailyCost) / 86400000;
        const disabled =
          rolBalance < props.durationCost + thisLocationCost &&
          thisLocationCost > 0;
        return {
          component: (
            <DropdownLocation
              disabled={disabled}
              location={val}
              duration={props.duration}
            />
          ),
          disabled: disabled,
        };
      })}
    />
  );
};

const baseUrl = process.env.ASSET_BASE_URL || '';
const DropdownLocation = (props: {
  location: LocationInfo;
  duration: number;
  disabled: boolean;
}) => {
  const cost =
    props.location.deposit +
    (props.duration * props.location.dailyCost) / 86400000;
  const locationImg = `${baseUrl}/${props.location.imageName}-icon.jpg`;
  return (
    <DropdownItem>
      {/* div to be replaced with img when location data is hooked up */}
      <DropdownLocImg>
        <img
          src={locationImg}
          width={'36px'}
          height={'36px'}
          style={{ objectFit: 'cover' }}
        />
      </DropdownLocImg>
      <DropdownLocInfo>
        <span style={{ fontSize: 16 }}>{props.location.name}</span>
        <DropdownCost>
          <span>{cost === 0 ? 'Free' : cost.toLocaleString() + ' $ROL'}</span>
          {props.disabled && (
            <span style={{ fontSize: 12, color: 'red' }}>Need more $ROL</span>
          )}
        </DropdownCost>
      </DropdownLocInfo>
    </DropdownItem>
  );
};

export default LocationDropdown;
