import { useTheme } from '@mui/material';
import {
  BlockstarActionType,
  CalculateRewardBuskingResponse,
  CalculateRewardResponse,
  SkillType,
  ThemeType,
} from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ActionOptions, onStartAction } from 'src/actions/action';
import rest, { ActionRequests } from 'src/actions/network/rest';
import { BlockstarInfo } from 'src/components/library/dialog-header';
import { SkillEntryType } from 'src/components/library/types';
import { useActionOptions } from 'src/hooks/action-options';
import { useAllBlockstarActions } from 'src/hooks/blockstar-action';
import {
  IBlockstarActionControl,
  useBlockstarActionControlContext,
} from 'src/hooks/blockstar-action-control';
import { useBlockstars } from 'src/hooks/blockstars';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import analytics from 'src/utils/analytics';
import {
  canRunAction,
  getLocalStorage,
  setLocalStorage,
  StorageType,
} from 'src/utils/utils';
import SelectAction from '.';
import BandActionSelectFooter from './contents/band-action-select-footer';
import BlockstarSkillDropdown from './contents/blockstar-skill-dropdown';
import DurationDropdown from './contents/duration-dropdown';
import LocationDropdown from './contents/location-dropdown';
import { IActionSkillsData } from './contents/skill-dropdown';
import { TabButton } from './style';

const BlockstarActionSelect = () => {
  const { publicKey, signTransaction } = useWallet();
  const { connection } = useConnection();

  const theme = useTheme() as ThemeType;
  const {
    openActionSelectDialog,
    setOpenActionSelectDialog,
    setOpenActionProgressDialog,
    actionButtonLoading,
    setActionButtonLoading,
    actionType,
    setActionType,
    blockstar,
  } = useBlockstarActionControlContext() as IBlockstarActionControl;

  const { locations, durations, mutateActionOptions } =
    useActionOptions(actionType);
  const { blockstars } = useBlockstars();
  const { mutate } = useAllBlockstarActions(
    blockstars?.map((b) => b.number) ?? [],
  );
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);

  const [selectedSkill, setSelectedSkill] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(-1);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [estimate, setEstimate] = useState<
    CalculateRewardResponse | CalculateRewardBuskingResponse | undefined
  >();

  useEffect(() => {
    if (!openActionSelectDialog) {
      return;
    }

    const defaultOptions = getLocalStorage(
      StorageType.ACTION_OPTIONS,
      blockstar?.number ?? 0,
    );

    if (defaultOptions) {
      const options = JSON.parse(defaultOptions) as ActionOptions;

      const skillsIdx = skills.findIndex(
        (s) => s.title === options.actionSkillType,
      );
      setSelectedSkill(skillsIdx >= 0 ? skillsIdx : 0);

      const locationIdx = locations.findIndex(
        (l) => l.id === options.location.id,
      );
      if (locationIdx >= 0) {
        setSelectedLocation(options.location.id);
      } else {
        setSelectedLocation(locations[0].id);
      }

      const durationIdx = durations.findIndex((d) => d === options.durationMs);
      if (durationIdx > 0) {
        setSelectedDuration(durationIdx);
      }
    } else {
      setSelectedSkill(0);
      setSelectedLocation(locations[0].id);
      setSelectedDuration(0);
    }

    let screen = '';
    if (actionType === BlockstarActionType.Practicing) {
      screen = 'practice';
    } else if (actionType === BlockstarActionType.Busking) {
      screen = 'busk';
    }
    analytics.logEvent('ViewScreenActionDialog', {
      groupType: 'individual',
      action: actionType,
      screen,
    });
  }, [openActionSelectDialog, actionType]);

  useEffect(() => {
    const onEstimate = async () => {
      setEstimate(undefined);
      const locationToRequest = locations.find(
        (l) => l.id === selectedLocation,
      );
      if (!locationToRequest) {
        setSelectedLocation(locations[0].id);
        return;
      }
      const options = {
        walletId: publicKey?.toString(),
        actionType,
        blockstarId: blockstar?.number,
        actionSkillType: skills[getSelectedSkill()].title,
        location: locations.find((l) => l.id === selectedLocation),
        durationMs: durations[selectedDuration],
      };

      try {
        const result: CalculateRewardResponse | CalculateRewardBuskingResponse =
          await rest.post(
            `${process.env.WORKER_URL}/${ActionRequests.CalculateReward}`,
            options,
          );

        setEstimate(result);
      } catch (e) {
        toast.error(`Error while calculating.`);
      }
    };

    if (!openActionSelectDialog || selectedLocation < 0) {
      return;
    }

    if (locations && durations && skills.length > 0) {
      onEstimate();
    }
  }, [selectedSkill, selectedLocation, selectedDuration]);

  const skills: IActionSkillsData[] = [];
  if (actionType === BlockstarActionType.Practicing) {
    blockstar?.skills
      .filter((s) => s.type === SkillType.Instrumental)
      .forEach((s) => {
        skills.push({
          type: SkillEntryType.MUSICAL,
          title: s.name,
          currentExp: s.currentExp ?? 0,
          targetExp: s.targetExp ?? 1,
          maxed: s.maxed ?? !!s.maxed,
        });
      });
    blockstar?.skills
      .filter((s) => s.type === SkillType.General)
      .forEach((s) => {
        const type = s.name.split(' ')[0].toUpperCase();
        if (type === 'SOCIAL' || type === 'WRITING') {
          skills.push({
            type: SkillEntryType[type as keyof typeof SkillEntryType],
            title: s.name,
            currentExp: s.currentExp ?? 0,
            targetExp: s.targetExp ?? 1,
            maxed: !!s.maxed,
          });
        }
      });
  } else if (actionType === BlockstarActionType.Busking) {
    const buskingSkill = blockstar?.skills[0];
    skills.push({
      type: SkillEntryType.MUSICAL,
      title: buskingSkill?.name ?? '',
      currentExp: buskingSkill?.currentExp ?? 0,
      targetExp: buskingSkill?.targetExp ?? 1,
      maxed: buskingSkill?.maxed ?? false,
    });
  }

  if (
    !blockstar ||
    selectedLocation === undefined ||
    selectedDuration === undefined
  ) {
    return <div />;
  }

  const getSelectedSkill = () => {
    switch (actionType) {
      case BlockstarActionType.Busking:
        return 0;
      default:
        return selectedSkill;
    }
  };

  const onTabClick = (type: BlockstarActionType) => {
    analytics.logEvent('TabClickActionDialog', {
      from: actionType,
      to: type,
    });

    setActionType(type);
    mutateActionOptions();
  };

  let selectedLocationId = selectedLocation;
  let location = locations.find((l) => l.id === selectedLocation);
  if (!location) {
    location = locations[0];
    selectedLocationId = location.id;
  }
  const rental = (durations[selectedDuration] * location.dailyCost) / 86400000;
  const locationCost = location.deposit + rental;
  const durationCost =
    (durations[selectedDuration] * (blockstar.wage.salary ?? 0)) / 86400000;

  const onStartPressed = async () => {
    if (!publicKey || !signTransaction) {
      const notReady = 'Tried to start band action with no wallet ready...';
      console.error(notReady);
      toast.error(notReady);
      return;
    }

    const actionOptions: ActionOptions = {
      actionType,
      actionSkillType: skills[getSelectedSkill()].title,
      durationMs: durations![selectedDuration],
      location: location!,
    };
    setLocalStorage(
      StorageType.ACTION_OPTIONS,
      blockstar.number ?? 0,
      JSON.stringify(actionOptions),
    );

    setActionButtonLoading(true);
    try {
      const newAction = await onStartAction(
        publicKey,
        blockstar,
        connection,
        rolBalance,
        signTransaction!,
        () => {},
        actionOptions,
      );
      if (!newAction) {
        setActionButtonLoading(false);
        return;
      }
      analytics.logEvent('ClickActionStart', {
        blockstarId: blockstar.number,
        action: actionType,
        skill: skills[getSelectedSkill()].title,
        location: location!.name,
        duration: durations[selectedDuration] / 60000,
        cost: locationCost + durationCost,
      });
      mutate();
      mutateBalance();
      setOpenActionProgressDialog(true);
      setActionButtonLoading(false);
    } catch (e: any) {
      setActionButtonLoading(false);
      let message = e.message ?? `Error while preparing the action.`;
      if (e.message === 'User rejected the request.') {
        message = 'Transaction not completed.';
      }
      toast.error(message);
    }
  };

  // Header
  const header = (
    <BlockstarInfo
      height={88}
      blockstar={blockstar}
      padding='0px 0px'
      margin='0px'
    />
  );

  // Tab Buttons
  const practiceTabButton = (
    <TabButton
      key={BlockstarActionType.Practicing}
      actionType={BlockstarActionType.Practicing}
      selectedActionType={actionType}
      variant='text'
      onClick={() => onTabClick(BlockstarActionType.Practicing)}>
      Practice
    </TabButton>
  );
  const buskTabButton = (
    <TabButton
      key={BlockstarActionType.Busking}
      actionType={BlockstarActionType.Busking}
      selectedActionType={actionType}
      variant='text'
      disabled={!canRunAction(blockstar.number)}
      onClick={() => onTabClick(BlockstarActionType.Busking)}>
      Busk
    </TabButton>
  );

  // contents
  const skillDropdown = (
    <BlockstarSkillDropdown
      key='skill'
      disabled={actionButtonLoading}
      skills={skills}
      selectedSkill={getSelectedSkill()}
      actionType={actionType}
      onSkillSelected={setSelectedSkill}
    />
  );
  const locationDropdown = (
    <LocationDropdown
      key='location'
      disabled={actionButtonLoading}
      error={false}
      locations={locations}
      selectedLocationId={selectedLocationId}
      duration={durations[selectedDuration]}
      durationCost={durationCost}
      onLocationSelected={(idx: number) => {
        setSelectedLocation(locations[idx].id);
      }}
    />
  );
  const durationDropdown = (
    <DurationDropdown
      key='duration'
      disabled={actionButtonLoading}
      error={false}
      durations={durations}
      selectedDurationIndex={selectedDuration}
      locationCost={locationCost}
      salary={blockstar.wage.salary}
      onDurationSelected={setSelectedDuration}
    />
  );

  const totalEarns = !estimate
    ? 0
    : actionType === BlockstarActionType.Practicing
    ? Math.floor(estimate.skills[0].gainedExp)
    : (estimate as CalculateRewardBuskingResponse).rolToEarn
    ? (estimate as CalculateRewardBuskingResponse).rolToEarn
    : 0;

  // footer
  const footer = (
    <BandActionSelectFooter
      data={{
        skillType: skills[getSelectedSkill()].type,
        spend: {
          title: 'Salary',
          total: `${(locationCost + durationCost).toLocaleString()} $ROL`,
          salary: durationCost,
          deposit: location.deposit,
          rental,
        },
        earn: {
          title: skills[getSelectedSkill()].type,
          total: `${totalEarns?.toLocaleString()} ${
            actionType === BlockstarActionType.Practicing ? 'XP' : '$ROL'
          }`,
        },
      }}
    />
  );

  const startButtonDisabled =
    rolBalance < durationCost + locationCost || totalEarns === 0;

  return (
    <SelectAction
      header={header}
      tabButtons={[practiceTabButton, buskTabButton]}
      contents={[skillDropdown, locationDropdown, durationDropdown]}
      footer={footer}
      disableStartButton={startButtonDisabled}
      onActionSelected={onStartPressed}
      open={openActionSelectDialog}
      actionButtonLoading={actionButtonLoading}
      onClose={() => {
        setOpenActionSelectDialog(false);
      }}
    />
  );
};

export default BlockstarActionSelect;
