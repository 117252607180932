import { styled } from '@mui/material/styles';
import { Bar } from '@visx/shape';
import bgImage from '../../../assets/images/bg_rol.png';

export const Container = styled('div')`
  background-image: url(${bgImage});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const InfoPriceTag = styled('h4')`
  text-align: left;
  margin-bottom: 10px;
`;

export const InfoPrice = styled('h2')`
  text-align: left;
  color: ${(props) => props.theme.colors.text.musicalAqua};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RolChartContainer = styled('div')`
  width: 600px;
  background-color: rgba(255, 255, 255, 0.5);
  height: 400px;
  border: none;
  border-radius: 15px;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const VisxBar = styled(Bar)`
  border: none;
  border-radius: 15px 15px 15px 15px;
`;

export const LineChartSvg = styled('svg')`
  position: absolute;
`;

export const TableStyles = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  table {
    border-spacing: 0;
    width: 600px;
    thead {
      color: ${(props) => props.theme.colors.ui.primaryPurple};
    }
    tr,
    th,
    td {
      margin: 0;
      padding: 0.5rem;
    }
  }
`;

export const Activity = styled('a')`
  text-decoration: none;

  &:link {
    color: ${(props) => props.theme.colors.positiveGraph};
  }
`;

export const MarketplaceButton = styled('button')`
  width: 150px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple};
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  :hover {
    cursor: pointer;
  }
`;
