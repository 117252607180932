import { Button, useTheme } from '@mui/material';
import { ThemeType } from '@shared-data';
import React, { useEffect, useState } from 'react';
import Countdown, {
  CountdownRenderProps,
  CountdownTimeDelta,
} from 'react-countdown';
import { calculateProgress } from 'src/utils/utils';
import { StatusIcon } from '../icons';
import { ProgressBar } from '../progress-bar';
import {
  ActionStatusContainer,
  ActionStatusSimpleContainer,
  CompleteText,
} from './style';

const countdownRenderer = ({
  completed,
  days,
  hours,
  minutes,
  seconds,
  total,
}: CountdownRenderProps) => {
  return ``;
};

export const ActionStatusView = (props: {
  timeLeft: number;
  durationMs: number;
  title: string;
  style?: React.CSSProperties;
  simple?: boolean;
  onViewClick?: () => void;
  onCounterTick?: (timeDelta: CountdownTimeDelta) => void;
  onCountDownComplete?: () => void;
}) => {
  const theme = useTheme() as ThemeType;

  const [endDate, setEndDate] = useState(Date.now() + props.timeLeft!);
  const [timeLeft, setTimeLeft] = useState<number | undefined>();
  const [progress, setProgress] = useState(
    calculateProgress(
      props.durationMs! - (timeLeft ?? props.timeLeft!),
      props.durationMs!,
      true,
    ),
  );

  useEffect(() => {
    setEndDate(Date.now() + props.timeLeft!);
  }, [props.timeLeft]);

  const onCounterTick = (timeDelta: CountdownTimeDelta) => {
    setTimeLeft(timeDelta.total);
    setProgress(
      calculateProgress(
        props.durationMs! - (timeLeft ?? props.timeLeft!),
        props.durationMs!,
        true,
      ),
    );

    if (props.onCounterTick) {
      props.onCounterTick(timeDelta);
    }
  };
  const onCountDownComplete = () => {
    setTimeLeft(0);
    setProgress(100);
    if (props.onCountDownComplete) {
      props.onCountDownComplete();
    }
  };

  const remaining = (
    <Countdown
      date={endDate}
      renderer={countdownRenderer}
      onTick={onCounterTick}
      onComplete={onCountDownComplete}
    />
  );

  if (props.simple) {
    const completed = progress === 100;
    return (
      <ActionStatusSimpleContainer onClick={props.onViewClick}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '98%',
            height: '36px',
          }}>
          <StatusIcon completed={completed} />
          {completed ? (
            <CompleteText>Completed</CompleteText>
          ) : (
            <ProgressBar
              currentLevel={progress}
              color={theme.colors.ui.specialLime}
            />
          )}
        </div>
        {remaining}
      </ActionStatusSimpleContainer>
    );
  } else {
    return (
      <ActionStatusContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <StatusIcon completed={progress === 100} />
            <div style={{ margin: 4 }} />
            {props.title}
          </div>
          <div style={{ display: 'flex', fontSize: 12, alignItems: 'center' }}>
            <Button
              variant='text'
              style={{ height: 20, fontSize: 12 }}
              onClick={props.onViewClick}>
              View
            </Button>
          </div>
        </div>
        <div style={{ height: 8, marginTop: 8, width: '90%' }}>
          <ProgressBar
            currentLevel={progress}
            color={theme.colors.ui.specialLime}
          />
        </div>
        {remaining}
      </ActionStatusContainer>
    );
  }
};
