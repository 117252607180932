import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Dialog, IconButton, useTheme } from '@mui/material';
import { ThemeType } from '@shared-data';
import React, { useState } from 'react';
import { DividerHorizontal } from 'src/styles/global-style';
import analytics from 'src/utils/analytics';
import {
  CloseButtonContainer,
  Container,
  ContentsContainer,
  FooterContainer,
  HeaderContainer,
  TabsContainer,
} from './style';

const SelectAction = (props: {
  header: JSX.Element;
  tabButtons: JSX.Element[];
  contents: JSX.Element[];
  footer: JSX.Element;
  disableStartButton: boolean;
  onActionSelected: () => void;
  open: boolean;
  actionButtonLoading: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme() as ThemeType;
  const [startLoading, setStartLoading] = useState(false);

  const onClose = () => {
    analytics.logEvent('ClickActionClose');
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={onClose}>
      <Container>
        <CloseButtonContainer>
          <IconButton color={'inherit'} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </CloseButtonContainer>
        <HeaderContainer>{props.header}</HeaderContainer>
        <DividerHorizontal
          style={{
            marginTop: '8px',
            marginBottom: '0px',
            opacity: '10%',
            color: theme.colors.ui.cellGray,
          }}
        />
        <TabsContainer>{props.tabButtons}</TabsContainer>
        <ContentsContainer>{props.contents}</ContentsContainer>
        <FooterContainer>
          <div style={{ flex: 4, height: '100%' }}>{props.footer}</div>
          <LoadingButton
            loading={props.actionButtonLoading}
            disableElevation
            disabled={props.disableStartButton}
            variant={'contained'}
            onClick={props.onActionSelected}
            style={{
              flex: 1,
              height: 60,
              borderRadius: 38,
            }}>
            Start
          </LoadingButton>
        </FooterContainer>
      </Container>
    </Dialog>
  );
};

export default SelectAction;
