import { styled } from '@mui/material/styles';
import bgImage from '../../assets/images/bg_image.jpg';
import logo from '../../assets/images/logo-transparent.png';
import { px2vw } from '../../utils/utils';

export const MainContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BGImage = styled('div')`
  position: absolute;
  background: url(${bgImage});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;

  z-index: -1;
`;

export const BGImageMobile = styled('div')`
  position: absolute;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: lighten;
  filter: blur(12px);
  -webkit-filter: blur(12px);
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  min-width: 500px;
  min-height: 500px;

  @media (max-width: 768px) {
    width: 700px;
    height: 700px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  overflow: hidden;
`;

export const LogoButton = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;

  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  width: 400px;
  height: 80px;
`;

export const Title = styled('h1')`
  font-size: ${px2vw(80)};
  margin-top: 5%;
  @media (min-width: 768px) {
    font-size: ${px2vw(40)};
  }

  @media (min-width: 1024px) {
    font-size: ${px2vw(60)};
  }
`;

export const ButtonContainer = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 30%;
  left: 0;
  right: 0;
  margin: auto;
`;

/*
<{ bgColor?: string }>
  background-color: ${(props) => props.bgColor};
  @media (min-width: 768px) {
    width: ${px2vw(320, 768)};
    min-height: ${px2vw(200, 768)};
    height: 100%;
  }

  @media (min-width: 1024px) {
    width: ${px2vw(500)};
    min-height: ${px2vw(300)};
    height: 100%;
  }
*/
