import { ConfirmedSignatureInfo, Connection, PublicKey } from '@solana/web3.js';
import useSWR from 'swr';
import {
  findAssociatedTokenAddress,
  getParsedTransactions,
  getSignaturesForAddress,
} from '../../actions/wallet';

export const useParsedRaffleTransactions = (
  connection: Connection,
  publicKey?: PublicKey | null,
) => {
  const { data, error, mutate } = useSWR(
    publicKey ? ['raffleSignatures', publicKey.toString()] : null,
    async () => {
      const tokenAddress = await findAssociatedTokenAddress(
        publicKey!,
        new PublicKey(process.env.ROL_MINT_ADDRESS!),
      );
      const confirmedSignatures = await getSignaturesForAddress(
        connection,
        tokenAddress,
      );
      return getRaffleInfo(connection, confirmedSignatures);
    },
  );
  return {
    parsedRaffleTransactions: data,
    mutateParsedRaffleTransactions: mutate,
  };
};

const getRaffleInfo = async (
  connection: Connection,
  confirmedSignatures: ConfirmedSignatureInfo[] | null,
) => {
  if (confirmedSignatures) {
    const raffleSignatures: string[] = [];
    confirmedSignatures?.forEach((element) => {
      if (element.memo) {
        try {
          const memo = JSON.parse(
            element.memo.substr(element.memo.indexOf('{'), element.memo.length),
          );
          if (memo.type === 'raffle') {
            raffleSignatures.push(element.signature);
          }
        } catch (e: any) {
          console.warn('Processed non-JSON ROL txn:', e);
        }
      }
    });

    return getParsedTransactions(connection, raffleSignatures);
  }
};
