import { useTheme } from '@mui/material/styles';
import {
  Band,
  BandMemberSkill,
  Blockstar,
  BlockstarSkillType,
  SubgenreInfo,
  ThemeType,
} from '@shared-data';
import { Subgenre } from '@shared-generated/generated-genres';
import React, { useEffect, useState } from 'react';
import { PerformanceSkillFamily } from 'shared/generated/generated-instruments';
import { DropdownComponentData } from 'src/components/library/dropdown';
import { skillSort } from 'src/utils/utils';
import { BandPreviewData, BandSlotSkill } from '.';
import SelectionDropDownItem, {
  ClearComponent,
  NoneAvailable,
  SelectBlockstar,
  SubHeader,
} from './band-selection-dropdown-item';
import {
  SelectionDropdown,
  SelectionDropdownContainer,
  SelectionDropdownTitle,
} from './style';

interface DropdownComponentDataForBand extends DropdownComponentData {
  blockstarId?: number;
  skillInfo?: BandMemberSkill;
}

export interface DropdownDataForBand {
  blockstarId?: number;
  skillInfo?: BandMemberSkill;
}

interface SkillInfo {
  blockstar: Blockstar;
  skill?: BandMemberSkill;
}

const BandSelectionDropdown = (props: {
  blockstars?: Blockstar[];
  bands?: Band[];
  subGenreId: Subgenre;
  subGenreInfo: SubgenreInfo;
  previewData?: BandPreviewData;
  memberIds: (number | undefined)[];
  selectedMemberInfo?: BandSlotSkill;
  skill: BandMemberSkill;
  skillIndex: number;
  allSlots: (BandSlotSkill | undefined)[];
  isMusical?: boolean;
  onSelected: (
    componentInfo: DropdownDataForBand,
    skillIndex: number,
    isMusical?: boolean,
    isRemoving?: boolean,
  ) => void;
}) => {
  if (props.isMusical && !props.skill.familyName) {
    console.error(`Instrument skill [${props.skill.name}] must have family`);
    return <div />;
  }

  const { blockstars } = props;
  const theme = useTheme() as ThemeType;

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedItem, setSelectedItem] =
    useState<DropdownComponentDataForBand>();
  const [components, setComponents] =
    useState<DropdownComponentDataForBand[]>();

  useEffect(() => {
    if (!blockstars || blockstars.length <= 0) {
      console.error('You need to have at least one blockstar');
      return;
    }

    const components = prepComponent();
    setComponents(components);
    if (components) {
      if (props.selectedMemberInfo) {
        const selectedIndex = components.findIndex(
          (component) =>
            component.blockstarId === props.selectedMemberInfo?.blockstarId &&
            component.skillInfo?.name === props.selectedMemberInfo?.skill.name,
        );

        if (props.selectedMemberInfo?.blockstarId !== undefined) {
          setSelectedIndex(selectedIndex);
        } else {
          setSelectedIndex(-1);
        }
      } else {
        setSelectedIndex(-1);
      }

      if (selectedItem) {
        if (
          props.memberIds.findIndex(
            (memberId) => memberId === selectedItem.blockstarId,
          ) < 0
        ) {
          setSelectedIndex(-1);
          setSelectedItem(undefined);
        }
      }
    }
  }, [blockstars, props.memberIds, props.selectedMemberInfo, props.allSlots]);

  const prepComponent = () => {
    if (!blockstars) {
      return;
    }

    const members = props.memberIds.map(
      (memberId) =>
        memberId != undefined &&
        blockstars.find((blockstar) => blockstar.number === memberId),
    ) as Blockstar[];

    // in ANOTHER band
    let blockstarsInOtherBands: SkillInfo[] = [];
    props.bands?.forEach((band) => {
      if (band.subgenre !== props.subGenreId) {
        band.memberIds.forEach((memberId) => {
          if (
            blockstarsInOtherBands.findIndex(
              (bsInOtherBand) => bsInOtherBand.blockstar.number === memberId,
            ) < 0
          ) {
            const member = blockstars.find(
              (blockstar) => blockstar.number === memberId,
            );
            const memberSkill = member?.skills.find(
              (skill) =>
                skill.name === props.skill.name ||
                (skill.familyName &&
                  skill.familyName === props.skill.familyName),
            );
            blockstarsInOtherBands.push({
              blockstar: blockstars.find(
                (blockstar) => blockstar.number === memberId,
              )!,
              skill: {
                name: memberSkill?.name ?? props.skill.name,
                familyName: props.skill.familyName,
              },
            });
          }
        });

        if (props.isMusical) {
          blockstarsInOtherBands = blockstarsInOtherBands.filter(
            (bsInOtherBand) => {
              const skillIndex = bsInOtherBand.blockstar.skills.findIndex(
                (skill) =>
                  skill.name === props.skill.name ||
                  skill.familyName === props.skill.familyName,
              );
              return skillIndex >= 0;
            },
          );
        }
      }
    });

    // Get blockstars in band
    // Skill
    const blockstarsWithSameSkillInBand = findBlockstarsWithSkill(
      members,
      new Set([
        ...blockstarsInOtherBands.map((member) => {
          return member.blockstar;
        }),
      ]),
      props.skill,
    );

    // Family
    let blockstarsWithSameFamilyInBand: SkillInfo[] | undefined;
    if (props.isMusical) {
      blockstarsWithSameFamilyInBand = findBlockstarsWithFamily(
        members,
        new Set([
          ...blockstarsInOtherBands.map((member) => {
            return member.blockstar;
          }),
        ]),
        props.skill,
      );
    }

    // Get blockstars NOT in band
    // Skill
    const membersSet = new Set([
      ...members,
      ...blockstarsInOtherBands.map((member) => {
        return member.blockstar;
      }),
    ]);
    const blockstarsWithSameSkill = findBlockstarsWithSkill(
      blockstars,
      membersSet,
      props.skill,
    );

    // Family
    let blockstarsWithSameFamily: SkillInfo[] | undefined;
    if (props.isMusical) {
      blockstarsWithSameFamily = findBlockstarsWithFamily(
        blockstars,
        membersSet,
        props.skill,
      );
    }

    const components = buildComponents(
      blockstarsWithSameSkillInBand,
      blockstarsWithSameSkill,
      blockstarsWithSameFamilyInBand,
      blockstarsWithSameFamily,
      blockstarsInOtherBands,
    );
    return components;
  };

  // build all the dropdown components based on filtered blockstars.
  const buildComponents = (
    blockstarsWithSameSkillInBand: SkillInfo[],
    blockstarsWithSameSkill: SkillInfo[],
    blockstarsWithSameFamilyInBand?: SkillInfo[],
    blockstarsWithSameFamily?: SkillInfo[],
    blockstarsInOtherBands?: SkillInfo[],
  ) => {
    const result: DropdownComponentDataForBand[] = [
      { component: <ClearComponent /> },
    ];
    const skillsInBand = blockstarsWithSameSkillInBand.map(
      (skillInfo, index) => {
        const disabled =
          props.allSlots.find((blockstarInSlot) => {
            return (
              blockstarInSlot?.blockstarId === skillInfo.blockstar.number &&
              skillInfo.skill?.name === blockstarInSlot.skill.name
            );
          }) !== undefined;
        return {
          component: dropdownItem(skillInfo),
          blockstarId: skillInfo.blockstar.number,
          disabled: disabled,
          skillInfo: props.skill,
        };
      },
    );
    result.push.apply(
      result,
      addComponents(result, `${props.skill.name} in the band`, skillsInBand),
    );

    if (blockstarsWithSameFamilyInBand) {
      const familyInBand = blockstarsWithSameFamilyInBand.map(
        (skillInfo, index) => {
          const disabled =
            props.allSlots.find((blockstarInSlot) => {
              return (
                blockstarInSlot?.blockstarId === skillInfo.blockstar.number &&
                skillInfo.skill?.name === blockstarInSlot.skill.name
              );
            }) !== undefined;
          return {
            component: dropdownItem(skillInfo),
            blockstarId: skillInfo.blockstar.number,
            disabled: disabled,
            skillInfo: skillInfo.skill,
          };
        },
      );
      result.push.apply(
        result,
        addComponents(
          result,
          `${props.skill.familyName} family in the band`,
          familyInBand,
        ),
      );
    }

    const isFull =
      props.memberIds.length === props.subGenreInfo.requirements.maxMembers;
    const skills = blockstarsWithSameSkill.map((skillInfo, index) => {
      return {
        component: dropdownItem(skillInfo),
        blockstarId: skillInfo.blockstar.number,
        disabled: isFull,
        skillInfo: skillInfo.skill,
      };
    });
    result.push.apply(
      result,
      addComponents(result, `All ${props.skill.name}`, skills, isFull),
    );

    if (blockstarsWithSameFamily) {
      const family = blockstarsWithSameFamily.map((skillInfo, index) => {
        return {
          component: dropdownItem(skillInfo),
          blockstarId: skillInfo.blockstar.number,
          disabled: isFull,
          skillInfo: skillInfo.skill,
        };
      });
      result.push.apply(
        result,
        addComponents(
          result,
          `All ${props.skill.familyName} family`,
          family,
          isFull,
        ),
      );
    }

    if (blockstarsInOtherBands) {
      const inOtherBands = blockstarsInOtherBands.map((skillInfo, index) => {
        return {
          component: dropdownItem(skillInfo, true),
          blockstarId: skillInfo.blockstar.number,
          disabled: true,
          skillInfo: skillInfo.skill,
        };
      });
      result.push.apply(
        result,
        addComponents(result, 'In another band', inOtherBands, false),
      );
    }
    return result.filter((element) => {
      return element !== undefined;
    });
  };

  const dropdownItem = (skillInfo: SkillInfo, considerFamily?: boolean) => {
    const targetSkill = skillInfo.blockstar.skills.find(
      (skill) =>
        skill.name === skillInfo.skill?.name ||
        (considerFamily &&
          skillInfo.skill?.familyName &&
          skill.familyName === skillInfo.skill?.familyName),
    );
    if (!targetSkill) {
      if (props.skill.familyName) {
        console.error(
          `This blockstar doesn't have skill with the family [${props.skill.familyName}].`,
        );
      } else {
        console.error(
          `This blockstar doesn't have skill [${props.skill.name}].`,
        );
      }
      return <div></div>;
    }

    const previewInfo = props.previewData
      ? props.previewData[props.skill.name].find(
          (info) =>
            info.blockstarId === skillInfo.blockstar.number &&
            skillInfo.skill?.name === info.skillName,
        )
      : undefined;
    return (
      <SelectionDropDownItem
        blockstar={skillInfo.blockstar}
        targetSkill={targetSkill}
        previewInfo={previewInfo}
      />
    );
  };

  if (!components) {
    return <div />;
  }

  let currentIndex = selectedIndex;
  if (selectedItem) {
    currentIndex = components.findIndex(
      (component) =>
        component.blockstarId === selectedItem.blockstarId &&
        component.skillInfo?.name === selectedItem.skillInfo?.name,
    );
  }

  return (
    <SelectionDropdownContainer>
      <SelectionDropdownTitle> {props.skill.name} </SelectionDropdownTitle>
      <SelectionDropdown
        style={{
          minWidth: '400px',
          width: '100%',
          height: '50px',
          backgroundColor: props.isMusical
            ? theme.colors.ui.cellMusicalCyan
            : theme.colors.ui.cellOtherSkills,
        }}
        defaultSelected={currentIndex}
        value={currentIndex}
        arrowColor={
          props.isMusical
            ? theme.colors.ui.musicalCyan
            : theme.colors.ui.primaryPurple
        }
        placeHolder={
          <SelectBlockstar
            skill={
              (props.skill.familyName ?? props.skill.name) as
                | PerformanceSkillFamily
                | BlockstarSkillType
            }
            isMusical={props.isMusical}
          />
        }
        components={components}
        onChange={(i) => {
          const index = i === 0 ? -2 : i;
          setSelectedIndex(index);
          components[index];

          const originalComponent = components[selectedIndex];
          const component = components[index];
          if (index < 0 && originalComponent?.blockstarId) {
            props.onSelected(
              originalComponent,
              props.skillIndex,
              props.isMusical,
              true,
            );
            setSelectedItem(undefined);
          } else if (component && component.blockstarId !== undefined) {
            setSelectedItem(component);
            props.onSelected(component, props.skillIndex, props.isMusical);
          }
        }}
      />
    </SelectionDropdownContainer>
  );
};

export default BandSelectionDropdown;

const findBlockstarsWithSkill = (
  sourceBlockstars: Blockstar[],
  removableSet: Set<Blockstar>,
  targetSkill: BandMemberSkill,
) => {
  const result: SkillInfo[] = [];
  for (const blockstar of sourceBlockstars) {
    if (!removableSet.has(blockstar)) {
      const skill = blockstar.skills.find(
        (skill) => skill.name === targetSkill.name,
      );
      if (skill) {
        result.push({
          blockstar: blockstar,
          skill: targetSkill,
        });
      }
    }
  }
  return result.sort((a, b) =>
    skillSort(a.blockstar, b.blockstar, targetSkill.name),
  );
};

const findBlockstarsWithFamily = (
  sourceBlockstars: Blockstar[],
  removableSet: Set<Blockstar>,
  targetSkill: BandMemberSkill,
) => {
  const result: SkillInfo[] = [];
  for (const blockstar of sourceBlockstars) {
    if (!removableSet.has(blockstar)) {
      const skills = blockstar.skills.filter(
        (skill) =>
          skill.name !== targetSkill.name &&
          skill.familyName === targetSkill.familyName,
      );
      for (const skill of skills) {
        result.push({
          blockstar: blockstar,
          skill: {
            name: skill.name,
            familyName: skill.familyName as PerformanceSkillFamily,
          },
        });
      }
    }
  }
  return result.sort((a, b) =>
    skillSort(a.blockstar, b.blockstar, targetSkill.familyName, true),
  );
};

const addComponents = (
  list: DropdownComponentDataForBand[],
  headerTitle: string,
  skillsToApply: {
    component: JSX.Element;
    blockstarId: number;
    disabled: boolean;
    skillInfo?: BandMemberSkill;
  }[],
  memberMaxed?: boolean,
) => {
  const result = [
    {
      component: (
        <SubHeader
          title={headerTitle}
          errorMessage={memberMaxed ? 'Band at max capacity' : undefined}
        />
      ),
      isSubHeader: true,
    },
    ...(skillsToApply.length > 0
      ? skillsToApply
      : [
          {
            component: <NoneAvailable />,
            disabled: true,
            disableOpacityChange: true,
          },
        ]),
  ];
  return result;
};
