import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import React from 'react';

const HoverComponent = (props: {
  hoverComponent: JSX.Element;
  tooltipComponent: JSX.Element;
}) => {
  const { tooltipOpen, tooltipTop, tooltipLeft, hideTooltip, showTooltip } =
    useTooltip();
  const { TooltipInPortal } = useTooltipInPortal();
  return (
    <div
      onMouseLeave={() => hideTooltip()}
      onMouseEnter={(event) => {
        showTooltip({
          tooltipTop: event.clientY - 40,
          tooltipLeft: event.clientX,
        });
      }}>
      {props.hoverComponent}
      {tooltipOpen && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 8,
            padding: 16,
            backgroundColor: 'white',
            boxShadow: '0px 0px 14px rgba(0,0,0,0.1)',
            alignItems: 'flex-start',
            fontSize: 12,
            width: 227,
            zIndex: 10,
          }}>
          <div className='tooltip-title'>{props.tooltipComponent}</div>
        </TooltipInPortal>
      )}
    </div>
  );
};

export default HoverComponent;
