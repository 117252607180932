import { PublicKey } from '@solana/web3.js';
import useSWR from 'swr';
import { findAssociatedTokenAddress } from '../../actions/wallet';

export const useRolTokenAccount = (publicKey: PublicKey | null) => {
  const { data, error } = useSWR(
    publicKey ? ['rolTokenAccount', publicKey.toString()] : null,
    () =>
      findAssociatedTokenAddress(
        publicKey!,
        new PublicKey(process.env.ROL_MINT_ADDRESS!),
      ),
  );
  return data;
};
