import { useTheme } from '@mui/material/styles';
import { ThemeType } from '@shared-data';
import { useWallet } from '@solana/wallet-adapter-react';
import { ParsedInstruction, ParsedTransactionWithMeta } from '@solana/web3.js';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import { curveLinear } from '@visx/curve';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { MarkerCircle } from '@visx/marker';
import { scaleBand, scaleLinear } from '@visx/scale';
import { BarRounded, LinePath } from '@visx/shape';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import { useRolTokenAccount } from '../../../hooks/wallet/rol-token-account';
import { numberFormatter } from '../../../utils/utils';

const chartMargin = {
  top: 20,
  left: 60,
};

interface FTDataPerDay {
  date: string;
  amount: number;
}

let tooltipTimeout: number | undefined;

// data accessors
const getXTotal = (d: FTDataPerDay) => d.date;
const getYTotal = (d: FTDataPerDay) => d.amount;

const RolChart = (props: {
  width: number;
  height: number;
  transactions: (ParsedTransactionWithMeta | null)[] | null;
  totalBalance: number | null;
}) => {
  const { width, height, totalBalance, transactions } = props;
  let chartData: (ParsedTransactionWithMeta | null)[] | null = Object.assign(
    [],
    transactions,
  );
  const {
    tooltipOpen,
    tooltipTop,
    tooltipLeft,
    hideTooltip,
    showTooltip,
    tooltipData,
  } = useTooltip();
  const { TooltipInPortal } = useTooltipInPortal();

  const [dates, setDate] = useState<string[]>([]);
  const [amounts, setAmount] = useState<number[]>([]);
  const [totalAmountDataPerDay, setTotalAmountDataPerDay] = useState<
    FTDataPerDay[]
  >([]);

  const [graphType, setGraphType] = useState<'none' | 'bar' | 'line'>('none');

  const theme = useTheme() as ThemeType;

  const { publicKey } = useWallet();
  const rolTokenAccount = useRolTokenAccount(publicKey);

  useEffect(() => {
    if (!rolTokenAccount) {
      return;
    }
    if (chartData) {
      chartData = chartData.filter((element) => {
        return element != null;
      });
      // Sort transaction data ascending, so the chart can be started from earlier date(left) to later date(right).
      chartData.sort((a, b) => {
        if (a?.blockTime && b?.blockTime) {
          return a.blockTime - b.blockTime;
        }
        return 0;
      });

      const dateList: string[] = [];
      const amountList: number[] = [];
      chartData.forEach((element) => {
        if (element && element.blockTime) {
          const blockTimeDate = new Date(element.blockTime * 1000);
          const day = blockTimeDate.toLocaleString('default', {
            day: '2-digit',
          });
          const month = blockTimeDate.toLocaleString('default', {
            month: 'short',
          });
          const dateString = `${day} ${month}`;
          let index = dateList.indexOf(dateString);
          if (index < 0) {
            dateList.push(dateString);
            index = dateList.length - 1;
          }

          let amount = 0;
          if (amountList.length > 0 && amountList.length > index) {
            amount = amountList[index];
          }

          element.transaction.message.instructions.forEach((element) => {
            const instruction = element as ParsedInstruction;
            if (instruction.program === 'spl-token') {
              const { source, tokenAmount } = instruction.parsed.info;
              if (tokenAmount) {
                if (source === rolTokenAccount?.toString()) {
                  amount -= tokenAmount.uiAmount;
                } else {
                  amount += tokenAmount.uiAmount;
                }
                if (amountList.length > index) {
                  amountList[index] = amount;
                } else {
                  amountList.push(amount);
                }
              }
            }
          });
        }
      });

      // Handle empty transaction days.
      const length = amountList.length;
      const lastDateString = dateList[dateList.length - 1];
      let date = new Date();
      const thisYear = date.toLocaleString('default', {
        year: 'numeric',
      });
      const lastDate = new Date(`${lastDateString} ${thisYear}`);
      if (length <= 7) {
        for (let i = 0; i < 7 - length; i++) {
          date = new Date();
          date.setDate(date.getDate() - (i + 1));
          const dateString = getDateString(date);
          // if inserting date is later than last transaction date, push.
          // if not, unshift.
          if (date.getTime() >= lastDate.getTime()) {
            date.setDate(date.getDate() + (i + 1));
            const dateString = getDateString(date);
            if (!dateList.includes(dateString)) {
              dateList.push(dateString);
              amountList.push(0);
            }
          } else {
            if (!dateList.includes(dateString)) {
              dateList.unshift(dateString);
              amountList.unshift(0);
            }
          }
        }
      }

      setDate(dateList);
      setAmount(amountList);

      const dateReversed = [...dateList].reverse();
      const amountReversed = [...amountList].reverse();

      if (totalBalance) {
        const amountDataPerDay: FTDataPerDay[] = [];
        dateReversed.forEach((element, index) => {
          let balance = totalBalance;
          if (amountDataPerDay.length > 0) {
            balance =
              amountDataPerDay[index - 1].amount - amountReversed[index - 1];
          }
          amountDataPerDay.push({
            date: element,
            amount: balance,
          });
        });

        amountDataPerDay.reverse();
        setTotalAmountDataPerDay(amountDataPerDay);
      }
    }
  }, [rolTokenAccount]);

  // bounds
  const xMax = width - chartMargin.left * 2;
  const yMax = height - chartMargin.top - 40;
  const yMin = Math.min(...amounts);

  // scales, memoize for performance
  const xScaleBar = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: dates,
        padding: 0.2,
      }),
    [dates, xMax],
  );

  const yScaleBar = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [yMin < 0 ? yMin : 0, Math.max(...amounts)],
        nice: true,
      }),
    [amounts, yMax],
  );

  const yMinLine = Math.min(
    ...totalAmountDataPerDay.map((data) => data.amount),
    totalBalance ?? 0,
  );
  const yScaleLine = scaleLinear<number>({
    range: [yMax, 0],
    domain: [
      Math.max(yMinLine - yMinLine / 10, 0),
      Math.max(
        ...totalAmountDataPerDay.map((data) => data.amount),
        totalBalance ?? 0,
      ) +
        yMinLine / 10,
    ],
    nice: true,
  });

  const marker = amounts.length > 1 ? 'url(#marker-circle)' : undefined;

  const yAxisScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [height - yScaleBar(yMin), 0],
        round: true,
        domain: [yMin < 0 ? yMin : 0, Math.max(...amounts)],
        nice: true,
      }),
    [amounts, height, yScaleBar],
  );

  const minY = yMax - yScaleBar(yMin);
  const tickFormatValue = (value: any) => numberFormatter(value);

  return (
    <div style={{ width: '100%', height: '100%', display: 'block' }}>
      <svg width={width} height={height}>
        <Group top={chartMargin.top} left={chartMargin.left}>
          <GridRows
            numTicks={5}
            scale={yScaleBar}
            width={xMax}
            height={yMax}
            stroke={theme.colors.text.primaryGray}
          />

          {dates.map((date, index) => {
            const amount = amounts[index];
            const barHeight =
              amount < 0
                ? yScaleBar(amount) - yScaleBar(0)
                : yScaleBar(0) - yScaleBar(amount);
            const barWidth = xScaleBar.bandwidth();
            const barX = xScaleBar(date);
            const barY = amount <= 0 ? yScaleBar(0) : yScaleBar(amount);

            return (
              <BarRounded
                key={date}
                x={barX ?? 0}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={
                  amount <= 0
                    ? theme.colors.legacy.negativeGraph
                    : theme.colors.legacy.positiveGraph
                }
                style={{
                  borderRadius: '15%',
                }}
                onMouseLeave={() => {
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip();
                    setGraphType('none');
                  }, 300);
                }}
                onMouseMove={(event) => {
                  if (tooltipTimeout) clearTimeout(tooltipTimeout);
                  setGraphType('bar');
                  const top = event.clientY - 40;
                  const left = event.clientX - 70;
                  showTooltip({
                    tooltipData: amount.toLocaleString(),
                    tooltipTop: top,
                    tooltipLeft: left,
                  });
                }}
                top={amount > 0}
                bottom={amount < 0}
                radius={6}
              />
            );
          })}

          <AxisBottom
            numTicks={dates.length}
            top={yMax + (minY < 0 ? Math.abs(minY * 2) + 10 : 0)}
            scale={xScaleBar}
            tickLabelProps={(value, index) => ({
              fill: 'black',
              fontSize: 12,
              textAnchor: 'middle',
              dy: 10,
              fontWeight: 'bold',
              width: 25,
            })}
          />
          <AxisLeft
            scale={yScaleBar}
            numTicks={5}
            top={0}
            tickLabelProps={(value, index) => ({
              fill:
                value <= 0
                  ? theme.colors.legacy.negativeGraph
                  : theme.colors.legacy.positiveGraph,
              fontSize: 12,
              textAnchor: 'end',
              verticalAnchor: 'middle',
            })}
            tickFormat={tickFormatValue}
          />
        </Group>
        <Group top={chartMargin.top} left={chartMargin.left}>
          <MarkerCircle id='marker-circle' fill='#333' size={1} refX={1} />
          <LinePath<FTDataPerDay>
            curve={curveLinear}
            data={totalAmountDataPerDay}
            x={(data) =>
              (xScaleBar(getXTotal(data)) ?? 0) + xScaleBar.bandwidth() / 2
            }
            y={(data) => yScaleLine(getYTotal(data)) ?? 0}
            stroke=' #2dabd4'
            strokeWidth={5}
            shapeRendering='geometricPrecision'
            markerMid={marker}
            markerStart={marker}
            markerEnd={marker}
            onMouseLeave={() => {
              tooltipTimeout = window.setTimeout(() => {
                setGraphType('none');
                hideTooltip();
              }, 300);
            }}
            onMouseMove={(event) => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              setGraphType('line');
              const { x } = localPoint(event) ?? { x: 0 };
              const index = Math.floor(x / xScaleBar.step()) - 1;
              if (index >= 0 && index < totalAmountDataPerDay.length) {
                const top = event.clientY - 40;
                const left = event.clientX - 70;
                showTooltip({
                  tooltipData:
                    totalAmountDataPerDay[index].amount.toLocaleString(),
                  tooltipTop: top,
                  tooltipLeft: left,
                });
              }
            }}
          />
          <AxisRight
            scale={yScaleLine}
            numTicks={5}
            top={0}
            left={xMax}
            hideZero
            tickLabelProps={(value, index) => ({
              fill: theme.colors.text.musicalAqua,
              fontSize: 12,
              fontWeight: 'bold',
              textAnchor: 'start',
              verticalAnchor: 'middle',
            })}
            tickFormat={tickFormatValue}
          />
        </Group>
      </svg>
      {tooltipOpen && tooltipData && graphType !== 'none' && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            borderWidth: 1,
            border: 'solid',
            borderColor: 'white',
            color: 'white',
            backgroundColor:
              graphType == 'bar'
                ? theme.colors.legacy.positiveGraph
                : theme.colors.text.musicalAqua,
          }}>
          <div className='tooltip-title'>
            <strong style={{ textAlign: 'center' }}>{tooltipData}</strong>
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
};

const getDateString = (date: Date) => {
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' });
  return `${day} ${month}`;
};
export default RolChart;
