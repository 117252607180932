import { useTheme } from '@mui/material/styles';
import {
  ActionStatus,
  Band,
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
  ThemeType,
} from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onFireButtonClicked } from 'src/actions/ngmi';
import NGMI from 'src/components/ngmi';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import {
  IBlockstarActionControl,
  useBlockstarActionControlContext,
} from 'src/hooks/blockstar-action-control';
import { useWindowSize } from 'src/hooks/window-size';
import { KeyedMutator } from 'swr/dist/types';
import { ActionOptions, onStartActions } from '../../../actions/action';
import { useActionOptions } from '../../../hooks/action-options';
import { useAllBlockstarActions } from '../../../hooks/blockstar-action';
import { useScrollPosition } from '../../../hooks/scroll-position';
import { useRolBalance } from '../../../hooks/wallet/rol-balance';
import analytics from '../../../utils/analytics';
import { getLocalStorage, StorageType } from '../../../utils/utils';
import { NFTCrateItem } from './nft-crate-item';
import { NFTListItem } from './nft-list-item';
import { NFTListGrid } from './style';

export enum NFTListLayout {
  GRID = 'grid',
  LIST = 'list',
}

interface IProps {
  blockstars?: Blockstar[];
  crates?: any[];
  bands?: Band[];
  // bandActions?: BandAction[];
  updateBlockstar: (id: number) => void;
}

type Order = 'asc' | 'desc';

export const NFTList = (props: IProps) => {
  const { blockstars, crates, bands } = props;
  const theme = useTheme() as ThemeType;
  const { publicKey, signTransaction, signAllTransactions } = useWallet();
  const { connection } = useConnection();
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);
  const { locations, durations } = useActionOptions();
  const { height } = useWindowSize();
  const navigate = useNavigate();
  const { actions, mutate } = useAllBlockstarActions(
    blockstars?.map((b) => b.number) ?? [],
  );
  const actionValues = Object.values(actions ?? {});

  const scrollPosition = useScrollPosition();
  const [ngmiOpen, setNGMIOpen] = useState(false);
  const [layout, setLayout] = useState<NFTListLayout>(() => {
    const savedLayout = getLocalStorage(StorageType.LIST_LAYOUT, 0);
    return savedLayout ? (savedLayout as NFTListLayout) : NFTListLayout.GRID;
  });
  const [repeatButtonLoading, setRepeatButtonLoading] = useState(false);
  // these two are used for controlling state of rows in list view
  const [rowsChecked, setRowsChecked] = useState<boolean[]>([]);
  const [buttonsExpanded, setButtonsExpanded] = useState<boolean[]>([]);

  const {
    blockstar: actionableBlockstar,
    setBlockstar,
    actionResult: blockstarActionResult,
  } = useBlockstarActionControlContext() as IBlockstarActionControl;

  const {
    setActionType: setBandActionType,
    band: actionableBand,
    setBand,
    actionResult: bandActionResult,
  } = useBandActionControlContext() as IBandActionControl;

  useEffect(() => {
    document.getElementById('scroll')?.scroll({ top: scrollPosition });
  }, []);

  useEffect(() => {
    if (!blockstars) {
      return;
    }

    // init states to false
    setRowsChecked(Array(blockstars.length).fill(false));
    setButtonsExpanded(Array(blockstars.length).fill(false));
  }, [blockstars]);

  useEffect(() => {
    if (bandActionResult || blockstarActionResult) {
      mutate();
    }
  }, [bandActionResult, blockstarActionResult]);

  const onFireClick = (blockstar: Blockstar) => {
    return () =>
      onFireButtonClicked(blockstar, setBlockstar, () => {
        setNGMIOpen(true);
      });
  };

  const checkAll = () => {
    const allChecked = rowsChecked.reduce((prev, curr) => prev && curr, true);
    setRowsChecked(rowsChecked.map(() => !allChecked));
  };

  const repeatLast = async () => {
    setRepeatButtonLoading(true);
    const toRepeat: number[] = [];
    const options: ActionOptions[] = [];
    rowsChecked.forEach((val, idx) => {
      if (val) {
        if (
          actions &&
          actions[blockstars![idx].number] &&
          actions[blockstars![idx].number].actionStatus === ActionStatus.None
        ) {
          toRepeat.push(blockstars![idx].number);
          const repeatOptions = getLocalStorage(
            StorageType.ACTION_OPTIONS,
            blockstars![idx].number,
          );
          if (repeatOptions) {
            const parsedOptions = JSON.parse(repeatOptions) as ActionOptions;
            if (!locations.find((l) => l.id === parsedOptions.location.id)) {
              parsedOptions.location = locations[0];
            }
            options.push(parsedOptions);
          } else {
            const skill = blockstars![idx].skills[0].name;
            const location = locations[0];
            const duration = durations[0];
            options.push({
              actionType: BlockstarActionType.Practicing,
              actionSkillType: skill,
              location,
              durationMs: duration,
            });
          }
        }
      }
    });
    analytics.logEvent('ClickActionRepeatLast', { blockstarIds: toRepeat });
    try {
      await onStartActions(
        publicKey,
        toRepeat,
        connection,
        rolBalance,
        signTransaction!,
        signAllTransactions!,
        options,
      );
      await mutate();
      mutateBalance();
      setRowsChecked((prev) => Array(prev.length).fill(false));
      setRepeatButtonLoading(false);
    } catch (e) {
      console.log(e);
      setRepeatButtonLoading(false);
    }
  };

  const selectAllDisabled =
    actions &&
    actionValues
      .map((action) => action && action.actionStatus !== ActionStatus.None)
      .reduce((prev, curr) => prev && curr, true);

  return (
    <div>
      <NFTListGrid>
        {blockstars &&
          getListItems(
            blockstars,
            crates ?? [],
            bands ?? [],
            onFireClick,
            actions ?? {},
            mutate,
            rolBalance,
          )}
      </NFTListGrid>
      {actionableBlockstar && blockstars && (
        <NGMI
          blockstar={actionableBlockstar}
          blockstars={blockstars}
          open={ngmiOpen}
          onClose={() => {
            setNGMIOpen(false);
            props.updateBlockstar(actionableBlockstar.number);
          }}
        />
      )}
    </div>
  );
};

const getListItems = (
  blockstars: Blockstar[],
  crates: any[],
  bands: Band[],
  onFireClick: (b: Blockstar) => () => void,
  actions: {
    [key: number]: BlockstarActionResponse;
  },
  mutate: KeyedMutator<{
    [key: number]: BlockstarActionResponse;
  }>,
  rolBalance: number,
) => {
  const navigate = useNavigate();
  const items = blockstars.map((blockstar, idx) => {
    return (
      <NFTListItem
        key={blockstar.number}
        blockstar={blockstar}
        bands={bands}
        onFireButtonClicked={onFireClick(blockstar)}
        fireButtonDisabledText={fireButtonDisabled(
          blockstars,
          blockstar,
          actions,
          idx,
          rolBalance,
        )}
        action={actions[blockstar.number]}
        mutate={mutate}
      />
    );
  });
  const crateItems = crates?.map((crate, idx) => {
    return <NFTCrateItem key={idx} crate={crate} />;
  });
  return items.concat(crateItems);
};

const fireButtonDisabled = (
  allBlockstars: Blockstar[],
  blockstar: Blockstar,
  actions: {
    [key: number]: BlockstarActionResponse;
  },
  idx: number,
  rolBalance: number,
) => {
  if (rolBalance < blockstar.wage.salary * 120) {
    return `You need at least ${
      blockstar.wage.salary * 120
    } ROL to fire this Blockstar.`;
  }
  const idleBlockstars = Object.values(actions).find(
    (action, index) =>
      index !== idx && action?.actionStatus === ActionStatus.None,
  );

  if (
    actions[blockstar.number]?.actionStatus !== ActionStatus.None ||
    !idleBlockstars
  ) {
    return actions[blockstar.number]?.actionStatus !== ActionStatus.None
      ? 'Blockstar must be idle to be fired.'
      : 'Must have an idle blockstar available to transfer experience to.';
  }

  return undefined;
};
