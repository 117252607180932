import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ThemeType } from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ParsedInstruction, PublicKey } from '@solana/web3.js';
import React, { useState } from 'react';
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';
import { toast } from 'react-toastify';
import {
  createSignedTransaction,
  sendSignedTransaction,
} from '../../actions/wallet';
import { useParsedRaffleTransactions } from '../../hooks/wallet/parsed-raffle-transactions';
import { useRolBalance } from '../../hooks/wallet/rol-balance';
import { useWindowSize } from '../../hooks/window-size';
import { BodyContainer } from '../../styles/global-style';
import { sleep } from '../../utils/utils';
import Header from '../header';
import { headerHeight } from '../header/style';
import {
  BuyButton,
  BuyMorePrompt,
  Container,
  EndCountdown,
  ErrorText,
  PriceSummary,
  RewardContainer,
  RewardImage,
  RewardName,
  RewardsRow,
  RolSvgStyled,
  Subtitle,
  Title,
} from './style';

interface RafflePrizeInfo {
  address: string;
  imgUrl: string;
  name: string;
}

const prizes: RafflePrizeInfo[] = [
  {
    address: '43TgZXT7YjFQPh4pfftNe9nC4WDxeBAPQMdugsK3ivx8',
    imgUrl: 'https://arweave.net/BKGi8E0D4WbcQsqXzvmf8YS2rfc_wcdHDQ7AF3sNzGg',
    name: 'Degen Ape #4721',
  },
  {
    address: '4xHoWTVadbpidcHMQequtgPkkT7bT3RCTp9iZ9LeCjf9',
    imgUrl: 'https://arweave.net/5Xfei9VYga-l68u69uqPRcz3P8IYcY4n_ONy7KXe2wk',
    name: 'Catalina Whale #3520',
  },
  {
    address: 'EkDzW8jGw7DAudiKbKPKZPdYpWytsUdyJbbrY5NcX8vC',
    imgUrl:
      'https://bafybeigjschglgqlylbprpxp7za7w3eqh3i2zlynlmrdoc6jpqkgpwvzni.ipfs.nftstorage.link/2713.png',
    name: 'Blocksmith Labs #2713',
  },
  {
    address: '9NDYJtvrUY1zQrFhC6QK1FxuGkrSL2F1PJFFd271Cg1u',
    imgUrl: 'https://arweave.net/BkzlUbl_3FLkoHtUt5COYRU2FiMCsdCLD8xJOCvy11M',
    name: 'Solana Money Boy #1036',
  },
  {
    address: '3zmWLRSMtJfQEEJ6MmTxDh8qpk55MewHmmPMsXXGGRb1',
    imgUrl: 'https://arweave.net/HMYdaJruly_vsdNwgwDD5MFSua-cPrtu9eG0ffK0YEM',
    name: 'Degen Trash Panda #16762',
  },
  {
    address: 'A1fk6Lnvi8Z6XRvVUc2Mvt49QCPCCWMGddpqkbbDcyYs',
    imgUrl: 'https://assets.blockstars.gg/images/25_portrait.png',
    name: 'Origin Blockstar #25',
  },
  {
    address: 'GD4YfkMC4gQu4SnxYLSLn6sDRNihsg3qCE7MQe9d64mi',
    imgUrl: 'https://arweave.net/0Dilit7JK1v1w8EtGwKvNh3heQIZqjHjj1y3pFCtK9g',
    name: 'Pesky Penguin #6317',
  },
  {
    address: '26WwMkxz5zoLkp8cN6HspuoWg48bpxoXBQsUk8aj8PR8',
    imgUrl: 'https://arweave.net/jCPN5LBfL1D90zegEteAVQsBoUssspQpGYCVVYsL6Bo',
    name: 'Lotus Lad #448',
  },
  {
    address: '8Z9cmqxjiZQPtGHDXjgicyhtRNV5yigV7KMez9bk5w7',
    imgUrl:
      'https://arweave.net/WeX4k81n1xfmCQjaqr16a_iK_R43b8F5mBYiob5YiUQ/5423.png',
    name: 'Drippies #5424',
  },
  {
    address: '46YJUx4o5Z9sehfSvKnxsw57iFNT4YMdQvTfxyiCrqDM',
    imgUrl: 'https://arweave.net/oj1tBLcFLz07BcBThf0qGIYo7BZt-JNWSRrDwMMvLAA',
    name: 'Galactic Gecko #1561',
  },
];

const raffleDestAddr = new PublicKey(
  'FxVtLyUtk6knN3NRM35UH1sXm4yeECKs6UqasEfqnh6R',
);
const buyRolUrl =
  'https://raydium.io/swap/?inputCurrency=sol&outputCurrency=RoLLn5qBN4juQ1D2KFpJyAcC7Deo3cYotXi4qDooHLU&outputAmount=0&fixed=in';
const pricePerTicket = 10000;
const endDate = new Date('2022-05-11T23:59:59.000Z');

const countdownRenderer = ({
  completed,
  days,
  hours,
  minutes,
  seconds,
}: CountdownRenderProps) => {
  if (completed) {
    return 'Raffle has ended! Thanks for participating!';
  }
  return `Raffle ends in: ${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(
    minutes,
  )}:${zeroPad(seconds)}`;
};

const RafflePage = () => {
  const { height } = useWindowSize();
  const theme = useTheme() as ThemeType;
  const { publicKey, signTransaction } = useWallet();
  const { connection } = useConnection();
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);
  const {
    parsedRaffleTransactions: parsedMyRaffleTransactions,
    mutateParsedRaffleTransactions: mutateParsedMyRaffleTransactions,
  } = useParsedRaffleTransactions(connection, publicKey);
  const {
    parsedRaffleTransactions: parsedAllRaffleTransactions,
    mutateParsedRaffleTransactions: mutateParsedAllRaffleTransactions,
  } = useParsedRaffleTransactions(connection, raffleDestAddr);

  const [amountToBuy, setAmountToBuy] = useState<number>(1);
  const [twitter, setTwitter] = useState<string>('');
  const [discord, setDiscord] = useState<string>('');

  const priceToPay = amountToBuy * pricePerTicket;
  const memo = { type: 'raffle', twitter, discord };

  const rolSpentOnMyRaffle = parsedMyRaffleTransactions?.reduce((prev, cur) => {
    const instruction = cur?.transaction?.message
      ?.instructions?.[0] as ParsedInstruction;
    if (!instruction?.parsed?.info) {
      return prev;
    }
    const { source, tokenAmount } = instruction.parsed.info;
    return prev + tokenAmount.uiAmount;
  }, 0);
  const numMyTicketsAlreadyBought = (rolSpentOnMyRaffle ?? 0) / pricePerTicket;

  const rolSpentOnRaffle = parsedAllRaffleTransactions?.reduce((prev, cur) => {
    const instruction = cur?.transaction?.message
      ?.instructions?.[0] as ParsedInstruction;
    if (!instruction?.parsed?.info) {
      return prev;
    }
    const { source, tokenAmount } = instruction.parsed.info;
    return prev + tokenAmount.uiAmount;
  }, 0);
  const numTicketsAlreadyBought = (rolSpentOnRaffle ?? 0) / pricePerTicket;

  const buttonStyle =
    priceToPay > rolBalance || (!twitter && !discord)
      ? { backgroundColor: theme.colors.text.secondaryGray }
      : {};

  return (
    <Container>
      <Header />
      <BodyContainer
        style={{
          height: `${height - headerHeight}px`,
        }}>
        <Title>
          <RolSvgStyled />
          Raffle with $ROL!
          <RolSvgStyled />
        </Title>
        <Subtitle>200+ ◎SOL in Solana NFT prizes!</Subtitle>
        <RewardsRow>
          {prizes.slice(0, 5).map((prize, idx) => (
            <RewardContainer
              key={`reward${idx}`}
              onClick={() =>
                window.open(
                  `https://explorer.solana.com/address/${prize.address}`,
                  '_blank',
                  'noreferrer',
                )
              }>
              <RewardImage>
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={prize.imgUrl}
                />
              </RewardImage>
              <RewardName>{prize.name}</RewardName>
            </RewardContainer>
          ))}
        </RewardsRow>
        <RewardsRow>
          {prizes.slice(5, 10).map((prize, idx) => (
            <RewardContainer
              key={`reward${idx}`}
              onClick={() =>
                window.open(
                  `https://explorer.solana.com/address/${prize.address}`,
                  '_blank',
                  'noreferrer',
                )
              }>
              <RewardImage>
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={prize.imgUrl}
                />
              </RewardImage>
              <RewardName>{prize.name}</RewardName>
            </RewardContainer>
          ))}
        </RewardsRow>
        <EndCountdown>
          <Countdown date={endDate} renderer={countdownRenderer} />
        </EndCountdown>
        {Date.now() > endDate.getTime() ? (
          <>
            <div>
              {numTicketsAlreadyBought.toLocaleString()} raffle tickets were
              sold.
            </div>
            <div>
              {numMyTicketsAlreadyBought.toLocaleString()} raffle tickets were
              purchased by this wallet!
            </div>
            <div>
              Winners will be announced Monday, May 16 at the weekly AMA with
              the team in Discord.
            </div>
          </>
        ) : (
          <>
            <div>
              {numTicketsAlreadyBought.toLocaleString()} raffle tickets have
              been sold.
            </div>
            <div>
              {numMyTicketsAlreadyBought.toLocaleString()} raffle tickets
              purchased by this wallet so far!
            </div>
            <div>
              <TextField
                id='twitter'
                label='Twitter Handle'
                InputLabelProps={{
                  shrink: true,
                }}
                margin='dense'
                placeholder='@nikothefinn'
                variant='filled'
                value={twitter}
                onChange={(e) => {
                  setTwitter(e.target.value);
                }}
              />
            </div>
            <div>
              <TextField
                id='discord'
                label='Discord Handle'
                InputLabelProps={{
                  shrink: true,
                }}
                margin='dense'
                placeholder='nikothefinn#0597'
                variant='filled'
                value={discord}
                onChange={(e) => {
                  setDiscord(e.target.value);
                }}
              />
            </div>
            {!twitter && !discord && (
              <ErrorText>
                Must provide either a twitter or discord handle for raffle
                reward distribution!
              </ErrorText>
            )}
            <div>
              <TextField
                id='amount'
                label='# Tickets to Buy'
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                margin='dense'
                variant='filled'
                value={amountToBuy}
                onChange={(e) => {
                  const val = parseInt(e.target.value, 10);
                  if (isNaN(val)) {
                    return;
                  }
                  const clampedAndRounded = Math.max(1, Math.round(val));
                  setAmountToBuy(clampedAndRounded);
                }}
              />
            </div>
            <PriceSummary>
              {`You'll pay: ${priceToPay.toLocaleString()}`}
              <RolSvgStyled />
              $ROL
            </PriceSummary>
            <div>
              <BuyButton
                style={buttonStyle}
                onClick={async () => {
                  if (priceToPay <= rolBalance && (twitter || discord)) {
                    // create signed transaction.
                    const signedTxn = await createSignedTransaction(
                      connection,
                      publicKey!,
                      raffleDestAddr,
                      new PublicKey(process.env.ROL_MINT_ADDRESS!),
                      signTransaction,
                      priceToPay,
                      JSON.stringify(memo),
                    );
                    if (signedTxn) {
                      toast.info('Sending transaction to Solana...', {
                        autoClose: 120000,
                      });
                      try {
                        const sig = await sendSignedTransaction(
                          signedTxn,
                          connection,
                        );
                        toast.dismiss();
                        toast.success(`Transaction succeeded!`);
                        await sleep(1000);
                        mutateBalance(rolBalance - priceToPay);
                        mutateParsedMyRaffleTransactions();
                        mutateParsedAllRaffleTransactions();
                      } catch (e: any) {
                        toast.dismiss();
                        toast.error(`Transaction failed: ${e.toString()}`);
                      }
                    } else {
                      console.error('Failed to sign transaction for sending.');
                      toast.error('Failed to sign transaction for sending.');
                    }
                  } else {
                    if (priceToPay > rolBalance) {
                      toast.error(
                        `Current $ROL balance (${rolBalance.toLocaleString()}) insufficient to buy ${amountToBuy.toLocaleString()} tickets for ${priceToPay.toLocaleString()} $ROL.`,
                      );
                    } else {
                      toast.error(
                        'Must provide either a twitter or discord handle for raffle reward distribution!',
                      );
                    }
                  }
                }}>
                {`Buy ${amountToBuy.toLocaleString()} Ticket(s)`}
              </BuyButton>
            </div>
            <BuyMorePrompt>
              Need more <RolSvgStyled />
              $ROL?
            </BuyMorePrompt>
            <div>
              <a href={buyRolUrl} target='_blank' rel='noreferrer'>
                <BuyButton>Get $ROL here!</BuyButton>
              </a>
            </div>
          </>
        )}
      </BodyContainer>
    </Container>
  );
};

export default RafflePage;
