import { headerButtonText, RoutePath } from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.jpg';
import { useRolBalance } from '../../hooks/wallet/rol-balance';
import analytics from '../../utils/analytics';
import HeaderMenuButton from './header-menu-button';
import HeaderRolInfo from './header-rol-info';
import HeaderWalletButton from './header-wallet-button';
import {
  Container,
  HeaderMenuContainer,
  HeaderRightContainer,
  SubHeaderContainer,
  SubHeaderContents,
  Title,
  TitleContainer,
} from './style';

const Header = (props: { children?: any; callback?: () => void }) => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { rolBalance } = useRolBalance(connection, publicKey, true);

  const navigate = useNavigate();
  const location = useLocation();
  const onHomeButtonClicked = () => {
    window.open('https://www.blockstars.gg/', '_blank');
  };

  useEffect(() => {
    if (publicKey) {
      analytics.setUserId(publicKey.toString());
    }
  }, [publicKey]);

  const onMenuButtonClick = (routePath: RoutePath) => {
    if (props.callback) {
      props.callback();
    }
    switch (routePath) {
      case RoutePath.Home:
        onHomeButtonClicked();
        break;
      case RoutePath.Tokens:
        navigate(RoutePath.Tokens, {
          state: { originPage: location.pathname },
        });
        break;
      case RoutePath.Blockstars:
        navigate(RoutePath.Blockstars, {
          state: { originPage: location.pathname },
        });
        break;
      case RoutePath.Bands:
        navigate(RoutePath.Bands, {
          state: { originPage: location.pathname },
        });
        break;
    }
  };

  return (
    <div>
      <Container>
        <TitleContainer onClick={onHomeButtonClicked}>
          <img
            src={logo}
            width={35}
            height={35}
            style={{
              border: 'none',
              borderRadius: '50%',
              marginRight: '10px',
            }}></img>
          <Title>Blockstars</Title>
        </TitleContainer>
        <HeaderMenuContainer>
          {/* Finance Office */}
          <HeaderMenuButton
            title={headerButtonText[RoutePath.Tokens] ?? ''}
            buttonRoute={RoutePath.Tokens}
            currentRoutePath={location.pathname as RoutePath}
            onButtonClick={(routePath: RoutePath) =>
              onMenuButtonClick(routePath)
            }
          />
          {/* My Blockstars */}
          <HeaderMenuButton
            title={headerButtonText[RoutePath.Blockstars] ?? ''}
            buttonRoute={RoutePath.Blockstars}
            currentRoutePath={location.pathname as RoutePath}
            onButtonClick={(routePath: RoutePath) =>
              onMenuButtonClick(routePath)
            }
          />
          {/* My Bands */}
          <HeaderMenuButton
            title={headerButtonText[RoutePath.Bands] ?? ''}
            buttonRoute={RoutePath.Bands}
            currentRoutePath={location.pathname as RoutePath}
            onButtonClick={(routePath: RoutePath) =>
              onMenuButtonClick(routePath)
            }
          />
        </HeaderMenuContainer>
        <HeaderRightContainer>
          <HeaderRolInfo rolBalance={rolBalance} />
          <HeaderWalletButton />
        </HeaderRightContainer>
      </Container>
      {props.children && (
        <SubHeaderContainer>
          <SubHeaderContents>{props.children}</SubHeaderContents>
        </SubHeaderContainer>
      )}
    </div>
  );
};

export default Header;
