import { Paper, Tooltip } from '@mui/material';
import React from 'react';
import { infoIcon } from 'src/components/library/info-icon';
import { SkillEntryType } from 'src/components/library/types';
import { DividerHorizontal } from 'src/styles/global-style';
import {
  Footer,
  FooterTextBody,
  FooterTextContainer,
  FooterTextTitle,
  FooterTooltipContainer,
} from '../style';
import {
  FooterTooltipBodyContainer,
  FooterTooltipBodyLeftText,
  FooterTooltipBodyRightText,
  FooterTooltipTitleContainer,
} from './style';

interface FooterData {
  skillType: SkillEntryType;
  spend?: {
    title: string;
    total: string;
    salary: number;
    deposit: number;
    rental: number;
  };
  earn?: {
    title: string;
    total: string;
    details?: XpDetail[];
  };
}

interface XpDetail {
  title: string;
  value: number;
}

const BandActionSelectFooter = (props: { data: FooterData }) => {
  const spendTooltip = (
    <Paper>
      <FooterTooltipContainer>
        <FooterTooltipTitleContainer>
          <FooterTooltipBodyLeftText>Total cost:</FooterTooltipBodyLeftText>
          <FooterTooltipBodyRightText>
            {props.data.spend?.total}
          </FooterTooltipBodyRightText>
        </FooterTooltipTitleContainer>
        <DividerHorizontal style={{ marginTop: '8px', marginBottom: '8px' }} />
        <FooterTooltipBodyContainer>
          <FooterTooltipBodyLeftText>Salary:</FooterTooltipBodyLeftText>
          <FooterTooltipBodyRightText>
            {props.data.spend?.salary.toLocaleString()} $ROL
          </FooterTooltipBodyRightText>
        </FooterTooltipBodyContainer>
        <FooterTooltipBodyContainer>
          <FooterTooltipBodyLeftText>Transportation:</FooterTooltipBodyLeftText>
          <FooterTooltipBodyRightText>
            {props.data.spend?.deposit.toLocaleString()} $ROL
          </FooterTooltipBodyRightText>
        </FooterTooltipBodyContainer>
        <FooterTooltipBodyContainer>
          <FooterTooltipBodyLeftText>Rental:</FooterTooltipBodyLeftText>
          <FooterTooltipBodyRightText>
            {props.data.spend?.rental.toLocaleString()} $ROL
          </FooterTooltipBodyRightText>
        </FooterTooltipBodyContainer>
      </FooterTooltipContainer>
    </Paper>
  );

  const earnTooltip = (
    <Paper>
      <FooterTooltipContainer>
        <FooterTooltipTitleContainer>
          <FooterTooltipBodyLeftText>{`${
            props.data.skillType === SkillEntryType.GIGGING ||
            props.data.skillType === SkillEntryType.RECORDING
              ? props.data.skillType
              : 'All Musical'
          }:`}</FooterTooltipBodyLeftText>
          <p
            style={{
              width: '120px',
              textAlign: 'right',
            }}>{`${props.data.earn?.total}`}</p>
        </FooterTooltipTitleContainer>
        <DividerHorizontal style={{ marginTop: '8px', marginBottom: '8px' }} />
        {props.data.earn?.details &&
          props.data.earn.details.map((detail) => {
            return props.data.earn?.details ? (
              <FooterTooltipBodyContainer key={detail.title}>
                <FooterTooltipBodyLeftText>
                  {detail.title}:
                </FooterTooltipBodyLeftText>
                <FooterTooltipBodyRightText>{`${
                  detail.value > 0
                    ? `${detail.value.toLocaleString()} XP`
                    : 'Max reached'
                }`}</FooterTooltipBodyRightText>
              </FooterTooltipBodyContainer>
            ) : (
              <div></div>
            );
          })}
      </FooterTooltipContainer>
    </Paper>
  );

  return (
    <Footer>
      {props.data.spend !== undefined && (
        <FooterTextContainer>
          <FooterTextTitle>Spend</FooterTextTitle>
          <FooterTextBody>{props.data.spend.total}</FooterTextBody>
          <Tooltip
            placement={'right'}
            title={spendTooltip}
            style={{ marginLeft: '-8px' }}>
            {infoIcon}
          </Tooltip>
        </FooterTextContainer>
      )}
      {props.data.earn !== undefined && (
        <FooterTextContainer>
          <FooterTextTitle>Earn</FooterTextTitle>
          <FooterTextBody>+{props.data.earn.total}</FooterTextBody>
          {props.data.earn.details !== undefined && (
            <Tooltip
              placement={'right'}
              title={earnTooltip}
              style={{ marginLeft: '-8px' }}>
              {infoIcon}
            </Tooltip>
          )}
        </FooterTextContainer>
      )}
    </Footer>
  );
};

export default BandActionSelectFooter;
