import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import {
  ActionTargetType,
  Blockstar,
  BlockstarSkillType,
  NameChangeInfo,
  NameChangeNotAvailableReason,
  PrepType,
  rolToPayForSignature,
  RoutePath,
  ThemeType,
} from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import rest, { BandRequestPath } from 'src/actions/network/rest';
import BandActionProgress from 'src/components/actions/action-progress/band-action-progress';
import BlockstarActionProgress from 'src/components/actions/action-progress/blockstar-action-progress';
import BandActionSelect from 'src/components/actions/action-select/band-action-select';
import { BackButton } from 'src/components/details/style';
import Header from 'src/components/header';
import { ButtonRolPayText } from 'src/components/library/button-rol-pay-text';
import { BuskResults } from 'src/components/library/results/busk';
import { PracticeResults } from 'src/components/library/results/practice';
import { SimpleDialog } from 'src/components/library/simple-dialog';
import { ISkillEntryProps } from 'src/components/library/skill-details/skill-entry';
import { SkillEntryType } from 'src/components/library/types';
import { Loading } from 'src/components/loading';
import NameChangeDialog from 'src/components/name-change';
import { useBandAction } from 'src/hooks/band-action';
import { BandActionControlProvider } from 'src/hooks/band-action-control';
import { useBand } from 'src/hooks/bands';
import { useAllBlockstarActions } from 'src/hooks/blockstar-action';
import { BlockstarActionControlProvider } from 'src/hooks/blockstar-action-control';
import { useBlockstarsByIds } from 'src/hooks/blockstars';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import { useWindowSize } from 'src/hooks/window-size';
import analytics from 'src/utils/analytics';
import {
  canBandChangeName,
  genreData,
  getBandName,
  getDateTimeString,
} from 'src/utils/utils';
import { BandPracticeResults } from '../../library/results/band-practice';
import { bandTxnPrep } from '../others/helper';
import StopActionDialog from '../others/stop-action-dialog';
import { BottomSkills } from './bottom-skills';
import { MiddleAlert } from './middle-alert';
import { MiddleInfo } from './middle-info';
import { MiddleMembers } from './middle-members';
import {
  BodyContainer,
  BottomDisbandButton,
  ContentsContainer,
  DetailContainer,
} from './style';
import { TopImage } from './top-image';

export const BandDetails = () => {
  const { id: bandId } = useParams();

  const { bandData, mutateBand } = useBand(bandId);
  const { bandAction } = useBandAction(bandId);
  const { blockstars, mutateBlockstars } = useBlockstarsByIds(
    bandData?.band?.memberIds,
  );

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteConfirmLoading, setDeleteConfirmLoading] = useState(false);
  const [canEditNameInfo, setCanEditNameInfo] = useState<NameChangeInfo>({
    canEdit: false,
    reason: NameChangeNotAvailableReason.None,
  });
  const [nameChangeOpen, setNameChangeOpen] = useState(false);
  const [stopActionDialogOpen, setStopActionDialogOpen] = useState(false);
  const [disbandDialogOpen, setDisbandDialogOpen] = useState(false);
  const [initAnalyticsSent, setInitAnalyticsSent] = useState(false);
  const [invalid, setInvalidState] = useState<boolean>(false);

  const { publicKey, signTransaction, sendTransaction } = useWallet();
  const { connection } = useConnection();
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme() as ThemeType;
  const { actions: blockstarActions, mutate: mutateBlockstarActions } =
    useAllBlockstarActions(bandData?.band?.memberIds ?? []);

  const { height } = useWindowSize();
  const bodyHeight = (height ?? 0) - 128;

  useEffect(() => {
    mutateBlockstars();
    mutateBlockstarActions();

    if (bandData) {
      if (!initAnalyticsSent) {
        setInitAnalyticsSent(true);
        analytics.logEvent('ViewScreenBand', {
          id: bandId,
          state: bandData.band.disbandedAt
            ? 'disbanded'
            : bandData?.band.notValid
            ? 'invalid'
            : 'valid',
          genre: bandData.band.genre,
          subgenre: bandData.band.subgenre,
          members: JSON.stringify(bandData.band.memberIds),
        });
      }
      setInvalidState(false);
    } else {
      const timer = setTimeout(() => {
        setInvalidState(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [bandData]);

  useEffect(() => {
    const nameEditInfo = canBandChangeName(
      bandData?.band,
      publicKey,
      bandData?.band?.walletId,
    );

    setCanEditNameInfo(nameEditInfo);
  }, [bandData, publicKey]);

  useEffect(() => {
    mutateBlockstarActions();
  }, [bandAction]);

  if (invalid) {
    return (
      <DetailContainer>
        <Header>
          <BackButton
            onClick={() =>
              navigate(RoutePath.Bands, {
                state: { originPage: location.pathname },
              })
            }>
            {'<      Back to My Bands'}
          </BackButton>
        </Header>
        <h3 style={{ marginTop: '24px' }}>Invalid Band ID</h3>
      </DetailContainer>
    );
  }

  if (!bandData || !blockstars || !blockstarActions) {
    return <Loading height='300px' iconSize='50px' />;
  }

  const nameChangeStatusChanged = () => {
    setNameChangeOpen(!nameChangeOpen);
  };

  const onDeleteBandClick = async () => {
    if (bandAction) {
      setStopActionDialogOpen(true);
      return;
    }
    setDeleteLoading(true);
    setDisbandDialogOpen(true);
  };

  const genreInfo = genreData[bandData.band.genre];
  const subGenreInfo = genreInfo.subgenres[bandData.band.subgenre.toString()];

  const onDeleteBandConfirm = async () => {
    if (!publicKey || !signTransaction) {
      const notReady = 'Tried to start band action with no wallet ready...';
      console.error(notReady);
      toast.error(notReady);
      return;
    }

    setDeleteConfirmLoading(true);

    const prepType = PrepType.DELETE;
    const prepBody = {
      prepType,
      saveRequest: {
        id: bandId,
        name: '',
        genre: bandData.band.genre,
        subgenre: bandData.band.subgenre,
        memberIds: [],
        musicalMembers: [],
        generalMembers: [],
      },
    };
    try {
      const txnResult = await bandTxnPrep(
        BandRequestPath.PrepEdit,
        prepBody,
        connection,
        publicKey,
        rolBalance,
        rolToPayForSignature,
        signTransaction,
        prepType,
        bandId,
      );

      mutateBalance();

      const body = {
        genre: bandData.band.genre,
        subgenre: bandData.band.subgenre,
        signature: txnResult.clientSignature,
        blockhash: txnResult.signedTxn.recentBlockhash,
      };
      const response: any = await rest.delete(
        `${process.env.WORKER_URL}/${BandRequestPath.DeleteBand}/${bandId}`,
        body,
      );

      if (response?.message) {
        toast.error(response.message);
      } else {
        mutateBand();
      }

      toast.success(
        `Transaction valid! ${rolToPayForSignature} $ROL deducted; Band disbanded.`,
      );

      analytics.logEvent('Disband', {
        id: bandId,
        genre: bandData.band.genre,
        subgenre: bandData.band.subgenre,
        members: JSON.stringify(bandData.band.memberIds),
      });

      setDisbandDialogOpen(false);
      setDeleteLoading(false);
      setDeleteConfirmLoading(false);
    } catch {
      setDeleteLoading(false);
      setDeleteConfirmLoading(false);
    }
  };

  const members: Blockstar[] = [];
  bandData.band.memberIds.forEach((memberId) => {
    const blockstar = blockstars.find(
      (blockstar) => blockstar.number === memberId,
    );
    if (blockstar) {
      members.push(blockstar);
    }
  });

  const skills: ISkillEntryProps[] = bandData.band.musicalMembers.map(
    (musicalMember, index) => {
      const blockstar = members?.find(
        (member) => member.number === musicalMember?.blockstarId,
      );

      const skill = blockstar?.skills.find(
        (skill) => skill.name === musicalMember?.blockstarSkill.name,
      );

      const skillPreviewDatas =
        bandData.preview[musicalMember?.skill?.name.toString() ?? ''];
      const skillPreview = skillPreviewDatas?.find(
        (skillPreviewData) =>
          skillPreviewData.blockstarId === blockstar?.number &&
          skillPreviewData.skillName === skill?.name,
      );

      return {
        type: SkillEntryType.MUSICAL,
        title:
          skill?.name ?? subGenreInfo.requirements.musicalSkills[index].name,
        currentLevel: skill?.currentAbilities ?? 0,
        targetLevel:
          skillPreview && blockstar?.number === skillPreview?.blockstarId
            ? skillPreview.ability
            : skill?.currentAbilities,
        minPotential: skill?.potentialLow ?? 0,
        maxPotential: skill?.potentialHigh ?? 0,
        currentExp: skill?.currentExp ?? 0,
        targetExp: skill?.targetExp ?? 0,
        maxed: skill?.maxed,
        blockstar,
      };
    },
  );

  bandData.band.generalMembers.forEach((generalMember, index) => {
    if (generalMember) {
      if (generalMember.skill !== BlockstarSkillType.Writing) {
        const blockstar = members?.find(
          (member) => member.number === generalMember.blockstarId,
        );
        const skill = blockstar?.skills.find(
          (skill) => skill.name === generalMember.skill,
        );
        const type = skill?.name.split(' ')[0].toUpperCase();
        skills.push({
          type: SkillEntryType[type as keyof typeof SkillEntryType],
          title: skill?.name ?? Object.values(BlockstarSkillType)[index + 1],
          currentLevel: skill?.currentAbilities ?? 0,
          minPotential: skill?.potentialLow ?? 0,
          maxPotential: skill?.potentialHigh ?? 0,
          currentExp: skill?.currentExp ?? 0,
          targetExp: skill?.targetExp ?? 0,
          maxed: skill?.maxed,
          blockstar,
        });
      }
    } else {
      // handle empty slot
      const title = Object.values(BlockstarSkillType)[index + 1].toString();
      const type = title.split(' ')[0].toUpperCase();
      skills.push({
        type: SkillEntryType[type as keyof typeof SkillEntryType],
        title: title,
        currentLevel: 0,
        minPotential: 0,
        maxPotential: 0,
        currentExp: 0,
        targetExp: 0,
      });
    }
  });

  const onStopActionConfirmClose = () => {
    setStopActionDialogOpen(false);
    toast.error(
      'Update was not completed due to action in progress. Please try again.',
    );
  };

  return (
    <DetailContainer>
      <BlockstarActionControlProvider>
        <BandActionControlProvider>
          <Header>
            <BackButton
              onClick={() =>
                navigate(RoutePath.Bands, {
                  state: { originPage: location.pathname },
                })
              }>
              {'<      Back to My Bands'}
            </BackButton>
          </Header>
          <ContentsContainer style={{ height: bodyHeight }}>
            <BodyContainer>
              <TopImage
                memberIds={bandData.band.memberIds}
                disbanded={bandData.band.disbandedAt !== undefined}
                inactive={bandData.band.notValid}
              />
              {bandData.band.disbandedAt === undefined &&
                bandData.band.walletId === publicKey?.toString() &&
                bandData.band.notValid && <MiddleAlert band={bandData.band} />}
              <MiddleInfo
                band={bandData.band}
                bandAction={bandAction}
                members={members}
                blockstarActions={blockstarActions}
                canEditNameInfo={canEditNameInfo}
                onNameChangeClicked={nameChangeStatusChanged}
              />
              <MiddleMembers
                band={bandData.band}
                members={members}
                bandAction={bandAction}
                blockstarActions={blockstarActions}
                mutateBlockstarActions={mutateBlockstarActions}
              />
              <BottomSkills
                skills={skills}
                disbanded={bandData.band.disbandedAt !== undefined}
              />
              {!bandData.band.disbandedAt ? (
                bandData.band.walletId === publicKey?.toString() && (
                  <BottomDisbandButton
                    variant='contained'
                    loading={deleteLoading}
                    onClick={onDeleteBandClick}>
                    <DeleteIcon />
                    Disband
                  </BottomDisbandButton>
                )
              ) : (
                <BottomDisbandButton disabled>{`Disbanded ${getDateTimeString(
                  new Date(bandData.band.disbandedAt),
                  false,
                )}`}</BottomDisbandButton>
              )}
            </BodyContainer>
          </ContentsContainer>
          {/* Name change */}
          <NameChangeDialog
            open={nameChangeOpen}
            target={bandData.band}
            nameChangeType={ActionTargetType.Band}
            updateTarget={() => mutateBand()}
            onClose={nameChangeStatusChanged}
          />
          {bandAction && (
            <StopActionDialog
              dialogOpen={stopActionDialogOpen}
              band={bandData.band}
              bandAction={bandAction}
              onClose={onStopActionConfirmClose}
            />
          )}
          <SimpleDialog
            open={disbandDialogOpen}
            title={`Disband ${getBandName(bandData.band)}?`}
            message='Once you disband, the action cannot be undone.You can reform the same band, but all progress this band has made together will be lost.'
            okTitle={
              <ButtonRolPayText
                title='Disband'
                rolAmount={rolToPayForSignature}
              />
            }
            cancelTitle='Nevermind'
            okButtonLoading={deleteConfirmLoading}
            onOKButtonClicked={onDeleteBandConfirm}
            onCancelButtonClicked={() => {
              setDisbandDialogOpen(false);
              setDeleteLoading(false);
              setDeleteConfirmLoading(false);
            }}
          />
          <BandActionSelect />
          <BandActionProgress />
          <BandPracticeResults />
        </BandActionControlProvider>
        <BlockstarActionProgress />
        <PracticeResults />
        <BuskResults />
      </BlockstarActionControlProvider>
    </DetailContainer>
  );
};
