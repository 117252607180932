import React from 'react';
import { FiredIcon } from './style';

export const DisabledBadge = (props: { title: string; showIcon: boolean }) => {
  return (
    <div
      style={{
        display: 'flex',
        // width: 52,
        height: 18,
        borderRadius: 45,
        padding: '4px 6px',
        gap: 4,
        background: '#D65745',
        fontSize: 10,
        color: 'white',
        alignItems: 'center',
      }}>
      {props.showIcon && <FiredIcon />}
      {props.title}
    </div>
  );
};
