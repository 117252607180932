import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LockIcon from '@mui/icons-material/Lock';
import React from 'react';
import { MaxReached } from '../library/max-reached';
import { Skill } from '../library/skill-details/skill';
import { SkillEntryType } from '../library/types';
import { TransferIcon } from './style';

export interface ITransferData {
  type: SkillEntryType;
  title: string;
  exp?: number;
  showAbility?: boolean;
  ability?: number;
  maxExp?: number;
  hideExp?: boolean;
  locked?: boolean;
  showGains?: boolean;
  levels?: number;
  radioButton?: JSX.Element;
  maxed?: boolean;
}

export const TransferEntry = (props: ITransferData) => {
  let info = props.exp ? (
    <span
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <TransferIcon style={{ marginRight: 12 }} />
      {`${Math.floor(props.exp).toLocaleString()} XP`}
    </span>
  ) : (
    <span></span>
  );
  if (props.title) {
    if (props.showAbility) {
      info = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}>
          <span>{`Ability ${props.ability}`}</span>
          {props.maxed ? (
            <MaxReached />
          ) : (
            !props.hideExp && (
              <span
                style={{
                  fontSize: 12,
                  color: '#BEBEBE',
                }}>{`${Math.floor(
                props.exp ?? 0,
              ).toLocaleString()}/${props.maxExp?.toLocaleString()} XP`}</span>
            )
          )}
        </div>
      );
    } else if (props.showGains) {
      info = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}>
          <span>{`Ability ${(props.ability ?? 0) + (props.levels ?? 0)}`}</span>
          <div style={{ display: 'flex', fontSize: 12, gap: 8 }}>
            {props.maxed ? (
              <MaxReached />
            ) : (
              <>
                {props.exp !== undefined && props.exp > 0 && (
                  <span
                    style={{
                      color: '#FF24FF',
                    }}>{`+ ${Math.floor(
                    props.exp ?? 0,
                  ).toLocaleString()} XP`}</span>
                )}
                {props.levels !== undefined && props.levels > 0 && (
                  <div style={{ display: 'flex' }}>
                    <ArrowUpwardIcon
                      htmlColor={'#009FC7'}
                      fontSize={'inherit'}
                    />
                    <span
                      style={{
                        color: '#009FC7',
                      }}>{`${props.levels} Ability`}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    }
  } else {
    info = <LockIcon htmlColor={'#BEBEBE'} />;
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #F4F4F4',
        padding: '4px 0px 4px 8px',
        width: '100%',
      }}>
      <Skill
        type={props.type}
        title={props.title ? props.title : 'Skill not available yet'}
        locked={!props.title || props.locked}
      />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {info}
        <div style={{ width: 8 }} />
        {props.radioButton}
      </div>
    </div>
  );
};
