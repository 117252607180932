import { styled } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';

export const ContentsParent = styled('div')`
  width: 528px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AnnouncementCarousel = styled(Carousel)`
  /* width: 528px; */
  width: 464px;
  margin-top: 50px;
  margin-bottom: 36px;
`;
