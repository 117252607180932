import { LoadingButton } from '@mui/lab';
import { Button, Dialog, useTheme } from '@mui/material';
import {
  ActionTargetType,
  BandExpGainableSkillType,
  BlockstarActionType,
  ExpGainableSkillType,
  LocationInfo,
  ThemeType,
} from '@shared-data';
import { PerformanceSkillType } from '@shared-generated/generated-instruments';
import React, { useEffect, useRef, useState } from 'react';
import Countdown, { CountdownTimeDelta } from 'react-countdown';
import { StatusIcon } from 'src/components/library/icons';
import { ProgressBar } from 'src/components/library/progress-bar';
import { SimpleDialog } from 'src/components/library/simple-dialog';
import { useScrollPosition } from 'src/hooks/scroll-position';
import { useWindowSize } from 'src/hooks/window-size';
import analytics from 'src/utils/analytics';
import {
  hideUnityElement,
  showUnityElement,
  styleUnityElement,
  unityMethods,
  unitySendMessage,
} from 'src/utils/unity-helper';
import { calculateProgress, fullTimeCountdownRenderer } from 'src/utils/utils';
import {
  BottomButtonContainer,
  BottomContainer,
  BottomTimerContainer,
  CharacterImageContainer,
  Container,
  LocationContainer,
  NameContainer,
  NameTextContainer,
  ProgressBarContainer,
  SecondHalfContainer,
} from './style';

export interface EaringInfo {
  actionTargetType: ActionTargetType;
  symbol: string;
  amount: number;
  type:
    | ExpGainableSkillType
    | PerformanceSkillType
    | BandExpGainableSkillType
    | undefined;
}

export interface UnityElementInfo {
  unityLoadCharacterData: UnityLoadCharacterData;
}

interface UnityLoadCharacterData {
  id: string;
  animation: string;
  source: string;
}

interface ProgressInfo {
  startMs: number;
  durationMs: number;
}

const ActionProgression = (props: {
  title: string;
  location: LocationInfo;
  characterImage?: JSX.Element;
  InfoView: JSX.Element;
  actionType: BlockstarActionType;
  earningInfo: EaringInfo;
  progressInfo: ProgressInfo;
  open: boolean;
  unityElementInfo?: UnityElementInfo;
  onShowConfirmation?: () => void;
  onStopAction: () => void;
  onClose: () => void;
  onTimeUp: () => void;
}) => {
  const windowSize = useWindowSize();
  const scrollPosition = useScrollPosition();
  const theme = useTheme() as ThemeType;

  const [stopButtonLoading, setStopButtonLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (props.open) {
      if (props.unityElementInfo) {
        // prep for 3d models
        unitySendMessage(
          unityMethods.loadSingleCharacter,
          JSON.stringify(props.unityElementInfo.unityLoadCharacterData),
        );
      }

      const newTimeLeft =
        Date.now() < props.progressInfo.startMs + props.progressInfo.durationMs
          ? props.progressInfo.startMs +
            props.progressInfo.durationMs -
            Date.now()
          : 0;
      setProgress(
        calculateProgress(
          props.progressInfo.durationMs - newTimeLeft,
          props.progressInfo.durationMs,
          true,
        ),
      );

      if (newTimeLeft <= 0) {
        props.onTimeUp();
      }
    }
    return () => {
      unitySendMessage(unityMethods.cleanSingleCharacter);
      hideUnityElement();
    };
  }, [props.open]);

  useEffect(() => {
    // Need to make sure containerRef is set before opening the unity.
    // If the position for unity is not set, unity would throw error.
    if (props.open && containerRef && props.unityElementInfo) {
      const containerRect = containerRef.current?.getBoundingClientRect();
      styleUnityElement({
        width: '500px',
        height: `${(500 * 3) / 4}px`,
        left: `${(containerRect?.right ?? 1238) - 430}px`,
        top: `${(containerRect?.top ?? 207) + 140}px`,
      });
    }
  }, [windowSize, scrollPosition, props.open]);

  const showConfirmation = () => {
    if (props.unityElementInfo) {
      hideUnityElement();
    }
    setStopButtonLoading(true);
    setOpenConfirmation(true);

    analytics.logEvent('ViewScreenActionStopConfirmation', {
      action: props.actionType,
    });
    if (props.onShowConfirmation) {
      props.onShowConfirmation();
    }
  };

  const onClose = () => {
    if (props.unityElementInfo) {
      unitySendMessage(unityMethods.cleanSingleCharacter);
      hideUnityElement();
    }
    setStopButtonLoading(false);
    setOpenConfirmation(false);
    setConfirmButtonLoading(false);
    props.onClose();
  };

  const onConfirmOK = () => {
    if (props.unityElementInfo) {
      showUnityElement();
      unitySendMessage(unityMethods.cleanSingleCharacter);
      hideUnityElement();
    }

    props.onStopAction();
    onClose();
  };

  const onConfirmCancel = () => {
    if (props.unityElementInfo) {
      showUnityElement();
    }
    setStopButtonLoading(false);
    setConfirmButtonLoading(false);
    setOpenConfirmation(false);
  };

  const locationImg = `${process.env.ASSET_BASE_URL || ''}/${
    props.location.imageName
  }.jpg`;

  const actionTextSplit = props.actionType.split(' ');
  const actionText =
    actionTextSplit.length > 1
      ? actionTextSplit[1].toLowerCase()
      : props.actionType.toLowerCase();

  const onCounterTick = (timeDelta: CountdownTimeDelta) => {
    setProgress(
      calculateProgress(
        props.progressInfo.durationMs - timeDelta.total,
        props.progressInfo.durationMs,
        true,
      ),
    );
  };

  const onCounterComplete = () => {
    setProgress(100);
    props.onTimeUp();
  };

  const endDate = props.progressInfo.startMs + props.progressInfo.durationMs;
  const timeRemaining = (
    <Countdown
      date={endDate}
      renderer={fullTimeCountdownRenderer}
      onTick={onCounterTick}
      onComplete={onCounterComplete}
    />
  );

  return (
    <Dialog
      open={props.open}
      maxWidth={false}
      onClose={onClose}
      scroll={'body'}>
      <Container ref={containerRef}>
        <LocationContainer>
          <img
            src={locationImg}
            width={'100%'}
            height={350}
            style={{ objectFit: 'cover' }}
          />
        </LocationContainer>
        {props.characterImage && (
          <CharacterImageContainer>
            {props.characterImage}
          </CharacterImageContainer>
        )}
        <SecondHalfContainer>
          <NameContainer>
            <StatusIcon completed={progress === 100} />
            <NameTextContainer>{props.title}</NameTextContainer>
          </NameContainer>
          {props.InfoView}
          <ProgressBarContainer>
            <ProgressBar
              currentLevel={progress}
              color={theme.colors.ui.specialLime}
            />
          </ProgressBarContainer>
          <BottomContainer>
            <BottomTimerContainer>
              {`${progress}% complete | `}
              {timeRemaining}
            </BottomTimerContainer>
            <BottomButtonContainer>
              <Button
                variant='text'
                style={{ width: '140px', height: '50px', fontWeight: 'bold' }}
                onClick={showConfirmation}>
                Stop {actionText}
              </Button>
              <LoadingButton
                loading={stopButtonLoading}
                variant='contained'
                fullWidth
                style={{ width: '110px', height: '50px', borderRadius: 38 }}
                onClick={onClose}>
                Ok
              </LoadingButton>
            </BottomButtonContainer>
          </BottomContainer>
        </SecondHalfContainer>
      </Container>
      <SimpleDialog
        open={openConfirmation}
        title={`Are you sure you want to stop ${actionText}?`}
        message={`You will earn +${Math.floor(props.earningInfo.amount)} ${
          props.earningInfo.symbol
        }${
          props.actionType === BlockstarActionType.Busking
            ? `, `
            : ` to your ${props.earningInfo.type?.toLowerCase()} skill, `
        } but you will not be refunded the cost of sending your ${
          props.earningInfo.actionTargetType === ActionTargetType.Blockstar
            ? ActionTargetType.Blockstar
            : ''
        } ${props.actionType.toLowerCase()}.`}
        okTitle={`Stop ${actionText}`}
        cancelTitle={'Nevermind'}
        okButtonLoading={confirmButtonLoading}
        onOKButtonClicked={onConfirmOK}
        onCancelButtonClicked={onConfirmCancel}
      />
    </Dialog>
  );
};

export default ActionProgression;
