import { styled } from '@mui/material/styles';
import { RolSvg } from '../../styles/global-style';

export const Container = styled('div')`
  background-color: white;
`;

export const Title = styled('h2')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 33px;
  text-align: center;
  color: ${(props) => props.theme.colors.text.primaryGray};
  margin-bottom: 32px;
`;

export const Subtitle = styled('h3')`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.text.primaryGray};
  margin-bottom: 32px;
`;

export const EndCountdown = styled('h3')`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.text.primaryGray};
  margin-bottom: 32px;
`;

export const RewardsRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
`;

export const RewardContainer = styled('div')`
  border: 2px solid ${(props) => props.theme.colors.ui.cellGray};
  border-radius: 8px;
  width: 160px;
  height: 200px;
  margin: 0 16px;
  overflow: hidden;
  :hover {
    cursor: pointer;
  }
`;

export const RewardImage = styled('div')``;

export const RewardName = styled('div')``;

export const RolSvgStyled = styled(RolSvg)`
  display: inline-block;
  background: ${(props) => props.theme.colors.text.primaryGray};
  margin-left: 5px;
`;

export const PriceSummary = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  text-align: center;
  color: ${(props) => props.theme.colors.text.primaryGray};
  margin: 10 auto;
`;

export const BuyButton = styled('button')`
  width: 150px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple};
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  :hover {
    cursor: pointer;
  }
`;

export const BuyMorePrompt = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  text-align: center;
  color: ${(props) => props.theme.colors.text.primaryGray};
  margin-top: 8px;
`;

export const ErrorText = styled('div')`
  color: red;
`;
