import { GlobalStyles } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { px2vw } from '../utils/utils';

declare module '@mui/material/styles' {
  interface Theme {
    colors: any;
  }
  interface ThemeOptions {
    colors?: any;
  }
}

const colors = {
  legacy: {
    blue: '#0c72fc',
    positiveText: '#14A352',
    negativeText: '#884170',
    positiveGraph: '#8d2dd4',
    negativeGraph: '#973075',
  },
  ui: {
    primaryPurple: '#783BFF',
    musicalCyan: '#27D3FF',
    starYellow: '#F8CE2B',
    specialPink: '#FF68FF',
    specialPeach: '#FFB98E',
    specialLime: '#A8FC7F',
    unfilledGray: '#9797972a',
    lineGray: '#9797973b',
    cellGray: '#F4F4F4',
    mediumGray: '#ECECEC',
    darkPurple: '#5526BC',
    lightPurple: `#844FF8`,
    notMatched: '#F0AD48',
    cellMusicalCyan: '#F8FCFE',
    cellOtherSkills: '#faf8ff',
    buttonOff: '#757575',
    okOuter: '#2BA2CC',
    okInner: '#0E94BD',
    starOuter: '#F3C820',
    starInner: '#E8BA0D',
  },
  text: {
    musicalAqua: '#009FC7',
    primaryGray: '#424242',
    secondaryGray: '#BEBEBE',
    error: '#D65745',
    darkError: '#B54130',
  },
};

const muiTheme = createTheme({
  palette: { primary: { main: colors.ui.primaryPurple } },
  colors: colors,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: 16,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          borderTop: 'none',
          borderBottom: 'none',
          borderRadius: 0,
          '&:last-of-type': {
            borderRight: 'none',
          },
          '&:first-of-type': {
            borderLeft: 'none',
          },
          '&.Mui-selected': {
            background: 'none',
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        content: {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});

export const globalStyle = (
  <GlobalStyles
    styles={`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:game-root {
  font-family: 'Roboto';
  font-size: ${px2vw(24)};

  @media (min-width: 768px) {
    font-size: ${px2vw(18)};
  }

  @media (min-width: 1024px) {
    font-size: ${px2vw(16)};
  }
}
#game-root {
  margin: 0;
  padding: 0;
  height: 100%;
}
h1, h2, h3, h4, h5, h6, p {
  color:${colors.text.primaryGray}
}
`}
  />
);

const Theme = (props: { children: any }) => (
  <ThemeProvider theme={muiTheme}>{props.children}</ThemeProvider>
);

export default Theme;
