import { Genres, Subgenre } from '@shared-generated/generated-genres';
import {
  PerformanceSkillFamily,
  PerformanceSkillType,
} from './generated/generated-instruments';

export const rolToPayForSignature = 1;

export enum BlockstarActionType {
  View = 'View',
  Edit = 'Edit',
  Practicing = 'Practicing',
  Busking = 'Busking',
  Fire = 'Fire',
  BandGigging = 'Band Gigging',
  BandPracticing = 'Band Practicing',
}

export enum BlockstarSkillType {
  Writing = 'Writing',
  Recording = 'Recording',
  Promoting = 'Promoting',
  Practicing = 'Practicing',
  Gigging = 'Gigging',
  Fame = 'Fame',
  SocialMedia = 'Social Media',
}

export enum LocationBoostType {
  Writing = 'Writing',
  SocialMedia = 'Social Media',
}

export enum BandActivityType {
  Gigging = 'Gigging',
  Writing = 'Writing',
  Recording = 'Recording',
  Promoting = 'Promoting',
  MemberTraining = 'MemberTraining',
}

export enum PhysicalAppearanceType {
  Type = 'Type',
  Skin = 'Skin',
  Tattoo = 'Tattoo',
  Eyes = 'Eyes',
  Nose = 'Nose',
  Mouth = 'Mouth',
  Ears = 'Ears',
  Face = 'Face',
  Hair = 'Hair',
  InnerTop = 'Inner Top',
  InnerBottom = 'Inner Bottom',
  ShoeRight = 'Shoes',
  OuterTop = 'Outer Top',
  OuterBottom = 'Outer Bottom',
  HeadTop = 'Head Top',
  EyeGear = 'Eye Gear',
  ArmRight = 'Arms',
  Neck = 'Neck',
  Instrument = 'Instrument',
  Series = 'Series',
  Background = 'Background Color',
}

export enum PhysicalAppearanceCategory {
  Musical = 'Musical',
  Head = 'Head',
  Top = 'Top',
  Bottom = 'Bottom',
  Other = 'Other',
}

export interface PhysicalAppearance {
  trait_type: PhysicalAppearanceType;
  value: string;
  category: PhysicalAppearanceCategory;
}

export interface StarRatingInfo {
  initialPoints: number;
  currentPoints: number;
  maxPoint: number;
  initialStarRating: number;
  currentStarRating: number;
  maxStarRating: number;
}

export enum SkillType {
  Instrumental = 'Instrumental',
  General = 'General',
}

export interface SkillInfo {
  type: SkillType;
  name: BlockstarSkillType | PerformanceSkillType;
  familyName?: PerformanceSkillFamily | LocationBoostType;
  initialAbilities: number;
  currentAbilities: number;
  maxAbilities: number;
  potentialLow: number;
  potentialHigh: number;
  currentExp?: number;
  targetExp?: number;
  random: number;
  maxed?: boolean;
  unlocked?: boolean;
  unlockedTimestamp?: number;
  new?: boolean;
}

export interface PerformanceSkill {
  name: PerformanceSkillType;
  familyName: PerformanceSkillFamily;
}

export interface Blockstar {
  series: string;
  name: string;
  description: string | null;
  starRatingInfo: StarRatingInfo;
  skills: SkillInfo[];
  wage: WageInfo;
  trainingPerformanceType?: PerformanceSkillType;
  revealedAt?: Date;
  bandId?: string;
  mintAddress: string;
  number: number;
  personality: Personality;
  backgroundColor: BackgroundColor;
  physicalAttributes: PhysicalAppearance[];
  fired?: boolean;
  customName?: NameInfo;
}

export interface BackgroundColor {
  name: string;
  colors: string[];
  image?: string;
}

export enum MBTIType {
  INTJ_A = 'INTJ-A',
  ISTJ_A = 'ISTJ-A',
  ENTJ_A = 'ENTJ-A',
  ESTJ_A = 'ESTJ-A',
  INTP_A = 'INTP-A',
  ISTP_A = 'ISTP-A',
  ENTP_A = 'ENTP-A',
  ESTP_A = 'ESTP-A',
  INFJ_A = 'INFJ-A',
  ISFJ_A = 'ISFJ-A',
  ENFJ_A = 'ENFJ-A',
  ESFJ_A = 'ESFJ-A',
  INFP_A = 'INFP-A',
  ISFP_A = 'ISFP-A',
  ENFP_A = 'ENFP-A',
  ESFP_A = 'ESFP-A',
  INTJ_P = 'INTJ-P',
  ISTJ_P = 'ISTJ-P',
  ENTJ_P = 'ENTJ-P',
  ESTJ_P = 'ESTJ-P',
  INTP_P = 'INTP-P',
  ISTP_P = 'ISTP-P',
  ENTP_P = 'ENTP-P',
  ESTP_P = 'ESTP-P',
  INFJ_P = 'INFJ-P',
  ISFJ_P = 'ISFJ-P',
  ENFJ_P = 'ENFJ-P',
  ESFJ_P = 'ESFJ-P',
  INFP_P = 'INFP-P',
  ISFP_P = 'ISFP-P',
  ENFP_P = 'ENFP-P',
  ESFP_P = 'ESFP-P',
}

export enum PersonalityPotentialType {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export interface Personality {
  mbti: MBTIType;
  potential: PersonalityPotentialType;
}

export enum WageBucket {
  Rock_bottom = 'Rock-bottom',
  Cheap = 'Cheap',
  Frugal = 'Frugal',
  Thrifty = 'Thrifty',
  Moderate = 'Moderate',
  Reasonable = 'Reasonable',
  Costly = 'Costly',
  Pricey = 'Pricey',
  Extortionate = 'Extortionate',
}

export interface WageInfo {
  salary: number;
  percentCut: number;
  bucket: WageBucket;
}

export interface SongQuality {
  initialValue: number;
  currentValue?: number;
  currentPotential: number[];
  targetPotential: number;
}

export interface SongCreationData {
  band: string;
  blockstars: string[]; // list of members of band when it was.
  startAt: Date; // write, recording date.
  duration: number;
  place?: string; // id or name of place where written or recorded.
}

export interface PromotionData {
  // type: PromotionType;
  startAt: Date;
  duration: number;
  // result: PromotionResult;
}

// export interface PromotionResult {}

export interface ChartInfo {
  seasonId: string;
  rankHistory: number[];
}

export interface Asset {
  image?: string;
  bgImage?: string;
  bgm?: string;
  sfx?: string;
}

export interface Profile {
  // TODO: Ki, what's in profile?
}

export enum TutorialStatusType {
  SKIPPED = 'SKIPPED',
  DONE = 'DONE',
}

export interface TutorialStatus {
  userName?: TutorialStatusType;
  email?: TutorialStatusType;
  tutorialDone?: TutorialStatusType;
}

export interface CharacterBGColor {
  color: string;
  image?: string;
  name: string;
}

export interface CharacterBGLevel {
  high: CharacterBGColor;
  medium: CharacterBGColor;
  low: CharacterBGColor;
}

// Client
export interface Players {
  publicKey: string;
  email?: string;
  userName?: string;
  authToken: string;
  money: number;
  verificationId?: string;
  createdAt: Date;
  verifiedAt?: Date;
  updatedAt?: Date;
  lastLogin?: Date;
  vacationStartedAt?: Date;
  emailSkipped: boolean;
  tutorialStatus?: TutorialStatus;
}

export enum RigVariation {
  Normal = 'Normal',
  Elevated = 'Elevated',
}

export enum TypeVariation {
  Normal = 'Normal',
  NoFeet = 'NoFeet',
  PointyToe = 'PointyToe',
}

export interface BlockstarsSortKeyMap {
  default: string;
  salary: string;
  music1: string;
  music2: string;
  music3: string;
}

export interface TableHeader {
  Header: string;
  accessor: string;
  columns?: TableHeader[];
}

export type AttributeCounter = {
  [key in PhysicalAppearanceType]: { [key: string]: number };
};

export interface ThemeType {
  colors: {
    ui: { [key: string]: string };
    text: { [key: string]: string };
    legacy: { [key: string]: string };
  };
}

export interface AbilityAnchorInfo {
  rangeStart: number;
  rangeEnd: number;
  anchor: number;
}

export interface AbilityInfo {
  ability: number;
  expToNextAbility: number;
}

export interface LocationInfo {
  id: number;
  viewOrder: number;
  tier: number;
  name: string;
  deposit: number;
  dailyCost: number;
  xpBoosts?: LocationBoost[];
  rolBoosts?: LocationBoost[];
  locationTypes: BlockstarActionType[];
  startTime?: number;
  endTime?: number;
  imageName: string;
}

export interface LocationBoost {
  type: string;
  boost: number;
}

export enum ExpGainableSkillType {
  Instrumental = 'Instrumental',
  Writing = 'Writing',
  SocialMedia = 'Social Media',
  Fame = 'Fame',
  RECORDING = 'Recording',
  GIGGING = 'Gigging',
}

export enum BandExpGainableSkillType {
  MUSICAL = 'Musical',
  RECORDING = 'Recording',
  GIGGING = 'Gigging',
}

export interface ExpAllocationChainInfo {
  actionType: string;
  skill: ExpAllocationChain;
}

export interface ExpAllocationChain {
  name: string;
  value: number;
  subSkills?: ExpAllocationChain[];
}

export interface StarRating {
  minAbility: number;
  maxAbility: number;
  stars: number;
}

export enum ActionStatus {
  None,
  prepAction,
  Paying,
  InAction,
  Completed,
  Rewarding,
  Done,
  ErroredDone,
}

export interface SkillReward {
  blockstarId: number;
  name: BlockstarSkillType | PerformanceSkillType;
  previousExp?: number;
  gainedExp: number;
  finalExp?: number;
  targetExp?: number;
  currentExp?: number;
  maxed: boolean;
}

export interface LevelUpInfo {
  skill: string;
  previousAbility: number;
  currentAbility: number;
  maxed: boolean;
  targetExp: number;
  currentExp: number;
}

export interface StarUpInfo {
  previousStars: number;
  currentStars: number;
}

export interface BlockstarActionResponse {
  blockstarId?: number;
  actionType?: BlockstarActionType;
  completed?: boolean;
  actionStatus?: ActionStatus;
  skills?: SkillReward[];
  levelUpInfo?: LevelUpInfo[];
  starUpInfo?: StarUpInfo;
  blockstar?: Blockstar;
  signedTransactionInfo?: SignedTransactionInfo;
  actionStartTimeMs?: number;
  durationMs?: number;
  location?: LocationRequest;
  selectedSkill?: ExpGainableSkillType | PerformanceSkillType;
  rolToPay?: number;
  rolEarned?: number;
  message?: string;
}

export interface BlockstarTransferResponse extends BlockstarActionResponse {
  actionCustomEndTimeMs: number;
}

export interface LocationRequest {
  id: number;
}

export interface SignedTransactionInfo {
  signature: string;
  signedTransaction: Buffer;
  timestamp: number;
  blockhash?: string;
}

export interface PreSignedTransactionInfo {
  prepType: PrepType;
  signedTransactionInfo: SignedTransactionInfo;
  rolAmount: number;
  memo: string;
}

export const sharableBlockstar = (fullData: Blockstar) => {
  const blockstar = { ...JSON.parse(JSON.stringify(fullData)) };
  const { personality, ...filteredBlockstar } = blockstar;
  filteredBlockstar.starRatingInfo.currentPoints = 0;
  filteredBlockstar.starRatingInfo.initialPoints = 0;
  let primaryInstrumentSeen = false;
  const skillsCopy = filteredBlockstar.skills.map((s: any) => {
    return { ...s };
  });
  (filteredBlockstar.skills as Partial<SkillInfo>[]).forEach((item, idx) => {
    item.maxed = item.currentAbilities === item.maxAbilities;
    item.maxAbilities = undefined;
    item.random = undefined;
    // "unreveal" locked secondary and tertiary skills
    if (item.type === SkillType.Instrumental) {
      const unlockedByExp =
        primaryInstrumentSeen &&
        (skillsCopy[idx - 1].currentAbilities ?? 1) >=
          (skillsCopy[idx - 1].maxAbilities! -
            skillsCopy[idx - 1].initialAbilities!) *
            0.75 +
            skillsCopy[idx - 1].initialAbilities!;
      if (!primaryInstrumentSeen) {
        // Leave primary alone but note we saw it...
        primaryInstrumentSeen = true;
      } else if (
        !(
          item.unlocked || // item.unlocked = unlocked via ngmi
          unlockedByExp
        )
      ) {
        // "unreveal" by nuking name, which will then get adjust below...
        item.name = undefined;
      }
    }

    // Zero out locked and / or "unrevealed" instrument skills
    if (item.type === SkillType.Instrumental && !item.name) {
      item.currentAbilities = 0;
      item.initialAbilities = 0;
      item.potentialHigh = 0;
      item.potentialLow = 0;
      item.familyName = undefined;
      item.new = undefined;
    }
  });

  return filteredBlockstar;
};

export interface CalculateRewardRequest {
  walletId: string;
  actionType: BlockstarActionType;
  blockstarId: number;
  actionSkillType: ExpGainableSkillType | PerformanceSkillType;
  location: LocationRequest;
  durationMs: number;
}

export interface CalculateRewardResponse {
  skills: SkillReward[];
}

export interface CalculateRewardBuskingResponse
  extends CalculateRewardResponse {
  rolToPay: number;
  rolToEarn: number;
}

export interface EndActionBuskingResponse extends BlockstarActionResponse {
  signature?: string;
  endTime?: number;
  timeTaken?: number;
  spentBreakDown: SpentBreakDown;
  earningBreakDown: EarningBreakDown;
  netBreakDown: NetBreakDown;
}

export interface SpentBreakDown {
  total: number;
  salary: number;
  locationDeposit: number;
  locationDaily: number;
}

export interface EarningBreakDown {
  total: number;
  extra: number;
  percentCut: number;
  originSalary: number;
}

export interface NetBreakDown {
  total: number;
  earned: number;
  spent: number;
}

export enum SongType {
  Cover,
  Original,
}

export enum PersonalityType {
  A = 'A',
  T = 'T',
}

export interface EarnRateInfo {
  personalityType: PersonalityType;
  personalityTemper?: PersonalityPotentialType;
  values: EarnRate[];
}

export interface EarnRate {
  durationMs: number;
  value: number;
}

export interface ErrorResponse {
  status: number;
}

export interface UnityMessageSingleCharacter {
  id: string;
  animation?: string;
  background?: string;
}

export enum CharacterAnimationType {
  BASIC_IDLE = 'basic_idle_new_rig',
}

export enum CrateRarity {
  Roadie,
  Promoter,
  TourManager,
  BandAgent,
}

export const CrateRarityNames = [
  'Roadie',
  'Promoter',
  'Tour Manager',
  'Band Agent',
];

export interface TransferInfo {
  name: string;
  type: SkillType;
  exp: number;
  levels?: number;
  maxed?: boolean;
}

export interface SignableTransaction {
  base64: string;
  lastValidBlockHeight: number;
}

export enum NGMISkillOption {
  NONE = 'none',
  LOCKED = 'locked',
}

export interface ViewedMusicalSkill {
  timestamp: number;
  skill: string;
}

export interface AnnouncementInfo {
  id: number;
  startTime?: number;
  endTime?: number;
  images: string[];
}

export enum ActionTargetType {
  Blockstar = 'Blockstar',
  Band = 'Band',
}

export interface NameInfo {
  name: string;
  timestamp: number;
}

export const blockstarNameRegex = /^[A-Za-z0-9. ]{0,17}$/i;
export const nameChangeTimeLimit = 24 * 60 * 60 * 1000; // 1 day

export interface BandMemberSkill {
  name: PerformanceSkillType | BlockstarSkillType;
  familyName?: PerformanceSkillFamily;
}

export interface BandPreviewRequest {
  genre: Genres;
  subgenre: Subgenre;
  blockstarIds: number[];
}

export interface BandPreviewResponse {
  [skill: string]: {
    blockstarId: number;
    skillName: string;
    ability: number;
  }[];
}

export interface BandRatingRequest {
  musicalMembers: BandMemberMusical[];
  generalMembers: BandMemberGeneral[];
}

export interface BandRequirements {
  minMembers: number;
  maxMembers: number;
  musicalSkills: PerformanceSkill[];
}

export interface BandMemberMusical {
  skill: PerformanceSkill;
  blockstarId: number;
  blockstarSkill: PerformanceSkill;
  position: number;
}

export interface BandMemberGeneral {
  skill: BlockstarSkillType;
  blockstarId: number;
}

export interface GenreData {
  [key: string]: GenreInfo;
}

export interface GenreInfo {
  displayName: string;
  subgenres: SubGenreData;
}

export interface SubGenreData {
  [key: string]: SubgenreInfo;
}

export interface SubgenreInfo {
  displayName: string;
  requirements: BandRequirements;
}

export interface GenreSelection {
  genre: string;
  subGenre: string;
}

export enum RoutePath {
  Home = '/',
  Game = '/game',
  Blockstars = '/blockstars',
  Raffle = '/raffle',
  Tokens = '/tokens',
  Bands = '/bands',
  BandsEdit = '/bandsEdit',
}

export const headerButtonText = {
  [RoutePath.Home]: 'Home',
  [RoutePath.Blockstars]: 'My Blockstars',
  [RoutePath.Tokens]: 'Finance Office',
  [RoutePath.Bands]: 'My Bands',
};

export type InstrumentData = {
  [key in PerformanceSkillType]: {
    name: string;
    familyName: PerformanceSkillFamily;
  };
};

export interface BandStarRating {
  currentStarRating: number;
  potentialStarRating: number;
}

export enum PrepType {
  CREATE,
  EDIT,
  NAME_CHANGE,
  DELETE,
  BAND_ACTION,
}

export interface Band {
  id: string;
  walletId: string;
  genre: Genres;
  subgenre: Subgenre;
  memberIds: number[];
  musicalMembers: (BandMemberMusical | undefined)[];
  generalMembers: (BandMemberGeneral | undefined)[];
  starRating?: BandStarRating;
  createdAt: number;
  disbandedAt?: number;
  customName?: NameInfo;
  notValid?: boolean;
}

export interface BandSaveRequest {
  id?: string;
  name?: string;
  genre: Genres;
  subgenre: Subgenre;
  memberIds: number[];
  musicalMembers: (BandMemberMusical | undefined)[];
  generalMembers: (BandMemberGeneral | undefined)[];
  create?: boolean;
  signature?: string;
  blockhash?: string;
}

export interface BandEditPrepRequest {
  prepType: PrepType;
  saveRequest: BandSaveRequest | BandNameChangeRequest;
}

export enum MemoTypes {
  UpdateBand = 'Update Band',
  CreateBand = 'Create Band',
  DisbandBand = 'Disband Band',
  BandNameChange = 'Band Name Change',
  ActionStart = 'Band Action Start',
}

export enum NameChangeNotAvailableReason {
  Fired,
  NotMine,
  InCooldown,
  None,
}

export interface NameChangeInfo {
  canEdit: boolean;
  reason: NameChangeNotAvailableReason;
}

export interface BandNameChangeRequest {
  id: string;
  name: string;
  signature?: string;
  blockhash?: string;
}

export interface BandActionBaseRequest {
  bandId: string;
}

export interface BandActionEstimateRequest extends BandActionBaseRequest {
  actionSkillType: BandExpGainableSkillType;
  actionType: BlockstarActionType;
  duration: number;
  location: LocationRequest;
}

export interface BandActionSignRequest extends BandActionBaseRequest {
  prepType: PrepType;
  signature: string;
}

export interface BandActionStartRequest extends BandActionBaseRequest {
  signature: string;
  blockhash: string;
}

export interface BandActionEndRequest extends BandActionBaseRequest {
  actionType: BlockstarActionType;
  startTimeMs: number;
}

export interface BandActionFFRequest extends BandActionEndRequest {
  ffInMs: number;
}

export interface BandActionProgressResponse {
  bandId: string;
  actionType: BlockstarActionType;
  actionSkillType: BandExpGainableSkillType;
  startTimeMs: number;
  durationMs: number;
  location: LocationRequest;
}

export interface BandActionData {
  bandId: string;
  actionStatus: ActionStatus;
  startTimeMs: number;
  actionType: BlockstarActionType;
  actionSkillType: BandExpGainableSkillType;
  durationMs: number;
  locationId: number;
  // hasStarUp?: boolean;
  ffStartTime?: number;
  signature?: string;
}

export interface VerifyRolTransactionSignatureRequest {
  signature?: string;
  blockhash?: string;
  verifyType: MemoTypes;
  verifyItem: any;
}

export interface BandActionEndResponse extends BandActionBaseRequest {
  bandActionResponse?: {
    originalStarInfo: BandStarRating;
    updatedStarInfo: BandStarRating;
  };
  blockstarActionResponses: BlockstarActionResponse[];
}

export interface BandActionResponse {
  action: BandActionData;
  bandActionResponse?: {
    originalStarInfo: BandStarRating;
    updatedStarInfo: BandStarRating;
  };
  blockstarActionResponses: BlockstarActionResponse[];
}

export const bandActionStartTime = (action: BandActionData) => {
  return action.ffStartTime ?? action.startTimeMs;
};
