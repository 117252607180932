import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, useTheme } from '@mui/material';
import { Blockstar, ThemeType } from '@shared-data';
import React from 'react';
import { DividerVertical } from 'src/styles/global-style';
import { getBlockstarName, getStringFromWage } from 'src/utils/utils';
import { BackContainer } from '../ngmi/style';
import Stars from '../star-rating';
import { NftListItemInfoSalary, RolSvgStyled } from '../tokens/nft/style';
import {
  CloseButtonRow,
  CloseContainer,
  InfoContainer,
  InfoItem,
  InfoNumber,
  TopContainer,
} from './style';

export const DialogHeader = (props: {
  onClose: () => void;
  blockstar: Blockstar;
  padding?: string;
  margin?: string;
}) => {
  const imgSrc = `${process.env.ASSET_BASE_URL || ''}/${
    props.blockstar?.number
  }.png`;
  return (
    <>
      <CloseButtonRow>
        <CloseContainer>
          <IconButton color={'inherit'} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </CloseContainer>
      </CloseButtonRow>
      <TopContainer style={{ padding: props.padding, margin: props.margin }}>
        <img
          src={imgSrc}
          width={84}
          height={84}
          style={{ objectFit: 'cover', borderRadius: 8 }}
        />
        <InfoContainer>
          <InfoItem style={{ fontSize: 24, textAlign: 'left' }}>
            {getBlockstarName(props.blockstar)}
          </InfoItem>
          <InfoItem style={{ marginLeft: -3 }}>
            <Stars
              currentStars={props.blockstar.starRatingInfo.currentStarRating}
              potentialStars={props.blockstar.starRatingInfo.maxStarRating}
            />
          </InfoItem>
          <InfoItem>
            <NftListItemInfoSalary style={{ width: 250 }}>
              <RolSvgStyled />
              {getStringFromWage(props.blockstar.wage)}
            </NftListItemInfoSalary>
          </InfoItem>
        </InfoContainer>
      </TopContainer>
    </>
  );
};

export const DialogClose = (props: { onClose?: () => void }) => {
  return (
    <CloseButtonRow>
      <CloseContainer>
        <IconButton color={'inherit'} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </CloseContainer>
    </CloseButtonRow>
  );
};

export const DialogBack = (props: { onClick: () => void }) => {
  return (
    <BackContainer>
      <Button
        variant={'text'}
        startIcon={<ArrowBackIosIcon />}
        onClick={props.onClick}>
        Back
      </Button>
    </BackContainer>
  );
};

export const BlockstarInfo = (props: {
  basic?: boolean;
  blockstar: Blockstar;
  padding?: string;
  margin?: string;
  height?: number;
  showBorders?: boolean;
  hideNumber?: boolean;
}) => {
  const theme = useTheme() as ThemeType;

  const imgSrc = `${process.env.ASSET_BASE_URL || ''}/${
    props.blockstar?.number
  }.png`;
  const borders = {
    border: 'solid #F4F4F4',
    borderWidth: props.showBorders ? '1px 0px' : '0px',
  };
  return props.basic ? (
    <TopContainer
      style={{
        padding: props.padding,
        margin: props.margin,
        height: props.height || 56,
        ...borders,
      }}>
      <img
        src={imgSrc}
        width={56}
        height={56}
        style={{ objectFit: 'cover', borderRadius: 8 }}
      />
      <InfoContainer style={{ height: 56 }}>
        <InfoItem style={{ fontSize: 24, textAlign: 'left' }}>
          {getBlockstarName(props.blockstar, !props.hideNumber)}
        </InfoItem>
        <InfoItem style={{ marginLeft: -3 }}>
          <Stars
            currentStars={props.blockstar.starRatingInfo.currentStarRating}
            potentialStars={props.blockstar.starRatingInfo.maxStarRating}
          />
        </InfoItem>
      </InfoContainer>
    </TopContainer>
  ) : (
    <TopContainer
      style={{
        padding: props.padding,
        margin: props.margin,
        height: props.height,
        ...borders,
      }}>
      <img
        src={imgSrc}
        width={84}
        height={84}
        style={{ objectFit: 'cover', borderRadius: 8 }}
      />
      <InfoContainer>
        <InfoItem style={{ fontSize: 24, textAlign: 'left' }}>
          {getBlockstarName(props.blockstar)}
        </InfoItem>
        <InfoNumber>{`#${props.blockstar.number}`}</InfoNumber>
        <InfoItem style={{ marginLeft: -3 }}>
          <Stars
            currentStars={props.blockstar.starRatingInfo.currentStarRating}
            potentialStars={props.blockstar.starRatingInfo.maxStarRating}
          />
          <DividerVertical />
          <RolSvgStyled />
          <p style={{ color: theme.colors.text.musicalAqua }}>
            {props.blockstar.wage.bucket}
          </p>
        </InfoItem>
      </InfoContainer>
    </TopContainer>
  );
};
