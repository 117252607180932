import React from 'react';
import {
  BarBG,
  CurrentBar,
  CurrentBarMusical,
  CurrentBarMusicalNotMatched,
  PotentialBar,
  XpGainBar,
} from './style';

export const AbilityBar = (props: {
  musical: boolean;
  currentLevel: number;
  targetLevel?: number;
  minPotential: number;
  maxPotential: number;
  xpGain?: number;
}) => {
  const CurrentAbilityBar = props.musical
    ? !props.targetLevel || props.currentLevel === props.targetLevel
      ? CurrentBarMusical
      : CurrentBarMusicalNotMatched
    : CurrentBar;
  return (
    <BarBG>
      {props.targetLevel && props.targetLevel !== props.currentLevel && (
        <PotentialBar
          style={{
            position: 'absolute',
            left: '0px',
            height: '100%',
            width: `${(props.currentLevel / 100) * 100}%`,
            zIndex: 0,
          }}
        />
      )}
      <CurrentAbilityBar
        style={{
          flex: props.targetLevel ?? props.currentLevel,
          zIndex: 2,
        }}
      />
      {props.xpGain && <XpGainBar style={{ flex: props.xpGain }} />}
      <div
        style={{
          display: 'flex',
          flex:
            props.minPotential -
            ((props.targetLevel ?? props.currentLevel) + (props.xpGain ?? 0)),
        }}
      />
      <PotentialBar
        style={{
          flex: props.maxPotential - props.minPotential,
        }}
      />
      <div style={{ display: 'flex', flex: 100 - props.maxPotential }} />
    </BarBG>
  );
};
