import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Paper,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BlockstarActionType, ThemeType } from '@shared-data';
import React, { useState } from 'react';
import { TooltipContainer } from '../ngmi/style';

export enum AccordionDirection {
  UP,
  DOWN,
}

export const AccordionButtonGroup = (props: {
  style?: React.CSSProperties;
  expanded?: boolean;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  elevation?: number;
  buttons: JSX.Element[];
  accordionDirection?: AccordionDirection;
}) => {
  const theme = useTheme() as ThemeType;
  const [zIndex, setZIndex] = useState<number>();

  return (
    <Accordion
      sx={{
        '&:before': {
          display: 'none',
        },
        '& .MuiAccordionDetails-root': { padding: 0 },
      }}
      style={{
        position: 'absolute',
        width: '100%',
        bottom:
          props.accordionDirection &&
          props.accordionDirection === AccordionDirection.DOWN
            ? undefined
            : 0,
        borderRadius: 8,
        border: `1px solid ${theme.colors.ui.primaryPurple}`,
        overflow: 'hidden',
        zIndex,
        ...props.style,
      }}
      expanded={props.expanded}
      onChange={props.onChange}
      elevation={props.elevation && zIndex ? props.elevation : 0}
      TransitionProps={{
        onEnter: () => {
          setZIndex(1);
        },
        onExited: () => {
          setZIndex(undefined);
        },
      }}
      disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color='primary' />}
        style={{ color: theme.colors.ui.primaryPurple, padding: '0px 16px' }}>
        Actions
      </AccordionSummary>
      <AccordionDetails>
        <ButtonGroup
          fullWidth
          orientation='vertical'
          variant='text'
          style={{
            borderTop: `1px solid ${theme.colors.ui.primaryPurple}`,
            borderRadius: 0,
          }}>
          {props.buttons}
        </ButtonGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export const AccordionActionButton = (props: {
  title: string;
  actionType: BlockstarActionType;
  onButtonClicked: (type: BlockstarActionType) => void;
  tooltip?: NonNullable<React.ReactNode>;
  disabled?: boolean;
}) => {
  const { title, actionType, onButtonClicked, tooltip, disabled } = props;
  const theme = useTheme() as ThemeType;
  const button = (
    <Button
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        paddingLeft: 16,
        cursor: disabled ? 'help' : 'pointer',
        color: disabled
          ? theme.colors.text.secondaryGray
          : theme.colors.ui.primaryPurple,
      }}
      fullWidth
      onClick={() => {
        if (!disabled) {
          onButtonClicked(actionType);
        }
      }}>
      <span
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}>
        {title}
      </span>
    </Button>
  );

  if (disabled) {
    if (!tooltip) {
      console.error('Tooltip needs to be provided');
      return button;
    }
    return (
      <Tooltip placement='right' title={tooltip}>
        {button}
      </Tooltip>
    );
  }
  return button;
};

export const AccordionToolTip = (text: string) => {
  return (
    <Paper>
      <TooltipContainer>
        <span>{text}</span>
      </TooltipContainer>
    </Paper>
  );
};
