import { styled } from '@mui/material';

export const Container = styled('div')`
  width: 800px;
  /* height: 612px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  row-gap: 32px;
`;

export const LocationContainer = styled('div')`
  width: 100%;
  height: 350px;
`;

export const CharacterImageContainer = styled('div')`
  position: absolute;
  width: 270px;
  height: 270px;
  right: 32px;
  bottom: 195px;
  z-index: 0;
`;

export const SecondHalfContainer = styled('div')`
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
`;

export const NameContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
`;

export const NameTextContainer = styled('h1')`
  width: 80%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 30px;
`;

export const InfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 28px;
`;

export const DataContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 28px;
`;

export const DataItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  column-gap: 8px;
`;

export const DataText = styled('p')`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProgressBarContainer = styled('div')`
  width: 100%;
`;

export const BottomContainer = styled('div')`
  height: 50px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  margin-bottom: 32px;
`;

export const BottomTimerContainer = styled('div')`
  display: flex;
  flex: 1.5;
  flex-direction: row;
`;

export const BottomButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  column-gap: 4px;
  justify-content: flex-end;
`;
