import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { styled } from '@mui/material/styles';

export const StarFilled = styled(StarRoundedIcon)`
  color: #f8ce2b;
`;

export const StarHalfFilled = styled(StarRoundedIcon)`
  color: #f8ce2b;
  clip-path: inset(0 50% 0 0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StarFullPotential = styled(StarRoundedIcon)`
  color: #979797;
`;

export const StarHalfPotential = styled(StarRoundedIcon)`
  color: #979797;
  clip-path: inset(0 50% 0 0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StarUnreachable = styled(StarRoundedIcon)`
  color: #f4f4f4;
`;

export const StarEmpty = styled(StarBorderRoundedIcon)`
  color: ${(props) => props.theme.colors.ui.mediumGray};
`;
