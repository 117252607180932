import {
  Band,
  BandActionResponse,
  Blockstar,
  BlockstarActionType,
} from '@shared-data';
import React, { useEffect } from 'react';
import { sendBlockstarClickActionEvent } from 'src/actions/action';
import { BlockstarActions } from 'src/hooks/blockstar-action';
import {
  IBlockstarActionControl,
  useBlockstarActionControlContext,
} from 'src/hooks/blockstar-action-control';
import { genreData } from 'src/utils/utils';
import { KeyedMutator } from 'swr';
import { MiddleMembersMember } from './middle-members-member';
import {
  MiddleMembersBottomContainer,
  MiddleMembersContainer,
  MiddleMembersMembersCount,
  MiddleMembersMembersText,
  MiddleMembersTopContainer,
} from './style';

export const MiddleMembers = (props: {
  band: Band;
  members: Blockstar[];
  blockstarActions?: BlockstarActions;
  bandAction?: BandActionResponse;
  mutateBlockstarActions: KeyedMutator<BlockstarActions>;
}) => {
  const genre = genreData[props.band.genre];
  const subgenre = genre.subgenres[props.band.subgenre.toString()];

  const {
    setActionType,
    setBlockstar,
    setOpenActionProgressDialog,
    actionResult,
  } = useBlockstarActionControlContext() as IBlockstarActionControl;

  useEffect(() => {
    if (actionResult) {
      props.mutateBlockstarActions();
    }
  }, [actionResult]);

  const onBlockstarActionOpen = (
    blockstar: Blockstar,
    actionType?: BlockstarActionType,
  ) => {
    if (!actionType) {
      return;
    }

    sendBlockstarClickActionEvent(blockstar, actionType);
    setActionType(actionType);
    setBlockstar(blockstar);
    setOpenActionProgressDialog(true);
  };

  return (
    <MiddleMembersContainer>
      <MiddleMembersTopContainer>
        <MiddleMembersMembersText>Members</MiddleMembersMembersText>
        <MiddleMembersMembersCount>{`Min ${subgenre.requirements.minMembers} / Max ${subgenre.requirements.maxMembers}`}</MiddleMembersMembersCount>
      </MiddleMembersTopContainer>
      <MiddleMembersBottomContainer columnCount={props.members.length}>
        {props.members.map((member, index) => {
          return (
            <MiddleMembersMember
              key={index}
              member={member}
              band={props.band}
              blockstarAction={
                props.blockstarActions
                  ? props.blockstarActions[member.number]
                  : undefined
              }
              bandAction={props.bandAction}
              onActionOpen={onBlockstarActionOpen}
              mutateBlockstarActions={props.mutateBlockstarActions}
            />
          );
        })}
      </MiddleMembersBottomContainer>
    </MiddleMembersContainer>
  );
};
