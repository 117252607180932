import { AttributeCounter } from '@shared-data';
import useSWRImmutable from 'swr/immutable';
import rest, { BlockstarsRequests } from '../actions/network/rest';

export const useAttributeCounts = () => {
  const { data, error } = useSWRImmutable<AttributeCounter>(
    BlockstarsRequests.Counts,
    rest.get,
  );

  return data;
};
