import { useTheme } from '@emotion/react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
  ActionStatus,
  Band,
  BandActionResponse,
  bandActionStartTime,
  Blockstar,
  BlockstarActionType,
  RoutePath,
  ThemeType,
} from '@shared-data';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionStatusView } from 'src/components/library/action-status-overlay';
import { ActionCustomOverlay } from 'src/components/library/action-status-overlay/custom-overlay';
import { DisabledBadge } from 'src/components/library/disabled-badge';
import { CheckMark, ExclamationMark } from 'src/components/library/icons';
import {
  CollageFrame,
  CollageImage,
  InfoContainer,
  InfoItem,
  InfoNumber,
  Opaque,
} from 'src/components/library/style';
import Stars from 'src/components/star-rating';
import { CompleteStar, RolSvgStyled } from 'src/components/tokens/nft/style';
import { useBandAction } from 'src/hooks/band-action';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import { useAllBlockstarActions } from 'src/hooks/blockstar-action';
import { DividerHorizontal, DividerVertical } from 'src/styles/global-style';
import analytics from 'src/utils/analytics';
import {
  bandMembers,
  bandSalary,
  bandSalaryCutMember,
  capitalizeFirstLetter,
  genreData,
  getBandActionResults,
  getBandCollegeColumnRowCount,
  getBandName,
} from 'src/utils/utils';
import { ActionAccordion } from '../../others/action-accordion';
import { generateNavigateState } from '../../others/helper';
import {
  GenreContainer,
  GenreIcon,
  GenreText,
  GridItemContainer,
  GridItemInfoContainer,
  GridItemNameContainer,
  SalaryContainer,
  StarSalaryContainer,
} from './style';

export const BandGridItem = (props: {
  band: Band;
  blockstars: Blockstar[];
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { actions: blockstarActions } = useAllBlockstarActions(
    props.band.memberIds,
  );
  const { bandAction, mutateBandAction } = useBandAction(props.band.id);

  const {
    setOpenActionResultDialog,
    setOpenActionProgressDialog,
    setBand,
    setActionType,
    setActionResult,
    setActionResultError,
  } = useBandActionControlContext() as IBandActionControl;

  const getResults = async () => {
    try {
      const result = await getBandActionResults(props.band.id, bandAction);
      setActionResult(result);
      setActionResultError(undefined);
    } catch {
      setActionResult(undefined);
      setActionResultError('Error getting results');
    }
  };

  const [showActionStatusOverly, setShowActionStatusOverly] = useState(false);

  useEffect(() => {
    setShowActionStatusOverly(bandAction !== undefined);
  }, [bandAction]);

  const onActionButtonClicked = (actionType: BlockstarActionType) => {
    switch (actionType) {
      case BlockstarActionType.View:
        navigate(`${RoutePath.Bands}/${props.band.id}`, {
          state: { originPage: location.pathname },
        });
        break;
      case BlockstarActionType.Edit:
        onEditBandClick();
        break;
      case BlockstarActionType.BandPracticing:
        // handled in accordion, do nothing here.
        break;
      case BlockstarActionType.BandGigging:
        // handled in accordion, do nothing here.
        break;
    }
  };

  const onEditBandClick = () => {
    navigate(RoutePath.BandsEdit, {
      state: {
        originPage: location.pathname,
        isEdit: true,
        ...generateNavigateState(props.band),
      },
    });
  };

  const members = bandMembers(props.band.memberIds, props.blockstars).filter(
    (member) => member !== undefined,
  );

  const genre = genreData[props.band.genre];
  const collageColumnRowCount = getBandCollegeColumnRowCount(
    props.band.memberIds.length,
  );

  const salaryCutInfo = bandSalaryCutMember(members);
  const salary = !props.band.notValid ? bandSalary(members) : 0;

  const onActionStatusOverlayClose = async (close?: boolean) => {
    setShowActionStatusOverly(false);
    // complete action here if we're not opening results page!!!
    if (close) {
      try {
        await getBandActionResults(props.band.id, bandAction);
        await mutateBandAction();
      } catch (e) {
        toast.error(`Couldn't finish ${bandAction?.action?.actionType}.`);
      }
    }
  };

  const onActionStatusOverlayDetail = async (completed: boolean) => {
    setShowActionStatusOverly(false);
    setBand(props.band);
    if (bandAction) {
      setActionType(bandAction?.action?.actionType);
    }
    if (completed) {
      await onActionStatusOverlayClose();
      // open result view
      setOpenActionResultDialog(true);
      getResults();
    } else {
      setOpenActionProgressDialog(true);
      setShowActionStatusOverly(true);
    }
  };

  return (
    <GridItemContainer>
      <CollageFrame
        style={{ width: '270px', height: '270px', borderRadius: '15px' }}
        collageColumnRowCount={collageColumnRowCount}>
        {props.band.memberIds.map((memberId, index) => {
          return (
            <CollageImage
              key={index}
              src={`${process.env.ASSET_BASE_URL}/${memberId}.png`}
            />
          );
        })}
        {props.band.notValid && <Opaque />}
        {props.band.notValid && (
          <ActionCustomOverlay
            icon={<ExclamationMark />}
            title='Requires action'
            buttonText='Edit band'
            onClick={onEditBandClick}
          />
        )}
        {showActionStatusOverly && bandAction && (
          <BandActionStatusOverlay
            actionResponse={bandAction}
            onClick={onActionStatusOverlayDetail}
            onClose={() => {
              onActionStatusOverlayClose(true);
            }}
          />
        )}
      </CollageFrame>
      <GridItemInfoContainer>
        <InfoContainer style={{ paddingLeft: '0px' }}>
          {/* name */}
          <GridItemNameContainer>
            <InfoItem
              style={{
                fontSize: 24,
                height: 28,
                textAlign: 'left',
                fontWeight: 'bold',
              }}>
              <p>{getBandName(props.band)}</p>
            </InfoItem>
            {props.band.notValid && (
              <DisabledBadge title='Inactive' showIcon={false} />
            )}
          </GridItemNameContainer>
          {/* number */}
          <InfoNumber>
            <PeopleAltIcon style={{ fontSize: '16px' }} />
            {`#${props.band.id}`}
          </InfoNumber>
          <StarSalaryContainer>
            {/* star | salary */}
            <Stars
              currentStars={props.band.starRating?.currentStarRating ?? 0}
              potentialStars={props.band.starRating?.potentialStarRating ?? 5}
            />
            <DividerVertical />
            <SalaryContainer>
              <RolSvgStyled />
              {bandSalaryCutMember(members)?.wage.bucket}
            </SalaryContainer>
          </StarSalaryContainer>
        </InfoContainer>
        <DividerHorizontal />
        {/* genre / subgenre */}
        <GenreContainer>
          <GenreIcon />
          <GenreText>{`${genre.displayName} / ${
            genre.subgenres[props.band.subgenre.toString()].displayName
          }`}</GenreText>
        </GenreContainer>
        {/* action accordion */}
        <ActionAccordion
          band={props.band}
          bandAction={bandAction?.action}
          blockstarActions={blockstarActions}
          viewBandAvailable
          onActionButtonClicked={onActionButtonClicked}
        />
      </GridItemInfoContainer>
    </GridItemContainer>
  );
};

const BandActionStatusOverlay = (props: {
  actionResponse: BandActionResponse;
  onClick: (completed: boolean) => void;
  onClose: () => void;
}) => {
  const startTime = bandActionStartTime(props.actionResponse.action);
  const [endDate, setEndDate] = useState(
    startTime + props.actionResponse.action.durationMs,
  );
  const [actionStatus, setActionStatus] = useState(
    props.actionResponse.action.actionStatus,
  );

  const fireStat = (userAction: string) => {
    let xpScreen = 'xp earned';
    if (props.actionResponse.bandActionResponse) {
      xpScreen = 'stars increased';
    }

    analytics.logEvent('ClickOverlayActionComplete', {
      groupType: 'band',
      action: props.actionResponse.action.actionType,
      userAction,
      xpScreen,
    });
  };

  const currentTimeLeft =
    Date.now() < startTime + props.actionResponse.action.durationMs
      ? startTime + props.actionResponse.action.durationMs - Date.now()
      : 0;
  const theme = useTheme() as ThemeType;
  switch (actionStatus) {
    case ActionStatus.InAction:
      const onCountDownComplete = () => {
        setActionStatus(ActionStatus.Completed);
      };

      return (
        <ActionStatusView
          timeLeft={currentTimeLeft}
          durationMs={props.actionResponse.action.durationMs}
          title={capitalizeFirstLetter(props.actionResponse.action.actionType)}
          onViewClick={() => props.onClick(false)}
          onCountDownComplete={onCountDownComplete}
        />
      );
    case ActionStatus.Completed:
      let icon = (
        <CheckMark
          width={24}
          height={24}
          bgOuterColor={theme.colors.ui.okOuter}
          bgInnerColor={theme.colors.ui.okInner}
        />
      );
      let text = `${capitalizeFirstLetter(
        props.actionResponse.action.actionType,
      )} complete!`;
      if (props.actionResponse.bandActionResponse) {
        icon = <CompleteStar />;
        text = 'You earned a star!';
      }

      return (
        <ActionCustomOverlay
          icon={icon}
          title={text}
          buttonText='View details'
          onClick={() => {
            fireStat('view details');
            props.onClick(true);
          }}
          onClose={() => {
            fireStat('dismiss');
            props.onClose();
          }}
        />
      );
    default:
      return <></>;
  }
};
