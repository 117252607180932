import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  WalletConnectionError,
  WalletError,
  WalletNotReadyError,
} from '@solana/wallet-adapter-base';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import React, { useCallback, useMemo } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SWRConfig } from 'swr';
import './App.css';
import AppRouter from './components/app-router';
import { UnityReact } from './components/unity-react';
import Theme, { globalStyle } from './styles/theme';
import analytics from './utils/analytics';
import { getSolNetwork } from './utils/utils';

declare const VERSION: string;

const App = () => {
  let version = '0.1.0';
  try {
    version = VERSION;
  } catch (error) {
    // Make sure to set webpack.DefinePlugin with VERSION.
    console.log("Couldn't get the version. Is it declared correctly?");
  }

  analytics.init();
  analytics.setVersion(version);
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    release: version,
    environment: process.env.TARGET_ENV,
    tracesSampleRate: Number(process.env.SENTRY_TRACE_SAMPLE_RATE),
  });

  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = getSolNetwork(process.env.SOLANA_ENV);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new SlopeWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network],
  );

  const onError = useCallback((error: WalletError) => {
    console.warn(`WalletError: ${error?.name}: ${error?.message}`);
    if (error instanceof WalletNotReadyError) {
      toast.error(`Extension not found!`);
    } else if (error instanceof WalletConnectionError) {
      toast.error(`Canceled.`);
    }
  }, []);

  return (
    <div className='App'>
      <SWRConfig value={{ revalidateOnFocus: false }}>
        <Theme>
          {globalStyle}
          {process.env.SOLANA_RPC_ENDPOINT ? (
            <ConnectionProvider endpoint={process.env.SOLANA_RPC_ENDPOINT}>
              <WalletProvider wallets={wallets} onError={onError} autoConnect>
                <WalletModalProvider>
                  <AppRouter />
                </WalletModalProvider>
              </WalletProvider>
            </ConnectionProvider>
          ) : (
            <div>
              <p>SOLANA_RPC_ENDPOINT is not set!.</p>
            </div>
          )}

          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            draggable
            pauseOnFocusLoss={false}
            pauseOnHover
          />
        </Theme>
      </SWRConfig>
      <UnityReact style={{ width: 1, height: 1, zIndex: 10000 }} />
    </div>
  );
};

export default App;
