import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { styled } from '@mui/material/styles';
import rolIcon from '../assets/images/icon_rol.svg';
import rolIconNoBG from '../assets/images/icon_rol_nobg.svg';
import brassIcon from '../assets/images/skill-icons/brass.svg';
import keyboardIcon from '../assets/images/skill-icons/keyboard.svg';
import otherIcon from '../assets/images/skill-icons/other.svg';
import percussionIcon from '../assets/images/skill-icons/percussion.svg';
import stringIcon from '../assets/images/skill-icons/string.svg';
import voiceIcon from '../assets/images/skill-icons/voice.svg';
import woodwindIcon from '../assets/images/skill-icons/woodwind.svg';
import { headerHeight } from '../components/header/style';
import { px2vw } from '../utils/utils';

export const TextH1 = styled('h3')`
  font-size: ${px2vw(80)};
  @media (min-width: 768px) {
    font-size: ${px2vw(40)};
  }

  @media (min-width: 1024px) {
    font-size: ${px2vw(60)};
  }
`;

export const TextH3 = styled('h3')`
  font-size: ${px2vw(40)};
  @media (min-width: 768px) {
    font-size: ${px2vw(20)};
  }

  @media (min-width: 1024px) {
    font-size: ${px2vw(30)};
  }
`;

export const TextNormal = styled('h6')`
  font-size: ${px2vw(24)};
  @media (min-width: 768px) {
    font-size: ${px2vw(12)};
  }

  @media (min-width: 1024px) {
    font-size: ${px2vw(18)};
  }
`;

export const Input = styled('input')`
  font-size: 18px;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 3px;
  ::placeholder {
    color: gray;
  }
`;

export const Button = styled('button')`
  font-size: 18px;
  padding: 10px;
  width: 200px;
  background: linear-gradient(20deg, hsl(180, 60%, 65%), hsl(-125, 64%, 60%));
  border: none;
  border-radius: 10px;
  &:hover:enabled {
    cursor: pointer;
  }
`;

export const TooltipText = styled('div')`
  /* background: rgba(28, 56, 151, 0.9); */
  /* color: #fff; */
  /* width: 200px; */
  /* text-align: center; */
  /* line-height: 44px;
  border-radius: 3px; */
  cursor: pointer;
`;
export const TooltipBox = styled('div')`
  position: absolute;
  top: calc(100% + 10px);
  left: -40px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 350px;
  padding: 5px 5px;
  border-radius: 4px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s,
    padding 0.5s ease-in-out;
  &:before {
    content: '';
    height: 100;
    left: 40px;
    top: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`;

export const TooltipCard = styled('div')`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: '350px';
    padding: 8px 8px;
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`;

export const BodyContainer = styled('div')`
  width: 100%;
  height: ${window.innerHeight - headerHeight}px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
`;

export const InfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  width: 600px;
  margin: auto;
`;

export const InfoHeader = styled('h1')`
  text-align: left;
  margin-bottom: 30px;
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const InfoBody = styled('p')`
  text-align: left;
`;

export const DividerHorizontal = styled('hr')`
  width: 100%;
  opacity: 30%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const DividerVertical = styled('hr')`
  height: 100%;
  opacity: 30%;
  margin-left: 16px;
  margin-right: 16px;
`;

export const RolSvg = styled('div')`
  mask-image: url(${rolIcon});
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;

  width: 25px;
  height: 25px;
  border: none;
  border-radius: 50%;
  margin-right: 5px;
  background-color: ${(props) => props.theme.colors.text.musicalAqua};
`;

export const RolIcon = styled('div')`
  mask-image: url(${rolIconNoBG});
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  width: 25px;
  height: 25px;
  background: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const CloseContainer = styled('div')`
  height: 50px;
  width: 100%;
  position: absolute;
  top: 64px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
`;

export const SkillIconBase = styled('div')`
  margin-top: 2px;
  width: 80%;
  height: 80%;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const InstrumentIcon = styled(MusicNoteIcon)`
  /* font-size: inherit; */
  width: 80%;
  height: 80%;
`;

export const BrassIcon = styled(SkillIconBase)`
  background-image: url(${brassIcon});
`;

export const FameIcon = styled(AutoAwesomeIcon)`
  font-size: inherit;
  width: 80%;
  height: 80%;
`;

export const GiggingIcon = styled(MicExternalOnIcon)`
  font-size: inherit;
  width: 80%;
  height: 80%;
`;

export const KeyboardIcon = styled(SkillIconBase)`
  background-image: url(${keyboardIcon});
`;

export const OtherIcon = styled(SkillIconBase)`
  background-image: url(${otherIcon});
`;

export const PercussionIcon = styled(SkillIconBase)`
  background-image: url(${percussionIcon});
`;

export const PracticingIcon = styled(HeadphonesIcon)`
  font-size: inherit;
  width: 80%;
  height: 80%;
`;

export const PromotingIcon = styled(StackedLineChartIcon)`
  font-size: inherit;
  width: 80%;
  height: 80%;
`;

export const RecordingIcon = styled(GraphicEqIcon)`
  font-size: inherit;
  width: 80%;
  height: 80%;
`;

export const SocialMediaIcon = styled(PhoneIphoneIcon)`
  font-size: inherit;
  width: 80%;
  height: 80%;
`;

export const StringIcon = styled(SkillIconBase)`
  background-image: url(${stringIcon});
`;

export const VoiceIcon = styled(SkillIconBase)`
  background-image: url(${voiceIcon});
`;

export const WoodwindIcon = styled(SkillIconBase)`
  background-image: url(${woodwindIcon});
`;

export const WritingIcon = styled(DriveFileRenameOutlineIcon)`
  font-size: inherit;
  width: 80%;
  height: 80%;
`;

export const ErrorText = styled('p')`
  color: ${(props) => props.theme.colors.text.error};
  font-weight: bold;
`;
