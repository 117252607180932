import { useTheme } from '@mui/material/styles';
import { ThemeType } from '@shared-data';
import React from 'react';
import {
  StarEmpty,
  StarFilled,
  StarFullPotential,
  StarHalfFilled,
  StarHalfPotential,
  StarUnreachable,
} from './style';

enum StarRatingType {
  Empty,
  Unreachable,
  HalfPotential,
  FullPotential,
  HalfFilled,
  Filled,
}

const Star = (props: { starRatingType: StarRatingType; starSize?: number }) => {
  const theme = useTheme() as ThemeType;
  const starSize = props.starSize ?? 24;
  const style = { fontSize: starSize, width: starSize, height: starSize };

  switch (props.starRatingType) {
    case StarRatingType.Filled:
      return <StarFilled style={style} />;
    case StarRatingType.HalfFilled:
      return (
        <div style={{ ...style, position: 'relative' }}>
          <StarFullPotential style={style} />
          <StarHalfFilled style={style} />
        </div>
      );
    case StarRatingType.FullPotential:
      return <StarFullPotential style={style} />;
    case StarRatingType.HalfPotential:
      return (
        <div style={{ ...style, position: 'relative' }}>
          <StarUnreachable style={style} />
          <StarHalfPotential style={style} />
        </div>
      );
    case StarRatingType.Unreachable:
      return <StarUnreachable style={style} />;
    default:
      return <StarEmpty style={style} />;
  }
};

const Stars = (props: {
  currentStars: number;
  potentialStars: number;
  starSize?: number;
  preview?: boolean;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
      {[1, 2, 3, 4, 5].map((val) => {
        let starRatingType = StarRatingType.Filled;
        if (val > props.potentialStars) {
          starRatingType = StarRatingType.Unreachable;
          if (val - props.potentialStars < 1) {
            starRatingType = StarRatingType.HalfPotential;
          }
        } else if (val > props.currentStars) {
          starRatingType = StarRatingType.FullPotential;
          if (val - props.currentStars < 1) {
            starRatingType = StarRatingType.HalfFilled;
          }
        }
        if (props.preview) {
          starRatingType = StarRatingType.Empty;
        }
        return (
          <Star
            key={val}
            starRatingType={starRatingType}
            starSize={props.starSize}></Star>
        );
      })}
    </div>
  );
};

export default Stars;
