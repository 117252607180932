import { useEffect } from 'react';

export const useWindowEvent = (
  event: string,
  callback: (event: any) => void,
  dependencies?: any[],
) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback, ...(dependencies ?? [])]);
};
