import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  InfoBody,
  InfoContainer,
  InfoHeader,
} from '../../../styles/global-style';
import analytics from '../../../utils/analytics';
import { MarketplaceButton } from '../ft/style';

export const NoNftInfo = () => {
  const currentPage = useLocation().pathname;
  return (
    <InfoContainer>
      <InfoHeader>My Blockstars</InfoHeader>
      <InfoBody>Oops, you have no Blockstars in this wallet! {`\n`}</InfoBody>
      <InfoBody>
        Check to make sure you are logged into the right wallet or buy a
        Blockstar from the market.
      </InfoBody>
      <div style={{ margin: 8 }} />
      <MarketplaceButton
        onClick={() => {
          analytics.logEvent('OpenMagicEden', {
            currentPage: currentPage,
          });
          window.open('https://magiceden.io/marketplace/blockstars', '_blank');
        }}>
        Visit Marketplace
      </MarketplaceButton>
    </InfoContainer>
  );
};

export default NoNftInfo;
