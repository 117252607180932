import DiamondIcon from '@mui/icons-material/Diamond';
import React from 'react';
import { AttributeEntryContainer, AttributeText, Common, Rare } from './style';

export const AttributeEntry = (props: {
  type: string;
  value: string;
  rarity?: number;
}) => {
  let rarity;
  if (props.rarity) {
    if (props.rarity < 111) {
      rarity = (
        <Rare>
          <DiamondIcon fontSize={'inherit'} />
          <span style={{ marginLeft: 6 }}>{props.rarity.toLocaleString()}</span>
        </Rare>
      );
    } else {
      rarity = <Common>{props.rarity.toLocaleString()}</Common>;
    }
  }
  return (
    <AttributeEntryContainer>
      <AttributeText>
        <span style={{ fontSize: 12 }}>{props.type}</span>
        <span
          style={{
            fontSize: 16,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}>
          {props.value}
        </span>
      </AttributeText>
      {rarity}
    </AttributeEntryContainer>
  );
};
