import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useTheme } from '@mui/material/styles';
import { ActionTargetType, NameChangeInfo, ThemeType } from '@shared-data';
import React from 'react';
import { useLocation } from 'react-router-dom';
import analytics from '../../utils/analytics';
import {
  getAccountLink,
  scanEnvString,
  truncateString,
} from '../../utils/utils';
import HoverComponent from '../hover-component/hover-component';
import { DisabledBadge } from '../library/disabled-badge';
import { infoIcon } from '../library/info-icon';
import { InfoNumber } from '../library/style';
import { StarRatingTooltipComponent } from '../library/tooltip-components';
import { NameEditButton } from '../name-change/name-edit-button';
import Stars from '../star-rating';
import { MarketplaceButton } from '../tokens/ft/style';
import { ItemInfoNumberContainer } from '../tokens/nft/style';
import {
  SummaryContainer,
  SummaryInfo,
  SummaryLeft,
  WalletText,
} from './style';

export const Summary = (props: {
  name: string;
  wallet: string;
  number: number;
  currentStars: number;
  potentialStars: number;
  fired?: boolean;
  canEditNameInfo: NameChangeInfo;
  bandId?: string;
  onNameChangeClicked?: () => void;
}) => {
  const currentPage = useLocation().pathname;
  const theme = useTheme() as ThemeType;

  return (
    <SummaryContainer>
      <SummaryInfo>
        <SummaryLeft>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
            <span style={{ fontSize: 30, fontWeight: 'bold' }}>
              {props.name}
            </span>
            {
              // TODO: remove this temp blocker when signature function is implemented.
              !process.env.BLOCK_NAME_CHANGE && (
                <NameEditButton
                  type={ActionTargetType.Blockstar}
                  canEditNameInfo={props.canEditNameInfo}
                  onNameChangeClicked={props.onNameChangeClicked}
                />
              )
            }
          </div>
          <div style={{ margin: 2 }} />
          <ItemInfoNumberContainer>
            <InfoNumber>{`#${props.number}`}</InfoNumber>
            {props.bandId !== undefined && (
              <InfoNumber>
                <PeopleAltIcon style={{ fontSize: '16px' }} />
                {`#${props.bandId}`}
              </InfoNumber>
            )}
          </ItemInfoNumberContainer>
          <div style={{ margin: 2 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Stars
              currentStars={props.currentStars}
              potentialStars={props.potentialStars}
            />
            <div style={{ margin: 5 }} />
            <HoverComponent
              hoverComponent={infoIcon}
              tooltipComponent={
                <StarRatingTooltipComponent
                  target={ActionTargetType.Blockstar}
                />
              }
            />
            <div style={{ margin: 5 }} />
            {props.fired && <DisabledBadge title={'FIRED'} showIcon={true} />}
          </div>
        </SummaryLeft>
        <div style={{ display: 'flex' }}>
          <MarketplaceButton
            style={{ width: 175 }}
            onClick={() => {
              analytics.logEvent('OpenHowRare', {
                blockstarId: props.number,
                currentPage: currentPage,
              });
              window.open(
                `https://howrare.is/blockstars/${props.number}`,
                '_blank',
              );
            }}>
            View on HowRare
          </MarketplaceButton>
        </div>
      </SummaryInfo>
      <span style={{ textAlign: 'start', fontSize: 16 }}>
        Owned by{' '}
        <WalletText
          style={{ textDecoration: 'none' }}
          href={getAccountLink(props.wallet, scanEnvString)}
          onClick={() =>
            analytics.logEvent('OpenTransactionHistory', {
              id: `${props.wallet}${scanEnvString}`,
              service: process.env.TRANSACTION_LINK_SERVICE,
            })
          }
          target='_blank'
          rel='noreferrer'>
          {truncateString(props.wallet)}
        </WalletText>
      </span>
    </SummaryContainer>
  );
};
