import { useTheme } from '@mui/material';
import {
  Blockstar,
  BlockstarActionType,
  BlockstarSkillType,
  PrepType,
  SkillReward,
  ThemeType,
} from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ActionOptions } from 'src/actions/action';
import rest, { BandActionPath } from 'src/actions/network/rest';
import BandHeader from 'src/components/bands/header/popup-header';
import { bandTxnPrep } from 'src/components/bands/others/helper';
import { SkillEntryType } from 'src/components/library/types';
import { useActionOptions } from 'src/hooks/action-options';
import { useBandAction } from 'src/hooks/band-action';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import { useBlockstarsByIds } from 'src/hooks/blockstars';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import analytics from 'src/utils/analytics';
import {
  bandSalary,
  bandSalaryCutMember,
  getLocalStorage,
  setLocalStorage,
  StorageType,
} from 'src/utils/utils';
import SelectAction from '.';
import BandActionSelectFooter from './contents/band-action-select-footer';
import BandSkillDropdown, {
  IActionBandSkillsData,
} from './contents/band-skill-dropdown';
import DurationDropdown from './contents/duration-dropdown';
import LocationDropdown from './contents/location-dropdown';
import { TabButton } from './style';

const BandActionSelect = () => {
  const { publicKey, signTransaction } = useWallet();
  const { connection } = useConnection();

  const theme = useTheme() as ThemeType;
  const {
    openActionSelectDialog,
    setOpenActionSelectDialog,
    setOpenActionProgressDialog,
    actionButtonLoading,
    setActionButtonLoading,
    actionType,
    band,
  } = useBandActionControlContext() as IBandActionControl;

  const { blockstars, mutateBlockstars } = useBlockstarsByIds(band?.memberIds);
  const { locations, durations } = useActionOptions(actionType);
  const { mutateBandAction } = useBandAction(band?.id);
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);

  const [selectedSkill, setSelectedSkill] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(-1);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [estimate, setEstimate] = useState<SkillReward[][] | undefined>();

  useEffect(() => {
    if (!openActionSelectDialog) {
      return;
    }
    const defaultOptions = getLocalStorage(
      StorageType.BAND_ACTION_OPTIONS,
      parseInt(band?.id ?? '0'),
    );

    if (defaultOptions) {
      const options = JSON.parse(defaultOptions) as ActionOptions;

      const skillsIdx = skills.findIndex(
        (s) => s.title === options.actionSkillType,
      );
      setSelectedSkill(skillsIdx >= 0 ? skillsIdx : 0);

      const locationIdx = locations.findIndex(
        (l) => l.id === options.location.id,
      );
      if (locationIdx >= 0) {
        setSelectedLocation(options.location.id);
      } else {
        setSelectedLocation(locations[0].id);
      }

      const durationIdx = durations.findIndex((d) => d === options.durationMs);
      if (durationIdx > 0) {
        setSelectedDuration(durationIdx);
      }
    } else {
      setSelectedLocation(locations[0].id);
    }

    analytics.logEvent('ViewScreenActionDialog', {
      groupType: 'band',
      action: BlockstarActionType.BandPracticing, // TODO: change actiontype if there're more actions added.
      screen: 'practice', // TODO: change actiontype if there're more actions added.
    });
  }, [openActionSelectDialog, actionType]);

  useEffect(() => {
    const onEstimate = async () => {
      const body = {
        bandId: band?.id,
        actionSkillType: skills[selectedSkill].type,
        actionType: BlockstarActionType.BandPracticing,
        duration: durations[selectedDuration],
        location: { id: selectedLocation },
      };
      try {
        const response = await rest.post<SkillReward[][]>(
          `${process.env.WORKER_URL}/${BandActionPath.Estimate}`,
          body,
        );
        setEstimate(response);
      } catch (e) {
        toast.error(`Error while calculating.`);
      }
    };

    if (!openActionSelectDialog || selectedLocation < 0) {
      return;
    }

    if (band && locations && durations && skills.length > 0) {
      onEstimate();
    }
  }, [
    openActionSelectDialog,
    selectedSkill,
    selectedLocation,
    selectedDuration,
  ]);

  const skills: IActionBandSkillsData[] = [];
  if (actionType === BlockstarActionType.BandPracticing) {
    let skillsName = '';
    band?.musicalMembers.forEach((member, index) => {
      skillsName += (index === 0 ? '' : ', ') + member?.blockstarSkill.name;
    });
    skills.push({
      type: SkillEntryType.MUSICAL,
      title: skillsName,
    });

    skills.push({
      type: SkillEntryType.GIGGING,
      title: SkillEntryType.GIGGING.toString(),
    });

    skills.push({
      type: SkillEntryType.RECORDING,
      title: SkillEntryType.RECORDING.toString(),
    });
  }

  if (
    !band ||
    !blockstars ||
    selectedLocation === undefined ||
    selectedDuration === undefined
  ) {
    return <div />;
  }

  const members: Blockstar[] = [];
  band?.memberIds.map((memberId) => {
    const blockstar = blockstars.find(
      (blockstar) => blockstar.number === memberId,
    );
    if (blockstar) {
      members.push(blockstar);
    }
  });

  const onTabClick = (type: BlockstarActionType) => {};

  const salary = bandSalary(members);
  const salaryCutInfo = bandSalaryCutMember(members);
  const durationCost = (durations[selectedDuration] * salary) / 86400000;

  let selectedLocationId = selectedLocation;
  let location = locations.find((l) => l.id === selectedLocation);
  if (!location) {
    location = locations[0];
    selectedLocationId = location.id;
  }
  const rental = (durations[selectedDuration] * location.dailyCost) / 86400000;
  const locationCost = location.deposit + rental;

  const onStartPressed = async () => {
    if (!publicKey || !signTransaction) {
      const notReady = 'Tried to start band action with no wallet ready...';
      console.error(notReady);
      toast.error(notReady);
      return;
    }
    const actionOptions: ActionOptions = {
      actionType: actionType,
      actionSkillType: skills[selectedSkill].title,
      durationMs: durations![selectedDuration],
      location: location!,
    };
    setLocalStorage(
      StorageType.BAND_ACTION_OPTIONS,
      parseInt(band?.id ?? '0'),
      JSON.stringify(actionOptions),
    );

    setActionButtonLoading(true);
    try {
      const rolToPay = locationCost + durationCost;
      const prepType = PrepType.BAND_ACTION;
      const prepBody = { bandId: band?.id };
      const txnResult = await bandTxnPrep(
        BandActionPath.Prepare,
        prepBody,
        connection,
        publicKey,
        rolBalance,
        rolToPay,
        signTransaction,
        prepType,
        band?.id,
      );

      // start action here!!!!
      const response = await rest.post(
        `${process.env.WORKER_URL}/${BandActionPath.Start}`,
        {
          bandId: band?.id,
          signature: txnResult.clientSignature,
          blockhash: txnResult.signedTxn.recentBlockhash,
        },
      );

      toast.success(
        `Transaction valid! ${rolToPay} $ROL deducted; ${
          band?.customName?.name ?? `#${band?.id}`
        }'s action has begun.`,
      );

      analytics.logEvent('ClickActionStart', {
        bandId: band.id,
        action: actionType,
        skill: skills[selectedSkill].title,
        location: location!.name,
        duration: durations[selectedDuration] / 60000,
        cost: rolToPay,
      });

      mutateBalance();
      setOpenActionProgressDialog(true);
      setActionButtonLoading(false);
      mutateBandAction();
    } catch (e: any) {
      setActionButtonLoading(false);
      let message = e.message ?? `Error while preparing the action.`;
      if (e.message === 'User rejected the request.') {
        message = 'Transaction not completed.';
      }
      toast.error(message);
    }
  };

  // Header
  const header = (
    <BandHeader band={band} salary={salary} salaryCutInfo={salaryCutInfo} />
  );

  // Tab Buttons
  const practiceTabButton = (
    <TabButton
      key={BlockstarActionType.BandPracticing}
      actionType={BlockstarActionType.BandPracticing}
      selectedActionType={actionType}
      variant={'text'}
      onClick={() => onTabClick(BlockstarActionType.BandPracticing)}>
      Practice
    </TabButton>
  );

  // contents
  const skillDropdown = (
    <BandSkillDropdown
      key={'skill'}
      disabled={actionButtonLoading}
      skills={skills}
      selectedSkill={selectedSkill}
      actionType={actionType}
      onSkillSelected={setSelectedSkill}
    />
  );
  const locationDropdown = (
    <LocationDropdown
      key={'location'}
      disabled={actionButtonLoading}
      error={false}
      locations={locations}
      selectedLocationId={selectedLocation}
      duration={durations[selectedDuration]}
      durationCost={durationCost}
      onLocationSelected={(idx: number) => {
        setSelectedLocation(locations[idx].id);
      }}
    />
  );
  const durationDropdown = (
    <DurationDropdown
      key={'duration'}
      disabled={actionButtonLoading}
      error={false}
      durations={durations}
      selectedDurationIndex={selectedDuration}
      locationCost={locationCost}
      salary={salary}
      onDurationSelected={setSelectedDuration}
    />
  );

  // get without practicing exp, actual practiced skill.
  const estimateParsed = estimate
    ?.map((skills) =>
      skills.find((skill) => skill.name !== BlockstarSkillType.Practicing),
    )
    .filter((item) => item !== undefined);

  const totalXpEarned = estimateParsed?.reduce(
    (accumulator, currentObj) =>
      accumulator + Math.floor(currentObj?.gainedExp ?? 0),
    0,
  );
  const xpDetails = estimateParsed?.map((skill) => {
    return {
      title:
        skill!.name === BlockstarSkillType.Gigging ||
        skill!.name === BlockstarSkillType.Recording
          ? `Blockstar #${skill!.blockstarId}`
          : skill!.name,
      value: Math.floor(skill!.gainedExp),
    };
  });

  // footer
  const footer = (
    <BandActionSelectFooter
      data={{
        skillType: skills[selectedSkill].type,
        spend: {
          title: 'Salary',
          total: `${(locationCost + durationCost).toLocaleString()} $ROL`,
          salary: durationCost,
          deposit: location.deposit,
          rental: rental,
        },
        earn: {
          title:
            skills[selectedSkill].type === SkillEntryType.GIGGING ||
            skills[selectedSkill].type === SkillEntryType.RECORDING
              ? skills[selectedSkill].type
              : 'All Musical',
          total: `${totalXpEarned?.toLocaleString()} XP`,
          details: xpDetails,
        },
      }}></BandActionSelectFooter>
  );

  const startButtonDisabled =
    (rolBalance < durationCost + locationCost && locationCost > 0) ||
    totalXpEarned === 0;

  return (
    <SelectAction
      header={header}
      tabButtons={[practiceTabButton]}
      contents={[skillDropdown, locationDropdown, durationDropdown]}
      footer={footer}
      disableStartButton={startButtonDisabled}
      onActionSelected={onStartPressed}
      open={openActionSelectDialog}
      actionButtonLoading={actionButtonLoading}
      onClose={() => {
        setOpenActionSelectDialog(false);
      }}
    />
  );
};

export default BandActionSelect;
