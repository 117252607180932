import { useTheme } from '@mui/material';
import { ActionTargetType, ThemeType } from '@shared-data';
import React from 'react';
import HoverComponent from 'src/components/hover-component/hover-component';
import { DividerHorizontal } from 'src/styles/global-style';
import { infoIcon } from '../info-icon';
import { SkillsTooltipComponent } from '../tooltip-components';
import { ISkillEntryProps, SkillEntry } from './skill-entry';
import { InfoLabelContainer, SkillTitle } from './style';

export const SkillSummary = (props: {
  skills: ISkillEntryProps[];
  nameOnly?: boolean;
}) => {
  const theme = useTheme() as ThemeType;

  return (
    <div style={{ width: '100%' }}>
      <SkillTitle>
        <span style={{ marginRight: 12 }}>Skills</span>
        <HoverComponent
          hoverComponent={infoIcon}
          tooltipComponent={
            <SkillsTooltipComponent target={ActionTargetType.Band} />
          }
        />
      </SkillTitle>
      {!props.nameOnly && <SkillLabels />}
      <DividerHorizontal />
      {props.skills.map((s, idx) => {
        let locked = false;
        let title = s.title;
        if (!s.title) {
          locked = true;
          title = 'Skill not available yet';
        }

        return (
          <div key={idx}>
            <SkillEntry
              {...s}
              locked={locked}
              title={title}
              nameOnly={props.nameOnly}
            />
            <DividerHorizontal />
          </div>
        );
      })}
    </div>
  );
};

export const SkillLabels = () => {
  return (
    <InfoLabelContainer>
      <span style={{ marginLeft: 24, width: 80 }}>Potential</span>
      <span style={{ width: 80 }}>Current</span>
    </InfoLabelContainer>
  );
};
