import React from 'react';
import ConnectButton from './connect-button';
import { Container } from './style';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

export const Wallet = () => {
  return (
    <Container>
      <ConnectButton />
    </Container>
  );
};

export default Wallet;
