import { BlockstarActionResponse } from '@shared-data';
import { KeyedMutator } from 'swr';

export enum SkillEntryType {
  MUSICAL = 'Musical',
  WRITING = 'Writing',
  RECORDING = 'Recording',
  GIGGING = 'Gigging',
  PRACTICING = 'Practicing',
  PROMOTING = 'Promoting',
  FAME = 'Fame',
  SOCIAL = 'Social Media',
}

export interface ISkillsData {
  type: SkillEntryType;
  title: string;
  currentValue: number;
  minPotential: number;
  maxPotential: number;
}

export interface IActionData {
  action: BlockstarActionResponse | undefined;
  mutate: KeyedMutator<BlockstarActionResponse>;
}
