import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { styled } from '@mui/material';
import { headerHeight } from 'src/components/header/style';
import { ButtonDefault } from 'src/components/library/style';
import {
  BodyContainer,
  InfoBody,
  InfoContainer,
  InfoHeader,
} from 'src/styles/global-style';

export const Container = styled('div')``;

export const BandsBodyContainer = styled(BodyContainer)`
  padding-top: 0px;
`;

export const MusicNoteSvg = styled(MusicNoteIcon)`
  width: 20px;
  height: 20px;
  color: white;
`;

export const CreateBandButtonContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'absolute',
})<{ absolute?: boolean }>`
  width: 1364px;
  margin: auto;
  height: 50px;
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: ${(props) => (props.absolute ? `${headerHeight + 50}px` : '50px')};
  left: ${(props) => (props.absolute ? '200px' : '0px')};
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
  @media (max-width: 1364px) {
    width: 632px;
    grid-template-columns: 632px;
    grid-row-gap: 50px;
  }
`;
export const CreateBandButton = styled(ButtonDefault)`
  width: 150px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${(props) => props.theme.colors.ui.darkPurple};
  }
  &.Mui-disabled {
    color: white;
    background-color: ${(props) => props.theme.colors.ui.buttonOff};
  }
`;

export const EmptyBandInfoContainer = styled(InfoContainer)`
  position: relative;
  width: 1304px;
  height: 727px;
  align-items: center;
  justify-content: flex-start;
`;

export const EmptyBandInfoHeader = styled(InfoHeader)`
  margin-top: 70px;
  width: 326px;
  text-align: center;
`;

export const EmptyBandInfoBody = styled(InfoBody)`
  width: 326px;
  text-align: center;
`;

export const EmptyBandVideo = styled('video')`
  position: absolute;
  top: -65px;
  z-index: -1;
  border: none;
`;

export const OriginOnlyTooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 275px;
  padding: 16px;
  font-size: 16px;
`;
