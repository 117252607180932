import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BlockstarActionType } from '@shared-data';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 555px;
  min-height: 500px;
  justify-content: flex-start;
  align-items: center;
`;

export const CloseButtonContainer = styled('div')`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const HeaderContainer = styled('div')`
  width: 90%;
`;

export const TabsContainer = styled('div')`
  display: flex;
  justify-content: center;
  height: 42px;
  width: 100%;
  color: ${(props) => props.theme.colors.ui.primaryPurple};
  column-gap: 12px;
`;

export const TabButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'actionType' && prop !== 'selectedActionType',
})<{
  actionType: BlockstarActionType;
  selectedActionType: BlockstarActionType;
}>`
  width: 80;
  color: ${(props) =>
    props.actionType === props.selectedActionType
      ? 'inherit'
      : props.theme.colors.text.primaryGray};
  font-weight: bold;
`;

export const ContentsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  row-gap: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const FooterContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
`;

export const Footer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 8px;
`;

export const FooterTextContainer = styled('div')`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
`;

export const FooterTextTitle = styled('h4')`
  width: 60px;
`;

export const FooterTextBody = styled('p')``;

export const FooterTooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 275px;
  padding: 16px;
  font-size: 16px;
`;
