import { Checkbox, IconButton } from '@mui/material';
import { ActionStatus, BlockstarActionResponse } from '@shared-data';
import React from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import Lottie from 'react-lottie';
import * as pulsingDot from 'src/assets/lottie/pulsing_dot.json';
import { Loading } from 'src/components/loading';
import { getTimeLeft } from 'src/utils/utils';
import { CompleteLvlup, CompleteStar, CompleteXp } from '../style';

const countdownRenderer = ({
  completed,
  days,
  hours,
  minutes,
  seconds,
  total,
}: CountdownRenderProps) => {
  return '';
};

const BlockstarsTableCheckbox = (props: {
  action?: BlockstarActionResponse;
  checked: boolean;
  onCheckChange: () => void;
  onActionsButtonClicked: () => void;
  onCountDownComplete?: () => void;
}) => {
  const onCountDownComplete = () => {
    if (props.onCountDownComplete) {
      props.onCountDownComplete();
    }
  };

  const remaining = (
    <Countdown
      date={Date.now() + getTimeLeft(props.action)}
      renderer={countdownRenderer}
      onComplete={onCountDownComplete}
    />
  );

  if (props.action) {
    switch (props.action.actionStatus) {
      case ActionStatus.Completed:
        let icon = <CompleteXp style={{ width: 20, height: 20 }} />;
        if (props.action.starUpInfo) {
          icon = <CompleteStar style={{ width: 20, height: 20 }} />;
        } else if (props.action.levelUpInfo) {
          icon = <CompleteLvlup style={{ width: 20, height: 20 }} />;
        }
        return (
          <IconButton
            style={{ width: 42 }}
            onClick={() => props.onActionsButtonClicked()}>
            {icon}
          </IconButton>
        );
      case ActionStatus.InAction:
        return (
          <IconButton
            style={{ width: 42 }}
            onClick={() => props.onActionsButtonClicked()}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: pulsingDot,
              }}
              width={42}
              height={42}
            />
            {remaining}
          </IconButton>
        );
      case ActionStatus.None:
        // default:
        return (
          <Checkbox
            style={{ width: '42px' }}
            checked={!!props.checked}
            onChange={props.onCheckChange}
          />
        );
      default:
        return <Loading width={42} height={42} />;
    }
  } else {
    return <Loading width={42} height={42} />;
  }
};

export default BlockstarsTableCheckbox;
