import { useEffect, useState } from 'react';

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    function handleScroll(ev: Event) {
      let target = ev.target as HTMLInputElement;
      setScrollPosition(target.scrollTop);
    }

    document.addEventListener('scroll', handleScroll, true);

    return () => document.removeEventListener('scroll', handleScroll, true);
  }, []);

  return scrollPosition;
};
