import _ from 'lodash';
import React, { useEffect, useState } from 'react';

export default function useIsWindowFocused(): boolean {
  const [windowIsActive, setWindowIsActive] = useState(true);

  const handleActivity = React.useCallback(
    _.debounce(
      (e: { type: string }) => {
        if (e?.type === 'focus') {
          return setWindowIsActive(true);
        }
        if (e?.type === 'blur') {
          return setWindowIsActive(false);
        }
        if (e?.type === 'visibilitychange') {
          if (document.hidden) {
            return setWindowIsActive(false);
          }
          return setWindowIsActive(true);
        }

        return undefined;
      },
      0,
      { leading: false },
    ),
    [],
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', handleActivity);
    document.addEventListener('blur', handleActivity);
    window.addEventListener('blur', handleActivity);
    window.addEventListener('focus', handleActivity);
    document.addEventListener('focus', handleActivity);

    return () => {
      window.removeEventListener('blur', handleActivity);
      document.removeEventListener('blur', handleActivity);
      window.removeEventListener('focus', handleActivity);
      document.removeEventListener('focus', handleActivity);
      document.removeEventListener('visibilitychange', handleActivity);
    };
  }, []);

  return windowIsActive;
}
