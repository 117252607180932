import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import { LoadingButton } from '@mui/lab';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ActionStatus, Blockstar, BlockstarActionType } from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useContext, useEffect, useState } from 'react';
import {
  ActionOptions,
  onStartActions,
  onStopAction,
} from 'src/actions/action';
import { ListToggles } from 'src/components/library/list-table/style';
import TableContext from 'src/components/library/list-table/table-context';
import { useActionOptions } from 'src/hooks/action-options';
import { useAllBlockstarActions } from 'src/hooks/blockstar-action';
import { useScrollPosition } from 'src/hooks/scroll-position';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import analytics from 'src/utils/analytics';
import { getLocalStorage, setLocalStorage, StorageType } from 'src/utils/utils';

export enum ListLayout {
  GRID = 'grid',
  LIST = 'list',
}

interface IProps {
  blockstars?: Blockstar[];
}

const BlockstarsListToggles = (props: IProps) => {
  const { blockstars } = props;
  const scrollPosition = useScrollPosition();
  const [open, setOpen] = useState(false);
  const [ngmiOpen, setNGMIOpen] = useState(false);
  const [actionType, setActionType] = useState<
    BlockstarActionType | undefined
  >();
  const [blockstar, setBlockstar] = useState<Blockstar>();
  const [repeatButtonLoading, setRepeatButtonLoading] = useState(false);
  const [completeAllLoading, setCompleteAllLoading] = useState(false);
  const { publicKey, signTransaction, signAllTransactions } = useWallet();
  const { connection } = useConnection();
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);
  const { locations, durations } = useActionOptions();

  const { actions, mutate } = useAllBlockstarActions(
    blockstars?.map((b) => b.number) ?? [],
  );
  const actionValues = Object.values(actions ?? {});

  // these two are used for controlling state of rows in list view
  const [buttonsExpanded, setButtonsExpanded] = useState<boolean[]>([]);

  // Table ctx - table.tsx
  const { layout, setLayout, rowsChecked, setRowsChecked } =
    useContext(TableContext);

  useEffect(() => {
    document.getElementById('scroll')?.scroll({ top: scrollPosition });
  }, []);

  useEffect(() => {
    if (blockstars) {
      // init states to false
      if (setRowsChecked) {
        setRowsChecked(Array(blockstars.length).fill(false));
      }
      setButtonsExpanded(Array(blockstars.length).fill(false));
    }
  }, [blockstars]);

  const completeAll = async () => {
    setCompleteAllLoading(true); // dont let players spam the complete button
    const stops: Promise<any>[] = [];
    const blockstarIds: number[] = [];
    if (actions) {
      const bandActions: { [bandId: string]: number } = {};
      Object.keys(actions).forEach((n, idx) => {
        const id = parseInt(n);
        const action = actions[id];
        if (action && action.actionStatus === ActionStatus.Completed) {
          switch (action.actionType) {
            case BlockstarActionType.BandPracticing:
            case BlockstarActionType.BandGigging:
              if (action.blockstar?.bandId) {
                if (!bandActions[action.blockstar?.bandId]) {
                  bandActions[action.blockstar?.bandId] = 1;
                  blockstarIds.push(id);
                  stops.push(
                    onStopAction(
                      publicKey,
                      blockstars!.find((b) => b.number === id)!,
                    ),
                  );
                }
              }
              break;
            default:
              blockstarIds.push(id);
              stops.push(
                onStopAction(
                  publicKey,
                  blockstars!.find((b) => b.number === id)!,
                ),
              );
              break;
          }
        }
      });
    }
    analytics.logEvent('ClickMarkAllCompletedViewed', {
      blockstarIds: JSON.stringify(blockstarIds),
    });
    await Promise.allSettled(stops).then(async () => {
      await mutate();
    });
    setCompleteAllLoading(false);
  };

  const repeatLast = async () => {
    setRepeatButtonLoading(true);
    const toRepeat: number[] = [];
    const options: ActionOptions[] = [];
    rowsChecked?.forEach((val, idx) => {
      if (val) {
        if (
          actions &&
          actions[blockstars![idx].number] &&
          actions[blockstars![idx].number].actionStatus === ActionStatus.None
        ) {
          toRepeat.push(blockstars![idx].number);
          const repeatOptions = getLocalStorage(
            StorageType.ACTION_OPTIONS,
            blockstars![idx].number,
          );
          if (repeatOptions) {
            const parsedOptions = JSON.parse(repeatOptions) as ActionOptions;
            if (!locations.find((l) => l.id === parsedOptions.location.id)) {
              parsedOptions.location = locations[0];
            }
            options.push(parsedOptions);
          } else {
            const skill = blockstars![idx].skills[0].name;
            const location = locations[0];
            const duration = durations[0];
            options.push({
              actionType: BlockstarActionType.Practicing,
              actionSkillType: skill,
              location,
              durationMs: duration,
            });
          }
        }
      }
    });
    analytics.logEvent('ClickActionRepeatLast', { blockstarIds: toRepeat });
    try {
      await onStartActions(
        publicKey,
        toRepeat,
        connection,
        rolBalance,
        signTransaction!,
        signAllTransactions!,
        options,
      );
      await mutate();
      mutateBalance();
      if (setRowsChecked) {
        setRowsChecked((prev) => Array(prev.length).fill(false));
      }
      setRepeatButtonLoading(false);
    } catch (e) {
      console.log(e);
      setRepeatButtonLoading(false);
    }
  };

  return (
    <div>
      <ListToggles>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* stuff at the left of row goes here */}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {layout === ListLayout.LIST && (
            <>
              {actions &&
                actionValues.filter(
                  (a) => a?.actionStatus === ActionStatus.Completed,
                ).length > 0 && (
                  <LoadingButton
                    variant='contained'
                    disableElevation
                    loading={completeAllLoading}
                    onClick={completeAll}
                    style={{
                      height: 35,
                      borderRadius: 8,
                      marginRight: 12,
                    }}>
                    Mark all completed as viewed
                  </LoadingButton>
                )}
              {rowsChecked &&
                rowsChecked.reduce((prev, curr) => prev || curr, false) && (
                  <LoadingButton
                    variant='contained'
                    disableElevation
                    loading={repeatButtonLoading}
                    onClick={repeatLast}
                    style={{
                      width: 175,
                      height: 35,
                      borderRadius: 8,
                      marginRight: 12,
                    }}>
                    Repeat last action
                  </LoadingButton>
                )}
            </>
          )}
          View
          <ToggleButtonGroup
            style={{ marginLeft: 12, height: 32 }}
            color='primary'
            value={layout}
            onChange={(event, newLayout) => {
              if (newLayout !== null) {
                analytics.logEvent('ClickMyBlockstarsLayout', {
                  layout: newLayout,
                });
                if (setLayout) {
                  setLayout(newLayout);
                }
                setLocalStorage(StorageType.LIST_LAYOUT, 0, newLayout);
                if (newLayout === ListLayout.GRID) {
                  setButtonsExpanded(buttonsExpanded.map(() => false));
                }
              }
            }}
            exclusive>
            <ToggleButton value={ListLayout.GRID}>
              <GridViewRoundedIcon />
            </ToggleButton>
            <ToggleButton value={ListLayout.LIST}>
              <ViewListRoundedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </ListToggles>
    </div>
  );
};

export default BlockstarsListToggles;
