import { FindNftsByOwnerOutput, Metaplex } from '@metaplex-foundation/js';
import { rolDecimal } from '@shared-constants';
import {
  AccountLayout,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {
  Commitment,
  Connection,
  LAMPORTS_PER_SOL,
  ParsedAccountData,
  PublicKey,
  RpcResponseAndContext,
  SimulatedTransactionResponse,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
  TransactionSignature,
} from '@solana/web3.js';
import { toast } from 'react-toastify';
import uiStore from '../stores/ui/ui';
import { getUnixTs, sleep } from '../utils/utils';

const METADATA_PREFIX = 'metadata';

export enum AccountState {
  Uninitialized = 0,
  Initialized = 1,
  Frozen = 2,
}

export const getAccountInfo = async (
  connection: Connection,
  address: PublicKey,
  commitment?: Commitment,
  programId = TOKEN_PROGRAM_ID,
) => {
  const info = await connection.getAccountInfo(address, commitment);
  if (!info) throw new Error('TokenAccountNotFoundError');
  if (!info.owner.equals(programId))
    throw new Error('TokenInvalidAccountOwnerError');
  if (info.data.length !== AccountLayout.span)
    throw new Error('TokenInvalidAccountSizeError');

  const rawAccount = AccountLayout.decode(Buffer.from(info.data));

  return {
    address,
    mint: rawAccount.mint,
    owner: rawAccount.owner,
    amount: rawAccount.amount,
    delegate: rawAccount.delegateOption ? rawAccount.delegate : null,
    delegatedAmount: rawAccount.delegatedAmount,
    isInitialized: rawAccount.state !== AccountState.Uninitialized,
    isFrozen: rawAccount.state === AccountState.Frozen,
    isNative: !!rawAccount.isNativeOption,
    rentExemptReserve: rawAccount.isNativeOption ? rawAccount.isNative : null,
    closeAuthority: rawAccount.closeAuthorityOption
      ? rawAccount.closeAuthority
      : null,
  };
};

export const getSolBalance = async (
  connection: Connection,
  publicKey: PublicKey,
) => {
  try {
    const lamports = await connection.getBalance(publicKey);
    return lamports / LAMPORTS_PER_SOL;
  } catch (error) {
    console.error('Get Sol Balance Error: ', error);
    uiStore.setError('wallet', 'Error getting Sol balance.');
    return null;
  }
};

export const getNFTTokens = async (
  connection: Connection,
  publicKey: PublicKey,
) => {
  try {
    const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
      publicKey,
      { programId: new PublicKey(TOKEN_PROGRAM_ID) },
      'processed',
    );
    const myNFTs = tokenAccounts.value.map(
      (t) => t.account.data.parsed.info.mint,
    );
    return myNFTs;
  } catch (error) {
    console.error('Get NFT Tokens Error: ', error);
    uiStore.setError('wallet', 'Error getting NFT tokens.');
    return null;
  }
};

export const getNFTBaseMetas = async (
  connection: Connection,
  owner: PublicKey,
) => {
  try {
    const metaplex = new Metaplex(connection);
    const metas = await metaplex.nfts().findAllByOwner({ owner }).run();
    return metas.reduce((memo: FindNftsByOwnerOutput, meta) => {
      // We only want BLOCKSTARS data.
      return meta.symbol === 'BLOCKSTARS' ? [...memo, meta] : memo;
    }, []);
  } catch (error) {
    console.error('Get NFT Metas Error: ', error);
    uiStore.setError('wallet', 'Error getting NFT Metas.');
    return null;
  }
};

export const getNFTOwner = async (
  connection: Connection,
  nftPublicKey: PublicKey,
) => {
  let largestAccounts: any = [];
  try {
    largestAccounts = await connection.getTokenLargestAccounts(
      nftPublicKey,
      'confirmed',
    );
  } catch (error) {
    console.error('Get NFT owner Error: getTokenLargestAccounts: ', error);
    uiStore.setError(
      'wallet',
      'Error getting NFT owner. [ getTokenLargestAccounts ]',
    );
    return null;
  }
  // NFT mint address should have amount 1 and it should be total supply
  const accountsWithAmountOne = largestAccounts.value.filter(
    (v: { amount: string }) => v.amount === '1',
  );
  if (accountsWithAmountOne.length === 0) {
    return {
      error: 'Mint address is not NFT.',
    };
  }
  // also used as NFT ID in Phantom wallet
  const ownerAccountPubKey = accountsWithAmountOne[0].address;
  try {
    const ownerAccount = await connection.getParsedAccountInfo(
      ownerAccountPubKey,
      'confirmed',
    );

    return {
      id: ownerAccountPubKey.toBase58(),
      owner: (ownerAccount?.value?.data as ParsedAccountData).parsed.info.owner,
    };
  } catch (error) {
    console.error('Get NFT owner Error: getParsedAccountInfo: ', error);
    uiStore.setError(
      'wallet',
      'Error getting NFT owner. [ getParsedAccountInfo ]',
    );
    return null;
  }
};

export const findAssociatedTokenAddress = async (
  walletAddress: PublicKey,
  tokenMintAddress: PublicKey,
) => {
  return (
    await PublicKey.findProgramAddress(
      [
        walletAddress.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        tokenMintAddress.toBuffer(),
      ],
      ASSOCIATED_TOKEN_PROGRAM_ID,
    )
  )[0];
};

export const getFTTokenAccounts = async (
  connection: Connection,
  publicKey: PublicKey,
) => {
  try {
    const myFTAccounts = await connection.getTokenAccountsByOwner(publicKey, {
      programId: TOKEN_PROGRAM_ID,
    });
    return myFTAccounts;
  } catch (error) {
    console.error('Get FT Token Accounts Error: ', error);
    uiStore.setError('wallet', 'Error getting FT token accounts.');
    return null;
  }
};

export const getFTTokenBalance = async (
  connection: Connection,
  tokenAddress: PublicKey,
) => {
  try {
    const balance = await connection.getTokenAccountBalance(tokenAddress);
    return balance.value.uiAmount;
  } catch (error: any) {
    console.error('Get FT Token Balance Error: ', error.message);
    if (error.message.includes('could not find account')) {
      return null;
    }
    uiStore.setError('wallet', 'Error getting FT token balance.');
    return null;
  }
};

export const getSignaturesForAddress = async (
  connection: Connection,
  tokenAddress: PublicKey,
) => {
  try {
    const signatures = await connection.getSignaturesForAddress(tokenAddress);
    return signatures;
  } catch (error) {
    console.error('Get Signatures For Address Error: ', error);
    uiStore.setError('wallet', 'Error getting signatures for address.');
    return null;
  }
};

export const getParsedTransactions = async (
  connection: Connection,
  signatures: string[],
) => {
  try {
    const transactions = await connection.getParsedTransactions(
      signatures,
      'confirmed',
    );
    return transactions;
  } catch (error) {
    console.error('Get Parsed Transactions Error: ', error);
    uiStore.setError('wallet', 'Error getting parsed transactions.');
    return null;
  }
};

const metaProgPubKey = new PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s',
);

const findProgramAddress = async (mintPubKey: PublicKey) => {
  const seeds = [
    Buffer.from(METADATA_PREFIX),
    metaProgPubKey.toBuffer(),
    mintPubKey.toBuffer(),
  ];
  const result = await PublicKey.findProgramAddress(seeds, metaProgPubKey);
  // TODO: describe the result
  return result[0];
};

export const createSignedTransaction = async (
  connection: Connection,
  senderWallet: PublicKey,
  receiverWallet: PublicKey,
  mintAddress: PublicKey,
  signTransaction:
    | ((transaction: Transaction) => Promise<Transaction>)
    | undefined,
  amount: number,
  memo: string,
) => {
  if (!signTransaction) {
    return undefined;
  }
  const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
    connection,
    senderWallet,
    mintAddress,
    senderWallet,
    signTransaction,
  );

  const toTokenAccount = await getOrCreateAssociatedTokenAccount(
    connection,
    senderWallet,
    mintAddress,
    receiverWallet,
    signTransaction,
  );

  const transaction = await createTransaction(
    fromTokenAccount!.address, // source
    mintAddress,
    toTokenAccount!.address, // dest
    senderWallet,
    amount,
    memo,
    connection,
  );

  const signed = await signTransaction(transaction);
  return signed;
};

export const createSignedTransactions = async (
  connection: Connection,
  senderWallet: PublicKey,
  receiverWallet: PublicKey,
  mintAddress: PublicKey,
  signTransaction:
    | ((transaction: Transaction) => Promise<Transaction>)
    | undefined,
  signAllTransactions:
    | ((transaction: Transaction[]) => Promise<Transaction[]>)
    | undefined,
  amounts: number[],
  memos: string[],
) => {
  if (!signTransaction || !signAllTransactions) {
    return undefined;
  }
  const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
    connection,
    senderWallet,
    mintAddress,
    senderWallet,
    signTransaction,
  );

  const toTokenAccount = await getOrCreateAssociatedTokenAccount(
    connection,
    senderWallet,
    mintAddress,
    receiverWallet,
    signTransaction,
  );

  const txnPromises = [];
  for (let i = 0; i < amounts.length; i++) {
    txnPromises.push(
      createTransaction(
        fromTokenAccount!.address, // source
        mintAddress,
        toTokenAccount!.address, // dest
        senderWallet,
        amounts[i],
        memos[i],
        connection,
      ),
    );
  }
  const transactions: (Transaction | undefined)[] = [];
  const failures: number[] = [];
  await Promise.allSettled(txnPromises).then((results) =>
    results.forEach((r, idx) => {
      if (r.status === 'fulfilled') transactions.push(r.value);
      else {
        transactions.push(undefined);
      }
    }),
  );
  if (transactions.length === 0) {
    throw new Error('Failed to create transaction set');
  }
  const signed = await signAllTransactions(
    transactions.filter((t) => t !== undefined) as Transaction[],
  );

  // signedWithFailures has the signed transactions with undefined in the failed positions so we can properly track from the calling function.
  const signedWithFailures: (Transaction | undefined)[] = [];
  for (let i = 0; i < transactions.length; i++) {
    if (transactions[i]) {
      signedWithFailures.push(signed.shift());
    } else {
      signedWithFailures.push(undefined);
    }
  }
  return signedWithFailures;
};

const createTransaction = async (
  fromTokenAccountAddress: PublicKey,
  mintAddress: PublicKey,
  toTokenAccountAddress: PublicKey,
  senderWallet: PublicKey,
  amount: number,
  memo: string,
  connection: Connection,
) => {
  try {
    const transaction = new Transaction().add(
      Token.createTransferCheckedInstruction(
        TOKEN_PROGRAM_ID,
        fromTokenAccountAddress, // source
        mintAddress,
        toTokenAccountAddress, // dest
        senderWallet,
        [],
        Math.round(amount * 10 ** rolDecimal),
        rolDecimal,
      ),
    );

    transaction.add(createMemoInstruction(memo));
    const blockHash = await connection.getLatestBlockhash('finalized');
    transaction.feePayer = senderWallet;
    transaction.recentBlockhash = blockHash.blockhash;
    transaction.lastValidBlockHeight = blockHash.lastValidBlockHeight;
    return transaction;
  } catch (e: any) {
    console.error(e.toString());
    toast.error(e.toString());
    throw new Error(e.toString());
  }
};

export const getOrCreateAssociatedTokenAccount = async (
  connection: Connection,
  payer: PublicKey,
  mint: PublicKey,
  owner: PublicKey,
  signTransaction:
    | ((transaction: Transaction) => Promise<Transaction>)
    | undefined,
  commitment?: Commitment,
  allowOwnerOffCurve = false,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID,
) => {
  if (!signTransaction) {
    return undefined;
  }

  const associatedToken = await getAssociatedTokenAddress(
    mint,
    owner,
    allowOwnerOffCurve,
    programId,
    associatedTokenProgramId,
  );

  // This is the optimal logic, considering TX fee, client-side computation, RPC roundtrips and guaranteed idempotent.
  // Sadly we can't do this atomically.
  let account;
  try {
    account = await getAccountInfo(
      connection,
      associatedToken,
      commitment,
      programId,
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // TokenAccountNotFoundError can be possible if the associated address has already received some lamports,
    // becoming a system account. Assuming program derived addressing is safe, this is the only case for the
    // TokenInvalidAccountOwnerError in this code path.
    if (
      error.message === 'TokenAccountNotFoundError' ||
      error.message === 'TokenInvalidAccountOwnerError'
    ) {
      // As this isn't atomic, it's possible others can create associated accounts meanwhile.
      try {
        const transaction = new Transaction().add(
          createAssociatedTokenAccountInstruction(
            payer,
            associatedToken,
            owner,
            mint,
            programId,
            associatedTokenProgramId,
          ),
        );

        const blockHash = await connection.getLatestBlockhash('finalized');
        transaction.feePayer = payer;
        transaction.recentBlockhash = blockHash.blockhash;
        transaction.lastValidBlockHeight = blockHash.lastValidBlockHeight;
        const signed = await signTransaction(transaction);

        const signature = await connection.sendRawTransaction(
          signed.serialize(),
        );

        await connection.confirmTransaction(signature);
      } catch (error: unknown) {
        // Ignore all errors; for now there is no API-compatible way to selectively ignore the expected
        // instruction error if the associated account exists already.
      }

      // Now this should always succeed
      account = await getAccountInfo(
        connection,
        associatedToken,
        commitment,
        programId,
      );
    } else {
      throw error;
    }
  }

  if (!account.mint.equals(mint.toBuffer()))
    throw Error('TokenInvalidMintError');
  if (!account.owner.equals(owner.toBuffer()))
    throw new Error('TokenInvalidOwnerError');

  return account;
};

const getAssociatedTokenAddress = async (
  mint: PublicKey,
  owner: PublicKey,
  allowOwnerOffCurve = false,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID,
): Promise<PublicKey> => {
  if (!allowOwnerOffCurve && !PublicKey.isOnCurve(owner.toBuffer()))
    throw new Error('TokenOwnerOffCurveError');

  const [address] = await PublicKey.findProgramAddress(
    [owner.toBuffer(), programId.toBuffer(), mint.toBuffer()],
    associatedTokenProgramId,
  );

  return address;
};

export const createAssociatedTokenAccountInstruction = (
  payer: PublicKey,
  associatedToken: PublicKey,
  owner: PublicKey,
  mint: PublicKey,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID,
): TransactionInstruction => {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedToken, isSigner: false, isWritable: true },
    { pubkey: owner, isSigner: false, isWritable: false },
    { pubkey: mint, isSigner: false, isWritable: false },
    { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
    { pubkey: programId, isSigner: false, isWritable: false },
    { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false },
  ];

  return new TransactionInstruction({
    keys,
    programId: associatedTokenProgramId,
    data: Buffer.alloc(0),
  });
};

export const MEMO_PROGRAM_ID = new PublicKey(
  'MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr',
);
export const createMemoInstruction = (memo: string) => {
  return new TransactionInstruction({
    keys: [],
    programId: MEMO_PROGRAM_ID,
    data: Buffer.from(memo),
  });
};

export async function sendSignedTransaction(
  signedTransaction: Transaction,
  connection: Connection,
): Promise<string> {
  const rawTransaction = signedTransaction.serialize();
  const startTime = getUnixTs();
  const txid: TransactionSignature = await connection.sendRawTransaction(
    rawTransaction,
  );

  // Try to get a valid blockheight from the txn, but if one is not already provided,
  // it is safe (but slower UX in degenerate case) to use the latest one as an expiry limit. -cojo
  const lastValidBlockHeight =
    signedTransaction.lastValidBlockHeight ??
    (await connection.getLatestBlockhash()).lastValidBlockHeight;
  // console.log('Started awaiting confirmation for', txid);

  let done = false;
  let latestBlockHeight = await connection.getBlockHeight();
  (async () => {
    while (!done && latestBlockHeight < lastValidBlockHeight) {
      connection.sendRawTransaction(rawTransaction, {
        skipPreflight: true,
      });
      /* eslint-disable no-await-in-loop */
      await sleep(5000);
      latestBlockHeight = await connection.getBlockHeight();
      /* eslint-enable no-await-in-loop */
    }
  })();

  try {
    await connection.confirmTransaction(
      {
        signature: txid,
        blockhash: signedTransaction.recentBlockhash!,
        lastValidBlockHeight,
      },
      'confirmed',
    );
  } catch (err: any) {
    if (err.timeout) {
      throw new Error(
        `Timed out awaiting confirmation on transaction: ${txid}`,
      );
    }
    let simulateResult: SimulatedTransactionResponse | null = null;
    try {
      simulateResult = (
        await simulateTransaction(connection, signedTransaction, 'single')
      ).value;
    } catch (e) {}
    if (simulateResult && simulateResult.err) {
      throw new Error(JSON.stringify(simulateResult.err));
    }
    throw new Error(`Transaction failed: ${txid} - ${err}`);
  } finally {
    done = true;
  }

  // console.log('Latency', txid, getUnixTs() - startTime);
  return txid;
}

/** Copy of Connection.simulateTransaction that takes a commitment parameter. */
async function simulateTransaction(
  connection: Connection,
  transaction: Transaction,
  commitment: Commitment,
): Promise<RpcResponseAndContext<SimulatedTransactionResponse>> {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  transaction.recentBlockhash = (
    await connection.getLatestBlockhash('finalized')
  ).blockhash;

  const signData = transaction.serializeMessage();
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  const wireTransaction = transaction._serialize(signData);
  const encodedTransaction = wireTransaction.toString('base64');
  const config: any = { encoding: 'base64', commitment };
  const args = [encodedTransaction, config];

  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  const res = await connection._rpcRequest('simulateTransaction', args);
  if (res.error) {
    throw new Error(`failed to simulate transaction: ${res.error.message}`);
  }
  return res.result;
  /* eslint-disable @typescript-eslint/ban-ts-comment */
}
