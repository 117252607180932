import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import {
  Button,
  styled,
  Table,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import completeLvlup from 'src/assets/images/complete_lvlup.svg';
import completeStar from 'src/assets/images/complete_star.svg';
import completeXp from 'src/assets/images/complete_xp.svg';

export const AddMemberIcon = styled(AddBoxIcon)`
  :hover {
    color: ${(props) => props.theme.colors.ui.darkPurple};
  }
`;

export const BlockstarsTableContainer = styled('div')`
  width: 1364px;
  margin: auto;
  @media (max-width: 1364px) {
    width: 850px;
  }
`;

export const ButtonContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonInList = styled(Button)`
  height: 26px;
  width: 26px;
  :hover {
    background-color: transparent;
  }
  :disabled {
    color: ${(props) => props.theme.colors.ui.buttonOff};
  }
`;

const CompleteIconBase = styled('div')`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
`;

export const CompleteLvlup = styled(CompleteIconBase)`
  background-image: url(${completeLvlup});
`;

export const CompleteStar = styled(CompleteIconBase)`
  background-image: url(${completeStar});
`;

export const CompleteXp = styled(CompleteIconBase)`
  background-image: url(${completeXp});
`;

export const ListToggles = styled('div')`
  width: 1364px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  align-items: center;
  @media (max-width: 1364px) {
    width: 632px;
  }
`;

export const Grid = styled('div')`
  width: 1364px;
  margin: auto;
  display: grid;
  grid-template-columns: 632px 632px;
  grid-gap: 100px;
  grid-row-gap: 100px;
  align-items: center;
  margin-bottom: 100px;
  @media (max-width: 1364px) {
    width: 632px;
    grid-template-columns: 632px;
    grid-row-gap: 50px;
  }
`;

export const ItemInfoNumberContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
  justify-content: flex-start;
`;

export const InfoNumber = styled('h5')`
  align-self: flex-start;
  text-align: center;
  color: ${(props) => props.theme.colors.text.secondaryGray};
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  border-radius: 4px;
  border-color: ${(props) => props.theme.colors.ui.cellGray};
  padding: 0px 3px 0px 3px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ListRowNew = styled('div')`
  display: flex;
  border: 1px solid #ff24ff;
  background-color: rgba(255, 36, 255, 0.05);
  border-radius: 6px;
  position: relative;
  height: 64px;
  align-items: center;
  justify-content: center;
`;

export const ListRowBlockstar = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
  overflow: hidden;
`;

export const ListRowBlockstarContainer = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding-left: 5px;
`;

export const ListRowMainSkillHeaderText = styled('p')`
  position: absolute;
  width: 75px;
  text-align: left;
  transform-origin: left;
  transform: translate(0%, 0%) rotate(-40.5deg);
`;

export const ListRowMainSkillHeaderTextContainer = styled('span')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transform: skew(50deg);
`;

export const ListRowMainSkillHeaderRotator = styled('div')`
  width: 100%;
  height: 100%;
  padding: 0px;
`;

export const ListRowMusical = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const ListRowSkill = styled('div')`
  display: flex;
  justify-content: center;
`;

export const ListTableHead = styled(TableHead)`
  position: sticky;
  top: 0px;
  z-index: 1;
`;

export const ListTableHeadRow = styled(TableRow)`
  background: linear-gradient(
    to top,
    ${(props) => props.theme.colors.ui.cellGray} 20%,
    white,
    white
  );
  height: 75px;
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

export const Wrapper = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  position: relative;
  table-layout: fixed;
`;

export const RemoveMemberIcon = styled(IndeterminateCheckBoxOutlinedIcon)``;

export const TableCellDefault = styled(TableCell)`
  border-left: 1px solid ${(props) => props.theme.colors.ui.lineGray};
  border-collapse: collapse;
  padding-left: 3px;
  padding-right: 3px;
`;

export const TableCellHeader = styled(TableCell)`
  border: 0px;
  position: sticky;
  top: 0px;
  overflow-y: clip;
`;

export const HidableTableCell = styled(TableCellDefault, {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
}) <{
  maxWidth?: string;
}>`
  overflow-y: clip;
  @media (max-width: 1364px) {
    display: none;
  }
`;

export const HidableTableCellHeader = styled(TableCellHeader, {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
}) <{
  maxWidth?: string;
}>`
  @media (max-width: 1364px) {
    display: none;
  }
`;
