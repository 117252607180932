import { Band, Blockstar } from '@shared-data';
import React from 'react';
import { BandGridItem } from './grid-item';
import { GridViewContainer } from './style';

export const BandsGridView = (props: {
  bands: Band[];
  blockstars: Blockstar[];
}) => {
  return (
    <GridViewContainer>
      {props.bands.map((band, index) => (
        <BandGridItem key={index} band={band} blockstars={props.blockstars} />
      ))}
    </GridViewContainer>
  );
};
