import { Blockstar } from '@shared-data';
import React from 'react';
import Lottie from 'react-lottie';
import * as transfer from 'src/assets/lottie/transfer.json';
import { TransferBody, TransferContainer, TransferLottie } from './style';

export const Transfer = (props: {
  blockstar: Blockstar;
  target: Blockstar;
}) => {
  const imgSrc = `${process.env.ASSET_BASE_URL || ''}/${
    props.blockstar.number
  }_transparent.png`;
  const targetImgSrc = `${process.env.ASSET_BASE_URL || ''}/${
    props.target.number
  }_transparent.png`;
  return (
    <TransferContainer>
      <TransferBody>
        <img
          src={imgSrc}
          style={{ transform: 'scaleX(-1)', objectFit: 'contain' }}
          width={'100%'}
          height={'100%'}
        />
      </TransferBody>
      <TransferLottie>
        <div style={{ display: 'flex', transform: 'scale(2)' }}>
          <Lottie options={{ animationData: transfer }} />
        </div>
      </TransferLottie>
      <TransferBody>
        <img
          src={targetImgSrc}
          style={{ objectFit: 'contain' }}
          width={'100%'}
          height={'100%'}
        />
      </TransferBody>
    </TransferContainer>
  );
};
