import {
  Band,
  BandMemberSkill,
  Blockstar,
  BlockstarSkillType,
  SubgenreInfo,
} from '@shared-data';
import { Subgenre } from '@shared-generated/generated-genres';
import React from 'react';
import { BandPreviewData, BandSlotSkill } from '.';
import BandSelectionDropdown, {
  DropdownDataForBand,
} from './band-selection-dropdown';
import {
  EditorBodyElementsContainerMiddle,
  EditorBodyElementsContainerMiddleSubContainer,
} from './style';

const BandEditorMiddle = (props: {
  blockstars?: Blockstar[];
  bands?: Band[];
  subGenreId: Subgenre;
  subGenreInfo: SubgenreInfo;
  members: number[];
  musicalSlots: (BandSlotSkill | undefined)[];
  otherSlots: (BandSlotSkill | undefined)[];
  previewData?: BandPreviewData;
  onMemberAdded: (
    memberId: number,
    slotIndex?: number,
    skillInfo?: BandMemberSkill,
    isMusical?: boolean,
  ) => void;
  onMemberRemoved: (
    memberId: number,
    slotIndex?: number,
    isMusical?: boolean,
  ) => void;
}) => {
  const onBlockstarSelected = (
    componentInfo: DropdownDataForBand,
    skillIndex: number,
    isMusical?: boolean,
    isRemoving?: boolean,
  ) => {
    if (componentInfo.blockstarId != undefined) {
      if (isRemoving) {
        props.onMemberRemoved(componentInfo.blockstarId, skillIndex, isMusical);
      } else {
        props.onMemberAdded(
          componentInfo.blockstarId,
          skillIndex,
          componentInfo.skillInfo,
          isMusical,
        );
      }
    }
  };

  const otherSkills: BandMemberSkill[] = [];
  Object.values(BlockstarSkillType).forEach((skill) => {
    if (skill !== BlockstarSkillType.Writing) {
      otherSkills.push({
        name: skill,
      });
    }
  });

  return (
    <EditorBodyElementsContainerMiddle>
      <EditorBodyElementsContainerMiddleSubContainer>
        {props.subGenreInfo.requirements.musicalSkills.map((skill, index) => (
          <BandSelectionDropdown
            blockstars={props.blockstars}
            bands={props.bands}
            subGenreId={props.subGenreId}
            subGenreInfo={props.subGenreInfo}
            previewData={props.previewData}
            key={index}
            skill={skill}
            skillIndex={index}
            allSlots={props.musicalSlots}
            memberIds={props.members}
            selectedMemberInfo={props.musicalSlots[index]}
            isMusical
            onSelected={onBlockstarSelected}
          />
        ))}
      </EditorBodyElementsContainerMiddleSubContainer>
      <EditorBodyElementsContainerMiddleSubContainer>
        {otherSkills.map((skill, index) => (
          <BandSelectionDropdown
            blockstars={props.blockstars}
            bands={props.bands}
            subGenreId={props.subGenreId}
            subGenreInfo={props.subGenreInfo}
            key={index}
            skill={skill}
            skillIndex={index}
            allSlots={props.otherSlots}
            memberIds={props.members}
            selectedMemberInfo={props.otherSlots[index]}
            onSelected={onBlockstarSelected}
          />
        ))}
      </EditorBodyElementsContainerMiddleSubContainer>
    </EditorBodyElementsContainerMiddle>
  );
};

export default BandEditorMiddle;
