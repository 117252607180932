import { Blockstar, BlockstarActionType } from '@shared-data';
import { sendBlockstarClickActionEvent } from './action';

export const onFireButtonClicked = (
  blockstar: Blockstar,
  setBlockstar: React.Dispatch<React.SetStateAction<Blockstar | undefined>>,
  onOpen: () => void,
) => {
  sendBlockstarClickActionEvent(blockstar, BlockstarActionType.Fire);
  setBlockstar(blockstar);
  onOpen();
};
