import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useTheme } from '@mui/material';
import { BlockstarSkillType, ThemeType } from '@shared-data';
import React, { FC, useContext } from 'react';
import TableContext, {
  ColumnState,
} from 'src/components/library/list-table/table-context';

import {
  HidableTableCellHeader,
  ListRowMainSkillHeaderRotator,
  ListRowMainSkillHeaderText,
  ListRowMainSkillHeaderTextContainer,
  TableCellHeader,
} from '../style';

export interface HeaderCell {
  id: string;
  label: string;
}

const mainHeadCells: HeaderCell[] = [
  {
    id: 'salary',
    label: 'Salary / Cut',
  },
  {
    id: 'music1',
    label: 'Music 1',
  },
  {
    id: 'music2',
    label: 'Music 2',
  },
  {
    id: 'music3',
    label: 'Music 3',
  },
];

const getGeneralHeaderCells = (skills: string[]) => {
  const labels: {
    id: string;
    label: string;
  }[] = [];
  skills.forEach((s) =>
    labels.push({
      id: s,
      label: s,
    }),
  );
  return labels;
};

const BlockstarsTableHeader: FC = () => {
  const theme = useTheme() as ThemeType;
  const dynamicHeaderCells = getGeneralHeaderCells(
    Object.values(BlockstarSkillType),
  );

  const { listToggle, selectedColumn, setSelectedColumn } =
    useContext(TableContext);

  let currentHeadCell = selectedColumn;
  const headerClicked = (headCell: HeaderCell) => {
    setSelectedColumn({
      id: headCell.id,
      state:
        selectedColumn.state === ColumnState.ascending ||
        currentHeadCell.id !== headCell.id
          ? ColumnState.descending
          : ColumnState.ascending,
    });
  };

  return (
    <>
      {mainHeadCells
        .map((headCell, idx) => (
          <TableCellHeader
            key={headCell.id}
            padding='none'
            width={listToggle ? '99px' : '94px'}
            height={'75px'}
            style={{
              whiteSpace: 'nowrap',
              transformOrigin: 'bottom left',
              transform: 'skew(-50deg)',
              WebkitTransform: 'skew(-50deg)',
              overflow: 'hidden',
              cursor: 'pointer',
            }}
            onClick={() => headerClicked(headCell)}>
            <ListRowMainSkillHeaderRotator
              style={{
                background: idx % 2 === 0 ? 'white' : 'transparent',
              }}>
              <ListRowMainSkillHeaderTextContainer>
                <div
                  style={{
                    width: '100%',
                    height: '20px',
                    position: 'absolute',
                    left: listToggle ? '' : '10px',
                  }}>
                  {selectedColumn.state === ColumnState.ascending &&
                  selectedColumn.id === headCell.id ? (
                    <ArrowDropUpIcon
                      style={{
                        color:
                          selectedColumn.id !== headCell.id
                            ? 'rgb(190, 190, 190)'
                            : '',
                      }}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      style={{
                        color:
                          selectedColumn.id !== headCell.id
                            ? 'rgb(190, 190, 190)'
                            : '',
                      }}
                    />
                  )}
                </div>

                <ListRowMainSkillHeaderText
                  style={{
                    fontWeight:
                      selectedColumn.id === headCell.id ? 'bolder' : '',
                    marginLeft:
                      listToggle && window.innerWidth > 1364 ? '8px' : '',
                    marginBottom: '4px',
                    marginRight:
                      !listToggle && window.innerWidth < 1364 ? '14px' : '',
                  }}>
                  {headCell.label}
                </ListRowMainSkillHeaderText>
              </ListRowMainSkillHeaderTextContainer>
            </ListRowMainSkillHeaderRotator>
          </TableCellHeader>
        ))
        .concat(
          dynamicHeaderCells.map((headCell, idx) => (
            <HidableTableCellHeader
              key={headCell.id}
              padding={'none'}
              width={listToggle ? '74px' : '50px'}
              height={'75px'}
              style={{
                whiteSpace: 'nowrap',
                transformOrigin: 'bottom left',
                transform: 'skew(-50deg)',
                WebkitTransform: 'skew(-50deg)',
                overflow: 'hidden',
                cursor: 'pointer',
              }}
              onClick={() => headerClicked(headCell)}>
              <ListRowMainSkillHeaderRotator
                onClick={() => headerClicked(headCell)}
                style={{
                  background: idx % 2 === 0 ? 'white' : 'transparent',
                }}>
                <ListRowMainSkillHeaderTextContainer>
                  <div
                    style={{
                      height: '20px',
                      position: 'absolute',
                      left: '-10px',
                    }}>
                    {selectedColumn.state === ColumnState.ascending &&
                    selectedColumn.id === headCell.id ? (
                      <ArrowDropUpIcon
                        style={{
                          color:
                            selectedColumn.id !== headCell.id
                              ? 'rgb(190, 190, 190)'
                              : '',
                        }}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        style={{
                          color:
                            selectedColumn.id !== headCell.id
                              ? 'rgb(190, 190, 190)'
                              : '',
                        }}
                      />
                    )}
                  </div>
                  <ListRowMainSkillHeaderText
                    style={{
                      fontWeight:
                        selectedColumn.id === headCell.id ? 'bolder' : '',
                      marginLeft: listToggle ? '8px' : '16px',
                      marginBottom: '4px',
                    }}>
                    {headCell.label}
                  </ListRowMainSkillHeaderText>
                </ListRowMainSkillHeaderTextContainer>
              </ListRowMainSkillHeaderRotator>
            </HidableTableCellHeader>
          )),
        )}
    </>
  );
};

export default BlockstarsTableHeader;
