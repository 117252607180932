import { styled } from '@mui/material';
import { ButtonDefault } from 'src/components/library/style';
import { InfoContainer, InfoHeader } from 'src/styles/global-style';

export const GenreSelectionContainer = styled(InfoContainer)`
  width: 555px;
`;

export const GenreSelectionInfoHeader = styled(InfoHeader)`
  text-align: center;
`;

export const HeaderContainer = styled('div')`
  width: 100%;
  height: 116px;
  padding: 0px 12px 0px 12px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BodyContainer = styled('div')`
  width: 100%;
  height: 176px;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  padding: 24px 48px 24px 48px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const GenreType = styled('p')`
  /* font-weight: bold;
  margin-bottom: 4px; */
  color: ${(props) => props.theme.colors.ui.buttonOff};
`;

export const ConfirmButton = styled(ButtonDefault)`
  width: 150px;
  height: 50px;
  left: 380px;
  margin-bottom: 24px;
`;

export const Link = styled('a')`
  color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const SubgenreText = styled('p')`
  width: 100%;
  /* color: ${(props) => props.theme.colors.text.secondaryGray};
  cursor: help; */
`;
export const SubgenreTooltipContainer = styled('div')`
  font-size: 16px;
  padding: 16px;
`;
