import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React from 'react';
import { Dropdown } from 'src/components/library/dropdown';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import { DropdownCost, DropdownDurInfo, DropdownItem } from './style';

const DurationDropdown = (props: {
  disabled: boolean;
  error: boolean;
  durations: number[];
  selectedDurationIndex: number;
  locationCost: number;
  salary: number;
  onDurationSelected: (duration: number) => void;
}) => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { rolBalance } = useRolBalance(connection, publicKey);

  const onChange = (index: number) => {
    props.onDurationSelected(index);
  };

  return (
    <Dropdown
      disabled={props.disabled}
      value={props.selectedDurationIndex}
      error={props.error}
      onChange={onChange}
      style={{ width: '85%' }}
      components={props.durations.map((val, idx) => {
        const disabled =
          rolBalance < props.locationCost + (val * props.salary) / 86400000;
        return {
          component: (
            <DropdownDuration
              disabled={disabled}
              duration={val}
              dailyCost={props.salary}
            />
          ),
          disabled: disabled,
        };
      })}
    />
  );
};

const DropdownDuration = (props: {
  duration: number;
  dailyCost: number;
  disabled: boolean;
}) => {
  const cost = ((props.duration * props.dailyCost) / 86400000).toLocaleString();
  const minutes = props.duration / 60000;
  const duration =
    minutes < 1440
      ? minutes === 60
        ? '1 hour'
        : `${minutes / 60} hours`
      : minutes === 1440
      ? '1 day'
      : `${minutes / 1440} days`;
  return (
    <DropdownItem>
      <DropdownDurInfo>
        <span>{duration}</span>
        <DropdownCost>
          <span>{`${cost} $ROL`}</span>
          {props.disabled && (
            <span style={{ fontSize: 12, color: 'red' }}>Need more $ROL</span>
          )}
        </DropdownCost>
      </DropdownDurInfo>
    </DropdownItem>
  );
};

export default DurationDropdown;
