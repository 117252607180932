import { styled } from '@mui/material/styles';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import profileIcon from '../../assets/images/icon_profile.svg';

export const headerHeight = 64;

export const Container = styled('div')`
  width: 100%;
  min-width: 1235px;
  height: ${headerHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const TitleContainer = styled('button')`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  height: 100%;
  min-width: 330px;
  padding-left: 50px;
  :hover {
    cursor: pointer;
  }
`;

export const Title = styled('h1')`
  color: white;
`;

export const HeaderMenuContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 60%;
  min-width: 500px;
`;

export const HeaderRightContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: center;
  max-width: 30%;
  margin-right: 50px;
`;

export const RolPriceButton = styled('button')`
  height: 45px;
  width: 120px;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 30px;
  &:hover:enabled {
    cursor: pointer;
  }
`;
export const RolPrice = styled('h3')`
  text-align: left;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const ProfileSvg = styled('div')`
  mask-image: url(${profileIcon});
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  width: 25px;
  height: 25px;
`;

export const WalletButton = styled(WalletMultiButton)`
  height: 45px;
  border: 0px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: normal;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple};
  &:hover:enabled {
    background-color: ${(props) => props.theme.colors.ui.primaryPurple};
  }
`;

export const SubHeaderContainer = styled('div')`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.ui.primaryPurple};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
`;

export const SubHeaderContents = styled('div')`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MenuUnselectedButton = styled('button')`
  height: 100%;
  margin-right: 6px;
  border-width: 0px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  opacity: 90%;
  font-size: 16px;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  &:hover:enabled {
    opacity: 100%;
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.ui.lightPurple};
    height: 52px;
    border-radius: 4px;
  }
`;

export const MenuSelectedButton = styled('button')`
  height: 52px;
  margin-right: 6px;
  border-width: 0px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.ui.darkPurple};
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  &:hover:enabled {
    cursor: pointer;
  }
`;
