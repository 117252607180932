import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Band, Blockstar } from '@shared-data';
import React from 'react';
import {
  CollageFrame,
  CollageImage,
  InfoNumber,
} from 'src/components/library/style';
import Stars from 'src/components/star-rating';
import { RolSvgStyled } from 'src/components/tokens/nft/style';
import { DividerVertical } from 'src/styles/global-style';
import {
  genreData,
  getBandCollegeColumnRowCount,
  getBandName,
} from 'src/utils/utils';
import {
  GenreIcon,
  GenreText,
  PopupHeaderCollegeContainer,
  PopupHeaderContainer,
  PopupHeaderInfoContainer,
  PopupHeaderInfoGenreContainer,
  PopupHeaderInfoRatingContainer,
  PopupHeaderInfoSalary,
  PopupHeaderInfoTag,
  PopupHeaderInfoTitle,
  PopupHeaderInfoTitleContainer,
} from './style';

const BandHeader = (props: {
  band: Band;
  salary: number;
  salaryCutInfo?: Blockstar;
}) => {
  const genre = genreData[props.band.genre];
  const collageColumnRowCount = getBandCollegeColumnRowCount(
    props.band.memberIds.length,
  );

  return (
    <PopupHeaderContainer>
      <PopupHeaderCollegeContainer>
        <CollageFrame collageColumnRowCount={collageColumnRowCount}>
          {props.band.memberIds.map((memberId, index) => {
            return (
              <CollageImage
                key={index}
                src={`${process.env.ASSET_BASE_URL}/${memberId}.png`}
              />
            );
          })}
        </CollageFrame>
      </PopupHeaderCollegeContainer>
      <PopupHeaderInfoContainer>
        <PopupHeaderInfoTitleContainer>
          <PopupHeaderInfoTitle>{getBandName(props.band)}</PopupHeaderInfoTitle>
          <PopupHeaderInfoTag>
            <InfoNumber>
              <PeopleAltIcon style={{ fontSize: '16px' }} />
              {`#${props.band.id}`}
            </InfoNumber>
          </PopupHeaderInfoTag>
        </PopupHeaderInfoTitleContainer>
        <PopupHeaderInfoRatingContainer>
          <Stars
            currentStars={props.band.starRating?.currentStarRating ?? 0}
            potentialStars={props.band.starRating?.potentialStarRating ?? 5}
          />
          <DividerVertical style={{ height: '80%' }} />
          <PopupHeaderInfoSalary>
            <RolSvgStyled />
            {`${props.salaryCutInfo?.wage.bucket}`}
          </PopupHeaderInfoSalary>
        </PopupHeaderInfoRatingContainer>
        <PopupHeaderInfoGenreContainer>
          <GenreIcon />
          <GenreText>{`${genre.displayName} / ${
            genre.subgenres[props.band.subgenre.toString()].displayName
          }`}</GenreText>
        </PopupHeaderInfoGenreContainer>
      </PopupHeaderInfoContainer>
    </PopupHeaderContainer>
  );
};

export default BandHeader;
