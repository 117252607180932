import { styled } from '@mui/material/styles';
import rollupLvlup from '../../../assets/images/rollup_lvlup.svg';
import rollupNewskill from '../../../assets/images/rollup_newskill.svg';
import rollupRol from '../../../assets/images/rollup_rol.svg';
import rollupStar from '../../../assets/images/rollup_star.svg';
import rollupXp from '../../../assets/images/rollup_xp.svg';

export const ResultsContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  padding-top: 32px;
  align-items: center;
  color: white;
`;

export const CloseContainer = styled('div')`
  height: 50px;
  width: 100%;
  top: 64px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
`;

export const BottomButtonContainer = styled('div')`
  display: flex;
  height: 60px;
  margin-top: 48px;
`;

export const ResultsContents = styled('div')`
  display: flex;
  flex-direction: row;
  width: 1200px;
  height: 100%;
  max-height: 600px;
`;

export const ResultsInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding: 20px;
  height: 600px;
  width: 100%;
`;
const ResultsIconBase = styled('div')`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 125px;
`;

export const ResultsXp = styled(ResultsIconBase)`
  background-image: url(${rollupXp});
`;

export const ResultsLvlup = styled(ResultsIconBase)`
  background-image: url(${rollupLvlup});
`;

export const ResultsStar = styled(ResultsIconBase)`
  background-image: url(${rollupStar});
`;

export const ResultsRol = styled(ResultsIconBase)`
  background-image: url(${rollupRol});
`;

export const ResultsNewskill = styled(ResultsIconBase)`
  background-image: url(${rollupNewskill});
`;
