import { useTheme } from '@mui/material/styles';
import { ActionTargetType, ThemeType } from '@shared-data';
import React from 'react';
import {
  StarFilled,
  StarFullPotential,
  StarUnreachable,
} from '../star-rating/style';
import { BarBG, CurrentBarMusical, PotentialBar } from './style';

export const StarRatingTooltipComponent = (props: {
  target: ActionTargetType;
}) => {
  const theme = useTheme() as ThemeType;
  return (
    <div style={{ color: theme.colors.text.primaryGray }}>
      <p
        style={{
          marginBottom: 8,
          fontSize: 16,
        }}>
        {"What's this?"}
      </p>
      <p style={{ fontSize: 12, lineHeight: 1.5 }}>
        {`Star Ratings are a quick & easy way to see each ${props.target}'s Current and Potential Ability. Star Ratings distill down the individual Skills and Attributes into an at-a-glance overview.`}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: 10,
          fontWeight: 'bold',
          alignItems: 'center',
          marginTop: 8,
        }}>
        <StarFilled style={{ width: 16, height: 16, marginRight: 8 }} />
        Current Star Rating
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: 10,
          fontWeight: 'bold',
          alignItems: 'center',
          marginTop: 8,
        }}>
        <StarFullPotential style={{ width: 16, height: 16, marginRight: 8 }} />
        Potential Star Rating
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: 10,
          fontWeight: 'bold',
          alignItems: 'center',
          marginTop: 8,
        }}>
        <StarUnreachable style={{ width: 16, height: 16, marginRight: 8 }} />
        Unreachable Star Rating
      </div>
    </div>
  );
};

export const SkillsTooltipComponent = (props: { target: ActionTargetType }) => {
  const theme = useTheme() as ThemeType;
  return (
    <div style={{ color: theme.colors.text.primaryGray, zIndex: 3 }}>
      <p
        style={{
          marginBottom: 8,
          fontSize: 16,
        }}>
        {"What's this?"}
      </p>
      <p style={{ fontSize: 12, lineHeight: 1.5 }}>
        {`Skills are mutable characteristics that show how strong each ${props.target}'s abilities are. These abilities can be improved, up to the Max Ability, through player actions.`}
      </p>
      <BarBG
        style={{
          margin: 0,
          marginTop: 16,
          marginBottom: 8,
        }}>
        <CurrentBarMusical
          style={{
            flex: 20,
          }}
        />
        <div
          style={{
            display: 'flex',
            flex: 45,
          }}
        />
        <PotentialBar
          style={{
            flex: 20,
          }}
        />
        <div style={{ display: 'flex', flex: 15 }} />
      </BarBG>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: 12,
          width: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            flex: 20,
            textAlign: 'left',
            overflow: 'visible',
          }}>
          Current
        </div>
        <div style={{ display: 'flex', flex: 30 }} />
        <div
          style={{
            display: 'flex',
            flex: 50,
            textAlign: 'center',
            justifyContent: 'center',
            overflow: 'visible',
          }}>
          Max Potential
        </div>
      </div>
    </div>
  );
};
