import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useTheme } from '@mui/material/styles';
import {
  ActionStatus,
  Band,
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
  SkillType,
  ThemeType,
} from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import { CountdownRenderProps } from 'react-countdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionStatusView } from 'src/components/library/action-status-overlay';
import { ActionCustomOverlay } from 'src/components/library/action-status-overlay/custom-overlay';
import { NewBadge } from 'src/components/library/new';
import { useBandAction } from 'src/hooks/band-action';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import {
  IBlockstarActionControl,
  useBlockstarActionControlContext,
} from 'src/hooks/blockstar-action-control';
import {
  capitalizeFirstLetter,
  getBandActionResults,
  getBlockstarName,
  getTimeLeft,
  shouldShowNew,
} from 'src/utils/utils';
import { KeyedMutator } from 'swr';
import {
  onStopAction,
  onViewButtonClicked,
  resumePaymentOnLoad,
} from '../../../actions/action';
import { useRolBalance } from '../../../hooks/wallet/rol-balance';
import {
  DividerHorizontal,
  DividerVertical,
} from '../../../styles/global-style';
import analytics from '../../../utils/analytics';
import Stars from '../../star-rating';
import { ExpandingActionButton } from './action-button';
import {
  CompleteLvlup,
  CompleteStar,
  CompleteXp,
  ItemInfoNumberContainer,
  LockSvg,
  MusicNoteSvg,
  NftListItemContainer,
  NftListItemImage,
  NftListItemInfoBaseContainer,
  NftListItemInfoContainer,
  NftListItemInfoInstrument,
  NftListItemInfoInstrumentsContainer,
  NftListItemInfoNumber,
  NftListItemInfoSalary,
  NftListItemInfoStars,
  NftListItemInfoTitle,
  RolSvgStyled,
} from './style';

const baseUrl = process.env.ASSET_BASE_URL || '';

const countdownRenderer = ({
  completed,
  days,
  hours,
  minutes,
  seconds,
  total,
}: CountdownRenderProps) => {
  return ``;
};

export const NFTListItem = (props: {
  blockstar: Blockstar;
  bands: Band[];
  onFireButtonClicked: () => void;
  fireButtonDisabledText?: string;
  action?: BlockstarActionResponse;
  mutate: KeyedMutator<{
    [key: number]: BlockstarActionResponse;
  }>;
}) => {
  const theme = useTheme() as ThemeType;

  const {
    setActionType: setBlockstarActionType,
    blockstar: actionableBlockstar,
    setBlockstar,
    setOpenActionSelectDialog: setOpenBlockstarActionSelectDialog,
    openActionProgressDialog: openBlockstarActionProgressDialog,
    setOpenActionProgressDialog: setOpenBlockstarActionProgressDialog,
    actionResult: blockstarActionResult,
  } = useBlockstarActionControlContext() as IBlockstarActionControl;

  const {
    setActionType: setBandActionType,
    setBand,
    setOpenActionProgressDialog: setOpenBandActionProgressDialog,
    actionResult: bandActionResult,
  } = useBandActionControlContext() as IBandActionControl;

  const { blockstar } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const { action, mutate } = props;
  const { bandAction, mutateBandAction } = useBandAction(blockstar.bandId);
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);

  const [disableClose, setDisableClose] = useState(false);

  const band = props.bands.find((band) =>
    band?.memberIds.includes(blockstar.number),
  );

  useEffect(() => {
    if (action && action.actionStatus === ActionStatus.Paying) {
      resumePaymentOnLoad(action, publicKey, connection, blockstar, () =>
        mutate(),
      );
    }
  }, [action]);

  const onActionsButtonClicked = (selectedActionType: BlockstarActionType) => {
    switch (selectedActionType) {
      case BlockstarActionType.View:
        onViewButtonClicked(blockstar.number, navigate);
        break;
      case BlockstarActionType.BandPracticing:
      case BlockstarActionType.BandGigging:
        onActionStatusOverlayDetail();
        break;
      default:
        break;
    }
  };

  const onActionStatusOverlayDetail = () => {
    if (action && action.actionType) {
      switch (action.actionType) {
        case BlockstarActionType.BandPracticing:
        case BlockstarActionType.BandGigging:
          setBandActionType(action.actionType);
          const band = props.bands?.find((band) =>
            band.memberIds.includes(blockstar.number),
          );
          setBand(band);
          setOpenBandActionProgressDialog(true);
          break;
        default:
          setBlockstarActionType(action.actionType);
          setBlockstar(blockstar);
          setOpenBlockstarActionProgressDialog(true);
          break;
      }
    }
  };

  const onOverlayClose = async () => {
    switch (action?.actionType) {
      case BlockstarActionType.BandPracticing:
      case BlockstarActionType.BandGigging:
        await getBandActionResults(band?.id, bandAction);
        break;
      default:
        setDisableClose(true); // dont let players spam the close button
        await onStopAction(publicKey, blockstar);
        break;
    }
    await mutate();
    setDisableClose(false);
    mutateBalance();
  };

  const getInstruments = () => {
    return blockstar.skills.map((skillInfo, index) => {
      if (skillInfo.type === SkillType.Instrumental) {
        if (skillInfo.name && skillInfo.name.length > 0) {
          const isNew = shouldShowNew(skillInfo);
          const style = isNew
            ? {
                border: '1px solid #FF24FF',
                justifyContent: 'space-between',
                paddingRight: 8,
                backgroundColor: 'rgba(255, 36, 255, 0.05)',
                borderRadius: 6,
              }
            : undefined;
          return (
            <NftListItemInfoInstrument key={index} isEnabled style={style}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <MusicNoteSvg />
                {skillInfo.name}
              </span>
              {isNew && (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <NewBadge />
                </span>
              )}
            </NftListItemInfoInstrument>
          );
        }
        return (
          <NftListItemInfoInstrument key={index}>
            <LockSvg />
            Skill not available yet
          </NftListItemInfoInstrument>
        );
      }
    });
  };

  return (
    <NftListItemContainer>
      <div style={{ position: 'relative' }}>
        <NftListItemImage src={`${baseUrl}/${blockstar.number}.png`} />
        {action && action.actionStatus !== ActionStatus.None && (
          <NFTListItemOverlay
            action={action}
            actionMutate={mutate}
            onClick={() => {
              onActionStatusOverlayDetail();
            }}
            onClose={onOverlayClose}
            disabled={disableClose}
          />
        )}
      </div>
      <NftListItemInfoContainer>
        <NftListItemInfoTitle>
          {getBlockstarName(blockstar)}
        </NftListItemInfoTitle>
        <ItemInfoNumberContainer>
          <NftListItemInfoNumber>{`#${blockstar.number}`}</NftListItemInfoNumber>
          {band && (
            <NftListItemInfoNumber>
              <PeopleAltIcon style={{ fontSize: '16px' }} />
              {`#${band.id}`}
            </NftListItemInfoNumber>
          )}
        </ItemInfoNumberContainer>
        <NftListItemInfoBaseContainer>
          <NftListItemInfoStars>
            <Stars
              currentStars={blockstar.starRatingInfo.currentStarRating ?? 0}
              potentialStars={blockstar.starRatingInfo.maxStarRating ?? 0}
            />
          </NftListItemInfoStars>
          <DividerVertical />
          <NftListItemInfoSalary>
            <RolSvgStyled />
            {blockstar.wage.bucket}
          </NftListItemInfoSalary>
        </NftListItemInfoBaseContainer>
        <DividerHorizontal />
        <NftListItemInfoInstrumentsContainer>
          {getInstruments()}
        </NftListItemInfoInstrumentsContainer>
        <ExpandingActionButton
          blockstar={blockstar}
          action={action}
          onActionsButtonClicked={onActionsButtonClicked}
          onFireButtonClicked={props.onFireButtonClicked}
          fireButtonDisabledText={props.fireButtonDisabledText}
        />
      </NftListItemInfoContainer>
    </NftListItemContainer>
  );
};

const NFTListItemOverlay = (props: {
  action: BlockstarActionResponse;
  actionMutate: KeyedMutator<{
    [key: number]: BlockstarActionResponse;
  }>;
  onClick: () => void;
  onClose: () => void;
  disabled: boolean;
}) => {
  const fireStat = (userAction: string) => {
    let xpScreen = 'xp earned';
    if (props.action.levelUpInfo) {
      xpScreen = 'ability increased';
    }
    const maxedSkill = props.action.skills?.find((item) => item.maxed);
    if (maxedSkill) {
      xpScreen = 'ability maxed';
    }
    if (props.action.starUpInfo) {
      xpScreen = 'stars increased';
    }
    analytics.logEvent('ClickOverlayActionComplete', {
      groupType: 'individual',
      action: props.action.actionType,
      userAction,
      xpScreen,
    });
  };
  const theme = useTheme() as ThemeType;
  switch (props.action.actionStatus) {
    case ActionStatus.InAction:
      const onCountDownComplete = () => {
        props.actionMutate();
      };

      return (
        <ActionStatusView
          timeLeft={getTimeLeft(props.action)}
          durationMs={props.action.durationMs ?? 0}
          title={capitalizeFirstLetter(props.action?.actionType ?? '')}
          onViewClick={() => {
            props.onClick();
          }}
          onCountDownComplete={onCountDownComplete}
        />
      );
    case ActionStatus.Completed:
      let icon = <CompleteXp />;
      let text = `${capitalizeFirstLetter(
        props.action.actionType ?? '',
      )} complete!`;
      if (props.action.starUpInfo) {
        icon = <CompleteStar />;
        text = 'You earned a star!';
      } else if (props.action.levelUpInfo) {
        icon = <CompleteLvlup />;
        text = 'Ability increased!';
      }

      return (
        <ActionCustomOverlay
          icon={icon}
          title={text}
          buttonText='View details'
          onClick={() => {
            fireStat('view details');
            props.onClick();
          }}
          onClose={() => {
            fireStat('dismiss');
            props.onClose();
          }}
        />
      );
    default:
      return <></>;
  }
};
