import { Button } from '@mui/material';
import React from 'react';

export const Confirm = (props: {
  onFire: () => void;
  onCancel: () => void;
  name: string;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        width: 528,
        height: 375,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: '0px 32px',
      }}>
      <span
        style={{
          fontSize: 32,
        }}>{`Are you sure you want to fire ${props.name}?`}</span>
      <span>
        Once you fire a Blockstar, the action cannot be undone. You will not be
        able to use this Blockstar in the future.
      </span>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}>
        <Button
          variant={'contained'}
          fullWidth
          onClick={props.onFire}
          style={{
            height: 60,
            borderRadius: 38,
          }}>
          Fire Blockstar
        </Button>
        <Button
          variant={'text'}
          onClick={props.onCancel}
          style={{ height: 60 }}>
          Nevermind
        </Button>
      </div>
    </div>
  );
};
