import * as React from 'react';
import { NoOverwriteContainer } from './style';

export enum NoOverwriteType {
  NO_SLOTS,
  ALREADY_HAVE,
}

export const NoOverwrite = (props: {
  skill: string;
  type: NoOverwriteType;
}) => {
  if (props.type === NoOverwriteType.ALREADY_HAVE) {
    return (
      <NoOverwriteContainer>
        <span style={{ fontSize: 32, textAlign: 'center' }}>
          Your Blockstar already knows
        </span>
        <span style={{ fontSize: 32, textAlign: 'center' }}>
          {props.skill}.
        </span>
        <span style={{ fontSize: 16, textAlign: 'center', marginTop: 10 }}>
          (Musical skill won't transfer)
        </span>
      </NoOverwriteContainer>
    );
  } else {
    return (
      <NoOverwriteContainer>
        <span style={{ fontSize: 32, textAlign: 'center' }}>
          Your Blockstar will not learn
        </span>
        <span style={{ fontSize: 32, textAlign: 'center' }}>
          {props.skill}.
        </span>
        <span style={{ fontSize: 16, textAlign: 'center', marginTop: 10 }}>
          (You must have at least one additional musical skill slot to learn a
          new musical skill.)
        </span>
      </NoOverwriteContainer>
    );
  }
};
