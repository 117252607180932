import React from 'react';
import { SkillSummary } from 'src/components/library/skill-details';
import { ISkillEntryProps } from 'src/components/library/skill-details/skill-entry';

export const BottomSkills = (props: {
  skills: ISkillEntryProps[];
  disbanded?: boolean;
}) => {
  return <SkillSummary skills={props.skills} nameOnly={props.disbanded} />;
};
