import { Blockstar, SkillInfo } from '@shared-data';
import * as React from 'react';
import { getBlockstarName } from 'src/utils/utils';
import { ResultsInfoContainer, ResultsNewskill } from '../style';

interface IProps {
  blockstar: Blockstar;
  skill: SkillInfo;
}

export const NewSkill = (props: IProps) => {
  return (
    <ResultsInfoContainer
      style={{ height: '100%', padding: 0, justifyContent: 'center', gap: 24 }}>
      <ResultsNewskill />
      <div
        style={{
          fontSize: 40,
          fontWeight: 600,
          textAlign: 'center',
        }}>{`${getBlockstarName(
        props.blockstar,
        true,
      )} unlocked a new musical skill: ${props.skill.name}!`}</div>
    </ResultsInfoContainer>
  );
};
