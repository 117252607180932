import {
  Band,
  BandActionResponse,
  bandActionStartTime,
  BandExpGainableSkillType,
  BlockstarActionType,
} from '@shared-data';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { SimpleDialog } from 'src/components/library/simple-dialog';
import { useActionOptions } from 'src/hooks/action-options';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import analytics from 'src/utils/analytics';
import {
  calculateProgress,
  fullTimeCountdownRenderer,
  getBandActionResults,
  getBandName,
} from 'src/utils/utils';

const StopActionDialog = (props: {
  dialogOpen: boolean;
  band: Band;
  bandAction: BandActionResponse;
  onClose: () => void;
}) => {
  const {
    setBand,
    setActionType,
    setOpenActionResultDialog,
    setActionResult,
    setActionResultError,
  } = useBandActionControlContext() as IBandActionControl;
  const { locations } = useActionOptions(props.bandAction.action.actionType);

  const [okButtonLoading, setOkButtonLoading] = useState(false);

  const stopActionTextSplit = props.bandAction.action?.actionType.split(' ');
  const stopActionText =
    stopActionTextSplit && stopActionTextSplit.length > 1
      ? stopActionTextSplit[1].toLowerCase()
      : props.bandAction.action?.actionType.toLowerCase();

  const stopActionEarningXP = props.bandAction.blockstarActionResponses.reduce(
    (prev, curr) => prev + (curr.skills ? curr.skills[0].gainedExp : 0),
    0,
  );

  const bandActionStartTimeMs = bandActionStartTime(props.bandAction.action);
  const bandActionEndDate =
    bandActionStartTimeMs + props.bandAction.action.durationMs;
  const bandActionTimeLeft =
    Date.now() < bandActionStartTimeMs + props.bandAction.action.durationMs
      ? bandActionStartTimeMs + props.bandAction.action.durationMs - Date.now()
      : 0;
  const bandActionProgress = calculateProgress(
    props.bandAction.action.durationMs - bandActionTimeLeft,
    props.bandAction.action.durationMs,
    true,
  );
  const bandActionTimeRemaining = (
    <Countdown date={bandActionEndDate} renderer={fullTimeCountdownRenderer} />
  );

  const bandActionStopMessage = (
    <div>
      <div>
        {`${bandActionProgress}% complete | `} {bandActionTimeRemaining}
      </div>
      <br />
      <div>{`You will earn +${Math.floor(
        stopActionEarningXP ?? 0,
      )} XP to your ${props.bandAction.action?.actionSkillType.toLowerCase()} skill, but you will not be refunded the cost of sending your ${props.bandAction.action.actionType.toLowerCase()}.`}</div>
    </div>
  );
  const getResults = async () => {
    try {
      const result = await getBandActionResults(
        props.band.id,
        props.bandAction,
      );
      setActionResult(result);
      setActionResultError(undefined);
    } catch {
      setActionResult(undefined);
      setActionResultError('Error getting results');
    }
  };

  const onOKButtonClicked = async () => {
    setOkButtonLoading(true);
    const startTimeMs = bandActionStartTime(props.bandAction.action);
    let skillsName = props.bandAction.action.actionSkillType.toString();
    if (
      props.bandAction.action.actionType === BlockstarActionType.BandPracticing
    ) {
      switch (props.bandAction.action.actionSkillType) {
        case BandExpGainableSkillType.MUSICAL:
          skillsName = '';
          props.band.musicalMembers.forEach((member, index) => {
            skillsName +=
              (index === 0 ? '' : ', ') + member?.blockstarSkill.name;
          });
          break;
        default:
          break;
      }
    }
    const location = locations.find(
      (l) => l.id === props.bandAction.action.locationId,
    );

    analytics.logEvent('ClickActionStopConfirm', {
      action: props.bandAction.action.actionType,
      bandId: props.band.id,
      skill: skillsName,
      location: location?.name,
      duration: props.bandAction.action.durationMs! / 60000,
      timeLapsed: Math.floor((Date.now() - startTimeMs) / 60000),
    });

    setBand(props.band);
    setActionType(props.bandAction.action.actionType);
    setOpenActionResultDialog(true);
    await getResults();
    onCancelButtonClicked();
  };
  const onCancelButtonClicked = () => {
    props.onClose();
    setOkButtonLoading(false);
  };

  return (
    <SimpleDialog
      open={props.dialogOpen}
      title={`${getBandName(
        props.band,
      )} is ${stopActionText}, are you sure you want to stop?`}
      message={bandActionStopMessage}
      okTitle={`Stop ${stopActionText}`}
      cancelTitle='Nevermind'
      okButtonLoading={okButtonLoading}
      onOKButtonClicked={onOKButtonClicked}
      onCancelButtonClicked={onCancelButtonClicked}
    />
  );
};

export default StopActionDialog;
