import { RoutePath } from '@shared-data';
import { useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BandActionProgress from 'src/components/actions/action-progress/band-action-progress';
import BandActionSelect from 'src/components/actions/action-select/band-action-select';
import Header from 'src/components/header';
import { PageBlockView } from 'src/components/library/page-block-view';
import { BandPracticeResults } from 'src/components/library/results/band-practice';
import { Loading } from 'src/components/loading';
import { NFTListLayout } from 'src/components/tokens/nft/nft-list';
import { BandActionControlProvider } from 'src/hooks/band-action-control';
import { useBands } from 'src/hooks/bands';
import { useBlockstars } from 'src/hooks/blockstars';
import analytics from 'src/utils/analytics';
import { genreData } from 'src/utils/utils';
import GenreSelectionDialog from '../genre-selection';
import { BandsView } from './bands-view';
import EmptyBand from './empty-band';
import {
  BandsBodyContainer,
  Container,
  CreateBandButton,
  CreateBandButtonContainer,
} from './style';

const BandsMain = (props: { children?: any }) => {
  const { blockstars } = useBlockstars();
  const { publicKey } = useWallet();
  const navigate = useNavigate();

  const [genreSelectionOpen, setGenreSelectionOpen] = useState(false);
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
  const { bands, mutateBands } = useBands();

  useEffect(() => {
    analytics.logEvent('ViewScreenMyBands', {
      layout: NFTListLayout.GRID,
    });
  }, []);

  useEffect(() => {
    setCreateButtonDisabled(!blockstars || blockstars.length <= 0);
  }, [blockstars]);

  const openGenreSelectionDialog = () => {
    analytics.logEvent('ClickStartBand');
    setGenreSelectionOpen(true);
  };

  const onGenreSelectionClose = () => {
    setGenreSelectionOpen(false);
  };

  const onGenreSelected = (genreId: string, subGenreId: string) => {
    onGenreSelectionClose();
    navigate(RoutePath.BandsEdit, {
      state: {
        originPage: location.pathname,
        genreId,
        subGenreId,
      },
    });
  };

  if (!blockstars || !bands) {
    return <Loading height='300px' iconSize='50px' />;
  }

  if (!blockstars || blockstars.length <= 0) {
    return (
      <PageBlockView
        title='My Bands'
        message='Oops, you have no Blockstars in this wallet!'
      />
    );
  }

  return (
    <Container>
      <Header />
      <BandActionControlProvider>
        <BandsBodyContainer>
          <CreateBandButtonContainer absolute={!bands || bands.length <= 0}>
            <CreateBandButton
              disabled={createButtonDisabled}
              onClick={openGenreSelectionDialog}>
              Start a band
            </CreateBandButton>
          </CreateBandButtonContainer>
          {bands && bands.length > 0 ? (
            <BandsView
              bands={bands}
              mutateBands={mutateBands}
              blockstars={blockstars}
            />
          ) : (
            <EmptyBand />
          )}
        </BandsBodyContainer>
        <GenreSelectionDialog
          bands={bands}
          genreData={genreData}
          open={genreSelectionOpen}
          onClose={onGenreSelectionClose}
          onGenreSelected={onGenreSelected}
        />
        <BandActionSelect />
        <BandActionProgress />
        <BandPracticeResults />
      </BandActionControlProvider>
    </Container>
  );
};

export default BandsMain;
