import { LoadingButton } from '@mui/lab';
import { Dialog } from '@mui/material';
import {
  BlockstarActionResponse,
  BlockstarActionType,
  SkillType,
} from '@shared-data';
import { PerformanceSkillType } from '@shared-generated/generated-instruments';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RollupLvlup, RollupXp } from 'src/components/actions/style';
import { Loading } from 'src/components/loading';
import {
  IBlockstarActionControl,
  useBlockstarActionControlContext,
} from 'src/hooks/blockstar-action-control';
import { useBlockstars } from 'src/hooks/blockstars';
import analytics from 'src/utils/analytics';
import Results, { ResultsPage } from '.';
import { SkillEntryType } from '../types';
import { RolTable } from './left/rol-table';
import { StarIncrease } from './left/star-increase';
import { XpEntryProps } from './left/xp-entry';
import { XpTable } from './left/xp-table';
import { BlockstarRender } from './right/blockstar-render';

export const BuskResults = () => {
  const {
    openActionResultDialog,
    setOpenActionResultDialog,
    actionResult,
    setActionResult,
    actionResultError,
    setActionResultError,
    actionType,
    setActionType,
    blockstar,
    setBlockstar,
  } = useBlockstarActionControlContext() as IBlockstarActionControl;
  const { mutateBlockstars } = useBlockstars();
  const [retryLoading, setRetryLoading] = useState(false);

  useEffect(() => {
    if (openActionResultDialog && actionType === BlockstarActionType.Busking) {
      if (actionResult) {
        logStats();
      }
    }
  }, [openActionResultDialog, actionType, actionResult]);

  const logStats = () => {
    const action = actionResult as BlockstarActionResponse;
    let xpScreen = 'xp earned';
    if (action.levelUpInfo) {
      xpScreen = 'ability increased';
    }
    const maxedSkill = action.skills?.find((item) => item.maxed);
    if (maxedSkill) {
      xpScreen = 'ability maxed';
    }
    if (action.starUpInfo) {
      xpScreen = 'stars increased';
    }
    analytics.logEvent('ViewScreenActionComplete', {
      groupType: 'individual',
      action: actionType,
      xpScreen,
    });
  };

  const onRetry = () => {
    setRetryLoading(true);
    // need to fill this out after we decide on how to consolidate these requests / less boilerplate
    setRetryLoading(false);
  };
  const onClose = () => {
    setOpenActionResultDialog(false);
    setActionResult(undefined);
    setActionResultError(undefined);
    setActionType(BlockstarActionType.Practicing);
    setBlockstar(undefined);
    mutateBlockstars();
  };

  const pages: ResultsPage[] = [];

  if (actionResult) {
    const action = actionResult as BlockstarActionResponse;
    pages.push({
      left: <RolTable blockstar={blockstar!} action={actionResult} />,
      right: <BlockstarRender key='rol' blockstarId={action.blockstarId!} />,
    });

    const xpEntryProps: XpEntryProps[] = [];
    action.skills?.forEach((s) => {
      if (s.gainedExp) {
        const bSkill = blockstar!.skills.find((bs) => bs.name === s.name)!;
        let type = SkillEntryType.MUSICAL;
        if (bSkill.type !== SkillType.Instrumental) {
          const t = s.name.split(' ')[0].toUpperCase();
          type = SkillEntryType[t as keyof typeof SkillEntryType];
        }
        const levelUpInfo =
          action.levelUpInfo &&
          action.levelUpInfo.find((lvlup) => lvlup.skill === s.name);
        const ability = levelUpInfo?.currentAbility ?? bSkill.currentAbilities;
        xpEntryProps.push({
          name: s.name,
          type,
          ability,
          targetExp: bSkill.targetExp ?? 1,
          finalExp: levelUpInfo?.currentExp ?? s.finalExp ?? 0,
          gainedExp: s.gainedExp ?? 0,
          previousExp: s.previousExp ?? 0,
          levelUpInfo,
        });
      }
    });

    const newSkill = action.blockstar?.skills.find(
      (s) => s.type === SkillType.Instrumental && s.new,
    );
    if (newSkill) {
      const musicalCount = xpEntryProps.filter(
        (p) => p.type === SkillEntryType.MUSICAL,
      ).length;
      xpEntryProps.splice(musicalCount, 0, {
        name: newSkill.name,
        type: SkillEntryType.MUSICAL,
        ability: newSkill.currentAbilities,
        targetExp: newSkill.targetExp ?? 1,
        finalExp: 0,
        gainedExp: 0,
        previousExp: 0,
        new: true,
      });
    }

    let icon = <RollupXp />;
    let text = 'XP Earned!';
    if (action.levelUpInfo) {
      icon = <RollupLvlup />;
      text = 'Your ability increased!';
      if (action.levelUpInfo.find((i) => i.maxed)) {
        text = 'Your ability maxed out!';
      }
    }
    pages.push({
      left: (
        <XpTable
          headerText='BUSK COMPLETE'
          mainText={[text]}
          icon={icon}
          newSkill={newSkill?.name as PerformanceSkillType}
          xpEntryProps={xpEntryProps}
          allLevelUps={action.levelUpInfo ?? []}
        />
      ),
      right: <BlockstarRender key='xp' blockstarId={action.blockstarId!} />,
    });

    if (action.starUpInfo) {
      const { previousStars, currentStars } = action.starUpInfo;
      pages.push({
        left: (
          <StarIncrease
            previousStars={previousStars}
            currentStars={currentStars}
            potentialStars={blockstar!.starRatingInfo.maxStarRating}
          />
        ),
        right: (
          <BlockstarRender
            key='star-increase'
            blockstarId={action.blockstarId!}
            confetti
          />
        ),
      });
    }
  }

  return (
    <Dialog
      open={
        openActionResultDialog && actionType === BlockstarActionType.Busking
      }
      onClose={onClose}
      maxWidth={false}
      fullScreen
      PaperProps={{ sx: { background: 'rgba(0, 0, 0, 0.8);' } }}>
      {actionResult ? (
        <Results pages={pages} onClose={onClose} />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Loading height='300px' iconSize='150px' />
          {actionResultError && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{actionResultError}</span>
              <LoadingButton
                variant='contained'
                loading={retryLoading}
                onClick={onRetry}>
                Retry
              </LoadingButton>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};
