import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Paper, Tooltip } from '@mui/material';
import {
  Blockstar,
  BlockstarSkillType,
  CrateRarity,
  LevelUpInfo,
  NGMISkillOption,
  SignableTransaction,
  SkillType,
  StarUpInfo,
  TransferInfo,
} from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Transaction } from '@solana/web3.js';
import bs58 from 'bs58';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import rest from 'src/actions/network/rest';
import { sendSignedTransaction } from 'src/actions/wallet';
import { useAllBlockstarActions } from 'src/hooks/blockstar-action';
import { useNFTs } from 'src/hooks/wallet/nfts';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import analytics from 'src/utils/analytics';
import { getBlockstarName } from 'src/utils/utils';
import { DialogClose } from '../library/dialog-header';
import { infoIcon } from '../library/info-icon';
import { NgmiResults } from '../library/results/ngmi';
import { Loading } from '../loading';
import { Confirm } from './confirm';
import { NoOverwrite, NoOverwriteType } from './no-overwrite';
import { SelectOverwrite } from './select-overwrite';
import { SelectTarget } from './select-target';
import { BottomContainer, MainContainer, TooltipContainer } from './style';
import { Summary } from './summary';
import { Transfer } from './transfer';

enum NGMIStep {
  SELECT,
  NO_OVERWRITE,
  OVERWRITE,
  SUMMARY,
  CONFIRM,
  TRANSFER,
  RESULTS,
}

export interface ResultsData {
  transferData: TransferInfo[];
  levelUpInfo: LevelUpInfo[];
  starUpInfo?: StarUpInfo;
  blockstar: Blockstar;
  iouRarity: CrateRarity;
  newSkill?: string;
}

const NGMI = ({
  blockstar,
  blockstars,
  open,
  onClose,
}: {
  blockstar: Blockstar;
  blockstars: Blockstar[];
  open: boolean;
  onClose: () => void;
}) => {
  const { publicKey, signTransaction } = useWallet();
  const { connection } = useConnection();
  const { mutateNFTs } = useNFTs(connection, publicKey);
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);
  const [target, setTarget] = useState(0);
  const [step, setStep] = useState<NGMIStep>(NGMIStep.SELECT);
  const [overwrite, setOverwrite] = useState<number>();
  const [noOverwrite, setNoOverwrite] = useState<NoOverwriteType>(
    NoOverwriteType.NO_SLOTS,
  );
  const [transfer, setTransfer] = useState<TransferInfo[]>([]);
  const [overwriteLoading, setOverwriteLoading] = useState(false);
  const [estimateLoading, setEstimateLoading] = useState(false);
  const [shouldMutateNfts, setShouldMutateNfts] = useState(false);
  const targets = blockstars.filter((b) => b.number !== blockstar.number);
  const [summaryData, setSummaryData] = useState<{
    transferData: TransferInfo[];
    overwriteSkill: string;
    newSkill: string;
  }>();
  const [resultsData, setResultsData] = useState<ResultsData>();

  // const actions = useBlockstarActions(blockstars.map((b) => b.number));
  const { actions, mutate } = useAllBlockstarActions(
    blockstars.map((b) => b.number),
  );
  const filteredActions = { ...actions };
  delete filteredActions[blockstar.number];

  const cleanup = () => {
    setTarget(0);
    setStep(0);
    setOverwrite(undefined);
    setSummaryData(undefined);
    setResultsData(undefined);
    if (shouldMutateNfts) {
      mutateNFTs();
    }
    setShouldMutateNfts(false);
  };

  const fire = async () => {
    if (!publicKey || !signTransaction) {
      const notReady = 'Tried to execute NGMI with no wallet ready...';
      console.error(notReady);
      toast.error(notReady);
      return;
    }
    if (rolBalance < blockstar.wage.salary * 120) {
      const error = `Insufficient ROL: ${
        blockstar.wage.salary * 120
      } required to fire #${blockstar.number}`;
      console.log(error);
      toast.error(error);
      analytics.logEvent('NGMIComplete', {
        blockstar: blockstar.number,
        state: 'insufficient-rol',
      });
      setStep(NGMIStep.SUMMARY);
      throw new Error(error);
    }

    analytics.logEvent('ClickFireConfirm', {
      fireBlockstarId: blockstar.number,
      targetBlockstarId: targets[target].number,
      musicalSkillInherited: summaryData?.newSkill,
      musicalXp: summaryData?.transferData.find(
        (t) => t.type === SkillType.Instrumental,
      )?.exp,
      writingXp: summaryData?.transferData.find(
        (t) => t.name === BlockstarSkillType.Writing,
      )?.exp,
      recordingXp: summaryData?.transferData.find(
        (t) => t.name === BlockstarSkillType.Recording,
      )?.exp,
      promotingXp: summaryData?.transferData.find(
        (t) => t.name === BlockstarSkillType.Promoting,
      )?.exp,
      practicingXp: summaryData?.transferData.find(
        (t) => t.name === BlockstarSkillType.Practicing,
      )?.exp,
      giggingXp: summaryData?.transferData.find(
        (t) => t.name === BlockstarSkillType.Gigging,
      )?.exp,
      fameXp: summaryData?.transferData.find(
        (t) => t.name === BlockstarSkillType.Fame,
      )?.exp,
      socialMediaXp: summaryData?.transferData.find(
        (t) => t.name === BlockstarSkillType.SocialMedia,
      )?.exp,
    });

    /* start txn to fire blockstar */
    setStep(NGMIStep.TRANSFER);

    try {
      const signable = await rest.post<SignableTransaction>(
        `${process.env.WORKER_URL}/api/ngmi/start`,
        {
          blockstarId: blockstar.number,
          initiator: publicKey.toBase58(),
        },
      );
      const txn = Transaction.from(Buffer.from(signable.base64, 'base64'));
      txn.lastValidBlockHeight = signable.lastValidBlockHeight;
      const signedTxn = await signTransaction(txn);

      const txnFee = (
        await connection.getFeeForMessage(signedTxn.compileMessage())
      ).value;
      const solBalance = await connection.getBalance(publicKey);
      if (solBalance < txnFee) {
        const error = `Insufficient $SOL balance to cover transaction fee.`;
        analytics.logEvent('NGMIComplete', {
          blockstar: blockstar.number,
          state: 'insufficient-sol',
        });
        setStep(NGMIStep.SUMMARY);
        throw new Error(error);
      }

      const firstSignature = bs58.encode(signedTxn.signature!);
      const receivedSignature = await rest.post(
        `${process.env.WORKER_URL}/api/ngmi/execute`,
        {
          blockstarId: blockstar.number,
          signature: firstSignature,
        },
      );
      console.log(
        `Sending NGMI txn: ${firstSignature} | ${receivedSignature}...`,
      );
      const sentSignature = await sendSignedTransaction(signedTxn, connection);
      console.log(`NGMI txn confirmed: ${sentSignature}`);

      const response = await rest.post<ResultsData>(
        `${process.env.WORKER_URL}/api/ngmi/complete`,
        {
          blockstarId: blockstar.number,
        },
      );
      setResultsData(response);
      setStep(NGMIStep.RESULTS);
      analytics.logEvent('NGMIComplete', {
        blockstar: blockstar.number,
      });
    } catch (e: any) {
      const error = `NGMI failed: ${e.message}`;
      console.log(error, e);
      toast.error(error);
      analytics.logEvent('NGMIComplete', {
        blockstar: blockstar.number,
        state: e.message,
      });
      setStep(NGMIStep.SUMMARY);
      throw new Error(error);
    }
  };

  let componentToShow = <Loading />;
  if (actions && Object.values(actions).length > 0) {
    // eslint-disable-next-line default-case
    switch (step) {
      case NGMIStep.SELECT:
        componentToShow = (
          <SelectTarget
            blockstar={blockstar}
            targets={targets}
            target={target}
            setTarget={setTarget}
            actions={filteredActions}
            onClose={onClose}
            transfer={transfer}
            setTransfer={setTransfer}
            setEstimateLoading={setEstimateLoading}
          />
        );
        break;
      case NGMIStep.NO_OVERWRITE:
        componentToShow = (
          <NoOverwrite type={noOverwrite} skill={blockstar.skills[0].name} />
        );
        break;
      case NGMIStep.OVERWRITE:
        componentToShow = (
          <SelectOverwrite
            blockstar={blockstar}
            targetBlockstar={targets[target]}
            overwrite={overwrite}
            setOverwrite={setOverwrite}
          />
        );
        break;
      case NGMIStep.SUMMARY:
        componentToShow = (
          <Summary
            blockstar={blockstar}
            targetBlockstar={targets[target]}
            summaryData={summaryData!}
          />
        );
        break;
      case NGMIStep.CONFIRM:
        componentToShow = (
          <Confirm
            onFire={fire}
            onCancel={() => setStep((curr) => curr - 1)}
            name={getBlockstarName(blockstar, true)}
          />
        );
        break;
      case NGMIStep.TRANSFER:
        componentToShow = (
          <Transfer blockstar={blockstar} target={targets[target]} />
        );
        break;
      case NGMIStep.RESULTS:
        mutateBalance();
        componentToShow = (
          <NgmiResults
            target={targets[target]}
            onClose={() => {
              onClose();
              setShouldMutateNfts(true);
            }}
            data={resultsData!}
          />
        );
        break;
    }
  }

  const onButtonClick = async () => {
    if (step === NGMIStep.SELECT) {
      let nextStep = NGMIStep.OVERWRITE;
      const skillSlots = targets[target].skills.filter(
        (s) => s.type === SkillType.Instrumental,
      );
      const skillExists = skillSlots.find(
        (s) => s.name === blockstar.skills[0].name,
      );
      if (skillSlots.length === 1 || skillExists) {
        if (skillExists) {
          setNoOverwrite(NoOverwriteType.ALREADY_HAVE);
        } else {
          setNoOverwrite(NoOverwriteType.NO_SLOTS);
        }
        // 0 available slots or already have skill = no overwrite
        await setOverwriteRequest(NGMISkillOption.NONE);
        nextStep = NGMIStep.NO_OVERWRITE;
      } else if (skillSlots.find((s) => !s.name)) {
        // auto select available locked slot
        await setOverwriteRequest(NGMISkillOption.LOCKED);
        nextStep = NGMIStep.SUMMARY;
      }
      setStep(nextStep);
    } else if (step === NGMIStep.NO_OVERWRITE) {
      setStep(NGMIStep.SUMMARY);
    } else if (step === NGMIStep.OVERWRITE) {
      await setOverwriteRequest(
        overwrite
          ? targets[target].skills[overwrite].name
          : NGMISkillOption.NONE,
      );
      setStep((curr) => curr + 1);
    } else {
      if (step === NGMIStep.SUMMARY) {
        analytics.logEvent('ClickActionStart', {
          action: 'fire',
          blockstarId: blockstar.number,
          skill: 'all',
          location: 'none',
          duration: 0,
          rolCost: blockstar.wage.salary * 120,
        });
      }
      setStep((curr) => curr + 1);
    }
  };

  const setOverwriteRequest = async (overwriteSkill: string) => {
    setOverwriteLoading(true);
    const response: any = await rest.post(
      `${process.env.WORKER_URL}/api/ngmi/overwrite`,
      {
        blockstarId: blockstar.number,
        targetId: targets[target].number,
        overwriteSkill,
      },
    );
    setSummaryData(response);
    setOverwriteLoading(false);
  };

  let buttonText = 'Next';
  if (step === 2) {
    buttonText = 'Confirm';
  }

  const tooltipRol = (
    <Paper>
      <TooltipContainer>
        This is 120 days of salary you will need to pay your fired Blockstar in
        severance.
      </TooltipContainer>
    </Paper>
  );

  const tooltipXp = (
    <Paper>
      <TooltipContainer>{`This is the total XP ${
        targets && targets.length > target && targets[target]
          ? targets[target].name
          : 'Blockstar'
      } will gain across all skills.`}</TooltipContainer>
    </Paper>
  );

  const buttonDisabled = (step: number) => {
    return step === NGMIStep.OVERWRITE && overwrite === undefined;
  };

  const showClose = step <= NGMIStep.SUMMARY;
  const showBottom = step <= NGMIStep.SUMMARY;
  const fullScreen = step >= NGMIStep.TRANSFER;

  const totalExp =
    transfer.length > 0
      ? Math.floor(
          transfer.reduce((prev, curr) => prev + curr.exp, 0),
        ).toLocaleString()
      : '';

  const paperProps = fullScreen
    ? {
        sx: {
          background: 'rgba(0, 0, 0, 0.8);',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }
    : undefined;

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      PaperProps={paperProps}
      TransitionProps={{
        onExited: cleanup,
        mountOnEnter: true,
        unmountOnExit: true,
      }}
      maxWidth={false}
      fullScreen={fullScreen}>
      <MainContainer
        style={fullScreen ? { width: '100%', height: '100%' } : {}}>
        {showClose && (
          <DialogClose
            onClose={() => {
              onClose();
              analytics.logEvent('ClickActionClose');
            }}
          />
        )}
        {componentToShow}
        {showBottom && (
          <BottomContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              {step !== NGMIStep.OVERWRITE && step !== NGMIStep.NO_OVERWRITE && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <div style={{ fontWeight: 'bold', width: 60 }}>Spend</div>
                    {`${(blockstar.wage.salary * 120).toLocaleString()} $ROL`}
                    <Tooltip placement='right' title={tooltipRol}>
                      {infoIcon}
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <div style={{ fontWeight: 'bold', width: 60 }}>XP</div>
                    {`+ ${totalExp} `}
                    <Tooltip placement='right' title={tooltipXp}>
                      {infoIcon}
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
            <div>
              {step > NGMIStep.SELECT && (
                <Button
                  variant='outlined'
                  onClick={() => {
                    setStep((curr) => {
                      if (curr === NGMIStep.OVERWRITE) {
                        setOverwrite(undefined);
                        return NGMIStep.SELECT;
                      }
                      if (
                        curr === NGMIStep.SUMMARY &&
                        overwrite === undefined
                      ) {
                        return NGMIStep.SELECT;
                      }
                      return curr - 1;
                    });
                  }}
                  style={{
                    width: 145,
                    height: 60,
                    borderRadius: 38,
                    marginRight: 8,
                  }}>
                  Back
                </Button>
              )}
              <LoadingButton
                loading={overwriteLoading || estimateLoading}
                disabled={buttonDisabled(step)}
                disableElevation
                variant='contained'
                onClick={onButtonClick}
                style={{
                  width: 145,
                  height: 60,
                  borderRadius: 38,
                }}>
                {buttonText}
              </LoadingButton>
            </div>
          </BottomContainer>
        )}
      </MainContainer>
    </Dialog>
  );
};

export default NGMI;
