import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TableBody, TableContainer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ThemeType } from '@shared-data';
import React, { ComponentType, FC, useContext } from 'react';

import TableContext, { ColumnState } from './table-context';

import { ListRowBlockstar, TableCellHeader, Wrapper } from './style';
import TableHeader from './table-header';
import TableListToggle from './table-list-toggle';

interface TableProps<ComponentProps> {
  Header: ComponentType<FC>;
  Body: FC<ComponentProps>;
  componentProps: ComponentProps;
  label: string; // First column label (aka main header)
}

const ListTable = <ComponentProps extends object>({
  Body,
  Header,
  componentProps,
  label,
}: TableProps<ComponentProps>) => {
  const theme = useTheme() as ThemeType;

  // Table ctx - table.tsx
  const {
    listToggle,
    rowsChecked,
    setRowsChecked,
    selectedColumn,
    setSelectedColumn,
  } = useContext(TableContext);

  const checkAll = () => {
    if (setRowsChecked && rowsChecked) {
      let allChecked = rowsChecked.reduce((prev, curr) => prev && curr, true);
      setRowsChecked(rowsChecked.map(() => !allChecked));
    }
  };

  return (
    <div style={{ width: '100%', minWidth: '900px' }}>
      <TableContainer
        style={{
          width: '100%',
          overflow: 'hidden',
          minWidth: '900px',
        }}>
        <Wrapper>
          {/* Structural Rendering Component */}
          <TableHeader Header={Header}>
            {/* Everything in here renderes as {children} in TableHeader */}
            {listToggle && (
              <TableCellHeader padding={'none'} width={'42px'}>
                <TableListToggle toggleheaderChecked={checkAll} />
              </TableCellHeader>
            )}

            <TableCellHeader
              padding='none'
              width={'220px'}
              style={{
                cursor: 'pointer',
                fontWeight: selectedColumn.id === 'default' ? 'bolder' : '',
              }}
              onClick={() =>
                setSelectedColumn({
                  id: 'default',
                  state:
                    selectedColumn.state === ColumnState.ascending
                      ? ColumnState.descending
                      : ColumnState.ascending,
                })
              }>
              <ListRowBlockstar
                style={{
                  flexDirection: 'unset',
                }}>
                {selectedColumn.state === ColumnState.ascending &&
                selectedColumn.id === 'default' ? (
                  <ArrowDropUpIcon
                    style={{
                      color:
                        selectedColumn.id !== 'default'
                          ? theme.colors.text.primaryGray
                          : '',
                    }}
                  />
                ) : (
                  <ArrowDropDownIcon
                    style={{
                      color:
                        selectedColumn.id !== 'default'
                          ? theme.colors.text.primaryGray
                          : '',
                    }}
                  />
                )}
                {label}
              </ListRowBlockstar>
            </TableCellHeader>
          </TableHeader>
        </Wrapper>

        <div
          style={{
            height: '80vh',
            overflowY: 'auto',
            width: '100%',
          }}>
          <Wrapper>
            <TableBody>
              <Body {...componentProps} />
            </TableBody>
          </Wrapper>
        </div>
      </TableContainer>
    </div>
  );
};

export default ListTable;
