import { LoadingButton } from '@mui/lab';
import { Dialog } from '@mui/material';
import { BlockstarSkillType, LevelUpInfo, SkillType } from '@shared-data';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Loading } from 'src/components/loading';
import { useBandAction } from 'src/hooks/band-action';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import analytics from 'src/utils/analytics';
import { getBandActionResults } from 'src/utils/utils';
import Results, { ResultsPage } from '.';
import { SkillEntryType } from '../types';
import { NewSkill } from './left/new-skill';
import { StarIncrease } from './left/star-increase';
import { XpEntryProps } from './left/xp-entry';
import { XpTable } from './left/xp-table';
import { BandRender } from './right/band-render';
import { BlockstarRender } from './right/blockstar-render';
import { ResultsLvlup, ResultsXp } from './style';

export const BandPracticeResults = () => {
  const {
    band,
    openActionResultDialog,
    setOpenActionResultDialog,
    actionResult,
    setActionResult,
    actionResultError,
    setActionResultError,
  } = useBandActionControlContext() as IBandActionControl;
  const { bandAction, mutateBandAction } = useBandAction(band?.id);
  const [retryLoading, setRetryLoading] = useState(false);

  const logStats = () => {
    let xpScreen = 'xp earned';
    const allLevelUps: LevelUpInfo[] = [];
    let starUp = false;
    actionResult!.blockstarActionResponses.forEach((r) => {
      if (r.levelUpInfo) {
        allLevelUps.push(...r.levelUpInfo);
      }
      if (r.starUpInfo) {
        starUp = true;
      }
    });
    if (allLevelUps.length > 0) {
      xpScreen = 'ability increased';
    }
    allLevelUps.forEach((item) => {
      if (item.maxed) {
        xpScreen = 'ability maxed';
      }
    });
    if (starUp) {
      xpScreen = 'stars increased';
    }
    analytics.logEvent('ViewScreenActionComplete', {
      action: 'practice',
      groupType: 'band',
      xpScreen,
    });
  };

  useEffect(() => {
    if (openActionResultDialog) {
      if (!bandAction) {
        mutateBandAction();
        return;
      }
      if (actionResult) {
        logStats();
        mutateBandAction(undefined);
      }
    }
  }, [openActionResultDialog, actionResult]);

  const onClose = () => {
    setOpenActionResultDialog(false);
    setActionResult(undefined);
    setActionResultError(undefined);
    mutateBandAction(undefined);
  };

  const pages: ResultsPage[] = [];

  if (actionResult) {
    // xp page
    let icon = <ResultsXp />;
    let text = 'XP Earned!';
    const headerText = 'PRACTICE COMPLETE';
    const xpEntryProps: XpEntryProps[] = [];
    const practiceXpEntryProps: XpEntryProps[] = [];
    const allLevelUps: LevelUpInfo[] = [];
    actionResult.blockstarActionResponses.forEach((r) => {
      if (r.levelUpInfo) {
        allLevelUps.push(...r.levelUpInfo);
      }
      r.skills?.map((s) => {
        if (s.gainedExp) {
          const bSkill = r.blockstar!.skills.find((bs) => bs.name === s.name)!;
          let type = SkillEntryType.MUSICAL;
          if (bSkill.type !== SkillType.Instrumental) {
            const t = s.name.split(' ')[0].toUpperCase();
            type = SkillEntryType[t as keyof typeof SkillEntryType];
          }
          const levelUpInfo =
            r.levelUpInfo &&
            r.levelUpInfo.find((lvlup) => lvlup.skill === s.name);

          if (levelUpInfo) {
            icon = <ResultsLvlup />;
            if (!text.includes('maxed')) {
              text = 'Your ability increased!';
            }
            if (levelUpInfo.maxed) {
              text = 'Your ability maxed out!';
            }
          }

          const ability =
            levelUpInfo?.currentAbility ?? bSkill.currentAbilities;

          const xpEntryProp = {
            name: s.name,
            type,
            ability,
            targetExp: bSkill.targetExp ?? 1,
            finalExp: levelUpInfo?.currentExp ?? s.finalExp ?? 0,
            gainedExp: s.gainedExp ?? 0,
            previousExp: s.previousExp ?? 0,
            levelUpInfo,
            blockstar: r.blockstar,
          };
          if (s.name === BlockstarSkillType.Practicing) {
            practiceXpEntryProps.push(xpEntryProp);
          } else {
            xpEntryProps.push(xpEntryProp);
          }
        }
      });
    });

    pages.push({
      left: (
        <XpTable
          key='skill-table'
          icon={icon}
          mainText={[text]}
          headerText={headerText}
          xpEntryProps={xpEntryProps}
          aggregateLevelups
          allLevelUps={allLevelUps}
        />
      ),
      right: <BandRender band={band!} />,
    });

    if (practiceXpEntryProps.length > 0) {
      pages.push({
        left: (
          <XpTable
            key='practice-table'
            icon={icon}
            mainText={[text]}
            headerText={headerText}
            xpEntryProps={practiceXpEntryProps}
            aggregateLevelups
            allLevelUps={allLevelUps}
          />
        ),
        right: <BandRender band={band!} />,
      });
    }

    // band star up page
    if (actionResult.bandActionResponse) {
      const previousStars =
        actionResult.bandActionResponse.originalStarInfo.currentStarRating;
      const currentStars =
        actionResult.bandActionResponse.updatedStarInfo.currentStarRating;
      const potentialStars =
        actionResult.bandActionResponse.updatedStarInfo.potentialStarRating;
      pages.push({
        left: (
          <StarIncrease
            key='band-stars-left'
            previousStars={previousStars}
            currentStars={currentStars}
            potentialStars={potentialStars}
            band={band}
          />
        ),
        right: <BandRender band={band!} confetti />,
      });
    }

    // blockstars star up pages
    actionResult.blockstarActionResponses.forEach((r) => {
      if (r.starUpInfo) {
        const { previousStars, currentStars } = r.starUpInfo;
        const potentialStars = r.blockstar?.starRatingInfo.maxStarRating ?? 0;
        pages.push({
          left: (
            <StarIncrease
              key={`${r.blockstar!.number}-stars-left`}
              previousStars={previousStars}
              currentStars={currentStars}
              potentialStars={potentialStars}
              blockstar={r.blockstar}
            />
          ),
          right: (
            <BlockstarRender
              key={`${r.blockstar!.number}-stars-right`}
              blockstarId={r.blockstar!.number}
              confetti
            />
          ),
        });
      }
    });

    // blockstar new skill pages
    actionResult.blockstarActionResponses.forEach((r) => {
      const newSkill = r.blockstar?.skills?.find(
        (s) => s.type === SkillType.Instrumental && s.new,
      );
      if (newSkill) {
        pages.push({
          left: (
            <NewSkill
              key={`${r.blockstar!.number}-newskill-left`}
              blockstar={r.blockstar!}
              skill={newSkill}
            />
          ),
          right: (
            <BlockstarRender
              key={`${r.blockstar!.number}-newskill-right`}
              blockstarId={r.blockstar!.number}
              confetti
            />
          ),
        });
      }
    });
  }

  const onRetry = async () => {
    setRetryLoading(true);
    try {
      const result = await getBandActionResults(band?.id, bandAction);
      setActionResult(result);
      setActionResultError(undefined);
      setRetryLoading(false);
    } catch {
      setActionResultError('Error getting band action result');
      setRetryLoading(false);
    }
  };

  return (
    <Dialog
      open={openActionResultDialog}
      onClose={onClose}
      maxWidth={false}
      fullScreen
      PaperProps={{ sx: { background: 'rgba(0, 0, 0, 0.8);' } }}>
      {actionResult ? (
        <Results pages={pages} onClose={onClose} />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Loading height='300px' iconSize='150px' />
          {actionResultError && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{actionResultError}</span>
              <LoadingButton
                variant='contained'
                loading={retryLoading}
                onClick={onRetry}>
                Retry
              </LoadingButton>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};
