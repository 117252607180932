import { useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect } from 'react';
import { truncateString } from 'src/utils/utils';
import analytics from '../../utils/analytics';
import { WalletButton } from './style';

const HeaderWalletButton = () => {
  const { publicKey, disconnecting, connected } = useWallet();
  useEffect(() => {
    if (disconnecting) {
      analytics.logEvent('DisconnectWallet');
    }

    const handlePublicKeyCopyEvent = (e: any) => {
      analytics.logEvent('CopyWalletAddress');
    };

    navigator.clipboard?.addEventListener('copy', handlePublicKeyCopyEvent);
    navigator.clipboard?.addEventListener('write', handlePublicKeyCopyEvent);
    navigator.clipboard?.addEventListener(
      'writeText',
      handlePublicKeyCopyEvent,
    );
    navigator.clipboard?.addEventListener(
      'writetext',
      handlePublicKeyCopyEvent,
    );
    const copyButtons = document.getElementsByClassName(
      'wallet-adapter-dropdown-list-item',
    );
    Array.from(copyButtons).forEach((element) => {
      element.removeEventListener('click', handlePublicKeyCopyEvent);
      if (element.textContent?.toLowerCase().includes('copy')) {
        element.addEventListener('click', handlePublicKeyCopyEvent);
      }
    });
  }, [publicKey, disconnecting]);

  return (
    <div>
      <WalletButton>{`(${truncateString(
        publicKey?.toString() ?? '',
      )})`}</WalletButton>
    </div>
  );
};

export default HeaderWalletButton;
