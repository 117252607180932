import {
  ConfirmedSignatureInfo,
  Connection,
  ParsedTransactionWithMeta,
  PublicKey,
} from '@solana/web3.js';
import useSWR from 'swr';
import {
  findAssociatedTokenAddress,
  getParsedTransactions,
  getSignaturesForAddress,
} from '../../actions/wallet';
import { getDateTimeString, limiter } from '../../utils/utils';

export const useParsedTransactions = (
  connection: Connection,
  publicKey?: PublicKey | null,
) => {
  const { data, error, mutate } = useSWR(
    publicKey ? ['signatures', publicKey.toString()] : null,
    async () => {
      const tokenAddress = await findAssociatedTokenAddress(
        publicKey!,
        new PublicKey(process.env.ROL_MINT_ADDRESS!),
      );
      const confirmedSignatures = await getSignaturesForAddress(
        connection,
        tokenAddress,
      );
      return getRolInfo(connection, confirmedSignatures);
    },
  );
  return { parsedTransactions: data, mutateParsedTransactions: mutate };
};

const getRolInfo = async (
  connection: Connection,
  confirmedSignatures: ConfirmedSignatureInfo[] | null,
) => {
  if (confirmedSignatures) {
    const signaturesDict: { [id: string]: string[] } = {};
    confirmedSignatures?.forEach((element) => {
      if (element.blockTime) {
        const blockTime = new Date(element.blockTime * 1000);
        blockTime.setHours(0, 0, 0, 0);
        const blockDate = getDateTimeString(blockTime);
        const weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);
        weekAgo.setHours(0, 0, 0, 0);
        if (weekAgo < blockTime) {
          if (signaturesDict[blockDate]) {
            signaturesDict[blockDate].push(element.signature);
          } else {
            signaturesDict[blockDate] = [element.signature];
          }
        }
      }
    });

    const sortedSignatureDates = Object.keys(signaturesDict).sort();
    sortedSignatureDates.reverse();

    const promises: Promise<(ParsedTransactionWithMeta | null)[] | null>[] = [];
    sortedSignatureDates.forEach(async (sortedSignatureDate) => {
      const signatures = signaturesDict[sortedSignatureDate];
      promises.push(
        limiter.schedule(() => getParsedTransactions(connection, signatures)),
      );
    });
    return Promise.all(promises);
  }
};
