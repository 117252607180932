import { UnityMessageSingleCharacter } from '@shared-data';
import React, { useEffect, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { IUnityProvider } from 'react-unity-webgl/distribution/interfaces/unity-provider';
import analytics from 'src/utils/analytics';
import {
  registerUnitySendMessage,
  setUnityElement,
  UNITY_CLASS_NAME,
} from 'src/utils/unity-helper';

interface UnityEventProperties {
  data: UnityEventData[];
}
interface UnityEventData {
  name: string;
  value: string;
}

const unityBaseUrl = 'https://assets.blockstars.gg/unity-build';
const unityDefaultGameViewSize = {
  width: 600,
  height: 450,
};

export const UnityReact = (props: {
  style?: React.CSSProperties | undefined;
  unityMessage?: UnityMessageSingleCharacter;
}) => {
  const [provider, setProvider] = useState<IUnityProvider>();
  const unityTargetEnv = process.env.TARGET_ENV ?? 'preview';
  const {
    unityProvider,
    sendMessage,
    isLoaded,
    addEventListener,
    removeEventListener,
    unload,
  } = useUnityContext({
    loaderUrl: `${unityBaseUrl}/${unityTargetEnv}/new_skeleton/build.loader.js`,
    dataUrl: `${unityBaseUrl}/${unityTargetEnv}/new_skeleton/build.data.br`,
    frameworkUrl: `${unityBaseUrl}/${unityTargetEnv}/new_skeleton/build.framework.js.br`,
    codeUrl: `${unityBaseUrl}/${unityTargetEnv}/new_skeleton/build.wasm.br`,
  });

  const unityLoaded = () => {
    setUnityElement();
  };

  const logAnalyticsEvent = (name: string, properties: string) => {
    const unityEventProperties = JSON.parse(properties) as UnityEventProperties;

    const propertiesToSend: { [id: string]: string } = {};
    for (const property of unityEventProperties.data) {
      propertiesToSend[property.name] = property.value;
    }

    analytics.logEvent(name, propertiesToSend);
  };

  useEffect(() => {
    if (isLoaded) {
      addEventListener('UnityLoaded', unityLoaded);
      addEventListener('LogAnalyticsEvent', logAnalyticsEvent);
    }

    registerUnitySendMessage(sendMessage);
    const unloadUnity = async () => {
      await unload();
    };

    return () => {
      if (isLoaded) {
        removeEventListener('UnityLoaded', unityLoaded);
        removeEventListener('LogAnalyticsEvent', logAnalyticsEvent);
        unloadUnity().catch(console.error);
      }
    };
  }, [isLoaded]);

  return (
    <Unity
      unityProvider={unityProvider}
      style={{
        width: unityDefaultGameViewSize.width,
        height: unityDefaultGameViewSize.height,
        position: 'absolute',
        ...props.style,
      }}
      className={UNITY_CLASS_NAME}
    />
  );
};
