import { Button, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AnnouncementInfo, ThemeType } from '@shared-data';
import React, { useEffect, useState } from 'react';
import rest, { MiscRequests } from 'src/actions/network/rest';
import analytics from 'src/utils/analytics';
import { getLocalStorage, setLocalStorage, StorageType } from 'src/utils/utils';
import { AnnouncementCarousel, ContentsParent } from './style';

const AnnouncementDialog = () => {
  const title = '';
  const [open, setOpen] = useState(true);
  const [announcements, setAnnouncements] = useState<
    AnnouncementInfo[] | undefined
  >();
  const [announcementIndex, setAnnouncementIndex] = useState<number>(0);
  const [imageIndex, setImageIndex] = useState<number | undefined>(0);
  const [maxImageHeight, setMaxImageHeight] = useState(0);
  const theme = useTheme() as ThemeType;

  useEffect(() => {
    const getAnnouncement = async () => {
      const response = await rest.get<AnnouncementInfo[]>(
        MiscRequests.Announcements,
      );

      // check if there're any announcements seen already.
      const announcementToShow = response.filter(
        (item) =>
          !JSON.parse(
            getLocalStorage(StorageType.ANNOUNCEMENT_VIEW, item.id) ?? 'false',
          ),
      );
      setAnnouncements(announcementToShow);
      if (announcementToShow.length > 0) {
        analytics.logEvent('ViewScreenAnnouncement', {
          id: announcementToShow[0].id,
        });
      }
    };

    getAnnouncement();
    setOpen(true);
  }, []);

  if (!announcements || announcements.length <= 0) {
    return <div></div>;
  }

  const onClose = () => {
    // Set seen announcement here.
    setLocalStorage(
      StorageType.ANNOUNCEMENT_VIEW,
      announcements[announcementIndex].id,
      JSON.stringify(true),
    );

    // if all announcements are seen, close the modal.
    // if not, open next announcement.
    if (announcementIndex < announcements.length - 1) {
      setAnnouncementIndex(announcementIndex + 1);
      // record analytics data from 2nd announcements.
      analytics.logEvent('ViewScreenAnnouncement', {
        id: announcements[announcementIndex].id,
      });
      setImageIndex(0);
    } else {
      setOpen(false);
    }
  };

  const onButtonPressed = () => {
    if ((imageIndex ?? 0) >= images.length - 1) {
      onClose();
    } else {
      let nextIndex = (imageIndex ?? 0) + 1;
      if (nextIndex >= images.length) {
        nextIndex = 0;
      }
      setImageIndex(nextIndex);
    }
  };

  const onImgLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (event.currentTarget.offsetHeight > maxImageHeight) {
      setMaxImageHeight(event.currentTarget.offsetHeight);
    }
  };

  const images = announcements[announcementIndex].images.map((item, index) => (
    <div
      role={'image'}
      style={{
        backgroundImage: `url(${item})`,
        backgroundRepeat: 'no-repeat',
      }}
      key={index}>
      {/* load image here to get the image size, but set it hidden. */}
      <img src={item} onLoad={onImgLoad} style={{ visibility: 'hidden' }} />
    </div>
  ));

  return (
    <Dialog scroll={'body'} open={open} onClose={onClose} maxWidth={false}>
      <ContentsParent>
        <AnnouncementCarousel
          index={imageIndex}
          height={`${maxImageHeight}px`}
          changeOnFirstRender={true}
          autoPlay={false}
          swipe={true}
          // TODO: Ki, update the react-material-ui-carousel when they push the fix to main.
          // https://github.com/Learus/react-material-ui-carousel/pull/194
          // or replace with this. https://github.com/oliviertassinari/react-swipeable-views
          animation={images.length === 2 ? 'fade' : 'slide'}
          navButtonsAlwaysInvisible={true}
          indicators={images.length > 1}
          onChange={(
            now?: number | undefined,
            previous?: number | undefined,
          ) => {
            setImageIndex(now);
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: theme.colors.ui.primaryPurple,
            },
          }}
          indicatorContainerProps={{
            style: {
              marginTop: '20px',
            },
          }}>
          {images.map((view, i) => view)}
        </AnnouncementCarousel>
        <Button
          variant={'contained'}
          onClick={() => onButtonPressed()}
          style={{
            width: '80%',
            height: 60,
            borderRadius: 38,
            marginBottom: '24px',
          }}>
          {(imageIndex ?? 0) >= images.length - 1 ? 'Close' : 'Next'}
        </Button>
      </ContentsParent>
    </Dialog>
  );
};

export default AnnouncementDialog;
