import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Dialog, useTheme } from '@mui/material';
import {
  ActionStatus,
  ActionTargetType,
  BlockstarActionType,
  BlockstarSkillType,
  CharacterAnimationType,
  SkillType,
  ThemeType,
} from '@shared-data';
import { PerformanceSkillType } from '@shared-generated/generated-instruments';
import { useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { onStopAction } from 'src/actions/action';
import { SkillIcon } from 'src/components/library/skill-icon';
import { SkillEntryType } from 'src/components/library/types';
import { Loading } from 'src/components/loading';
import { useActionOptions } from 'src/hooks/action-options';
import { useBlockstarAction } from 'src/hooks/blockstar-action';
import {
  IBlockstarActionControl,
  useBlockstarActionControlContext,
} from 'src/hooks/blockstar-action-control';
import analytics from 'src/utils/analytics';
import {
  getBlockstarName,
  getSkillName,
  getTimeLeft,
  truncateName,
} from 'src/utils/utils';
import ActionProgression from '.';
import {
  Container,
  DataContainer,
  DataItem,
  DataText,
  InfoContainer,
} from './style';

const BlockstarActionProgress = () => {
  const { publicKey } = useWallet();
  const theme = useTheme() as ThemeType;

  const {
    actionType,
    blockstar,
    openActionProgressDialog,
    setOpenActionProgressDialog,
    setOpenActionResultDialog,
    setActionResult,
    setActionResultError,
  } = useBlockstarActionControlContext() as IBlockstarActionControl;
  const { action, mutate } = useBlockstarAction(blockstar?.number);
  const { locations, durations } = useActionOptions(actionType);

  const getResults = async () => {
    try {
      if (!blockstar) {
        throw new Error('Blockstar cannot be null.');
      }
      const result = await onStopAction(publicKey, blockstar);
      setActionResult(result);
      setActionResultError(undefined);
    } catch {
      setActionResult(undefined);
      setActionResultError('Error getting results');
    }
  };

  useEffect(() => {
    if (!openActionProgressDialog) {
      return;
    }

    mutate();
    if (openActionProgressDialog) {
      analytics.logEvent('ViewScreenActionStatus', {
        groupType: 'individual',
        action: actionType,
      });
    }
  }, [openActionProgressDialog]);

  if (
    !blockstar ||
    !action ||
    action?.actionStatus === ActionStatus.None ||
    !openActionProgressDialog
  ) {
    return (
      <Dialog maxWidth={false} open={openActionProgressDialog}>
        <Container>
          <Loading height='300px' iconSize='50px' />
        </Container>
      </Dialog>
    );
  }

  const title = `${truncateName(
    getBlockstarName(blockstar),
  )} is ${actionType.toLowerCase()}`;
  const location = locations.find((l) => l.id === action.location?.id);
  if (!location) {
    toast.error('Location error.');
    return <div />;
  }

  let skillName: BlockstarSkillType | PerformanceSkillType | string =
    action.actionType === BlockstarActionType.Practicing
      ? action.skills![0].name
      : blockstar.skills[0].name;
  const skillType =
    blockstar.skills.find((s) => s.name === skillName)?.type ===
    SkillType.Instrumental
      ? SkillEntryType.MUSICAL
      : SkillEntryType.PRACTICING; // just using practicing as the "NOT MUSICAL" option since that's what we care about.
  skillName = getSkillName(skillName, skillType);

  const iconStyle = { color: theme.colors.text.secondaryGray };

  const infoView = (
    <InfoContainer>
      <DataContainer>
        <DataItem>
          <SkillIcon
            skill={skillType}
            isMusical={skillType === SkillEntryType.MUSICAL}
            style={{
              ...iconStyle,
              fontSize: 'inherit',
              width: '16px',
              height: '16px',
            }}
          />
          <DataText>{skillName}</DataText>
        </DataItem>
        <DataItem>
          <LocationOnIcon fontSize='inherit' style={iconStyle} />
          <DataText>{location?.name}</DataText>
        </DataItem>
        <DataItem>
          <TimerOutlinedIcon fontSize='inherit' style={iconStyle} />
          <DataText>{`${action.durationMs! / 60000} min`}</DataText>
        </DataItem>
      </DataContainer>
    </InfoContainer>
  );

  const onStopActionRequest = () => {
    analytics.logEvent('ClickActionStopConfirm', {
      action: actionType,
      blockstarId: blockstar.number,
      skill: skillName,
      location: location?.name,
      duration: action.durationMs! / 60000,
      timeLapsed: Math.floor(getTimeLeft(action) / 60000),
    });
    openResult();
  };

  const openResult = () => {
    setOpenActionResultDialog(true);
    getResults();
  };

  const onClose = () => {
    setOpenActionProgressDialog(false);
  };

  const totalEarns = !action
    ? 0
    : actionType === BlockstarActionType.Practicing
    ? Math.floor(action.skills ? action.skills[0].gainedExp : 0)
    : action.rolEarned
    ? action.rolEarned
    : 0;
  const earningInfo = {
    actionTargetType: ActionTargetType.Blockstar,
    symbol: actionType === BlockstarActionType.Practicing ? 'XP' : '$ROL',
    amount: totalEarns,
    type: action?.selectedSkill,
  };

  return (
    <ActionProgression
      title={title}
      location={location}
      InfoView={infoView}
      earningInfo={earningInfo}
      actionType={actionType}
      progressInfo={{
        startMs: action.actionStartTimeMs!,
        durationMs: action.durationMs!,
      }}
      open={openActionProgressDialog}
      unityElementInfo={{
        unityLoadCharacterData: {
          id: blockstar?.number.toString(),
          animation: CharacterAnimationType.BASIC_IDLE,
          source: 'view-action',
        },
      }}
      onStopAction={onStopActionRequest}
      onClose={onClose}
      onTimeUp={openResult}
    />
  );
};

export default BlockstarActionProgress;
