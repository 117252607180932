import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Dialog, Paper, Tooltip, useTheme } from '@mui/material';
import {
  ActionTargetType,
  bandActionStartTime,
  BandExpGainableSkillType,
  BlockstarActionType,
  ThemeType,
} from '@shared-data';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { SkillIcon } from 'src/components/library/skill-icon';
import { CollageFrame, CollageImage } from 'src/components/library/style';
import { SkillEntryType } from 'src/components/library/types';
import { Loading } from 'src/components/loading';
import { useActionOptions } from 'src/hooks/action-options';
import { useBandAction } from 'src/hooks/band-action';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import { useBlockstarsByIds } from 'src/hooks/blockstars';
import analytics from 'src/utils/analytics';
import {
  genreData,
  getBandActionResults,
  getBandCollegeColumnRowCount,
  truncateName,
} from 'src/utils/utils';
import ActionProgression from '.';
import { TooltipContainer } from '../style';
import {
  Container,
  DataContainer,
  DataItem,
  DataText,
  InfoContainer,
} from './style';

const BandActionProgress = () => {
  const theme = useTheme() as ThemeType;

  const {
    actionType,
    band,
    openActionProgressDialog,
    setOpenActionProgressDialog,
    setOpenActionResultDialog,
    setActionResult,
    setActionResultError,
  } = useBandActionControlContext() as IBandActionControl;
  const { bandAction } = useBandAction(band?.id);
  const { locations, durations } = useActionOptions(actionType);
  const { blockstars } = useBlockstarsByIds(band?.memberIds);

  const getResults = async () => {
    try {
      const result = await getBandActionResults(band?.id, bandAction);
      setActionResult(result);
      setActionResultError(undefined);
    } catch {
      setActionResult(undefined);
      setActionResultError('Error getting results');
    }
  };

  useEffect(() => {
    if (!openActionProgressDialog) {
      return;
    }

    if (bandAction?.action) {
      analytics.logEvent('ViewScreenActionStatus', {
        groupType: 'band',
        action: actionType,
      });
    }
  }, [bandAction, openActionProgressDialog]);

  if (!band || !bandAction) {
    return (
      <Dialog maxWidth={false} open={openActionProgressDialog}>
        <Container>
          <Loading height='300px' iconSize='50px' />
        </Container>
      </Dialog>
    );
  }

  const title = `${truncateName(
    band.customName?.name ??
      genreData[band.genre].subgenres[band.subgenre.toString()].displayName,
  )} is ${
    BlockstarActionType.BandPracticing.toString().toLowerCase().split(' ')[1]
  }`;
  const location = locations.find((l) => l.id === bandAction.action.locationId);
  if (!location) {
    toast.error('Location error.');
    return <div />;
  }

  if (blockstars) {
    blockstars.sort((a, b) => {
      const memberAIndex = band.memberIds.findIndex(
        (memberId) => memberId === a.number,
      );
      const memberBIndex = band.memberIds.findIndex(
        (memberId) => memberId === b.number,
      );
      return memberAIndex - memberBIndex;
    });
  }

  const collageColumnRowCount = getBandCollegeColumnRowCount(
    blockstars ? blockstars.length : 0,
  );
  const characterImage = (
    <CollageFrame
      style={{ borderRadius: '15px' }}
      collageColumnRowCount={collageColumnRowCount}>
      {blockstars?.map((blockstar, index) => {
        return (
          <CollageImage
            key={index}
            src={`${process.env.ASSET_BASE_URL}/${blockstar.number}.png`}
          />
        );
      })}
    </CollageFrame>
  );

  let skillsName = bandAction.action.actionSkillType.toString();
  let skillType = SkillEntryType.MUSICAL;
  if (actionType === BlockstarActionType.BandPracticing) {
    switch (bandAction.action.actionSkillType) {
      case BandExpGainableSkillType.MUSICAL:
        skillsName = '';
        band?.musicalMembers.forEach((member, index) => {
          skillsName += (index === 0 ? '' : ', ') + member?.blockstarSkill.name;
        });
        break;
      case BandExpGainableSkillType.GIGGING:
        skillType = SkillEntryType.GIGGING;
        break;
      case BandExpGainableSkillType.RECORDING:
        skillType = SkillEntryType.RECORDING;
        break;
      default:
        break;
    }
  }

  const musicalTooltip = (
    <Paper>
      <TooltipContainer style={{ width: '100%' }}>
        {skillsName}
      </TooltipContainer>
    </Paper>
  );

  const iconStyle = { color: theme.colors.text.secondaryGray };
  const infoView = (
    <InfoContainer>
      <DataContainer>
        <Tooltip placement='bottom' title={musicalTooltip}>
          <DataItem>
            <SkillIcon
              skill={skillType}
              isMusical={skillType === SkillEntryType.MUSICAL}
              style={{
                ...iconStyle,
                fontSize: 'inherit',
                width: '16px',
                height: '16px',
              }}
            />
            <DataText>{skillsName}</DataText>
          </DataItem>
        </Tooltip>
        <DataItem>
          <LocationOnIcon fontSize='inherit' style={iconStyle} />
          <DataText>{location?.name}</DataText>
        </DataItem>
        <DataItem>
          <TimerOutlinedIcon fontSize='inherit' style={iconStyle} />
          <DataText>{`${bandAction.action.durationMs / 60000} min`}</DataText>
        </DataItem>
      </DataContainer>
    </InfoContainer>
  );

  const onStopAction = () => {
    const startTimeMs = bandActionStartTime(bandAction.action);
    analytics.logEvent('ClickActionStopConfirm', {
      action: actionType,
      bandId: band.id,
      skill: skillsName,
      location: location?.name,
      duration: bandAction.action.durationMs! / 60000,
      timeLapsed: Math.floor((Date.now() - startTimeMs) / 60000),
    });
    openResult();
  };

  const openResult = () => {
    setOpenActionResultDialog(true);
    getResults();
  };

  const onClose = () => {
    setOpenActionProgressDialog(false);
  };

  const earningXP = bandAction.blockstarActionResponses.reduce(
    (prev, curr) => prev + (curr.skills ? curr.skills[0].gainedExp : 0),
    0,
  );
  const earningInfo = {
    actionTargetType: ActionTargetType.Band,
    symbol: 'XP',
    amount: earningXP,
    type: bandAction.action.actionSkillType,
  };

  const startTimeMs = bandActionStartTime(bandAction.action);
  const newTimeLeft =
    Date.now() < startTimeMs + bandAction.action.durationMs
      ? startTimeMs + bandAction.action.durationMs - Date.now()
      : 0;
  const endDate = startTimeMs + bandAction.action.durationMs;

  return (
    <ActionProgression
      title={title}
      location={location}
      characterImage={characterImage}
      InfoView={infoView}
      earningInfo={earningInfo}
      actionType={actionType}
      progressInfo={{
        startMs: bandActionStartTime(bandAction.action),
        durationMs: bandAction.action.durationMs,
      }}
      open={openActionProgressDialog}
      onStopAction={onStopAction}
      onClose={onClose}
      onTimeUp={openResult}
    />
  );
};

export default BandActionProgress;
