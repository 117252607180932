import { styled } from '@mui/material';
import { ButtonDefault } from '../style';

export const SimpleDialogContainer = styled('div')`
  width: 528px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px;
  row-gap: 36px;
`;

export const TextContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
`;

export const SimpleDialogTitle = styled('div')`
  width: 100%;
  text-align: left;
  font-size: 32px;
  font-weight: bold;
`;
export const SimpleDialogMessage = styled('div')`
  width: 100%;
  text-align: left;
`;

export const ButtonContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
`;

export const ConfirmButton = styled(ButtonDefault)`
  width: 100%;
  height: 50px;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple};
  border: none;
  border-radius: 50px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.ui.primaryPurple};
    color: white;
  }
  :disabled {
    background-color: ${(props) => props.theme.colors.ui.cellGray};
  }
`;

export const CancelButton = styled(ButtonDefault)`
  width: 100%;
  height: 50px;
  background-color: white;
  color: ${(props) => props.theme.colors.text.primaryGray};
  /* border-radius: 50%; */
  font-weight: bold;
  font-size: 15px;
  :hover {
    cursor: pointer;
    background-color: white;
    color: ${(props) => props.theme.colors.text.primaryGray};
  }
`;
