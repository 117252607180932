import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Paper, Tooltip, useTheme } from '@mui/material';
import {
  ActionTargetType,
  NameChangeInfo,
  NameChangeNotAvailableReason,
  ThemeType,
} from '@shared-data';
import React from 'react';
import { TooltipMessage } from '../details/style';
import { EditIconButton, TooltipContainer } from './style';

export const NameEditButton = (props: {
  type: ActionTargetType;
  canEditNameInfo: NameChangeInfo;
  onNameChangeClicked?: () => void;
}) => {
  const theme = useTheme() as ThemeType;

  const aType =
    props.type === ActionTargetType.Blockstar
      ? props.type
      : props.type.toLocaleLowerCase();
  const nameChangeTooltipComponent = () => {
    let message = <div></div>;
    switch (props.canEditNameInfo.reason) {
      case NameChangeNotAvailableReason.InCooldown:
        message = (
          <TooltipContainer>
            <TooltipMessage>
              You can only change the name once every 24 hours.
            </TooltipMessage>
          </TooltipContainer>
        );
        break;
      case NameChangeNotAvailableReason.NotMine:
        message = (
          <TooltipContainer>
            <TooltipMessage>
              {`You can only change the name of the ${aType} ${
                props.type === ActionTargetType.Blockstar
                  ? 'that you own'
                  : 'that you manage'
              }.`}
            </TooltipMessage>
          </TooltipContainer>
        );
        break;
      case NameChangeNotAvailableReason.Fired:
        message = (
          <TooltipContainer>
            <TooltipMessage>
              {`This ${aType} has been ${
                props.type === ActionTargetType.Blockstar
                  ? 'fired'
                  : 'disbanded'
              }.`}
            </TooltipMessage>
          </TooltipContainer>
        );
        break;
      default:
        break;
    }

    return <Paper>{message}</Paper>;
  };

  const editIcon = (
    <EditRoundedIcon
      style={{
        height: '20px',
        width: '20px',
        color: props.canEditNameInfo.canEdit
          ? theme.colors.text.primaryGray
          : theme.colors.text.secondaryGray,
      }}
    />
  );
  if (props.canEditNameInfo.canEdit) {
    return (
      <EditIconButton onClick={props.onNameChangeClicked}>
        {editIcon}
      </EditIconButton>
    );
  }

  return (
    <Tooltip placement='right' title={nameChangeTooltipComponent()}>
      <EditIconButton style={{ cursor: 'default' }}>{editIcon}</EditIconButton>
    </Tooltip>
  );
};
