import { Band, BandPreviewResponse } from '@shared-data';
import { useWallet } from '@solana/wallet-adapter-react';
import { toast } from 'react-toastify';
import rest, { BandRequestPath } from 'src/actions/network/rest';
import useSWR from 'swr';

export const useBand = (id?: string) => {
  const { data, error, mutate } = useSWR<{
    band: Band;
    preview: BandPreviewResponse;
  }>(
    `${process.env.WORKER_URL}/${BandRequestPath.GetBand}/${id}`,
    (url: string) => {
      const result = rest.get(url).then((res: any) => {
        if (res?.message) {
          toast.info(res.message);
        }
        return res;
      });
      return result;
    },
  );
  return { bandData: data, mutateBand: mutate };
};

export const useBands = () => {
  const { publicKey } = useWallet();

  const { data, error, mutate } = useSWR<Band[]>(
    `${process.env.WORKER_URL}/${
      BandRequestPath.GetAllBands
    }/${publicKey?.toBase58()}`,
    async (url: string) => {
      const result = await rest.get(url).then((res: any) => {
        if (res?.message) {
          toast.info(res.message);
        }

        return res;
      });

      return result;
    },
  );

  return { bands: data, mutateBands: mutate };
};
