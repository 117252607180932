import {
  ActionTargetType,
  Band,
  BandMemberGeneral,
  BandMemberMusical,
  BandMemberSkill,
  BandPreviewRequest,
  BandSaveRequest,
  BlockstarSkillType,
  GenreData,
  GenreInfo,
  NameChangeInfo,
  NameChangeNotAvailableReason,
  PrepType,
  rolToPayForSignature,
  RoutePath,
  SubgenreInfo,
} from '@shared-data';
import { Genres, Subgenre } from '@shared-generated/generated-genres';
import { PerformanceSkillType } from '@shared-generated/generated-instruments';
import genreDataJson from '@shared-json/genres.json';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import rest, { BandRequestPath } from 'src/actions/network/rest';
import Header from 'src/components/header';
import ListTable from 'src/components/library/list-table';
import BlockstarsTableHeader from 'src/components/library/list-table/blockstars/blockstars-table-header';
import BlockstarsTableRows from 'src/components/library/list-table/blockstars/blockstars-table-rows';
import TableContext, {
  ColumnState,
  SelectedColumn,
} from 'src/components/library/list-table/table-context';
import { BandPracticeResults } from 'src/components/library/results/band-practice';
import { Loading } from 'src/components/loading';
import NameChangeDialog from 'src/components/name-change';
import { useBandAction } from 'src/hooks/band-action';
import { BandActionControlProvider } from 'src/hooks/band-action-control';
import { useBands } from 'src/hooks/bands';
import { BlockstarActionControlProvider } from 'src/hooks/blockstar-action-control';
import { useBlockstars } from 'src/hooks/blockstars';
import { useCallbackPrompt } from 'src/hooks/use-custom-prompt';
import { useNavigateState } from 'src/hooks/use-navigate-state';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import { useWindowEvent } from 'src/hooks/window-event';
import { useWindowSize } from 'src/hooks/window-size';
import analytics from 'src/utils/analytics';
import { bandMembers, bandSalary, canBandChangeName } from 'src/utils/utils';
import { bandTxnPrep } from '../others/helper';
import StopActionDialog from '../others/stop-action-dialog';
import BandEditorMiddle from './middle';
import {
  BandEditorBodyContainer,
  BandEditorBodyWrapper,
  Container,
} from './style';
import BandEditorTop from './top';

interface EditStateInfo {
  originPage: string;
  isEdit?: boolean;
  bandId?: string;
  genreId: Genres;
  subGenreId: Subgenre;
  members?: number[];
  genreInfo?: GenreInfo;
  subGenreInfo?: SubgenreInfo;
  musicalSlots?: (BandSlotSkill | undefined)[];
  otherSlots?: (BandSlotSkill | undefined)[];
  previewData?: BandPreviewData;
  starRatingInfo?: StarRatingInfo;
  name?: string;
}

export interface BandPreviewData {
  [key: string]: BandPreviewInfo[];
}

export interface BandPreviewInfo {
  blockstarId: number;
  skillName: string;
  ability: number;
}

export interface BandSlotSkill {
  blockstarId: number;
  skill: BandMemberSkill;
}

export interface StarRatingInfo {
  currentStarRating: number;
  potentialStarRating: number;
  completed: boolean;
}

const allGenreData = JSON.parse(JSON.stringify(genreDataJson)) as GenreData;
const otherSkillsLength = Object.keys(BlockstarSkillType).length - 1;

const BandEditor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as EditStateInfo;
  const [bandId] = useState(state.bandId);
  const [originPage] = useState(state.originPage);
  const [isEdit] = useState(state.isEdit);

  const { blockstars, mutateBlockstars } = useBlockstars();
  const { bands, mutateBands } = useBands();
  const { bandAction } = useBandAction(bandId);
  const windowSize = useWindowSize();
  const { publicKey, signTransaction, sendTransaction } = useWallet();
  const { connection } = useConnection();
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { confirmNavigation, cancelNavigation } = useCallbackPrompt(showDialog);

  const [band, setBand] = useState<Band | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [nameChangeOpen, setNameChangeOpen] = useState(false);
  const [stopActionDialogOpen, setStopActionDialogOpen] = useState(false);

  const [canEditNameInfo, setCanEditNameInfo] = useState<NameChangeInfo>({
    canEdit: true,
    reason: NameChangeNotAvailableReason.None,
  });
  const [name, setName] = useNavigateState<string | undefined>(
    'name',
    state.name,
  );
  const [genreId, setGenreId] = useNavigateState<Genres>(
    'genreId',
    state.genreId,
  );
  const [subGenreId, setSubGenreId] = useNavigateState<Subgenre>(
    'subGenreId',
    state.subGenreId,
  );
  const [genreInfo, setGenreInfo] = useNavigateState<GenreInfo>(
    'genreInfo',
    state.genreInfo,
  );
  const [subGenreInfo, setSubGenreInfo] = useNavigateState<SubgenreInfo>(
    'subGenreInfo',
    state.subGenreInfo,
  );
  const [members, setMembers] = useNavigateState<number[]>(
    'members',
    state.members ?? [],
  );
  const [musicalSlots, setMusicalSlots] = useNavigateState<
    (BandSlotSkill | undefined)[]
  >('musicalSlots', state.musicalSlots ?? []);
  const [otherSlots, setOtherSlots] = useNavigateState<
    (BandSlotSkill | undefined)[]
  >('otherSlots', state.otherSlots ?? []);
  const [previewData, setPreviewData] = useNavigateState<BandPreviewData>(
    'previewData',
    state.previewData,
  );
  const [starRatingInfo, setStarRatingInfo] = useNavigateState<StarRatingInfo>(
    'starRatingInfo',
    state.starRatingInfo ?? {
      currentStarRating: 0,
      potentialStarRating: 5,
      completed: false,
    },
  );

  // Sort Controls
  const [selectedColumn, setSelectedColumn] = useState<SelectedColumn>({
    id: 'default',
    state: ColumnState.ascending,
  });

  useEffect(() => {
    analytics.logEvent('ViewScreenBandEditor', {
      genre: genreId,
      subgenre: subGenreId,
    });
  }, []);

  useEffect(() => {
    if (bands && bandId) {
      const band = bands.find((band) => band.id === bandId);
      if (band) {
        const nameEditInfo = canBandChangeName(band, publicKey, band.walletId);
        setCanEditNameInfo(nameEditInfo);
      }
      setBand(band);
    }
  }, [publicKey, bands]);

  const showBeforeUnloadAlert = (
    event: any,
    reason: 'cancel' | 'refresh' | 'back' | 'other app page' | 'unknown',
  ) => {
    if (!dirty) {
      return event.returnValue;
    }
    event.preventDefault();
    event.returnValue =
      'Are you sure you want to leave this page?\nAny unsaved changes will be lost.';

    analytics.logEvent('BandEditorExit', {
      genre: genreId,
      subgenre: subGenreId,
      method: reason,
    });

    return event.returnValue;
  };

  const showPopStateAlert = (
    reason: 'cancel' | 'refresh' | 'back' | 'other app page' | 'unknown',
    cancelCallback?: () => void,
    confirmCallback?: () => void,
  ) => {
    if (!dirty) {
      if (cancelCallback) {
        cancelCallback();
        return;
      }

      onExit();
      return;
    }

    if (
      confirm(
        'Are you sure you want to leave this page?\nAny unsaved changes will be lost.',
      ) == true
    ) {
      analytics.logEvent('BandEditorExit', {
        genre: genreId,
        subgenre: subGenreId,
        method: reason,
      });

      if (confirmCallback) {
        confirmCallback();
        return;
      }

      onExit();
    } else {
      if (cancelCallback) {
        cancelCallback();
        return;
      }

      const state: EditStateInfo = {
        originPage,
        genreId,
        subGenreId,
        members,
        genreInfo,
        subGenreInfo,
        musicalSlots,
        otherSlots,
        previewData,
        starRatingInfo,
      };
      navigate(RoutePath.BandsEdit, {
        state,
      });
    }
  };

  const checkToShowPopStateAlert = (
    reason: 'cancel' | 'refresh' | 'back' | 'other app page' | 'unknown',
  ) => {
    showPopStateAlert(reason, cancelNavigation, confirmNavigation);
  };
  useWindowEvent('popstate', () => {
    showPopStateAlert('back');
  });
  useWindowEvent('beforeunload', (event) =>
    showBeforeUnloadAlert(event, 'refresh'),
  );

  useEffect(() => {
    if (!blockstars) {
      return;
    }

    const getWeightedData = async (genre: Genres, subgenre: Subgenre) => {
      const body: BandPreviewRequest = {
        genre,
        subgenre,
        blockstarIds: blockstars.map((blockstar) => blockstar.number),
      };
      const response: BandPreviewData = await rest.post(
        `${process.env.WORKER_URL}/${BandRequestPath.Preview}`,
        body,
      );
      setPreviewData(response);
    };

    const editStateInfo = location.state as EditStateInfo;
    if (editStateInfo) {
      if (!editStateInfo.genreId || !editStateInfo.subGenreId) {
        console.error('Genre ID and SubGenre ID are required!!');
        return;
      }
    } else {
      console.error('Genre ID and SubGenre ID are required!!');
      return;
    }
    getWeightedData(editStateInfo.genreId, editStateInfo.subGenreId);
  }, [blockstars]);

  useEffect(() => {
    const editStateInfo = location.state as EditStateInfo;
    setGenreId(editStateInfo.genreId);
    setSubGenreId(editStateInfo.subGenreId);

    const genre = allGenreData[editStateInfo.genreId];

    if (!genre) {
      console.error(`Genre [${editStateInfo.genreId}] cannot be found.`);
      return;
    }
    const subGenre = genre.subgenres[editStateInfo.subGenreId.toString()];
    if (!subGenre) {
      console.error(`Genre [${editStateInfo.subGenreId}] cannot be found.`);
      return;
    }
    setGenreInfo(genre);
    setSubGenreInfo(subGenre);
    setMusicalSlots(
      musicalSlots ?? [...Array(subGenre.requirements.musicalSkills.length)],
    );
    setOtherSlots(otherSlots ?? [...Array(otherSkillsLength)]);
  }, [previewData]);

  useEffect(() => {
    if (!subGenreInfo) {
      return;
    }
    const getStarRating = async () => {
      const body = generateSlotInfo(
        members,
        musicalSlots,
        otherSlots,
        subGenreInfo,
      );

      const response: StarRatingInfo = await rest.post(
        `${process.env.WORKER_URL}/${BandRequestPath.Rating}`,
        body,
      );

      setStarRatingInfo({ ...response, completed: true });

      window.history.replaceState(
        {
          members,
          genreId,
          subGenreId,
          genreInfo,
          subGenreInfo,
          musicalSlots,
          otherSlots,
          previewData,
          starRatingInfo: response,
        },
        '',
      );
    };
    if (
      members &&
      subGenreInfo &&
      members.length >= subGenreInfo.requirements.minMembers
    ) {
      if (
        musicalSlots.length >= subGenreInfo.requirements.musicalSkills.length &&
        !musicalSlots.includes(undefined) &&
        otherSlots.length >= otherSkillsLength &&
        !otherSlots.includes(undefined)
      ) {
        getStarRating();
      }
    }
  }, [members, musicalSlots, otherSlots]);

  if (
    !blockstars ||
    blockstars.length <= 0 ||
    !genreId ||
    !subGenreId ||
    !genreInfo ||
    !subGenreInfo ||
    !previewData
  ) {
    return <Loading height='300px' iconSize='50px' />;
  }

  const onMemberAdded = (
    memberId: number,
    slotIndex?: number,
    skillInfo?: BandMemberSkill,
    isMusical?: boolean,
  ) => {
    let slotsToModify = isMusical ? [...musicalSlots] : [...otherSlots];
    let updatedMembers = [...members];
    if (slotIndex !== undefined && skillInfo !== undefined) {
      const originalSlotInfo = slotsToModify[slotIndex];
      if (originalSlotInfo?.blockstarId) {
        const slotsNotModified = isMusical
          ? [...otherSlots]
          : [...musicalSlots];
        const removeResult = removeMemberInSlot(
          slotsToModify,
          slotsNotModified,
          slotIndex,
        );
        slotsToModify = removeResult.slotsModified;
        updatedMembers = removeResult.members;
      }
      slotsToModify[slotIndex] = { blockstarId: memberId, skill: skillInfo };
      if (isMusical) {
        setMusicalSlots(slotsToModify);
      } else {
        setOtherSlots(slotsToModify);
      }
    }

    if (
      !updatedMembers.find((id) => id === memberId) &&
      updatedMembers.length < subGenreInfo.requirements.maxMembers
    ) {
      setMembers([...updatedMembers, memberId]);
      window.history.replaceState(
        {
          members,
          genreId,
          subGenreId,
          genreInfo,
          subGenreInfo,
          musicalSlots,
          otherSlots,
          previewData,
          starRatingInfo: [...updatedMembers, memberId],
        },
        '',
      );
    }

    setDirty(true);
    setShowDialog(true);
  };

  const onMemberRemoved = (
    memberId: number,
    slotIndex?: number,
    isMusical?: boolean,
  ) => {
    const slotsToModify = isMusical ? [...musicalSlots] : [...otherSlots];
    const slotsNotModified = isMusical ? [...otherSlots] : [...musicalSlots];
    if (slotIndex !== undefined) {
      const removeResult = removeMemberInSlot(
        slotsToModify,
        slotsNotModified,
        slotIndex,
      );
      if (isMusical) {
        setMusicalSlots(removeResult.slotsModified);
      } else {
        setOtherSlots(removeResult.slotsModified);
      }
      setMembers(removeResult.members);
      window.history.replaceState(
        {
          members,
          genreId,
          subGenreId,
          genreInfo,
          subGenreInfo,
          musicalSlots,
          otherSlots,
          previewData,
          starRatingInfo: removeResult.members,
        },
        '',
      );
    } else {
      for (let i = 0; i < slotsToModify.length; i++) {
        const slot = slotsToModify[i];
        if (slot?.blockstarId === memberId) {
          slotsToModify[i] = undefined;
        }
      }
      for (let i = 0; i < slotsNotModified.length; i++) {
        const slot = slotsNotModified[i];
        if (slot?.blockstarId === memberId) {
          slotsNotModified[i] = undefined;
        }
      }
      if (isMusical) {
        setMusicalSlots(slotsToModify);
        setOtherSlots(slotsNotModified);
      } else {
        setMusicalSlots(slotsNotModified);
        setOtherSlots(slotsToModify);
      }
      setMembers(members.filter((id) => id !== memberId));
      window.history.replaceState(
        {
          members,
          genreId,
          subGenreId,
          genreInfo,
          subGenreInfo,
          musicalSlots,
          otherSlots,
          previewData,
          starRatingInfo: members.filter((id) => id !== memberId),
        },
        '',
      );
    }
    setStarRatingInfo({
      currentStarRating: 0,
      potentialStarRating: 5,
      completed: false,
    });
  };

  const removeMemberInSlot = (
    slotsToModify: (BandSlotSkill | undefined)[],
    slotsNotModified: (BandSlotSkill | undefined)[],
    slotIndex: number,
  ) => {
    const originalMemberInfo = slotsToModify[slotIndex];
    slotsToModify[slotIndex] = undefined;
    const allSlots = [...slotsToModify, ...slotsNotModified];
    // make sure if this blockstar is being used in other slots.
    const stillInUseMemberInfo = allSlots.filter(
      (slot) => slot?.blockstarId === originalMemberInfo?.blockstarId,
    );
    let membersModified = [...members];
    if (stillInUseMemberInfo.length <= 0) {
      membersModified = members.filter(
        (member) => member !== originalMemberInfo?.blockstarId,
      );
    }

    return {
      slotsModified: slotsToModify,
      members: membersModified,
    };
  };

  const nameChangeStatusChanged = () => {
    setNameChangeOpen(!nameChangeOpen);
  };

  const nameChanged = (newName: string) => {
    setDirty(true);
    setName(newName);
  };

  const onExit = () => {
    navigate(originPage, {
      state: { originPage: RoutePath.Bands },
    });
    confirmNavigation();
  };

  const onSubmit = async () => {
    if (bandAction) {
      setStopActionDialogOpen(true);
      return;
    }
    if (
      musicalSlots.includes(undefined) ||
      musicalSlots.length < subGenreInfo.requirements.musicalSkills.length ||
      otherSlots.includes(undefined) ||
      otherSlots.length < otherSkillsLength ||
      members.length < subGenreInfo.requirements.minMembers
    ) {
      toast.error('The band cannot be created.');
      return;
    }

    if (!publicKey || !signTransaction) {
      const notReady = 'Tried to start band action with no wallet ready...';
      console.error(notReady);
      toast.error(notReady);
      return;
    }

    setIsSaving(true);

    const prepType = isEdit ? PrepType.EDIT : PrepType.CREATE;
    const slotInfos = generateSlotInfo(
      members,
      musicalSlots,
      otherSlots,
      subGenreInfo,
    );
    if (!slotInfos) {
      return;
    }
    const prepBody = {
      prepType: prepType,
      saveRequest: {
        id: bandId,
        name,
        genre: genreId,
        subgenre: subGenreId,
        memberIds: members,
        musicalMembers: slotInfos.musicalMembers,
        generalMembers: slotInfos.generalMembers,
      },
    };

    try {
      const txnResult = await bandTxnPrep(
        BandRequestPath.PrepEdit,
        prepBody,
        connection,
        publicKey,
        rolBalance,
        rolToPayForSignature,
        signTransaction,
        prepType,
        bandId,
      );

      mutateBalance();
      const membersInSlots = generateSlotInfo(
        members,
        musicalSlots,
        otherSlots,
        subGenreInfo,
      );
      if (!membersInSlots) {
        return;
      }
      const body: BandSaveRequest = {
        signature: txnResult.clientSignature,
        blockhash: txnResult.signedTxn.recentBlockhash,
        genre: genreId,
        subgenre: subGenreId,
        memberIds: members,
        name,
        ...membersInSlots,
      };

      const response: any = await rest.post(
        `${process.env.WORKER_URL}/${
          isEdit
            ? `${BandRequestPath.UpdateBand}/${bandId}`
            : BandRequestPath.Create
        }`,
        body,
      );

      setIsSaving(false);
      if (response?.message) {
        // There're certain conditions that worker returns string as error message. I.E. band exists when trying to create a band with existing id.
        toast.info(response.message);
      } else {
        toast.success(
          `Transaction valid! ${rolToPayForSignature} $ROL deducted; Band saved.`,
        );
        const musicalsAnalytics: { [id: string]: any } = {};
        musicalSlots.forEach((slot, index) => {
          const key = `musical ${index + 1}`;
          musicalsAnalytics[key] = JSON.stringify({
            requiredSkill: subGenreInfo.requirements.musicalSkills[index].name,
            blockstarId: slot?.blockstarId,
            blockstarSkill: slot?.skill.name,
          });
        });
        const otherSlotsAnalytics: { [id: string]: any } = {};
        otherSlots.forEach((slot, index) => {
          if (slot) {
            const key = slot.skill.name;
            otherSlotsAnalytics[key] = JSON.stringify({
              blockstarId: slot?.blockstarId,
            });
          }
        });
        analytics.logEvent('BandEditorSave', {
          id: response.bandId,
          genre: genreId,
          subgenre: subGenreId,
          members: JSON.stringify(members),
          salary: bandSalary(bandMembers(members, blockstars)),
          currentStar: starRatingInfo.currentStarRating,
          maxStar: starRatingInfo.potentialStarRating,
          saveType: isEdit ? 'Update band' : 'New band',
          ...musicalsAnalytics,
          ...otherSlotsAnalytics,
        });

        navigate(`${RoutePath.Bands}/${response.bandId}`, {
          state: { originPage: RoutePath.Bands },
        });
        confirmNavigation();
      }
    } catch (e: any) {
      let message = e.message ?? 'Unable to save the band.';
      if (e.message === 'User rejected the request.') {
        message = 'Transaction not completed.';
      }
      toast.error(message);
      setIsSaving(false);
      return;
    }
  };

  const onStopActionConfirmClose = () => {
    setStopActionDialogOpen(false);
    toast.error(
      'Update was not completed due to action in progress. Please try again.',
    );
  };

  return (
    <Container>
      <BlockstarActionControlProvider>
        <BandActionControlProvider>
          <Header callback={() => checkToShowPopStateAlert('other app page')} />
          <BandEditorBodyContainer>
            <BandEditorBodyWrapper>
              <BandEditorTop
                blockstars={blockstars}
                genreInfo={genreInfo}
                subGenreInfo={subGenreInfo}
                subGenreId={subGenreId}
                memberIds={members}
                starRatingInfo={starRatingInfo}
                isEdit={isEdit}
                isSaving={isSaving}
                disableSubmit={
                  !dirty ||
                  musicalSlots.includes(undefined) ||
                  musicalSlots.length <
                    subGenreInfo.requirements.musicalSkills.length ||
                  otherSlots.includes(undefined) ||
                  otherSlots.length < otherSkillsLength ||
                  members.length < subGenreInfo.requirements.minMembers
                }
                canEditNameInfo={canEditNameInfo}
                onNameChangeClicked={nameChangeStatusChanged}
                newName={name}
                onMemberRemoved={onMemberRemoved}
                onCancel={() => showPopStateAlert('cancel')}
                onSubmit={onSubmit}
              />
              <BandEditorMiddle
                blockstars={blockstars}
                bands={bands}
                subGenreId={subGenreId}
                subGenreInfo={subGenreInfo}
                members={members}
                musicalSlots={musicalSlots}
                otherSlots={otherSlots}
                previewData={previewData}
                onMemberAdded={onMemberAdded}
                onMemberRemoved={onMemberRemoved}
              />
              <TableContext.Provider
                value={{
                  listToggle: false,
                  // Sort
                  selectedColumn,
                  setSelectedColumn,
                }}>
                <ListTable
                  label='BLOCKSTAR'
                  Header={BlockstarsTableHeader}
                  Body={BlockstarsTableRows}
                  componentProps={{
                    blockstars,
                    bands,
                    clickableBlockstar: false,
                    genre: genreId,
                    subGenre: subGenreId,
                    subGenreInfo,
                    memberIds: members,
                    onMemberAdded,
                    onMemberRemoved,
                  }}
                />
              </TableContext.Provider>
            </BandEditorBodyWrapper>
          </BandEditorBodyContainer>
          {/* Name change: band data is not important here, except memberIds */}
          <NameChangeDialog
            open={nameChangeOpen}
            target={{
              id: bandId ?? '',
              walletId: publicKey ? publicKey.toString() : '',
              genre: genreId,
              subgenre: subGenreId,
              musicalMembers: [],
              generalMembers: [],
              createdAt: +new Date(),
              memberIds: members,
            }}
            doNotUpdateToServer
            nameChangeType={ActionTargetType.Band}
            updateTarget={nameChanged}
            onClose={nameChangeStatusChanged}
          />
          {bandAction && band && (
            <StopActionDialog
              dialogOpen={stopActionDialogOpen}
              band={band}
              bandAction={bandAction}
              onClose={onStopActionConfirmClose}
            />
          )}
          <BandPracticeResults />
        </BandActionControlProvider>
      </BlockstarActionControlProvider>
    </Container>
  );
};

export default BandEditor;

const generateSlotInfo = (
  members: number[],
  musicalSlots: (BandSlotSkill | undefined)[],
  otherSlots: (BandSlotSkill | undefined)[],
  subGenreInfo: SubgenreInfo,
):
  | {
      musicalMembers: BandMemberMusical[];
      generalMembers: BandMemberGeneral[];
    }
  | undefined => {
  if (musicalSlots.includes(undefined)) {
    toast.error('Please reset the slots.');
    return undefined;
  }

  const musicalMembers: BandMemberMusical[] = musicalSlots.map(
    (slotSkill, index) => {
      return {
        skill: subGenreInfo.requirements.musicalSkills[index],
        blockstarId: slotSkill!.blockstarId,
        blockstarSkill: {
          name: slotSkill!.skill.name as PerformanceSkillType,
          familyName: slotSkill!.skill.familyName!,
        },
        position: index,
      };
    },
  );

  const generalMembers: BandMemberGeneral[] = otherSlots.map(
    (slotSkill, index) => {
      return {
        skill: slotSkill!.skill.name as BlockstarSkillType,
        blockstarId: slotSkill!.blockstarId,
      };
    },
  );

  return {
    musicalMembers,
    generalMembers,
  };
};
