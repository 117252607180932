import { useTheme } from '@mui/material';
import { BlockstarActionType, ThemeType } from '@shared-data';
import React from 'react';
import { Dropdown } from 'src/components/library/dropdown';
import { Skill } from 'src/components/library/skill-details/skill';
import { SkillEntryType } from 'src/components/library/types';
import { DropdownItem } from './style';

export interface IActionBandSkillsData {
  type: SkillEntryType;
  title: string;
}

const BandSkillDropdown = (props: {
  disabled: boolean;
  skills: IActionBandSkillsData[];
  selectedSkill: number;
  actionType: BlockstarActionType;
  onSkillSelected: (index: number) => void;
}) => {
  const theme = useTheme() as ThemeType;

  const onChange = (index: number) => {
    props.onSkillSelected(index);
  };

  return (
    <Dropdown
      disabled={props.disabled}
      value={
        props.selectedSkill < props.skills.length
          ? props.selectedSkill
          : props.skills.length - 1
      }
      onChange={onChange}
      style={{
        pointerEvents:
          props.actionType === BlockstarActionType.Busking ? 'none' : 'auto',
        width: '85%',
      }}
      components={props.skills.map((val, idx) => {
        return {
          component: (
            <DropdownSkill
              skill={val}
              color={theme.colors.ui.specialPink}
              actionType={props.actionType}
            />
          ),
          disabled: !val.title,
        };
      })}
    />
  );
};

const DropdownSkill = (props: {
  skill: IActionBandSkillsData;
  color: string;
  actionType: BlockstarActionType;
}) => {
  const locked = !props.skill.title;
  const title = locked ? 'Skill not available yet' : props.skill.title;
  return (
    <DropdownItem>
      <div style={{ width: '100%' }}>
        <Skill type={props.skill.type} title={title} width={'91%'} />
      </div>
    </DropdownItem>
  );
};

export default BandSkillDropdown;
