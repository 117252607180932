import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../app-router';
import { MenuSelectedButton, MenuUnselectedButton } from './style';

interface IProps {
  title: string;
  currentRoutePath: RoutePath;
  buttonRoute: RoutePath;
  onButtonClick: (buttonRoute: RoutePath) => void;
}

const HeaderMenuButton = (props: IProps) => {
  const [selected, setSelected] = useState(false);

  const navigate = useNavigate();
  const onButtonClick = () => {
    props.onButtonClick(props.buttonRoute);
  };
  useEffect(() => {
    setSelected(props.currentRoutePath.toString().includes(props.buttonRoute));
  }, []);

  if (selected) {
    return (
      <MenuSelectedButton onClick={() => onButtonClick()}>
        {props.title}
      </MenuSelectedButton>
    );
  } else {
    return (
      <MenuUnselectedButton onClick={() => onButtonClick()}>
        {props.title}
      </MenuUnselectedButton>
    );
  }
};

export default HeaderMenuButton;
