import { useTheme } from '@mui/material';
import { ActionStatus, BlockstarActionResponse, ThemeType } from '@shared-data';
import React from 'react';
import { ActionStatusView } from 'src/components/library/action-status-overlay';
import { ActionCustomOverlay } from 'src/components/library/action-status-overlay/custom-overlay';
import {
  AbilityUpMark,
  CheckMark,
  StarUpMark,
} from 'src/components/library/icons';
import analytics from 'src/utils/analytics';
import { capitalizeFirstLetter, getTimeLeft } from 'src/utils/utils';

export const ItemOverlay = (props: {
  blockstarAction?: BlockstarActionResponse;
  onClick?: () => void;
  onCountDownComplete?: () => void;
}) => {
  const theme = useTheme() as ThemeType;

  const { blockstarAction } = props;
  if (!blockstarAction) {
    return <div />;
  }

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const onCountDownComplete = () => {
    if (props.onCountDownComplete) {
      props.onCountDownComplete();
    }
  };

  const logEvent = (userAction: string) => {
    let xpScreen = 'xp earned';
    if (blockstarAction.levelUpInfo) {
      xpScreen = 'ability increased';
    }
    const maxedSkill = blockstarAction.skills?.find((item) => item.maxed);
    if (maxedSkill) {
      xpScreen = 'ability maxed';
    }
    if (blockstarAction.starUpInfo) {
      xpScreen = 'stars increased';
    }

    analytics.logEvent('ClickOverlayActionComplete', {
      groupType: 'individual',
      action: blockstarAction.actionType,
      userAction,
      xpScreen,
    });
  };

  switch (blockstarAction.actionStatus) {
    case ActionStatus.InAction:
      return (
        <ActionStatusView
          timeLeft={getTimeLeft(blockstarAction)}
          durationMs={blockstarAction.durationMs ?? 0}
          title={capitalizeFirstLetter(blockstarAction.actionType ?? '')}
          simple
          onViewClick={onClick}
          onCountDownComplete={onCountDownComplete}
        />
      );
    case ActionStatus.Completed:
      let icon = (
        <CheckMark
          width='calc(14px + 0.1vw)'
          height='calc(14px + 0.1vw)'
          bgOuterColor={theme.colors.ui.okOuter}
          bgInnerColor={theme.colors.ui.okInner}
        />
      );
      const text = `Completed`;
      if (blockstarAction.starUpInfo) {
        icon = (
          <StarUpMark
            width='calc(14px + 0.1vw)'
            height='calc(14px + 0.1vw)'
            bgOuterColor={theme.colors.ui.starOuter}
            bgInnerColor={theme.colors.ui.starInner}
          />
        );
      } else if (blockstarAction.levelUpInfo) {
        icon = (
          <AbilityUpMark
            width='calc(14px + 0.1vw)'
            height='calc(14px + 0.1vw)'
            bgOuterColor={theme.colors.ui.lightPurple}
            bgInnerColor={theme.colors.ui.darkPurple}
          />
        );
      }

      return (
        <ActionCustomOverlay
          icon={icon}
          title={text}
          simple
          onClick={() => {
            logEvent('view details');
            onClick();
          }}
        />
      );
    default:
      return <div />;
  }
};
