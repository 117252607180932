import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useTheme } from '@mui/material/styles';
import { BlockstarSkillType, LevelUpInfo, ThemeType } from '@shared-data';
import {
  instrumentsData,
  PerformanceSkillType,
} from '@shared-generated/generated-instruments';
import * as React from 'react';
import {
  Divider,
  RollupContentsInfo,
  RollupInfoContainer,
  RollupInfoLevelup,
} from '../../style';
import { SkillEntryType } from '../../types';
import { XpEntry, XpEntryProps } from './xp-entry';

interface IProps {
  icon?: JSX.Element;
  headerText: string;
  mainText: string[];
  newSkill?: PerformanceSkillType;
  xpEntryProps: XpEntryProps[];
  containerStyle?: React.CSSProperties;
  aggregateLevelups?: boolean;
  groupGeneral?: boolean;
  allLevelUps: LevelUpInfo[];
}

export const XpTable = (props: IProps) => {
  const theme = useTheme() as ThemeType;

  const musicalLevelUpInfo: LevelUpInfo[] = [];
  const generalLevelUpInfo: LevelUpInfo[] = [];

  // get all levelups for the summary
  props.allLevelUps.forEach((l) => {
    if (l) {
      if (Object.values(SkillEntryType).includes(l.skill as SkillEntryType)) {
        generalLevelUpInfo.push(l);
      } else {
        musicalLevelUpInfo.push(l);
      }
    }
  });

  // levelUpSummary is used for the top section, xpInfoProps already contains
  // the info for each skill separately.
  const levelUpSummary: LevelUpInfo[] = [];

  // aggregate includes grouping of musical abilities, general does not
  if (props.aggregateLevelups) {
    const groupedMusicalLevels = musicalLevelUpInfo.reduce(
      (prev, curr) => prev + curr.currentAbility - curr.previousAbility,
      0,
    );
    if (groupedMusicalLevels > 0) {
      levelUpSummary.push({
        skill: 'Musical',
        currentAbility: groupedMusicalLevels,
        previousAbility: 0,
        maxed: false,
        targetExp: 0,
        currentExp: 0,
      });
    }
  } else {
    levelUpSummary.push(...musicalLevelUpInfo);
  }

  if (props.aggregateLevelups || props.groupGeneral) {
    if (props.groupGeneral) {
      const groupedLevels = generalLevelUpInfo.reduce(
        (prev, curr) => prev + curr.currentAbility - curr.previousAbility,
        0,
      );
      levelUpSummary.push({
        skill: 'Abilities',
        currentAbility: groupedLevels,
        previousAbility: 0,
        maxed: false,
        targetExp: 0,
        currentExp: 0,
      });
    } else {
      let practicingSummary: LevelUpInfo | undefined;
      Object.keys(BlockstarSkillType).forEach((skill) => {
        const levelUps = generalLevelUpInfo.filter((i) => i.skill === skill);
        const groupedLevels = levelUps.reduce(
          (prev, curr) => prev + curr.currentAbility - curr.previousAbility,
          0,
        );
        const info = {
          skill,
          currentAbility: groupedLevels,
          previousAbility: 0,
          maxed: false,
          targetExp: 0,
          currentExp: 0,
        };
        if (groupedLevels > 0) {
          if (skill === BlockstarSkillType.Practicing) {
            practicingSummary = info;
          } else {
            levelUpSummary.push(info);
          }
        }
      });
      if (practicingSummary) {
        levelUpSummary.push(practicingSummary);
      }
    }
  } else {
    levelUpSummary.push(...generalLevelUpInfo);
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
      }}>
      <RollupInfoContainer style={{ width: '80%', ...props.containerStyle }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
          {props.icon}
          <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 4 }}>
            {props.headerText}
          </div>
          {props.mainText.map((s) => {
            return (
              <div
                key={s}
                style={{ fontSize: 40, fontWeight: 600, whiteSpace: 'nowrap' }}>
                {s}
              </div>
            );
          })}
        </div>
        <RollupInfoLevelup>
          {props.newSkill && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <div
                style={{
                  fontSize: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.colors.ui.specialPink,
                  }}>
                  <AutoAwesomeIcon fontSize='inherit' color='inherit' />
                </span>
                New
              </div>
              <span style={{ fontSize: 14 }}>
                {instrumentsData[props.newSkill].name}
              </span>
            </div>
          )}
          {levelUpSummary.map((val) => {
            const levels = val.currentAbility - val.previousAbility;
            return (
              <div
                key={val.skill}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    fontSize: 20,
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.colors.ui.primaryPurple,
                  }}>
                  <ArrowUpwardIcon
                    fontSize='inherit'
                    htmlColor={theme.colors.ui.primaryPurple}
                  />
                  {levels}
                </div>
                <span style={{ fontSize: 14 }}>{val.skill}</span>
              </div>
            );
          })}
        </RollupInfoLevelup>
        <RollupContentsInfo style={{ paddingTop: 4, paddingBottom: 24 }}>
          {props.xpEntryProps.map((val, idx) => {
            return (
              <div
                key={`${val.blockstar?.number}-${val.name}`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '95%',
                }}>
                <XpEntry {...val} />
                {idx < props.xpEntryProps.length - 1 && <Divider />}
              </div>
            );
          })}
        </RollupContentsInfo>
      </RollupInfoContainer>
    </div>
  );
};
