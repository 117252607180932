import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { RolSvg } from '../../styles/global-style';

export const MainContainer = styled('div')`
  width: 100%;
  height: ${window.innerHeight - 128}px;
  overflow: auto;
`;

export const Container = styled('div')`
  display: flex;
  width: 60%;
  margin: auto;
  min-width: 700px;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1364px) {
    width: 632px;
  }
`;

export const ImageContainer = styled('div')`
  width: 100%;
  height: 410px;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
export const BgImage = styled('img')`
  position: absolute;
  z-index: -1;

  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const SelectedImage = styled('img')`
  position: absolute;
  display: flex;
  border-radius: 6px;

  width: 350px;
  height: 350px;
`;

export const SelectedImageContainer = styled('div')`
  position: absolute;
  display: flex;

  width: 350px;
  height: 350px;
  margin: 30px;
`;

export const ThumbnailContainer = styled('div')`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 30px;
  justify-content: space-between;
  align-items: flex-end;
`;
export const Thumbnail = styled('img')`
  display: flex;
  width: 78px;
  height: 78px;
  border-radius: 6px;
  overflow: hidden;
  object-fit: contain;

  :hover {
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px white;
  }
`;

export const InfoContainer = styled('div')`
  padding: 48px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoTitle = styled('div')`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 24px;
  color: ${(props) => props.theme.colors.text.primaryGray};
  align-items: center;
`;

export const AttributeContainer = styled('div')`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AttributeHeader = styled('div')`
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.primaryGray};
  margin-bottom: 12px;
`;

export const AttributeBody = styled('div')`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-auto-rows: 60px;
  column-gap: 12px;
  row-gap: 12px;
  overflow: hidden;
  min-width: 0px;
  @media (max-width: 1364px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
`;

const StarBase = styled('div')`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  width: 26px;
  height: 26px;
`;

export const Divider = styled('div')`
  height: 0px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.ui.cellGray};
`;

export const BackButton = styled('div')`
  font-size: 16px;
  position: relative;
  left: -48px;
  white-space: pre;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;

export const ShareContainer = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`;

const RarityBase = styled('div')`
  font-size: 16px;
  border-radius: 50px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Common = styled(RarityBase)`
  justify-content: center;
  background-color: ${(props) => props.theme.colors.ui.mediumGray};
`;

export const Rare = styled(RarityBase)`
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple}26;
  color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const AttributeEntryContainer = styled('div')`
  width: 100%;
  height: 100%;
  min-width: 0px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.text.primaryGray};
  padding: 12px;
`;

export const AttributeText = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
`;

export const SummaryContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const SummaryInfo = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SummaryLeft = styled('div')`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const WalletText = styled('a')`
  color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const AboutRolFill = styled('div')`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  background: ${(props) => props.theme.colors.text.primaryGray};
`;

export const AboutRolIcon = styled(RolSvg)`
  width: 16px;
  height: 16px;
  margin-right: 0px;
  background: ${(props) => props.theme.colors.ui.cellGray};
`;

export const AboutLabel = styled('span')`
  font-size: 12px;
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const MusicalLabel = styled('span')`
  font-size: 12px;
  color: ${(props) => props.theme.colors.text.musicalAqua};
`;

export const LockedText = styled('div')`
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const BarBG = styled('div')`
  display: flex;
  flex: 1;
  margin-left: 24px;
  margin-right: 24px;
  height: 8px;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  border-radius: 20px;
`;

export const CurrentBarMusical = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.ui.musicalCyan};
`;

export const PotentialBar = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const AbilityContainer = styled('div')`
  display: flex;
  width: 80px;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const CurrentAbility = styled(AbilityContainer)`
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const PotentialAbility = styled(AbilityContainer)`
  color: ${(props) => props.theme.colors.text.primaryGray};
  margin-left: 24px;
`;

export const InfoLabelContainer = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 16px;
  padding-right: 24px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const XpInfo = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0px 24px;
`;

export const XpText = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 8px;
`;

export const XpBar = styled('div')`
  display: flex;
  width: 100%;
  height: 8px;
`;

export const TooltipMessage = styled('p')`
  width: 100%;
  text-align: left;
  font-size: 16px;
  white-space: pre-line;
`;

export const AboutContainer = styled('div')`
  margin-top: 16px;
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 68px;
  padding-left: 24px;
  @media (max-width: 1500px) {
    column-gap: 12px;
  }
`;

export const AboutItemContainer = styled('div')`
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;

export const AboutItemLeftContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AboutItemRightContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 6px;
`;

export const AboutItemRightTop = styled('p')`
  color: ${(props) => props.theme.colors.text.secondaryGray};
  text-align: left;
`;

export const AboutItemRightBottom = styled('h4')`
  text-align: left;
`;

export const BandButton = styled(LoadingButton)`
  font-weight: bolder;
  display: flex;
  box-shadow: none;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  &.MuiLoadingButton-root {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
  :hover {
    background-color: transparent;
    box-shadow: none;
  }
`;
