import { BlockstarSkillType } from '@shared-data';
import { PerformanceSkillFamily } from '@shared-generated/generated-instruments';
import React from 'react';
import {
  BrassIcon,
  FameIcon,
  GiggingIcon,
  InstrumentIcon,
  KeyboardIcon,
  OtherIcon,
  PercussionIcon,
  PracticingIcon,
  PromotingIcon,
  RecordingIcon,
  SkillIconBase,
  SocialMediaIcon,
  StringIcon,
  VoiceIcon,
  WoodwindIcon,
  WritingIcon,
} from 'src/styles/global-style';
import { GeneralIconBG, MusicalIconBG } from './style';
import { SkillEntryType } from './types';

export const SkillIcon = (props: {
  skill: PerformanceSkillFamily | BlockstarSkillType | SkillEntryType;
  isMusical?: boolean;
  includeFrame?: boolean;
  style?: React.CSSProperties;
}) => {
  let icon = (
    <SkillIconBase style={!props.includeFrame ? props.style : undefined} />
  );
  switch (props.skill) {
    case SkillEntryType.MUSICAL:
      icon = (
        <InstrumentIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case PerformanceSkillFamily.Brass:
      icon = (
        <BrassIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case PerformanceSkillFamily.Keyboard:
      icon = (
        <KeyboardIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case PerformanceSkillFamily.Other:
      icon = (
        <OtherIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case PerformanceSkillFamily.Percussion:
      icon = (
        <PercussionIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case PerformanceSkillFamily.String:
      icon = (
        <StringIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case PerformanceSkillFamily.Voice:
      icon = (
        <VoiceIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case PerformanceSkillFamily.Woodwind:
      icon = (
        <WoodwindIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case BlockstarSkillType.Fame:
      icon = <FameIcon style={!props.includeFrame ? props.style : undefined} />;
      break;
    case BlockstarSkillType.Gigging:
      icon = (
        <GiggingIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case BlockstarSkillType.Practicing:
      icon = (
        <PracticingIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case BlockstarSkillType.Promoting:
      icon = (
        <PromotingIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case BlockstarSkillType.Recording:
      icon = (
        <RecordingIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
    case BlockstarSkillType.SocialMedia:
      icon = (
        <SocialMediaIcon
          style={!props.includeFrame ? props.style : undefined}
        />
      );
      break;
    case BlockstarSkillType.Writing:
      icon = (
        <WritingIcon style={!props.includeFrame ? props.style : undefined} />
      );
      break;
  }

  return props.includeFrame ? (
    props.isMusical ? (
      <MusicalIconBG style={props.style}>{icon}</MusicalIconBG>
    ) : (
      <GeneralIconBG style={props.style}>{icon}</GeneralIconBG>
    )
  ) : (
    icon
  );
};
