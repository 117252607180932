import { Band, RoutePath } from '@shared-data';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ExclamationMark } from 'src/components/library/icons';
import { genreData } from 'src/utils/utils';
import { generateNavigateState } from '../others/helper';
import {
  MiddleAlertContainer,
  MiddleAlertDescriptionButton,
  MiddleAlertDescriptionContainer,
  MiddleAlertDescriptionTitle,
  MiddleAlertIconContainer,
} from './style';

export const MiddleAlert = (props: { band: Band }) => {
  const navigate = useNavigate();

  const genreInfo = genreData[props.band.genre];
  const musicalSlots = props.band.musicalMembers.map((member) => {
    if (!member?.blockstarId === undefined) {
      return undefined;
    }
    return {
      blockstarId: member?.blockstarId,
      skill: {
        name: member?.blockstarSkill.name,
        familyName: member?.blockstarSkill.familyName,
      },
    };
  });
  const otherSlots = props.band.generalMembers.map((member) => {
    if (!member?.blockstarId === undefined) {
      return undefined;
    }
    return {
      blockstarId: member?.blockstarId,
      skill: {
        name: member?.skill,
      },
    };
  });

  const onButtonClicked = () => {
    navigate(RoutePath.BandsEdit, {
      state: {
        originPage: location.pathname,
        isEdit: true,
        ...generateNavigateState(props.band),
      },
    });
  };

  return (
    <MiddleAlertContainer>
      <MiddleAlertIconContainer>
        <ExclamationMark width={32} height={32} />
      </MiddleAlertIconContainer>
      <MiddleAlertDescriptionContainer>
        <MiddleAlertDescriptionTitle>
          Requires action
        </MiddleAlertDescriptionTitle>
        <MiddleAlertDescriptionButton onClick={onButtonClicked}>
          Edit band
        </MiddleAlertDescriptionButton>
      </MiddleAlertDescriptionContainer>
    </MiddleAlertContainer>
  );
};
