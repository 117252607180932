import { BandActionResponse } from '@shared-data';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import rest, { BandActionPath } from '../actions/network/rest';

export interface BandActions {
  [key: string]: BandActionResponse;
}

export const useBandAction = (id?: string) => {
  const { data, error, mutate } = useSWR<BandActionResponse | undefined>(
    `${process.env.WORKER_URL}/${BandActionPath.Check}/${id}`,
    (url: string) => {
      if (id === undefined) {
        return undefined;
      }
      const result = rest.get(url).then((res: any) => {
        if (res?.message) {
          toast.info(res.message);
        }

        return res;
      });
      return result;
    },
  );
  return { bandAction: data, mutateBandAction: mutate };
};

export const useAllBandActions = () => {
  const { data, error, mutate } = useSWR<BandActionResponse[] | undefined>(
    `${process.env.WORKER_URL}/${BandActionPath.CheckAll}`,
    (url: string) =>
      rest.get(url).then((res: any) => {
        if (res?.message) {
          toast.info(res.message);
        }
        return res;
      }),
  );
  return { bandActions: data, mutate };
};
