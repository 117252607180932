import { Radio } from '@mui/material';
import { Blockstar, SkillType } from '@shared-data';
import React from 'react';
import { BlockstarInfo } from '../library/dialog-header';
import { SkillEntryType } from '../library/types';
import { OverwriteBody, OverwriteContainer, OverwriteNone } from './style';
import { TransferEntry } from './transfer-entry';

export const SelectOverwrite = (props: {
  blockstar: Blockstar;
  targetBlockstar: Blockstar;
  overwrite?: number;
  setOverwrite: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
  const imgSrc = `${process.env.ASSET_BASE_URL || ''}/${
    props.targetBlockstar.number
  }.png`;

  return (
    <OverwriteContainer>
      <span style={{ fontSize: 32, textAlign: 'center' }}>
        Replace a skill with
      </span>
      <span style={{ fontSize: 32, textAlign: 'center' }}>
        {props.blockstar.skills[0].name}
      </span>
      <OverwriteBody>
        <BlockstarInfo
          basic
          showBorders
          height={88}
          blockstar={props.targetBlockstar}
          padding={'16px 0px'}
          margin={'0px'}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'space-evenly',
          }}>
          {props.targetBlockstar.skills
            .filter((s) => s.type === SkillType.Instrumental)
            .map((s, idx) => {
              const suffix = idx === 0 ? ' (not replaceable)' : '';
              let data: any = {
                title: s.name + suffix,
                exp: s.currentExp,
                ability: s.currentAbilities,
                maxExp: s.targetExp,
                type: SkillEntryType.MUSICAL,
                locked: idx === 0,
              };
              return (
                <div
                  key={idx}
                  style={{
                    display: 'flex',
                    width: '100%',
                    flex: 1,
                  }}>
                  <TransferEntry
                    showAbility
                    {...data}
                    radioButton={
                      idx > 0 ? (
                        <Radio
                          checked={props.overwrite === idx}
                          value={idx}
                          onChange={(e) =>
                            props.setOverwrite(parseInt(e.target.value))
                          }
                        />
                      ) : (
                        <div style={{ width: 42 }} />
                      )
                    }
                  />
                </div>
              );
            })}
          <OverwriteNone>
            <span style={{ marginLeft: 8 }}>Keep current skills</span>
            <Radio
              style={{ borderTop: '1px solid #f4f4f4' }}
              checked={props.overwrite === 0}
              value={0}
              onChange={(e) => props.setOverwrite(parseInt(e.target.value))}
            />
          </OverwriteNone>
        </div>
      </OverwriteBody>
    </OverwriteContainer>
  );
};
