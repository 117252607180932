import { timeoutPromise } from '../../utils/utils';

export enum AuthRequests {
  check = 'users/check',
  create = 'users/create',
  signWallet = 'users/signWallet',
}

export enum UserRequests {
  profile = 'users/profile',
  updateUserName = 'users/userName',
  updateEmail = 'users/email',
  updateTutorialSate = 'users/updateTutorialSate',
}

export enum BlockstarsRequests {
  getBlockstarsByMintIds = 'blockstars/withMintIds',
  Blockstar = '/api/blockstar',
  Counts = '/api/blockstar/attribute/counts',
  GetBlockstar = 'api/blockstar/get', // Params ==> /:blockstarId
  GetAllBlockstars = 'api/blockstar/getAll/',
  NameChange = 'api/blockstar/name/change',
}

export enum NGMIRequests {
  test = 'api/ngmi',
  IOU = 'api/iou', // Params ==> /:numberId
}

export enum MiscRequests {
  ActionDurations = '/api/misc/action-durations',
  SingleLocation = '/api/misc/location',
  Announcements = '/api/misc/announcements',
  Whitelist = '/api/misc/profanity-whitelist',
}

export enum Web3Request {
  prepROLTransaction = 'api/blockstar/action/web3/prepROLTransaction',
  saveSignedTransaction = 'api/blockstar/action/web3/saveSignedTransaction',
  verifyROLTransactionSignature = 'api/blockstar/action/web3/verifyROLTransactionSignature',
}

export enum ActionRequests {
  CheckStatus = 'api/blockstar/action/check',
  CheckAllStatus = 'api/blockstar/action/checkAll',
  EndAction = 'api/blockstar/action/end',
  CalculateReward = 'api/blockstar/action/calcReward',
}

export enum StaticDataRequestPath {
  GetLocations = 'api/staticData/get/locations',
}

export enum AdminRequests {
  NameChange = 'api/admin/blockstar/name/change',
  AllBandCount = 'api/admin/bands/count',
  AllBandData = 'api/admin/bands/all',
  UpdateProfanityWhitelist = 'api/admin/blockstar/misc/profanity-whitelist',
  BandActionFF = 'api/admin/band/action/ff',
  GetMaxedBlockstars = 'api/admin/getMaxedBlockstar',
}

export enum BandRequestPath {
  Preview = 'api/band/calculate/preview',
  Rating = 'api/band/calculate/rating',
  PrepEdit = 'api/band/prep/',
  Create = 'api/band/create',
  UpdateBand = 'api/band', // Params ==> /:numberId
  GetAllBands = 'api/band/getAll', // Params ==> /:walletAddress
  GetBand = 'api/band', // Params ==> /:numberId
  DeleteBand = 'api/band', // Params ==> /:numberId
  NameChange = 'api/band/name/change',
}

export enum BandActionPath {
  Check = 'api/band/action/check',
  CheckAll = 'api/band/action/checkAll',
  Estimate = 'api/band/action/estimate',
  Prepare = 'api/band/action/prepare',
  Sign = 'api/band/action/sign',
  Start = 'api/band/action/start',
  End = 'api/band/action/end',
}

const retryMax = 1;
export class RestManager {
  abortControllers: {
    [key: string]: AbortController | undefined;
  };

  walletId: string | undefined;

  constructor() {
    this.abortControllers = {};
  }

  init(walletId: string) {
    this.walletId = walletId;
  }

  getHeaders(headers?: HeadersInit) {
    if (!this.walletId) {
      return headers;
    }
    return { 'X-Wallet-Id': this.walletId, ...headers };
  }

  public post = async <T>(
    endPoint: string,
    body?: unknown,
    headers?: HeadersInit,
    tries = 0,
  ): Promise<T> => {
    const abortController = new AbortController();
    const { signal } = abortController;

    this.abortControllers[endPoint] = abortController;

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        ...this.getHeaders(headers),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(body),
      signal,
    };

    try {
      const response = await timeoutPromise(
        120000,
        fetch(`${endPoint}`, requestOptions),
      );
      if (response.status === 204) {
        return undefined as unknown as T;
      }

      if (!response.ok) {
        if (response.status === 507 && tries < retryMax) {
          return await this.post(endPoint, body, headers, ++tries);
        }
        const data = await response.json();
        throw new Error(data?.message || data?.error || JSON.stringify(data));
      }

      this.abortControllers[endPoint] = undefined;
      return response.json();
    } catch (error: any) {
      this.abortControllers[endPoint] = undefined;
      if (error.name === 'AbortError') {
        console.log(`rest post aborted`);
        return undefined as unknown as T;
      }
      console.error('rest post fail:', error);
      throw error;
    }
  };

  public get = async <T>(
    endPoint: string,
    headers?: HeadersInit,
    tries = 0,
  ): Promise<T> => {
    const abortController = new AbortController();
    const { signal } = abortController;

    this.abortControllers[endPoint] = abortController;

    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...this.getHeaders(headers),
      },
      credentials: 'include',
      signal,
    };

    try {
      const response = await timeoutPromise(
        120000,
        fetch(`${endPoint}`, requestOptions),
      );

      if (response.status === 204) {
        return undefined as unknown as T;
      }

      if (!response.ok) {
        if (response.status === 507 && tries < retryMax) {
          return await this.get(endPoint, headers, ++tries);
        }
        const data = await response.json();
        throw new Error(data.message);
      }

      return response.json();
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log(`rest get Aborted`);
        return undefined as unknown as T;
      }
      console.error(`rest get fail: ${error}`);
      throw error;
    }
  };

  public abortFetch = (endPoint: string) => {
    this.abortControllers[endPoint]?.abort();
  };

  public delete = async <T>(
    endPoint: string,
    body?: unknown,
    headers?: HeadersInit,
    tries = 0,
  ): Promise<T> => {
    const abortController = new AbortController();
    const { signal } = abortController;

    this.abortControllers[endPoint] = abortController;

    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: {
        ...this.getHeaders(headers),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(body),
      signal,
    };

    try {
      const response = await timeoutPromise(
        120000,
        fetch(`${endPoint}`, requestOptions),
      );
      if (response.status === 204) {
        return undefined as unknown as T;
      }

      if (!response.ok) {
        if (response.status === 507 && tries < retryMax) {
          return await this.delete(endPoint, body, headers, ++tries);
        }
        const data = await response.json();
        throw new Error(data?.message || data?.error || JSON.stringify(data));
      }

      this.abortControllers[endPoint] = undefined;
      return response.json();
    } catch (error: any) {
      this.abortControllers[endPoint] = undefined;
      if (error.name === 'AbortError') {
        console.log(`rest delete aborted`);
        return undefined as unknown as T;
      }
      console.error('rest delete fail:', error);
      throw error;
    }
  };
}

const rest = new RestManager();
export default rest;
