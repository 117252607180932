import { Paper, Tooltip } from '@mui/material';
import {
  ActionStatus,
  Blockstar,
  BlockstarActionResponse,
  SkillType,
  TransferInfo,
} from '@shared-data';
import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import rest from 'src/actions/network/rest';
import * as pulsingDot from 'src/assets/lottie/pulsing_dot.json';
import analytics from 'src/utils/analytics';
import { getBlockstarName } from 'src/utils/utils';
import { BlockstarInfo } from '../library/dialog-header';
import { Dropdown } from '../library/dropdown';
import { infoIcon } from '../library/info-icon';
import { InfoNumber } from '../library/style';
import { SkillEntryType } from '../library/types';
import Stars from '../star-rating';
import {
  DropdownItem,
  DropdownLottie,
  DropdownStatus,
  MidContainer,
  SelectContainer,
  TooltipContainer,
  TransferDropdown,
  TransferEntries,
  TransferIcon,
  TransferLabel,
} from './style';
import { TransferEntry } from './transfer-entry';

export const SelectTarget = (props: {
  blockstar: Blockstar;
  targets: Blockstar[];
  target: number;
  setTarget: React.Dispatch<React.SetStateAction<number>>;
  actions: {
    [key: number]: BlockstarActionResponse;
  };
  onClose: () => void;
  transfer: TransferInfo[];
  setTransfer: React.Dispatch<React.SetStateAction<TransferInfo[]>>;
  setEstimateLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const actionValues = Object.values(props.actions);
  useEffect(() => {
    analytics.logEvent('ViewScreenActionDialog', {
      action: 'fire',
      groupType: 'blockstar',
      screen: 'ngmi-select-target',
    });
    const idx = actionValues.findIndex(
      (a) => a.actionStatus === ActionStatus.None,
    );
    if (props.target < idx) {
      props.setTarget(idx);
    }
  }, []);

  useEffect(() => {
    const getData = async (target: number) => {
      const body = {
        blockstarId: props.blockstar.number,
        targetId: props.targets[target].number,
      };
      props.setEstimateLoading(true);
      const data = (await rest.post(
        `${process.env.WORKER_URL}/api/ngmi/estimate`,
        body,
      )) as TransferInfo[];

      props.setTransfer(data);
      props.setEstimateLoading(false);
    };
    if (
      props.targets &&
      props.targets.length > props.target &&
      actionValues.length > props.target &&
      props.actions[props.targets[props.target].number].actionStatus ===
        ActionStatus.None
    ) {
      // only get data if it's a valid target
      getData(props.target);
    }
  }, [props.target]);

  const tooltip = (
    <Paper>
      <TooltipContainer>
        Different Blockstars will absorb different amounts of XP. Only Primary
        musical skill is transferred from the fired Blockstars.
      </TooltipContainer>
    </Paper>
  );

  return (
    <SelectContainer>
      <BlockstarInfo
        blockstar={props.blockstar}
        padding='0px 0px 0px 48px'
        margin='0px 0px 16px 0px'
      />
      <MidContainer>
        <div
          style={{
            display: 'flex',
            width: '100%',
            borderBottom: '1px solid #F4F4F4',
            paddingBottom: 8,
          }}>
          <span style={{ fontSize: 14 }}>
            By firing this Blockstar you will be transferring the following XP:
          </span>
          <Tooltip placement='right' title={tooltip}>
            {infoIcon}
          </Tooltip>
        </div>
        <TransferEntries>
          {props.blockstar.skills.map((s, idx) => {
            const expData = props.transfer.find((d: any) =>
              idx === 0
                ? d.type === SkillType.Instrumental
                : d.type === s.type && d.name === s.name,
            );
            const exp = expData?.exp;
            if (s.type === SkillType.Instrumental) {
              const entry = {
                type: SkillEntryType.MUSICAL,
                title: s.name,
                exp: idx === 0 ? exp : 0,
              };
              return <TransferEntry key={idx} {...entry} />;
            }
            const type = s.name.split(' ')[0].toUpperCase();
            const entry = {
              type: SkillEntryType[type as keyof typeof SkillEntryType],
              title: s.name,
              exp,
            };
            return <TransferEntry key={idx} {...entry} />;
          })}
        </TransferEntries>
        <TransferLabel>
          <TransferIcon style={{ marginRight: 12 }} /> Transfer to
        </TransferLabel>
        <TransferDropdown>
          <Dropdown
            value={props.target}
            onChange={(idx: number) => props.setTarget(idx)}
            error={
              props.actions[props.targets[props.target].number]
                ?.actionStatus !== ActionStatus.None
            }
            style={{
              pointerEvents: props.targets.length === 1 ? 'none' : 'auto',
            }}
            components={props.targets.map((b, idx) => {
              return {
                component: (
                  <DropdownTarget
                    key={idx}
                    blockstar={b}
                    action={props.actions[b.number]}
                  />
                ),
                disabled:
                  props.actions[b.number]?.actionStatus !== ActionStatus.None,
              };
            })}
          />
        </TransferDropdown>
        <div style={{ borderTop: '1px solid #F4F4F4' }} />
        <TransferEntries>
          {props.targets &&
            props.targets[props.target]?.skills?.map((s, idx) => {
              let data: any = {
                title: s.name,
                exp: s.currentExp,
                ability: s.currentAbilities,
                maxExp: s.targetExp,
                maxed: s.maxed,
              };
              if (s.type === SkillType.Instrumental) {
                data = {
                  ...data,
                  type: SkillEntryType.MUSICAL,
                };
              } else {
                const type = s.name.split(' ')[0].toUpperCase();
                data = {
                  ...data,
                  type: SkillEntryType[type as keyof typeof SkillEntryType],
                };
              }
              return <TransferEntry key={idx} showAbility {...data} />;
            })}
        </TransferEntries>
      </MidContainer>
    </SelectContainer>
  );
};

const DropdownTarget = (props: {
  blockstar: Blockstar;
  action?: BlockstarActionResponse;
}) => {
  const imgSrc = `${process.env.ASSET_BASE_URL || ''}/${
    props.blockstar.number
  }.png`;
  return (
    <DropdownItem>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}>
        <img src={imgSrc} style={{ width: 36, height: 36, borderRadius: 4 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}>
            <span style={{ margin: '0px 5px' }}>
              {getBlockstarName(props.blockstar)}
            </span>
            <InfoNumber>{`#${props.blockstar.number}`}</InfoNumber>
          </div>
          <div style={{ marginLeft: '5px' }}>
            <Stars
              currentStars={props.blockstar.starRatingInfo.currentStarRating}
              potentialStars={props.blockstar.starRatingInfo.maxStarRating}
              starSize={16}
            />
          </div>
        </div>
      </div>
      {props.action?.actionStatus !== ActionStatus.None && (
        <DropdownStatus>
          <DropdownLottie>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: pulsingDot,
              }}
              width={16}
              height={16}
            />
          </DropdownLottie>
          {props.action?.actionType}
        </DropdownStatus>
      )}
    </DropdownItem>
  );
};
