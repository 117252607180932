import { useTheme } from '@mui/material';
import {
  Blockstar,
  BlockstarSkillType,
  InstrumentData,
  ThemeType,
} from '@shared-data';
import {
  instrumentsData,
  PerformanceSkillFamily,
} from '@shared-generated/generated-instruments';
import React from 'react';
import { SkillIcon } from '../skill-icon';
import { SkillEntryType } from '../types';
import {
  BlockstarImage,
  BlockstarImageContainer,
  LockedText,
  MusicalLabel,
  SkillTextContainer,
} from './style';

export const Skill = (props: {
  type: SkillEntryType;
  title: string;
  locked?: boolean;
  width?: number | string;
  hideLabel?: boolean;
  fontSize?: number;
  blockstar?: Blockstar;
  notFulfilled?: boolean;
  skillTextColor?: string;
}) => {
  let label;
  let isMusical = false;
  let { title } = props;
  const theme = useTheme() as ThemeType;

  switch (props.type) {
    case SkillEntryType.MUSICAL:
      if (!props.hideLabel) {
        label = <MusicalLabel>Musical</MusicalLabel>;
      }
      isMusical = true;
      title = instrumentsData[title as keyof InstrumentData]?.name ?? title;
      break;
  }

  const skill = props.blockstar?.skills.find((skill) => skill.name === title);
  const skillType = !props.blockstar
    ? props.type
    : props.type === SkillEntryType.MUSICAL
    ? (skill?.familyName as PerformanceSkillFamily)
    : (skill?.name as BlockstarSkillType);

  const skillIcon = (
    <SkillIcon skill={skillType} isMusical={isMusical} includeFrame />
  );

  let blockstarIcon = undefined;
  if (props.blockstar) {
    blockstarIcon = (
      <BlockstarImageContainer>
        <BlockstarImage
          src={`${process.env.ASSET_BASE_URL || ''}/${
            props.blockstar.number
          }.png`}></BlockstarImage>
        <SkillIcon
          skill={skillType}
          isMusical={isMusical}
          includeFrame
          style={{
            position: 'absolute',
            top: '-4px',
            left: '26px',
            width: '14px',
            height: '14px',
            borderRadius: '3px',
            background: 'white',
            backgroundColor: 'white',
          }}
        />
      </BlockstarImageContainer>
    );
  }

  const icon = blockstarIcon ?? skillIcon;

  return (
    <div style={{ display: 'flex' }}>
      {icon}
      <SkillTextContainer
        style={{
          width: props.width ?? 218,
          fontSize: props.fontSize ?? 16,
        }}>
        {label}
        {props.locked ? (
          <LockedText>{title}</LockedText>
        ) : (
          <span
            style={{
              height: 19,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              lineHeight: '19px',
              color: props.notFulfilled
                ? theme.colors.text.error
                : props.skillTextColor ?? theme.colors.text.primaryGray,
            }}>
            {title}
          </span>
        )}
      </SkillTextContainer>
    </div>
  );
};
