import {
  Band,
  BandActionResponse,
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
  RoutePath,
} from '@shared-data';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { InfoNumber } from 'src/components/library/style';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import { BlockstarActions } from 'src/hooks/blockstar-action';
import { useBlockstars } from 'src/hooks/blockstars';
import { getBandActionResults, getBlockstarName } from 'src/utils/utils';
import { KeyedMutator } from 'swr';
import { ItemOverlay } from '../others/item-overlay';
import {
  MiddleMemberContainer,
  MiddleMemberPicture,
  MiddleMemberPictureButton,
  MiddleMemberPictureContainer,
  MiddleMemberText,
} from './style';

export const MiddleMembersMember = (props: {
  member: Blockstar;
  band: Band;
  blockstarAction?: BlockstarActionResponse;
  bandAction?: BandActionResponse;
  onActionOpen: (member: Blockstar, actionType?: BlockstarActionType) => void;
  mutateBlockstarActions: KeyedMutator<BlockstarActions>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { blockstars } = useBlockstars();
  const {
    setOpenActionResultDialog,
    setOpenActionProgressDialog,
    setBand,
    setActionType,
    setActionResult,
    setActionResultError,
  } = useBandActionControlContext() as IBandActionControl;

  const onBlockstarClicked = () => {
    navigate(`${RoutePath.Blockstars}/${props.member.number}`, {
      state: { originPage: location.pathname },
    });
  };

  const onActionClick = async () => {
    switch (props.blockstarAction?.actionType) {
      case BlockstarActionType.BandPracticing:
      case BlockstarActionType.BandGigging:
        setActionType(props.blockstarAction?.actionType);
        setBand(props.band);
        if (props.blockstarAction?.completed) {
          try {
            const result = await getBandActionResults(
              props.band.id,
              props.bandAction,
            );
            setActionResult(result);
            setActionResultError(undefined);
            setOpenActionResultDialog(true);
          } catch {
            setActionResultError('Error getting band action result');
          }
        } else {
          setOpenActionProgressDialog(true);
        }
        break;
      default:
        props.onActionOpen(props.member, props.blockstarAction?.actionType);
        break;
    }
  };

  return (
    <MiddleMemberContainer>
      <MiddleMemberPictureContainer>
        <MiddleMemberPictureButton onClick={onBlockstarClicked}>
          <MiddleMemberPicture
            src={`${process.env.ASSET_BASE_URL || ''}/${
              props.member.number
            }.png`}
          />
        </MiddleMemberPictureButton>
        {blockstars &&
          blockstars?.findIndex(
            (blockstar) => blockstar.number === props.member.number,
          ) >= 0 && (
            <ItemOverlay
              blockstarAction={props.blockstarAction}
              onClick={onActionClick}
              onCountDownComplete={props.mutateBlockstarActions}
            />
          )}
      </MiddleMemberPictureContainer>

      <MiddleMemberText>{getBlockstarName(props.member)}</MiddleMemberText>
      <InfoNumber>{`#${props.member.number}`}</InfoNumber>
    </MiddleMemberContainer>
  );
};
