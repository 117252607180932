import { Button, useTheme } from '@mui/material';
import { RoutePath, ThemeType } from '@shared-data';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../header';

export const PageBlockView = (props: { title?: string; message?: string }) => {
  const navigate = useNavigate();
  const theme = useTheme() as ThemeType;
  return (
    <div>
      <Header />
      <div style={{ marginTop: '20px' }}>
        <h1>{props.title}</h1>
        <p style={{ marginTop: '20px' }}>{props.message}</p>
        <Button
          style={{
            marginTop: '80px',
            height: '50px',
            width: '150px',
            backgroundColor: theme.colors.ui.primaryPurple,
            border: 'none',
            borderRadius: '10px',
            color: 'white',
            fontWeight: 'bold',
          }}
          onClick={() =>
            navigate(RoutePath.Blockstars, {
              state: { originPage: location.pathname },
            })
          }>
          Home
        </Button>
      </div>
    </div>
  );
};
