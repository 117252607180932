import React from 'react';
import { useLocation } from 'react-router-dom';
// import emptyBandCharacter from '../../../assets/images/empty-band-character.gif';
import {
  EmptyBandInfoBody,
  EmptyBandInfoContainer,
  EmptyBandInfoHeader,
  EmptyBandVideo,
} from './style';

const emptyBandCharacter = `https://assets.blockstars.gg/static/empty-band-character.gif`;

export const EmptyBand = () => {
  const currentPage = useLocation().pathname;
  return (
    <EmptyBandInfoContainer>
      <EmptyBandInfoHeader>
        You can’t top the charts without a band!
      </EmptyBandInfoHeader>
      <EmptyBandInfoBody>
        Browse the genres and subgenres to see the different musical skill
        requirements.
      </EmptyBandInfoBody>
      <EmptyBandVideo
        autoPlay
        loop
        playsInline
        muted
        src={'https://assets.blockstars.gg/static/game/etc/band_empty.mp4'}
      />
      {/* <EmptyBandCharacter src={emptyBandCharacter}></EmptyBandCharacter> */}
    </EmptyBandInfoContainer>
  );
};

export default EmptyBand;
