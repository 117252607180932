import { useTheme } from '@mui/material';
import { ThemeType } from '@shared-data';
import React from 'react';
import {
  AbilityUpIcon,
  CheckMarkIcon,
  ExclamationMarkIcon,
  IconCircleContainer,
  IconCircleInner,
  IconCircleOuter,
  PulsingDotCircle,
  PulsingDotContainer,
  PulsingDotRing,
  SkillMarkIcon,
  StarUpIcon,
} from './style';

export const PulsingDot = (props: {
  width: number;
  height: number;
  color: string;
}) => {
  return (
    <PulsingDotContainer width={props.width} height={props.height}>
      <PulsingDotRing
        width={props.width}
        height={props.height}
        color={props.color}
      />
      <PulsingDotCircle
        width={props.width}
        height={props.height}
        color={props.color}
      />
    </PulsingDotContainer>
  );
};

export const ExclamationMark = (props: {
  bgInnerColor?: string;
  bgOuterColor?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <IconCircleContainer width={props.width ?? 24} height={props.height ?? 24}>
      <IconCircleOuter color={props.bgOuterColor} />
      <IconCircleInner color={props.bgInnerColor} />
      <ExclamationMarkIcon />
    </IconCircleContainer>
  );
};

export const CheckMark = (props: {
  bgInnerColor?: string;
  bgOuterColor?: string;
  width?: number | string;
  height?: number | string;
}) => {
  return <MarkBase {...props} child={<CheckMarkIcon />} />;
};

export const SkillMark = (props: {
  bgInnerColor?: string;
  bgOuterColor?: string;
  width?: number | string;
  height?: number | string;
}) => {
  return <MarkBase {...props} child={<SkillMarkIcon />} />;
};

export const AbilityUpMark = (props: {
  bgInnerColor?: string;
  bgOuterColor?: string;
  width?: number | string;
  height?: number | string;
}) => {
  return <MarkBase {...props} child={<AbilityUpIcon />} />;
};

export const StarUpMark = (props: {
  bgInnerColor?: string;
  bgOuterColor?: string;
  width?: number | string;
  height?: number | string;
}) => {
  return <MarkBase {...props} child={<StarUpIcon />} />;
};

const MarkBase = (props: {
  bgInnerColor?: string;
  bgOuterColor?: string;
  width?: number | string;
  height?: number | string;
  child?: any;
}) => {
  return (
    <IconCircleContainer width={props.width} height={props.height}>
      <IconCircleOuter color={props.bgOuterColor} />
      <IconCircleInner color={props.bgInnerColor} />
      {props.child}
    </IconCircleContainer>
  );
};

export const StatusIcon = (props: { completed: boolean }) => {
  const theme = useTheme() as ThemeType;

  if (!props.completed) {
    return (
      <PulsingDot
        color={theme.colors.ui.primaryPurple}
        width={16}
        height={16}
      />
    );
  } else {
    return (
      <CheckMark
        width={16}
        height={16}
        bgOuterColor={theme.colors.ui.okOuter}
        bgInnerColor={theme.colors.ui.okInner}
      />
    );
  }
};
