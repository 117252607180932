import { BlockstarActionResponse } from '@shared-data';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import rest, { ActionRequests } from '../actions/network/rest';

export interface BlockstarActions {
  [key: number]: BlockstarActionResponse;
}

export const useBlockstarAction = (id?: number) => {
  const { data, error, mutate } = useSWR<BlockstarActionResponse | undefined>(
    `${process.env.WORKER_URL}/${ActionRequests.CheckStatus}/${id}`,
    (url: string) => {
      if (id === undefined) {
        return undefined;
      }
      const result = rest.get(url).then((res: any) => {
        if (res?.message) {
          toast.info(res.message);
        }
        return res;
      });
      return result;
    },
  );
  return { action: data, mutate };
};

export const useAllBlockstarActions = (ids: number[]) => {
  const { data, error, mutate } = useSWR<BlockstarActions>(
    `CheckAllActions/${JSON.stringify(ids)}`,
    (url: string) =>
      rest
        .post(`${process.env.WORKER_URL}/${ActionRequests.CheckAllStatus}`, {
          blockstarIds: ids,
        })
        .then((res: any) => {
          if (res?.message) {
            toast.info(res.message);
          }
          return res;
        }),
  );
  return { actions: data, mutate };
};
