import { styled } from '@mui/material/styles';
import transferIcon from 'src/assets/images/transfer.svg';

export const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SelectContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 555px;
  height: 500px;
`;

export const OverwriteContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0px 48px;
  width: 555px;
  height: 420px;
`;

export const NoOverwriteContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 48px;
  width: 555px;
  height: 175px;
`;

export const SummaryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 555px;
  height: 500px;
`;

export const BackContainer = styled('div')`
  display: flex;
  height: 27px;
  width: 100%;
  padding-left: 24px;
`;

export const MidContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 460px;
  padding: 0px 48px;
`;

export const BottomContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 105px;
  padding: 24px;
  justify-content: space-between;
`;

export const InfoIconContainer = styled('div')`
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.secondaryGray};
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: 8px;
`;

export const TooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 275px;
  padding: 16px;
  font-size: 16px;
`;

export const DropdownItem = styled('div')`
  display: flex;
  width: 420px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownStatus = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const TransferIcon = styled('div')`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  background-image: url(${transferIcon});
`;

export const TransferEntries = styled('div')`
  display: flex;
  height: 120px;
  flex-direction: column;
  overflow: auto;

  /* scrollbar width */
  &::-webkit-scrollbar {
    width: 6px;
  }
  /* scrollbar Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* scrollbar Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.ui.unfilledGray};
    border-radius: 2px;
  }
  /* scrollbar Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.ui.unfilledGray};
  }
`;

export const TransferLabel = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 42px;
  margin: 4px;
`;

export const TransferDropdown = styled('div')`
  display: flex;
  height: 70px;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const DropdownLottie = styled('div')`
  display: flex;
  margin: auto;
  margin-top: 3px;
  margin-right: 8px;
`;

export const OverwriteBody = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  height: 100%;
`;

export const OverwriteNone = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
`;

export const SummaryBody = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const SummaryEntry = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`;

export const TransferContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const TransferBody = styled('div')`
  display: flex;
  flex: 3;
  height: 500px;
  width: 100%;
  align-items: center;
`;

export const TransferLottie = styled('div')`
  display: flex;
  flex: 1;
  height: 500px;
  width: 100%;
  align-items: center;
`;

export const ResultContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  font-weight: 600;
`;

export const ResultBody = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 600px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const IOUContainer = styled('div')`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
`;

export const IOUBody = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const IOUText = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  width: 400px;
`;
