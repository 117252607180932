import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { styled } from '@mui/material';

// Grid View
export const GridViewContainer = styled('div')`
  width: 1364px;
  margin: auto;
  display: grid;
  grid-template-columns: 632px 632px;
  grid-gap: 100px;
  grid-row-gap: 100px;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  @media (max-width: 1364px) {
    width: 632px;
    grid-template-columns: 632px;
    grid-row-gap: 50px;
  }
`;

// Grid Items
export const GridItemContainer = styled('div')`
  width: 632px;
  height: 270px;
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  position: relative;
`;

export const InactivatedIcon = styled('div')`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 66px;
  height: 22px;
  color: ${(props) => props.theme.colors.text.error};
  border-radius: 50%;
  border-width: 0px;
`;

export const GridItemInfoContainer = styled('div')`
  position: relative;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const GridItemNameContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StarSalaryContainer = styled('div')`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SalaryContainer = styled('div')`
  width: 168px;
  height: 100%;
  text-align: left;
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.musicalAqua};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const GenreContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  column-gap: 12px;
`;

export const GenreIcon = styled(LibraryMusicIcon)`
  color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const GenreText = styled('p')``;
