import { Connection, PublicKey } from '@solana/web3.js';
import useSWR from 'swr';
import {
  findAssociatedTokenAddress,
  getFTTokenBalance,
} from '../../actions/wallet';
import analytics from '../../utils/analytics';

export const useRolBalance = (
  connection: Connection,
  publicKey?: PublicKey | null,
  setUserProperty?: boolean,
) => {
  const { data, error, mutate } = useSWR(
    publicKey ? ['rolBalance', publicKey.toString()] : null,
    async () => {
      const tokenAddress = await findAssociatedTokenAddress(
        publicKey!,
        new PublicKey(process.env.ROL_MINT_ADDRESS!),
      );
      return getFTTokenBalance(connection, tokenAddress);
    },
  );
  if (data && setUserProperty) {
    analytics.setUserProperties({ rolBalance: data });
  }
  return { rolBalance: data ?? 0, mutateBalance: mutate };
};
