/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material/styles';
import { ThemeType } from '@shared-data';
import { useWallet } from '@solana/wallet-adapter-react';
import { ParsedInstruction, ParsedTransactionWithMeta } from '@solana/web3.js';
import { isNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { useRolTokenAccount } from '../../../hooks/wallet/rol-token-account';
import analytics from '../../../utils/analytics';
import {
  getDateTimeString,
  getTransactionLink,
  scanEnvString,
  truncateString,
} from '../../../utils/utils';
import { Activity, TableStyles } from './style';

interface FTTransactionData {
  time: string;
  idTruncated: string;
  id: string;
  type: string;
  amount: number;
}

const columns = [
  {
    Header: 'TIME',
    accessor: 'time',
  },
  {
    Header: 'TRANSACTION ID',
    accessor: 'idTruncated',
  },
  {
    Header: 'TRANSACTION TYPE',
    accessor: 'type',
  },
  {
    Header: 'AMOUNT',
    accessor: 'amount',
  },
];

const RolActivity = (props: {
  transactions: (ParsedTransactionWithMeta | null)[] | null;
}) => {
  const { transactions } = props;

  const [transactionList, setTransactionList] = useState<FTTransactionData[]>(
    [],
  );

  const { publicKey } = useWallet();
  const rolTokenAccount = useRolTokenAccount(publicKey);
  useEffect(() => {
    if (!rolTokenAccount) {
      return;
    }
    if (transactions) {
      const dataList: FTTransactionData[] = [];
      transactions.forEach((element) => {
        if (element) {
          const now = new Date();
          let dateString = getDateTimeString(now);
          if (element.blockTime) {
            const myDate = new Date(element.blockTime * 1000);
            dateString = getDateTimeString(myDate);
          }

          let amount = 0;
          let type = '';
          element.transaction.message.instructions.forEach((element) => {
            const instruction = element as ParsedInstruction;
            if (instruction.program === 'spl-token') {
              const { source, tokenAmount } = instruction.parsed.info;

              if (tokenAmount) {
                if (source === rolTokenAccount?.toString()) {
                  amount -= tokenAmount.uiAmount;
                } else {
                  amount += tokenAmount.uiAmount;
                }
              }
            }
            // for Transaction Type
            if (instruction.program === 'spl-memo') {
              const typeJson = instruction.parsed as string;
              try {
                const typeData = JSON.parse(typeJson);
                type = String(typeData.type).toUpperCase();
              } catch (e) {
                // Look for shortened fallback for NGMI (non-JSON)
                if (typeJson.toLowerCase().includes('ngmi')) {
                  type = 'NGMI';
                } else {
                  type = 'UNKNOWN';
                }
              }
            }
          });

          const signature = element.transaction.signatures[0] ?? '';
          // Truncate the signature.
          const truncatedSignature = truncateString(signature);

          const data: FTTransactionData = {
            time: dateString,
            id: signature,
            idTruncated: truncatedSignature,
            type,
            amount,
          };
          dataList.push(data);
        }
      });
      setTransactionList(dataList);
    }
  }, [rolTokenAccount]);

  return (
    <TableStyles>
      <Table columns={columns} data={transactionList} />
    </TableStyles>
  );
};

const Table = (props: { columns: any[]; data: any[] }) => {
  const { columns, data } = props;
  const theme = useTheme() as ThemeType;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const idButtonClicked = (signature: string) => {
    analytics.logEvent('OpenTransactionHistory', {
      id: `${signature}${scanEnvString}`,
      service: process.env.TRANSACTION_LINK_SERVICE,
    });
  };

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i, h) => {
              const style: React.CSSProperties =
                i === h.length - 1
                  ? { textAlign: 'right', paddingRight: 0 }
                  : i === 0
                  ? { textAlign: 'left', paddingLeft: 0 }
                  : { textAlign: 'left' };
              return (
                <th {...column.getHeaderProps()} style={style}>
                  {column.render('Header')}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const idFull = data[i].id;
          const bgColor =
            i % 2 === 0 ? 'rgba(255,255,255,0.5)' : 'rgba(218,218,218,0.5)';
          return (
            <tr {...row.getRowProps()} style={{ backgroundColor: bgColor }}>
              {row.cells.map((cell, i, cells) => {
                let style: React.CSSProperties = { textAlign: 'left' };
                let numberString;
                if (isNumber(cell.value)) {
                  if (cell.value > 0) {
                    style = {
                      color: theme.colors.legacy.positiveText,
                      textAlign: 'right',
                    };
                    numberString = `+${cell.value.toLocaleString()}`;
                  } else if (cell.value < 0) {
                    style = {
                      color: theme.colors.legacy.negativeText,
                      textAlign: 'right',
                    };
                    numberString = cell.value.toLocaleString();
                  } else {
                    style = { textAlign: 'right' };
                    numberString = '0';
                  }
                }

                let returnTd = (
                  <td {...cell.getCellProps()} style={style}>
                    {isNumber(cell.value) ? numberString : cell.render('Cell')}
                  </td>
                );
                if (cell.column.id === 'idTruncated') {
                  returnTd = (
                    <td {...cell.getCellProps()} style={style}>
                      <Activity
                        href={getTransactionLink(idFull, scanEnvString)}
                        onClick={() => idButtonClicked(idFull)}
                        target='_blank'
                        rel='noreferrer'>
                        {cell.render('Cell')}
                      </Activity>
                    </td>
                  );
                } else if (cell.column.id === 'type') {
                  if (!cell.value) {
                    let txnType = 'TRANSACTION IN';
                    const amountCell = cells.find(
                      (c) => c.column.id === 'amount',
                    );
                    if (
                      amountCell &&
                      isNumber(amountCell.value) &&
                      amountCell.value < 0
                    ) {
                      txnType = 'TRANSACTION OUT';
                    }
                    returnTd = (
                      <td {...cell.getCellProps()} style={style}>
                        {txnType}
                      </td>
                    );
                  }
                }
                return returnTd;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default RolActivity;
