import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from '@mui/icons-material/Close';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LoopIcon from '@mui/icons-material/Loop';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  css,
  keyframes,
  styled,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Dropdown } from 'src/components/library/dropdown';
import { BodyContainer } from 'src/styles/global-style';

export const Container = styled('div')``;

export const BandEditorBodyContainer = styled(BodyContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  @media (max-width: 1100px) {
    align-items: flex-start;
  }
`;

export const BandEditorBodyWrapper = styled('div')`
  width: 1364px;
  margin: auto;
  @media (max-width: 1460px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 1364px) {
    width: 1000px;
  }
`;

export const EditorBodyElementsContainer = styled('div')`
  min-width: 900px;
  width: 100%;
`;

// TOP
export const EditorBodyElementsContainerTop = styled(
  EditorBodyElementsContainer,
)`
  height: 314px;
  border-bottom: 2px solid ${(props) => props.theme.colors.ui.lineGray};
  margin-bottom: 24px;
`;

export const EditorTopTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 110px;
`;

export const EditorTopTitleInfoContainer = styled('div')`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const EditorTopTitleInfo = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`;

export const EditorTopTitleInfoTopTitle = styled('h1')`
  margin-right: 12px;
`;

export const EditorTopTitleInfoTopMemberInfo = styled('h5')`
  text-align: left;
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const EditorTopTitleInfoBottomStarContainer = styled('div')`
  margin-left: -2px;
  margin-right: 12px;
  position: relative;
`;

export const EditorTopTitleInfoBottomStarInfoTextContainer = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0.25turn,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.85),
    rgba(255, 255, 255, 0.6)
  );
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditorTopTitleInfoBottomStarInfoText = styled('h4')``;

export const EditorTopTitleInfoBottomMiscContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 3px;
`;

export const SalaryText = styled('p')`
  color: ${(props) => props.theme.colors.text.musicalAqua};
`;

export const GenreIcon = styled(LibraryMusicIcon)`
  color: ${(props) => props.theme.colors.ui.primaryPurple};
  margin-right: 8px;
`;

export const GenreText = styled('p')``;

export const EditorTopTitleButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 235px;
  min-width: 235px;
  height: 100%;
`;

export const EditorCancelButton = styled('button')`
  width: 80px;
  height: 50px;
  background-color: white;
  border: 2px solid ${(props) => props.theme.colors.ui.primaryPurple};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.ui.primaryPurple};
  font-weight: bold;
  font-size: 15px;
  :hover {
    cursor: pointer;
  }
`;

export const EditorSubmitButton = styled(LoadingButton)`
  width: 150px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple};
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${(props) => props.theme.colors.ui.darkPurple};
  }
  :disabled {
    background-color: ${(props) => props.theme.colors.ui.cellGray};
    color: ${(props) => props.theme.colors.text.secondaryGray};
  }
`;

export const EditorTopMembersContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 175px;
  @media (max-width: 1100px) {
    width: 900px;
    height: 133px;
  }
`;

export const EditorTopMemberContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'doAnimation',
})<{
  doAnimation?: boolean;
}>`
  width: 128px;
  height: 175px;
  margin-right: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  @media (max-width: 1100px) {
    width: 96px;
    height: 133px;
  }
  animation: ${(props) =>
    props.doAnimation
      ? css`
          ${zoomIn} 0.5s ease
        `
      : ''};
`;

export const EditorTopMemberPictureContainer = styled('div')`
  position: relative;
  @media (max-width: 1100px) {
    width: 96px;
    height: 96px;
  }
  width: 128px;
  height: 128px;
`;

export const EditorTopMemberPicture = styled('img')`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;

export const EditorTopMemberRemoveButton = styled(Button)`
  position: absolute;
  top: 0px;
  right: -4px;
  height: 26px;
  min-width: 26px;
  color: black;
  :hover {
    background-color: transparent;
  }
`;

export const EditorTopMemberRemoveIcon = styled(CloseIcon)`
  height: 18px;
  width: 18px;
`;

export const EditorTopMemberText = styled('div')`
  width: 128px;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 1100px) {
    width: 96px;
  }
`;

// MIDDLE
export const EditorBodyElementsContainerMiddle = styled(
  EditorBodyElementsContainer,
)`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

export const EditorBodyElementsContainerMiddleSubContainer = styled('div')`
  padding: 24px;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const SelectionDropdownContainer = styled('div')`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SelectionDropdownTitle = styled('h4')`
  margin-bottom: 4px;
`;

export const SelectionDropdown = styled(Dropdown)``;

export const DropdownItemContainer = styled('div')`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
  margin-left: -6px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const DropdownItemContainerHeader = styled(DropdownItemContainer)`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
`;

export const DropdownItemImageContainer = styled('div')`
  width: 36px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.text.secondaryGray};
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownItemImageContainerClear = styled(
  DropdownItemImageContainer,
)`
  background-color: ${(props) => props.theme.colors.ui.cellGray};
`;

export const DropdownItemImageContainerOther = styled(
  DropdownItemImageContainer,
)`
  background-color: ${(props) => props.theme.colors.ui.lightPurple};
`;

export const ClearIcon = styled(LoopIcon)`
  width: 50%;
  height: 50%;
`;

export const DropdownIconImage = styled('img')`
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 6px;
`;

export const DropdownName = styled('p')``;

export const DropdownNameBlockstar = styled('div')`
  min-width: 100px;
  width: 50%;
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DropdownAbilityInfoContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  height: 36px;
`;

export const DropdownAbilityInfoName = styled('div')`
  width: 94px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  margin-right: 6px;
`;

export const DropdownAbilityInfoAbilityNumber = styled('h3')`
  width: 30px;
  text-align: right;
`;

// BOTTOM
export const EditorBodyElementsContainerBottom = styled(
  EditorBodyElementsContainer,
)`
  margin-bottom: 24px;
`;

export const ListTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  position: relative;
  table-layout: fixed;
`;

export const ListTableBodyTable = styled(Table)``;

export const ListTableHead = styled(TableHead)`
  position: sticky;
  top: 0px;
  z-index: 1;
`;

export const ListTableHeadRow = styled(TableRow)`
  background: linear-gradient(
    to top,
    ${(props) => props.theme.colors.ui.cellGray} 20%,
    white,
    white
  );
  height: 75px;
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

export const TableCellHeader = styled(TableCell)`
  border: 0px;
  position: sticky;
  top: 0px;
  overflow-y: clip;
`;

export const HidableTableCellHeader = styled(TableCellHeader, {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{
  maxWidth?: string;
}>`
  @media (max-width: 1364px) {
    display: none;
  }
`;

export const TableCellDefault = styled(TableCell)`
  border-left: 1px solid ${(props) => props.theme.colors.ui.lineGray};
  border-collapse: collapse;
  padding-left: 3px;
  padding-right: 3px;
`;

export const HidableTableCell = styled(TableCellDefault, {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{
  maxWidth?: string;
}>`
  overflow-y: clip;
  @media (max-width: 1364px) {
    display: none;
  }
`;

export const ListRowBlockstarContainer = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding-left: 5px;
`;

export const ListRowBlockstar = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const ListRowMainSkillHeaderRotator = styled('div')`
  width: 100%;
  height: 100%;
  transform-origin: bottom left;
  transform: skew(-50deg);
  padding: 0px;
`;

export const ListRowMainSkillHeaderTextContainer = styled('span')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transform: skew(50deg);
`;

export const ListRowMainSkillHeaderText = styled('p')`
  width: 75px;
  text-align: left;
  transform-origin: left;
  transform: translate(0%, 0%) rotate(-40.5deg);
`;

export const ButtonContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonInList = styled(Button)`
  height: 26px;
  width: 26px;
  :hover {
    background-color: transparent;
  }
  :disabled {
    color: ${(props) => props.theme.colors.ui.buttonOff};
  }
`;

export const AddMemberIcon = styled(AddBoxIcon)`
  :hover {
    color: ${(props) => props.theme.colors.ui.darkPurple};
  }
`;

export const AddMemberIconDisabled = styled(AddBoxIcon)`
  color: ${(props) => props.theme.colors.ui.unfilledGray};
`;

export const RemoveMemberIcon = styled(IndeterminateCheckBoxOutlinedIcon)``;

const zoomIn = keyframes`
  0% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
`;
