import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  InfoBody,
  InfoContainer,
  InfoHeader,
} from '../../../styles/global-style';
import analytics from '../../../utils/analytics';
import { MarketplaceButton } from './style';

export const NoRolInfo = () => {
  const currentPage = useLocation().pathname;
  return (
    <InfoContainer>
      <InfoHeader>$ROL Summary</InfoHeader>
      <InfoBody>Oops, you have no $ROL in this wallet! {`\n`}</InfoBody>
      <InfoBody>
        You need $ROL to pay your Blockstars their salaries{' '}
        <span style={{ fontStyle: 'italic' }}>or they won't work</span>. You can
        acquire $ROL on Raydium or other P2P marketplaces to get started. Once
        you have some, you can earn more $ROL by sending your Blockstars
        Busking.
      </InfoBody>
      <div style={{ margin: 8 }} />
      <MarketplaceButton
        onClick={() => {
          analytics.logEvent('OpenRaydium', {
            currentPage: currentPage,
          });
          window.open(
            'https://raydium.io/swap/?inputCurrency=sol&outputCurrency=RoLLn5qBN4juQ1D2KFpJyAcC7Deo3cYotXi4qDooHLU&outputAmount=0&fixed=in',
            '_blank',
          );
        }}>
        Visit Raydium
      </MarketplaceButton>
    </InfoContainer>
  );
};

export default NoRolInfo;
