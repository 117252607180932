import {
  ActionStatus,
  Band,
  BandActionData,
  BlockstarActionType,
} from '@shared-data';
import React from 'react';
import {
  AccordionActionButton,
  AccordionButtonGroup,
  AccordionDirection,
  AccordionToolTip,
} from 'src/components/library/accordion-button-group';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import { BlockstarActions } from 'src/hooks/blockstar-action';
import analytics from 'src/utils/analytics';
import { capitalizeFirstLetter } from 'src/utils/utils';

export const ActionAccordion = (props: {
  band: Band;
  style?: React.CSSProperties;
  bandAction?: BandActionData;
  blockstarActions?: BlockstarActions;
  viewBandAvailable?: boolean;
  accordionDirection?: AccordionDirection;
  elevation?: number;
  onChange?: () => void;
  onActionButtonClicked: (actionType: BlockstarActionType) => void;
}) => {
  const {
    setActionType,
    setBand,
    setOpenActionSelectDialog,
    setOpenActionProgressDialog,
  } = useBandActionControlContext() as IBandActionControl;

  const buttons: JSX.Element[] = [];
  const areAllBlockstarsFree =
    !props.blockstarActions ||
    Object.values(props.blockstarActions).filter(
      (action) =>
        action.actionType !== BlockstarActionType.BandPracticing &&
        action.actionType !== BlockstarActionType.BandGigging &&
        action.actionStatus >= ActionStatus.InAction,
    ).length <= 0;
  const disabled = !areAllBlockstarsFree || (props.band.notValid ?? false);

  const getTooltipText = (type: string) => {
    if (props.band.notValid) {
      return 'Band is in invalid state.';
    }
    if (!areAllBlockstarsFree) {
      return `One or more blockstars are doing other actions.`;
    }
    return `${type} is not available yet.`;
  };

  if (props.viewBandAvailable) {
    buttons.push(
      <AccordionActionButton
        title={'View band'}
        key={BlockstarActionType.View}
        actionType={BlockstarActionType.View}
        onButtonClicked={props.onActionButtonClicked}
      />,
    );
  }

  const onActionButtonClicked = (type: BlockstarActionType) => {
    setBand(props.band);
    setActionType(type);
    if (props.bandAction) {
      switch (props.bandAction.actionStatus) {
        case ActionStatus.InAction:
          setOpenActionProgressDialog(true);
          break;
        case ActionStatus.Completed:
          setOpenActionProgressDialog(true);
          break;
      }
    } else {
      setOpenActionSelectDialog(true);
    }

    analytics.logEvent('ClickAction', {
      action: type,
      bandId: props.band.id,
      currentStatus: props.bandAction?.actionStatus,
    });

    props.onActionButtonClicked(type);
  };

  buttons.push.apply(buttons, [
    <AccordionActionButton
      title={'Edit band'}
      key={BlockstarActionType.Edit}
      actionType={BlockstarActionType.Edit}
      onButtonClicked={props.onActionButtonClicked}
    />,
    <AccordionActionButton
      title={actionButtonText(disabled, props.bandAction)}
      key={BlockstarActionType.BandPracticing}
      actionType={BlockstarActionType.BandPracticing}
      onButtonClicked={onActionButtonClicked}
      disabled={disabled}
      tooltip={AccordionToolTip(
        getTooltipText(BlockstarActionType.BandPracticing),
      )}
    />,
    // TODO: Ki, We will need it later.
    // <AccordionActionButton
    //   title={'Gig'}
    //   key={BlockstarActionType.BandGigging}
    //   actionType={BlockstarActionType.BandGigging}
    //   onButtonClicked={props.onActionButtonClicked}
    //   //TODO: Ki, Gigging
    //   // disabled={!canRunAction}
    //   disabled={true}
    //   tooltip={AccordionToolTip(tooltipText(BlockstarActionType.Gigging))}
    // />,
  ]);

  return (
    <AccordionButtonGroup
      style={props.style}
      onChange={props.onChange}
      elevation={props.elevation}
      buttons={buttons}
      accordionDirection={props.accordionDirection ?? AccordionDirection.UP}
    />
  );
};

const actionButtonText = (buttonDisabled: boolean, action?: BandActionData) => {
  let result = 'Practice';

  if (!buttonDisabled) {
    if (action) {
      switch (action.actionStatus) {
        case ActionStatus.InAction:
          result = `${capitalizeFirstLetter(
            action.actionType,
          )} - Check progress`;
          break;
        case ActionStatus.Completed:
          result = `${capitalizeFirstLetter(
            action.actionType,
          )} complete - View details`;
          break;
      }
    }
  }
  return result;
};
