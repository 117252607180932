import { RoutePath } from '@shared-data';
import { useWallet } from '@solana/wallet-adapter-react';
import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import rest from 'src/actions/network/rest';
import { isBrave } from 'src/utils/utils';
import AnnouncementDialog from './announcement/announcement-dialog';
import { BandDetails } from './bands/details';
import BandEditor from './bands/editor';
import BandsMain from './bands/main';
import Details from './details';
import HomePage from './home';
import RafflePage from './raffle';
import { UnityCharacter } from './test/unity-character';
import FungibleTokenSummary from './tokens/ft';
import NFTs from './tokens/nft';

const AppRouter = () => {
  const { connected } = useWallet();
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Router>
        <AppRoutes />
      </Router>
      {connected && <AnnouncementDialog />}
    </div>
  );
};

const AppRoutes = () => {
  const { connected, publicKey } = useWallet();
  if (publicKey) {
    rest.init(publicKey.toString());
  }
  const location = useLocation();
  const locationState = location.state as any;
  return (
    <Routes>
      <Route
        path={RoutePath.Home}
        element={
          !connected || isBrave ? (
            <HomePage />
          ) : (
            <Navigate
              to={locationState?.originPage ?? RoutePath.Blockstars}
              state={{ originPage: RoutePath.Home }}
              replace={true}
            />
          )
        }
      />
      <Route
        path={RoutePath.Tokens}
        element={
          connected ? (
            <FungibleTokenSummary />
          ) : (
            <Navigate
              to={RoutePath.Home}
              state={{ originPage: RoutePath.Tokens }}
            />
          )
        }
      />
      <Route
        path={RoutePath.Raffle}
        element={
          connected ? (
            <RafflePage />
          ) : (
            <Navigate
              to={RoutePath.Home}
              state={{ originPage: RoutePath.Raffle }}
            />
          )
        }
      />
      <Route
        path={RoutePath.Blockstars}
        element={
          connected ? (
            <NFTs />
          ) : (
            <Navigate
              to={RoutePath.Home}
              state={{ originPage: RoutePath.Blockstars }}
            />
          )
        }
      />
      <Route path={`${RoutePath.Blockstars}/:id`} element={<Details />} />
      <Route
        path={RoutePath.Bands}
        element={
          connected ? (
            <BandsMain />
          ) : (
            <Navigate
              to={RoutePath.Home}
              state={{ originPage: RoutePath.Bands }}
            />
          )
        }
      />
      <Route
        path={RoutePath.BandsEdit}
        element={
          connected && locationState && !isBrave ? (
            <BandEditor />
          ) : (
            <Navigate
              to={RoutePath.Home}
              state={{ originPage: RoutePath.Bands }}
            />
          )
        }
      />
      <Route
        path={`${RoutePath.Bands}/:id`}
        element={
          !isBrave ? (
            <BandDetails />
          ) : (
            <Navigate
              to={RoutePath.Home}
              state={{ originPage: RoutePath.Bands }}
            />
          )
        }
      />

      {process.env.TARGET_ENV !== 'production' && (
        <Route path='/test/unity-character' element={<UnityCharacter />} />
      )}
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

const NotFound = () => {
  return (
    <div>
      <h2 style={{ marginBottom: 0 }}>404</h2>
      <h4 style={{ marginTop: 0 }}>Page Not Found!</h4>
    </div>
  );
};

export default AppRouter;
