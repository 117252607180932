import LockIcon from '@mui/icons-material/Lock';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { TableCell, TableRow, useTheme } from '@mui/material';
import {
  ActionStatus,
  Band,
  BandMemberSkill,
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
  BlockstarsSortKeyMap,
  SkillInfo,
  SkillType,
  SubgenreInfo,
  ThemeType,
} from '@shared-data';
import { Genres, Subgenre } from '@shared-generated/generated-genres';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onViewButtonClicked } from 'src/actions/action';
import { onFireButtonClicked } from 'src/actions/ngmi';
import TableContext, {
  ColumnState,
} from 'src/components/library/list-table/table-context';
import { NewBadge } from 'src/components/library/new';
import { InfoNumber } from 'src/components/library/style';
import Stars from 'src/components/star-rating';
import {
  ListRowMusical,
  ListRowNew,
  ListRowSkill,
} from 'src/components/tokens/nft/style';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import { useAllBlockstarActions } from 'src/hooks/blockstar-action';
import {
  IBlockstarActionControl,
  useBlockstarActionControlContext,
} from 'src/hooks/blockstar-action-control';
import { useRolBalance } from 'src/hooks/wallet/rol-balance';
import {
  blockstarsSortComparator,
  getBlockstarName,
  shouldShowNew,
} from 'src/utils/utils';
import {
  AddMemberIcon,
  ButtonContainer,
  ButtonInList,
  HidableTableCell,
  ItemInfoNumberContainer,
  ListRowBlockstar,
  ListRowBlockstarContainer,
  RemoveMemberIcon,
  TableCellDefault,
} from '../style';
import BlockstarsTableActionButton from './blockstars-table-action-button';
import BlockstarsTableCheckbox from './blockstars-table-checkbox';

interface TableBodyProps {
  blockstars: Blockstar[];
  clickableBlockstar: boolean;
  // My Blockstars
  crates?: any[];
  updateBlockstar?: (id: number) => void;
  // Bands
  bands?: Band[];
  memberIds?: number[];
  genre?: Genres;
  subGenre?: Subgenre;
  subGenreInfo?: SubgenreInfo;
  onMemberAdded?: (
    memberId: number,
    slotIndex?: number,
    skillInfo?: BandMemberSkill,
    isMusical?: boolean,
  ) => void;
  onMemberRemoved?: (
    memberId: number,
    slotIndex?: number,
    isMusical?: boolean,
  ) => void;
}

interface IProps {
  props: TableBodyProps;
}

const baseUrl = process.env.ASSET_BASE_URL || '';

const BlockstarsTableRows: FC<TableBodyProps> = ({
  blockstars,
  bands,
  memberIds,
  subGenre,
  subGenreInfo,
  clickableBlockstar,
  crates,
  onMemberAdded,
  onMemberRemoved,
  updateBlockstar,
}: TableBodyProps) => {
  const { actions, mutate } = useAllBlockstarActions(
    blockstars?.map((b) => b.number) ?? [],
  );
  const actionValues = Object.values(actions ?? {});

  // Table ctx - table.tsx
  const {
    listToggle,
    selectedColumn,
    rowsChecked,
    setRowsChecked,
    selectAllDisabled,
    setSelectAllDisabled,
  } = useContext(TableContext);

  // Local hooks
  const [open, setOpen] = useState(false);
  const [ngmiOpen, setNGMIOpen] = useState(false);
  const [buttonsExpanded, setButtonsExpanded] = useState<boolean[]>([]);
  const [members, setMembers] = useState<Blockstar[]>();

  const {
    actionType,
    setActionType: setBlockstarActionType,
    blockstar,
    setBlockstar,
    setOpenActionSelectDialog: setOpenBlockstarActionSelectDialog,
    openActionProgressDialog: openBlockstarActionProgressDialog,
    setOpenActionProgressDialog: setOpenBlockstarActionProgressDialog,
    actionResult: blockstarActionResult,
  } = useBlockstarActionControlContext() as IBlockstarActionControl;

  const {
    setActionType: setBandActionType,
    setBand,
    setOpenActionProgressDialog: setOpenBandActionProgressDialog,
    actionResult: bandActionResult,
  } = useBandActionControlContext() as IBandActionControl;

  useEffect(() => {
    if (memberIds) {
      const members = memberIds.map(
        (memberId) =>
          memberId != undefined &&
          blockstars &&
          blockstars.find((blockstar) => blockstar.number === memberId),
      ) as Blockstar[];
      setMembers(members);
    }
  }, [blockstars, memberIds]);

  useEffect(() => {
    if (blockstars && setRowsChecked) {
      // init states to false
      setRowsChecked(
        rowsChecked?.find((checked) => checked === true)
          ? rowsChecked
          : Array(blockstars.length).fill(false),
      );
      setButtonsExpanded(Array(blockstars.length).fill(false));
    }
  }, [blockstars]);

  useEffect(() => {
    if (setSelectAllDisabled) {
      setSelectAllDisabled(
        !!(
          actions &&
          actionValues
            .map(
              (action) => action && action.actionStatus !== ActionStatus.None,
            )
            .reduce((prev, curr) => prev && curr, true)
        ),
      );
    }
  }, [actionValues]);

  useEffect(() => {
    if (bandActionResult || blockstarActionResult) {
      mutate();
    }
  }, [bandActionResult, blockstarActionResult]);

  const onActionsButtonClicked = async (
    blockstar: Blockstar,
    actionType: BlockstarActionType,
  ) => {
    switch (actionType) {
      case BlockstarActionType.View:
        onViewButtonClicked(blockstar.number, navigate);
        break;
      case BlockstarActionType.BandPracticing:
      case BlockstarActionType.BandGigging:
        onActionStatusDetail(blockstar);
        break;
      default:
        break;
    }

    setButtonsExpanded(Array(blockstars.length).fill(false));
  };

  const onActionStatusDetail = (blockstar: Blockstar) => {
    if (actions) {
      const action = actions[blockstar.number];
      switch (action?.actionType) {
        case BlockstarActionType.BandPracticing:
        case BlockstarActionType.BandGigging:
          setBandActionType(action?.actionType);
          const band = bands?.find((band) =>
            band.memberIds.includes(blockstar.number),
          );
          setBand(band);
          setOpenBandActionProgressDialog(true);
          break;
        default:
          setBlockstarActionType(
            action?.actionType ?? BlockstarActionType.Practicing,
          );
          setBlockstar(blockstar);
          setOpenBlockstarActionProgressDialog(true);
          break;
      }
    }
  };

  // const viewButtonClicked = (blockstarId: number) => {
  //   if (clickableBlockstar) {
  //     onViewButtonClicked(blockstarId, navigate);
  //   }
  // };

  const navigate = useNavigate();
  const theme = useTheme() as ThemeType;
  const bgColor = theme.colors.ui.cellGray;

  const sortKeyMap: BlockstarsSortKeyMap = {
    default: 'number',
    salary: 'wage.salary',
    music1: '0.currentAbilities',
    music2: '1.currentAbilities',
    music3: '2.currentAbilities',
  };

  const blockstarsSorted =
    selectedColumn.state === ColumnState.descending
      ? blockstars.sort((a, b) =>
          blockstarsSortComparator(a, b, selectedColumn.id, sortKeyMap),
        )
      : blockstars.sort((a, b) =>
          blockstarsSortComparator(b, a, selectedColumn.id, sortKeyMap),
        );

  const rows = blockstarsSorted.map((blockstar, idx) => {
    const blockstarInOtherBands = bands?.find((band) => {
      if (band.subgenre !== subGenre) {
        return band.memberIds.find((memberId) => memberId === blockstar.number);
      }
      return undefined;
    });

    // My Blockstars
    // checkbox
    const onCheckChange = () => {
      if (setRowsChecked) {
        return setRowsChecked((prev) => {
          const checks = [...prev];
          checks[idx] = !prev[idx];
          return checks;
        });
      }
    };

    // action bar
    const { publicKey, signTransaction, signAllTransactions } = useWallet();
    const { connection } = useConnection();
    const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);
    const buttonOnChange = () => {
      const newState = !buttonsExpanded[idx];
      setButtonsExpanded((prev) => {
        const curr = Array(blockstars.length).fill(false);
        curr[idx] = newState;
        return curr;
      });
    };

    const onFireClick = (blockstar: Blockstar) => {
      return () =>
        onFireButtonClicked(blockstar, setBlockstar, () => {
          setButtonsExpanded(Array(blockstars.length).fill(false));

          setNGMIOpen(true);
        });
    };

    // Bands
    const buttonClicked = (isAdding: boolean) => {
      isAdding
        ? onMemberAdded?.(blockstar.number)
        : onMemberRemoved?.(blockstar.number);
    };
    const blockstarInMembers = members?.find(
      (member) => member.number === blockstar.number,
    );

    const isFull =
      members &&
      (members.length === subGenreInfo?.requirements.musicalSkills.length ||
        members.length === subGenreInfo?.requirements.maxMembers);

    const musicalSkills: any[] = blockstar.skills.filter(
      (s) => s.type === SkillType.Instrumental,
    );
    const generalSkills = blockstar.skills.filter(
      (s) => s.type === SkillType.General,
    );

    const empty = Array(3 - musicalSkills.length).fill(0);
    const band = bands?.find((band) =>
      band?.memberIds.includes(blockstar.number),
    );

    return (
      <TableRow
        key={blockstar.number}
        style={{
          backgroundColor: idx % 2 ? bgColor : undefined,
          position: 'relative',
          height: '76px',
        }}>
        {listToggle && (
          <TableCell padding='none' style={{ width: '42px' }}>
            <BlockstarsTableCheckbox
              action={actions ? actions[blockstar.number] : undefined}
              checked={rowsChecked ? rowsChecked[idx] : false}
              onCheckChange={onCheckChange}
              onActionsButtonClicked={() => {
                onActionStatusDetail(blockstar);
              }}
              onCountDownComplete={mutate}
            />
          </TableCell>
        )}

        <TableCell padding='checkbox' style={{ width: '220px' }}>
          <ListRowBlockstarContainer
            style={{ cursor: clickableBlockstar ? 'pointer' : undefined }}
            onClick={() => {
              onActionsButtonClicked(blockstar, BlockstarActionType.View);
            }}>
            <img
              src={`${baseUrl}/${blockstar.number}.png`}
              width={52}
              height={52}
              style={{ borderRadius: 8 }}
            />
            <ListRowBlockstar>
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                  width: '90%',
                }}>
                {getBlockstarName(blockstar)}
              </span>
              <ItemInfoNumberContainer>
                <InfoNumber>{`#${blockstar.number}`}</InfoNumber>
                {/*  */}
                {band && (
                  <InfoNumber>
                    <PeopleAltIcon style={{ fontSize: '16px' }} />
                    {`#${band.id}`}
                  </InfoNumber>
                )}
              </ItemInfoNumberContainer>
              <Stars
                currentStars={blockstar.starRatingInfo.currentStarRating}
                potentialStars={blockstar.starRatingInfo.maxStarRating}
                starSize={16}
              />
            </ListRowBlockstar>
          </ListRowBlockstarContainer>
        </TableCell>
        <TableCellDefault padding='checkbox' style={{ width: '99px' }}>
          <ListRowSkill>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.colors.text.musicalAqua,
                height: '55px',
              }}>
              <h5>
                {`${blockstar.wage.salary} | ${Math.floor(
                  100 * blockstar.wage.percentCut,
                )}%`}
              </h5>
              <h5>{blockstar.wage.bucket}</h5>
            </div>
          </ListRowSkill>
        </TableCellDefault>
        {musicalSkills.map((val, idx) => {
          const unlocked = val.name?.length > 0;
          const isNew = shouldShowNew(val);

          return (
            <TableCellDefault
              key={idx}
              padding='checkbox'
              style={{ width: listToggle ? '99px' : '94px' }}>
              <MusicalContainer new={isNew}>
                <ListRowMusical
                  style={{
                    color: unlocked
                      ? theme.colors.text.musicalAqua
                      : theme.colors.text.secondaryGray,
                  }}>
                  {isNew && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: -9,
                      }}>
                      <NewBadge />{' '}
                    </div>
                  )}
                  <span style={{ fontSize: '16px' }}>
                    {unlocked ? (
                      val.currentAbilities
                    ) : (
                      <LockIcon fontSize='inherit' />
                    )}
                  </span>
                  <span style={{ fontSize: '12px' }}>
                    {unlocked ? val.name : 'N/A'}
                  </span>
                </ListRowMusical>
              </MusicalContainer>
            </TableCellDefault>
          );
        })}
        {getEmptyCells(empty, listToggle)}
        {getGeneralSkills(generalSkills, theme, listToggle)}
        {/* Action Bar */}
        {listToggle && (
          <TableCellDefault padding='checkbox' style={{ width: '188px' }}>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexGrow: 1,
                height: 63,
                marginTop: 12,
                justifyContent: 'center',
              }}>
              <BlockstarsTableActionButton
                blockstar={blockstar}
                action={actions ? actions[blockstar.number] : undefined}
                onActionsButtonClicked={onActionsButtonClicked}
                expanded={!!buttonsExpanded[idx]}
                onChange={buttonOnChange}
                elevation={2}
                style={{
                  width: 120,
                  top: 0,
                  bottom: undefined,
                }}
                onFireButtonClicked={onFireClick(blockstar)}
                fireButtonDisabledText={fireButtonDisabled(
                  blockstars,
                  blockstar,
                  actions || [],
                  idx,
                  rolBalance,
                )}
              />
            </div>
          </TableCellDefault>
        )}
        {/* Toggle */}
        {!listToggle && (
          <TableCellDefault
            padding='checkbox'
            style={{ width: '7%', minWidth: '60px' }}>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexGrow: 1,
                height: 63,
                justifyContent: 'center',
              }}>
              <ButtonContainer>
                <ButtonInList
                  onClick={() =>
                    buttonClicked(blockstarInMembers === undefined)
                  }
                  disabled={
                    (!blockstarInMembers && isFull) ||
                    blockstarInOtherBands !== undefined
                  }>
                  {blockstarInMembers ? (
                    <RemoveMemberIcon />
                  ) : (
                    <AddMemberIcon />
                  )}
                </ButtonInList>
              </ButtonContainer>
            </div>
          </TableCellDefault>
        )}
      </TableRow>
    );
  });

  // Add empty row, so we can give enough padding inside the table.
  const emptyRow = (
    <TableRow
      key='empty'
      style={{
        backgroundColor: blockstars.length % 2 ? bgColor : undefined,
        height: '76px',
      }}
    />
  );

  // const overlays = (
  //   <TableRow key='overlays'>
  //     {blockstar && (
  //       <Actions
  //         blockstar={blockstar}
  //         open={open}
  //         actionType={actionType}
  //         onClose={() => {
  //           setOpen(false);
  //           updateBlockstar?.(blockstar.number);
  //           setBlockstar(undefined);
  //         }}
  //         action={actions ? actions[blockstar.number] : undefined}
  //         mutate={mutate}
  //       />
  //     )}
  //     {blockstar && blockstars && (
  //       <NGMI
  //         blockstar={blockstar}
  //         blockstars={blockstars}
  //         open={ngmiOpen}
  //         onClose={() => {
  //           setNGMIOpen(false);
  //           updateBlockstar?.(blockstar.number);
  //         }}
  //       />
  //     )}
  //   </TableRow>
  // );

  return <>{rows.concat([emptyRow])}</>;
};

const fireButtonDisabled = (
  allBlockstars: Blockstar[],
  blockstar: Blockstar,
  actions: {
    [key: number]: BlockstarActionResponse;
  },
  idx: number,
  rolBalance: number,
) => {
  if (rolBalance < blockstar.wage.salary * 120) {
    return `You need at least ${
      blockstar.wage.salary * 120
    } ROL to fire this Blockstar.`;
  }
  const idleBlockstars = Object.values(actions).find(
    (action, index) =>
      index !== idx && action?.actionStatus === ActionStatus.None,
  );

  if (
    actions[blockstar.number]?.actionStatus !== ActionStatus.None ||
    !idleBlockstars
  ) {
    return actions[blockstar.number]?.actionStatus !== ActionStatus.None
      ? 'Blockstar must be idle to be fired.'
      : 'Must have an idle blockstar available to transfer experience to.';
  }

  return undefined;
};

const getEmptyCells = (empty: any[], listToggle: boolean) => {
  return empty.map((val, idx) => {
    return (
      <TableCellDefault
        key={idx}
        padding='checkbox'
        style={{ width: listToggle ? '99px' : '94px' }}>
        <ListRowSkill />
      </TableCellDefault>
    );
  });
};

const getGeneralSkills = (
  generalSkills: SkillInfo[],
  theme: ThemeType,
  listToggle: boolean,
) => {
  return generalSkills.map((val, idx) => {
    return (
      <HidableTableCell
        key={idx}
        padding='checkbox'
        style={{ width: listToggle ? '74px' : '50px' }}>
        <ListRowSkill
          style={{
            color: theme.colors.ui.primaryPurple,
            fontSize: '16px',
          }}>
          {val.currentAbilities}
        </ListRowSkill>
      </HidableTableCell>
    );
  });
};

const MusicalContainer = (props: { new?: boolean; children: any }) => {
  return props.new ? (
    <ListRowNew>{props.children}</ListRowNew>
  ) : (
    <div>{props.children}</div>
  );
};

export default BlockstarsTableRows;
