import {
  ActionStatus,
  Band,
  BandActionData,
  BandActionEndResponse,
  BandActionResponse,
  bandActionStartTime,
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
  blockstarNameRegex,
  BlockstarSkillType,
  BlockstarsSortKeyMap,
  GenreData,
  NameChangeNotAvailableReason,
  nameChangeTimeLimit,
  SkillInfo,
  SkillType,
  WageInfo,
} from '@shared-data';
import {
  instrumentsData,
  PerformanceSkillType,
} from '@shared-generated/generated-instruments';
import genreDataJson from '@shared-json/genres.json';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PublicKey } from '@solana/web3.js';
import Bottleneck from 'bottleneck';
import { CountdownRenderProps, zeroPad } from 'react-countdown';
import rest, { BandActionPath } from 'src/actions/network/rest';
import { SkillEntryType } from 'src/components/library/types';

export const px2vw = (size: number, width = 1440) =>
  `${(size / width) * 100}vw`;

export const timeoutPromise = <T>(
  ms: number,
  promise: Promise<T>,
): Promise<T> => {
  return new Promise<T>(async (resolve, reject) => {
    let timeoutId: NodeJS.Timeout | undefined;
    timeoutId = setTimeout(() => {
      timeoutId = undefined;
      reject(new Error('Connection timeout. Please try again.'));
    }, ms);

    try {
      const promiseResult = await promise;
      if (timeoutId) {
        clearTimeout(timeoutId);
        resolve(promiseResult);
      }
    } catch (error) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        reject(error);
      }
    }
  });
};

export const getSolNetwork = (solEnv?: string) => {
  switch (solEnv) {
    case WalletAdapterNetwork.Devnet.toString():
      return WalletAdapterNetwork.Devnet;
    case WalletAdapterNetwork.Testnet.toString():
      return WalletAdapterNetwork.Testnet;
    case WalletAdapterNetwork.Mainnet.toString():
      return WalletAdapterNetwork.Mainnet;
    default:
      return WalletAdapterNetwork.Devnet;
  }
};

// process.env.SOLANA_ENV

export const getDateTimeString = (date: Date, withTime = true) => {
  let options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  if (withTime) {
    options = {
      ...options,
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
  }
  return date.toLocaleString('sv-SE', options); // Swedish format
};

export const getROLBalanceString = (rolBalance: number) => {
  return `${rolBalance.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: parseInt(process.env.ROL_DECIMAL!),
  })} $ROL`;
};

export const limiter = new Bottleneck({
  maxConcurrent: 50,
  minTime: 20,
});

export const numberFormatter = (num: number) => {
  const isNegative = num < 0;
  const absoluteNum = Math.abs(num);
  let result: string | number = 0;
  if (absoluteNum < 900) {
    result = num;
  } else if (absoluteNum < 1000000) {
    result = `${absoluteNum / 1000}K`;
  } else if (absoluteNum < 1000000000) {
    result = `${absoluteNum / 1000000}M`;
  } else {
    result = `${absoluteNum / 1000000000}B`;
  }
  return (isNegative ? '-' : '') + result;
};

export enum TransactionLinkType {
  SOLSCAN = 'Solscan',
  SOLANA_EXPLORER = 'Solana_Explorer',
}

export const scanEnvString =
  process.env.SOLANA_ENV !== WalletAdapterNetwork.Mainnet
    ? `?cluster=${process.env.SOLANA_ENV}`
    : '';

export const getTransactionLink = (
  signature: string,
  envString: string,
): string => {
  switch (process.env.TRANSACTION_LINK_SERVICE) {
    case TransactionLinkType.SOLANA_EXPLORER:
      return `https://explorer.solana.com/tx/${signature}${envString}`;
    default:
      return `https://solscan.io/tx/${signature}${envString}`;
  }
};

export const getAccountLink = (
  signature: string,
  envString: string,
): string => {
  switch (process.env.TRANSACTION_LINK_SERVICE) {
    case TransactionLinkType.SOLANA_EXPLORER:
      return `https://explorer.solana.com/account/${signature}${envString}`;
    default:
      return `https://solscan.io/account/${signature}${envString}`;
  }
};

export const getOriginPage = (state: any) => {
  let originPage = state?.originPage;
  if (!originPage) {
    originPage = document.referrer;
  }
  return originPage;
};

export const truncateString = (origin: string) => {
  return `${origin.slice(0, 4)}...${origin.slice(origin.length - 4)}`;
};

export const getUnixTs = () => {
  return new Date().getTime() / 1000;
};

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getStringFromWage = (wage: WageInfo) => {
  return `${wage.salary.toLocaleString()} | ${wage.bucket} (${Math.floor(
    wage.percentCut * 100,
  )}% cut)`;
};

export const canRunAction = (blockstarId: number) => {
  return (
    process.env.BUSKING_ORIGIN_ONLY === 'false' ||
    (process.env.BUSKING_ORIGIN_ONLY === 'true' && blockstarId < 110)
  );
};

export enum StorageType {
  ACTION_OPTIONS = 'action-options-',
  BAND_ACTION_OPTIONS = 'band-action-options-',
  LIST_LAYOUT = 'list-layout-',
  ANNOUNCEMENT_VIEW = 'announcement-seen-',
}

export const getLocalStorage = (type: StorageType, id: number) => {
  return localStorage.getItem(`${type}${id}`);
};

export const setLocalStorage = (
  type: StorageType,
  id: number,
  data: string,
) => {
  localStorage.setItem(`${type}${id}`, data);
};

export const isInAction = (actionStatus?: ActionStatus) => {
  return (
    actionStatus != undefined &&
    (actionStatus === ActionStatus.InAction ||
      actionStatus === ActionStatus.Completed ||
      actionStatus === ActionStatus.Rewarding)
  );
};

export const calculateProgress = (
  current: number,
  total: number,
  inInteger: boolean,
) => {
  if (total === 0) {
    return 0;
  }
  const progress = (100 * current) / total;
  if (inInteger) {
    return Math.floor(progress);
  }

  return progress;
};

export const shouldShowNew = (skill: SkillInfo) => {
  if (skill.unlocked) {
    if (skill.new !== undefined) {
      return skill.new;
    }
    return (
      skill.unlockedTimestamp !== undefined &&
      Date.now() - skill.unlockedTimestamp < 86400000
    );
  }
  return false;
};

export const nameAvailable = (name: string) => {
  return blockstarNameRegex.exec(name);
};

export const getBlockstarName = (
  target: Blockstar,
  withNumberIfNotEdited = false,
) => {
  return (
    target.customName?.name ??
    (withNumberIfNotEdited ? target.name : 'Blockstar')
  );
};

export const genreData = JSON.parse(JSON.stringify(genreDataJson)) as GenreData;

export const getBandName = (target: Band) => {
  return (
    target.customName?.name ??
    `${
      genreData[target.genre].subgenres[target.subgenre.toString()].displayName
    } Band`
  );
};

export const skillSort = (
  blockstarA?: Blockstar,
  blockstarB?: Blockstar,
  targetSkillName?: string,
  byFamily?: boolean,
) => {
  const aSkill = blockstarA?.skills.find((skill) =>
    byFamily
      ? skill.familyName === targetSkillName
      : skill.name === targetSkillName,
  );
  const bSkill = blockstarB?.skills.find((skill) =>
    byFamily
      ? skill.familyName === targetSkillName
      : skill.name === targetSkillName,
  );
  if (!aSkill || !bSkill) return 0;
  if (aSkill.currentAbilities > bSkill.currentAbilities) return -1;
  if (aSkill.currentAbilities < bSkill.currentAbilities) return 1;
  return 0;
};

function getDescendantProp(obj: any, orderBy: string): number {
  let container: any;
  const value = orderBy.split('.').reduce((a, b) => {
    if (a[b] === undefined) {
      return 0;
    }

    return a[b];
  }, obj);

  return value;
}

export const blockstarsSortComparator = <T>(
  a: Blockstar,
  b: Blockstar,
  selectedColumn: string,
  sortKeyMap: BlockstarsSortKeyMap,
) => {
  const propertyCrawler =
    sortKeyMap[selectedColumn as keyof BlockstarsSortKeyMap];

  let valueA;
  let valueB;
  // Musical Skills
  if (selectedColumn.includes('music')) {
    let musicalSkillsA: SkillInfo[] = a.skills.filter(
      (s) => s.type === SkillType.Instrumental,
    );
    let musicalSkillsB: SkillInfo[] = b.skills.filter(
      (s) => s.type === SkillType.Instrumental,
    );

    musicalSkillsA = [
      ...musicalSkillsA,
      ...Array(3 - musicalSkillsA.length).fill({ currentAbilities: -1 }),
    ];
    musicalSkillsB = [
      ...musicalSkillsB,
      ...Array(3 - musicalSkillsB.length).fill({ currentAbilities: -1 }),
    ];

    valueA = getDescendantProp(musicalSkillsA, propertyCrawler);
    valueB = getDescendantProp(musicalSkillsB, propertyCrawler);
  }
  // Generl Skills
  else if (
    Object.values(BlockstarSkillType).some((skill) =>
      selectedColumn.includes(skill),
    )
  ) {
    valueA = a.skills.filter((s) => s.name === selectedColumn)[0]
      .currentAbilities;
    valueB = b.skills.filter((s) => s.name === selectedColumn)[0]
      .currentAbilities;
  }
  // All others
  else {
    valueA = getDescendantProp(a, propertyCrawler);
    valueB = getDescendantProp(b, propertyCrawler);
  }

  if (valueA !== undefined && valueB !== undefined) {
    // If equal values, sort by blockstar number
    if (valueB === valueA) {
      valueA = a.number;
      valueB = b.number;
    }
    // Descending sort
    if (valueB < valueA) {
      return -1;
    }
    if (valueB > valueA && b.number > a.number) {
      return 1;
    }
  }
  return 0;
};

export const checkOriginHoldingStatus = (allBlockstars: Blockstar[]) => {
  return allBlockstars.some((elem) => elem.number < 110);
};

export const canBlockstarChangeName = (
  blockstar?: Blockstar,
  publicKey?: PublicKey | null,
  owner?: string,
) => {
  let canChangeName = false;
  let reason: NameChangeNotAvailableReason = NameChangeNotAvailableReason.None;

  if (blockstar?.fired) {
    reason = NameChangeNotAvailableReason.Fired;
  } else if (!publicKey || owner !== publicKey.toString()) {
    reason = NameChangeNotAvailableReason.NotMine;
  } else if (blockstar) {
    if (blockstar.customName) {
      const nameInfo = blockstar.customName;
      const nextAvailTime = new Date(
        (nameInfo.timestamp ?? 0) + nameChangeTimeLimit,
      );
      if (Date.now() >= nextAvailTime.getTime()) {
        canChangeName = true;
      } else {
        reason = NameChangeNotAvailableReason.InCooldown;
      }
    } else {
      canChangeName = true;
    }
  }

  return { canEdit: canChangeName, reason };
};

export const getBandCollegeColumnRowCount = (memberCount: number) => {
  if (memberCount / 3 < 0.5) {
    return 1; // only 1 member
  }
  if (memberCount / 3 < 1.5) {
    return 2; // less than 5 members
  }
  return 3; // more than 4 members
};

export const bandMembers = (memberIds: number[], blockstars?: Blockstar[]) => {
  return memberIds.map(
    (memberId) =>
      memberId != undefined &&
      blockstars &&
      blockstars.find((blockstar) => blockstar.number === memberId),
  ) as Blockstar[];
};

export const bandSalaryCutMember = (members: Blockstar[]) => {
  let member;
  if (members.length > 0) {
    member = members.reduce((prev, current) => {
      return prev.wage.percentCut > current.wage.percentCut ? prev : current;
    });
  }
  return member;
};

export const bandSalary = (members: Blockstar[]) => {
  return members.reduce(
    (accumulator, currentObj) => accumulator + currentObj.wage.salary,
    0,
  );
};

export const canBandChangeName = (
  band?: Band,
  publicKey?: PublicKey | null,
  owner?: string,
) => {
  let canChangeName = false;
  let reason: NameChangeNotAvailableReason = NameChangeNotAvailableReason.None;

  if (band?.disbandedAt != undefined) {
    reason = NameChangeNotAvailableReason.Fired;
  } else if (!publicKey || owner !== publicKey.toString()) {
    reason = NameChangeNotAvailableReason.NotMine;
  } else if (band) {
    if (band.customName) {
      const nameInfo = band.customName;
      const nextAvailTime = new Date(
        (nameInfo.timestamp ?? 0) + nameChangeTimeLimit,
      );
      if (Date.now() >= nextAvailTime.getTime()) {
        canChangeName = true;
      } else {
        reason = NameChangeNotAvailableReason.InCooldown;
      }
    } else {
      canChangeName = true;
    }
  }

  return { canEdit: canChangeName, reason };
};

export const getSkillName = (namedId: string, skillType: SkillEntryType) => {
  // We originally used name as id, but we have id / name field now, so we need to get actual name.
  if (skillType === SkillEntryType.MUSICAL) {
    instrumentsData[namedId as PerformanceSkillType]?.name ?? '';
  }
  return namedId;
};

export const capitalizeFirstLetter = (str: string) => {
  const result = str.toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const fullTimeCountdownRenderer = ({
  completed,
  days,
  hours,
  minutes,
  seconds,
  total,
}: CountdownRenderProps) => {
  if (completed) {
    return 'Completed!';
  }
  return `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(
    seconds,
  )} remaining`;
};

export const getBandActionResults = async (
  bandId?: string,
  bandAction?: BandActionResponse,
) => {
  const result = (await rest.post(
    `${process.env.WORKER_URL}/${BandActionPath.End}`,
    {
      bandId,
      actionType: bandAction?.action?.actionType,
      startTimeMs: bandAction?.action?.startTimeMs,
    },
  )) as BandActionEndResponse;
  return result;
};

export const truncateName = (origin: string) => {
  return origin.length > 10 ? `${origin.slice(0, 10)}...` : origin;
};

export const getTimeLeft = (
  action?: BlockstarActionResponse | BandActionData,
) => {
  if (action) {
    let startTimeMs =
      (action as BlockstarActionResponse).actionStartTimeMs ?? 0;
    if (startTimeMs === undefined) {
      switch (action.actionType) {
        case BlockstarActionType.BandPracticing:
        case BlockstarActionType.BandGigging:
          startTimeMs = bandActionStartTime(action as BandActionData);
          break;
      }
    }
    const endTime = startTimeMs + action.durationMs!;
    const timeLeft = endTime - Date.now();
    return timeLeft > 0 ? timeLeft : 0;
  }
  return 0;
};

// navigator.brave exists if browser is Brave browser.
export const isBrave = navigator.brave;
