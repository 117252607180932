import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, useTheme } from '@mui/material';
import { ThemeType } from '@shared-data';
import React from 'react';
import {
  ActionStatusContainer,
  ActionStatusSimpleContainer,
  CloseContainer,
} from './style';

export const ActionCustomOverlay = (props: {
  icon?: JSX.Element;
  title?: string;
  buttonText?: string;
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  simple?: boolean;
  onClick?: () => void;
  onClose?: () => void;
}) => {
  const theme = useTheme() as ThemeType;

  if (props.simple) {
    return (
      <ActionStatusSimpleContainer onClick={props.onClick}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '98%',
            height: '36px',
          }}>
          {props.icon}
          {props.title && (
            <span
              style={{
                fontSize: 'calc(10px + 0.1vw)',
                flex: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: 'left',
                marginLeft: 'calc(1px + 0.1vw)',
              }}>
              {props.title}
            </span>
          )}
        </div>
      </ActionStatusSimpleContainer>
    );
  }

  return (
    <ActionStatusContainer style={{ ...props.containerStyle }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '90%',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
          {props.icon}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            marginLeft: 8,
          }}>
          {props.title && (
            <span
              style={{
                fontSize: '14px',
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: 'left',
              }}>
              {props.title}
            </span>
          )}
          {props.buttonText && (
            <Button
              variant='text'
              style={{
                height: 14,
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: props.title ? 12 : 14,
                paddingLeft: 0,
              }}
              onClick={props.onClick}>
              {props.buttonText}
            </Button>
          )}
        </div>
        {props.onClose && (
          <CloseContainer
            style={{ fontSize: 12, width: 24, marginTop: -4, marginRight: -8 }}>
            <IconButton
              disabled={props.disabled}
              color='inherit'
              onClick={props.onClose}
              style={{ width: 24, height: 24, fontSize: 16 }}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          </CloseContainer>
        )}
      </div>
    </ActionStatusContainer>
  );
};
