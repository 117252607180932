import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import React from 'react';

export const NewBadge = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: 52,
        height: 18,
        borderRadius: 45,
        padding: '4px 6px',
        gap: 4,
        background: '#FF24FF',
        fontSize: 10,
        color: 'white',
        alignItems: 'center',
      }}>
      <AutoAwesomeIcon color={'inherit'} fontSize={'inherit'} />
      NEW
    </div>
  );
};
