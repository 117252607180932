import { Band, BlockstarActionType, PrepType } from '@shared-data';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import bs58 from 'bs58';
import { toast } from 'react-toastify';
import rest, { BandActionPath } from 'src/actions/network/rest';
import { sendSignedTransaction } from 'src/actions/wallet';
import analytics from 'src/utils/analytics';
import { genreData } from 'src/utils/utils';

export const bandTxnPrep = async (
  prepPath: string,
  prepBody: any,
  connection: Connection,
  publicKey: PublicKey,
  totalRolBalance: number,
  rolToPay: number,
  signTransaction: (transaction: Transaction) => Promise<Transaction>,
  prepType?: PrepType,
  bandId?: string,
) => {
  let txnToast;

  const actionForAnalytics =
    prepType === PrepType.BAND_ACTION
      ? BlockstarActionType.BandPracticing
      : BlockstarActionType.Edit;

  if (totalRolBalance < rolToPay) {
    const error = `Insufficient ROL: ${rolToPay} required to prepare.`;
    console.log(error);
    analytics.logEvent('TransactionComplete', {
      bandId: bandId,
      action: actionForAnalytics,
      prepType: prepType,
      state: 'insufficient-rol',
    });
    throw new Error(error);
  }

  try {
    const signable: any = await rest.post(
      `${process.env.WORKER_URL}/${prepPath}`,
      prepBody,
    );

    if (!signable || typeof signable === 'string') {
      // error Preparing
      throw new Error(`${signable}`);
    }

    const txn = Transaction.from(signable.buffer.data);
    const signedTxn = await signTransaction(txn);

    const txnFee = (
      await connection.getFeeForMessage(signedTxn.compileMessage())
    ).value;
    const solBalance = await connection.getBalance(publicKey);
    if (solBalance < txnFee) {
      const error = `Insufficient $SOL balance to cover transaction fee.`;
      analytics.logEvent('TransactionComplete', {
        bandId: bandId,
        action: actionForAnalytics,
        prepType: prepType,
        state: 'insufficient-sol',
      });
      throw new Error(error);
    }

    // sign action
    const clientSignature = bs58.encode(signedTxn.signature!);
    const succeeded = await rest.post(
      `${process.env.WORKER_URL}/${BandActionPath.Sign}`,
      {
        bandId: bandId,
        action: actionForAnalytics,
        prepType: prepType,
        signature: clientSignature,
      },
    );
    if (!succeeded) {
      throw new Error('Sign failed.');
    }
    txnToast = toast.info('Sending transactions to Solana...', {
      autoClose: 120000,
    });

    const sentSignature = await sendSignedTransaction(signedTxn, connection);

    toast.dismiss(txnToast);
    toast.success(`Transaction recorded on Solana successfully!`);

    analytics.logEvent('TransactionComplete', {
      bandId: bandId,
      action: actionForAnalytics,
      state: 'success',
      cost: rolToPay,
    });

    return { clientSignature: clientSignature, signedTxn: signedTxn };
  } catch (e: any) {
    console.log(`Couldn't prep ${prepType?.toString()} ${e?.message || e}.`);
    toast.dismiss(txnToast);
    analytics.logEvent('TransactionComplete', {
      bandId: bandId,
      action: actionForAnalytics,
      prepType: prepType,
      state: e.message ?? JSON.stringify(e),
    });
    throw new Error(e?.message || e);
  }
};

export const generateNavigateState = (band: Band) => {
  const genre = genreData[band.genre];
  const musicalSlots = band.musicalMembers.map((member) => {
    if (member?.blockstarId === undefined) {
      return undefined;
    }
    return {
      blockstarId: member?.blockstarId,
      skill: {
        name: member?.blockstarSkill.name,
        familyName: member?.blockstarSkill.familyName,
      },
    };
  });
  const otherSlots = band.generalMembers.map((member) => {
    if (member?.blockstarId === undefined) {
      return undefined;
    }
    return {
      blockstarId: member?.blockstarId,
      skill: {
        name: member?.skill,
      },
    };
  });

  return {
    bandId: band.id,
    genreId: band.genre,
    subGenreId: band.subgenre,
    members: band.memberIds,
    genreInfo: genre,
    subGenreInfo: genre.subgenres[band.subgenre.toString()],
    musicalSlots,
    otherSlots,
    starRatingInfo: band.starRating,
    name: band.customName?.name,
  };
};
