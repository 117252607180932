import {
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
} from '@shared-data';
import React, { useContext, useState } from 'react';

export interface IBlockstarActionControl {
  openActionSelectDialog: boolean;
  setOpenActionSelectDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openActionProgressDialog: boolean;
  setOpenActionProgressDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openActionResultDialog: boolean;
  setOpenActionResultDialog: React.Dispatch<React.SetStateAction<boolean>>;
  actionResult?:
    | BlockstarActionResponse
    | {
        status: any;
      };
  setActionResult: React.Dispatch<
    React.SetStateAction<
      | BlockstarActionResponse
      | {
          status: any;
        }
      | undefined
    >
  >;
  actionResultError?: string;
  setActionResultError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  actionButtonLoading: boolean;
  setActionButtonLoading: React.Dispatch<React.SetStateAction<boolean>>;
  actionType: BlockstarActionType;
  setActionType: React.Dispatch<React.SetStateAction<BlockstarActionType>>;
  blockstar?: Blockstar;
  setBlockstar: React.Dispatch<React.SetStateAction<Blockstar | undefined>>;
}
// our context
const BlockstarActionControlContext = React.createContext<
  IBlockstarActionControl | undefined
>(undefined);

// a custom hook to access our context
export const useBlockstarActionControlContext = () =>
  useContext(BlockstarActionControlContext);

// the context provider component
export const BlockstarActionControlProvider = (props: { children: any }) => {
  const [blockstar, setBlockstar] = useState<Blockstar | undefined>();
  const [actionType, setActionType] = useState(BlockstarActionType.Practicing);
  const [openActionSelectDialog, setOpenActionSelectDialog] = useState(false);
  const [openActionProgressDialog, setOpenActionProgressDialog] =
    useState(false);
  const [openActionResultDialog, setOpenActionResultDialog] = useState(false);
  const [actionButtonLoading, setActionButtonLoading] = useState(false);
  const [actionResult, setActionResult] = useState<
    | BlockstarActionResponse
    | {
        status: any;
      }
    | undefined
  >();
  const [actionResultError, setActionResultError] = useState<
    string | undefined
  >();

  const value = React.useMemo(() => {
    if (openActionResultDialog) {
      setOpenActionSelectDialog(false);
      setOpenActionProgressDialog(false);
    } else if (openActionProgressDialog) {
      setOpenActionSelectDialog(false);
      setOpenActionResultDialog(false);
    } else if (openActionSelectDialog) {
      setOpenActionProgressDialog(false);
      setOpenActionResultDialog(false);
    }
    return {
      openActionSelectDialog,
      setOpenActionSelectDialog,
      openActionProgressDialog,
      setOpenActionProgressDialog,
      openActionResultDialog,
      setOpenActionResultDialog,
      actionResult,
      setActionResult,
      actionResultError,
      setActionResultError,
      actionButtonLoading,
      setActionButtonLoading,
      actionType,
      setActionType,
      blockstar,
      setBlockstar,
    };
  }, [
    openActionSelectDialog,
    openActionProgressDialog,
    openActionResultDialog,
    actionResult,
    actionResultError,
    actionButtonLoading,
    actionType,
    blockstar,
  ]);

  return (
    <BlockstarActionControlContext.Provider value={value}>
      {props.children}
    </BlockstarActionControlContext.Provider>
  );
};
