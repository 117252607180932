import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Paper,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  ActionStatus,
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
  ThemeType,
} from '@shared-data';
import React, { useState } from 'react';
import { sendBlockstarClickActionEvent } from 'src/actions/action';
import {
  IBlockstarActionControl,
  useBlockstarActionControlContext,
} from 'src/hooks/blockstar-action-control';
import {
  canRunAction,
  capitalizeFirstLetter,
  isInAction,
} from '../../../../utils/utils';
import { TooltipContainer } from '../../../actions/style';

const BlockstarsTableActionButton = (props: {
  style?: React.CSSProperties;
  action?: BlockstarActionResponse;
  expanded?: boolean;
  onChange?: () => void;
  onActionsButtonClicked: (
    blockstar: Blockstar,
    type: BlockstarActionType,
  ) => void;
  elevation?: number;
  blockstar: Blockstar;
  onFireButtonClicked: () => void;
  fireButtonDisabledText?: string;
}) => {
  const theme = useTheme() as ThemeType;
  const [zIndex, setZIndex] = useState<number>();
  const blockstarId = props.blockstar.number;

  const {
    actionType,
    setActionType,
    setBlockstar,
    setOpenActionSelectDialog,
    setOpenActionProgressDialog,
  } = useBlockstarActionControlContext() as IBlockstarActionControl;

  let practiceButtonText = 'Practice';
  if (props.action) {
    switch (props.action.actionStatus) {
      case ActionStatus.InAction:
        practiceButtonText = 'Practicing - Check progress';
        break;
      case ActionStatus.Completed:
        practiceButtonText = 'Practice complete - View details';
    }
  }

  const onActionButtonClicked = (type: BlockstarActionType) => {
    switch (type) {
      case BlockstarActionType.Practicing:
      case BlockstarActionType.Busking:
        setBlockstar(props.blockstar);
        setActionType(type);
        if (
          props.action?.actionStatus &&
          props.action.actionStatus > ActionStatus.None
        ) {
          switch (props.action.actionStatus) {
            case ActionStatus.InAction:
              setOpenActionProgressDialog(true);
              break;
            case ActionStatus.Completed:
              setOpenActionProgressDialog(true);
              break;
          }
        } else {
          setOpenActionSelectDialog(true);
        }
        break;
    }
    props.onActionsButtonClicked(props.blockstar, type);

    sendBlockstarClickActionEvent(props.blockstar, type);
  };

  return (
    <Accordion
      sx={{
        '&:before': {
          display: 'none',
        },
        '& .MuiAccordionDetails-root': { padding: 0 },
      }}
      style={{
        position: 'absolute',
        width: '100%',
        bottom: 0,
        borderRadius: 8,
        border: `1px solid ${theme.colors.ui.primaryPurple}`,
        overflow: 'hidden',
        zIndex,
        ...props.style,
      }}
      expanded={props.expanded}
      onChange={props.onChange}
      elevation={props.elevation && zIndex ? props.elevation : 0}
      TransitionProps={{
        onEnter: () => {
          setZIndex(1);
        },
        onExited: () => {
          setZIndex(undefined);
        },
      }}
      disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color='primary' />}
        style={{ color: theme.colors.ui.primaryPurple, padding: '0px 16px' }}>
        Actions
      </AccordionSummary>
      <AccordionDetails>
        <ButtonGroup
          fullWidth
          orientation='vertical'
          variant='text'
          style={{
            borderTop: `1px solid ${theme.colors.ui.primaryPurple}`,
            borderRadius: 0,
          }}>
          <Button
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: 16,
            }}
            fullWidth
            onClick={() => onActionButtonClicked(BlockstarActionType.View)}>
            <span
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
              View Blockstar
            </span>
          </Button>
          <ActionButton
            blockstarId={blockstarId}
            action={props.action}
            actionType={BlockstarActionType.Practicing}
            onActionsButtonClicked={() => {
              onActionButtonClicked(
                props.action?.actionType ?? BlockstarActionType.Practicing,
              );
            }}
          />
          <ActionButton
            blockstarId={blockstarId}
            action={props.action}
            actionType={BlockstarActionType.Busking}
            onActionsButtonClicked={() => {
              onActionButtonClicked(BlockstarActionType.Busking);
            }}
          />
          <FireButton
            blockstarId={blockstarId}
            onFireButtonClicked={props.onFireButtonClicked}
            buttonDisabledText={props.fireButtonDisabledText}
          />
        </ButtonGroup>
      </AccordionDetails>
    </Accordion>
  );
};

const actionButtonDisabled = (
  blockstarId: number,
  actionType: BlockstarActionType,
  action?: BlockstarActionResponse,
) => {
  let actionTypeNotMatched = action?.actionType !== actionType;
  switch (action?.actionType) {
    case BlockstarActionType.BandPracticing:
      actionTypeNotMatched = actionType !== BlockstarActionType.Practicing;
  }

  return (
    action != undefined &&
    actionTypeNotMatched &&
    isInAction(action.actionStatus)
  );
};

const actionButtonText = (
  blockstarId: number,
  actionType: BlockstarActionType,
  action?: BlockstarActionResponse,
) => {
  let result = 'Practice';
  if (actionType === BlockstarActionType.Busking) {
    result = 'Busk';
  }

  if (!actionButtonDisabled(blockstarId, actionType, action)) {
    if (action) {
      switch (action.actionStatus) {
        case ActionStatus.InAction:
          result = `${capitalizeFirstLetter(actionType)} - Check Progress`;
          break;
        case ActionStatus.Completed:
          result = `${capitalizeFirstLetter(
            actionType,
          )} complete - View details`;
          break;
      }
    }
  }
  return result;
};

const tooltipText = (blockstarId: number) => {
  if (!canRunAction(blockstarId)) {
    return 'Only Origin Series of blockstar can use this action.';
  }
  return 'You can start a new action when the Blockstar is idle.';
};

const ActionButton = (props: {
  blockstarId: number;
  action?: BlockstarActionResponse;
  actionType: BlockstarActionType;
  onActionsButtonClicked: (type: BlockstarActionType) => void;
}) => {
  const { blockstarId, action, actionType, onActionsButtonClicked } = props;
  const buttonDisabled = actionButtonDisabled(blockstarId, actionType, action);
  const theme = useTheme() as ThemeType;

  const tooltip = (
    <Paper>
      <TooltipContainer>
        <span>{tooltipText(blockstarId)}</span>
      </TooltipContainer>
    </Paper>
  );

  const button = (
    <Button
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        paddingLeft: 16,
        cursor: buttonDisabled ? 'help' : 'pointer',
        color: buttonDisabled
          ? theme.colors.text.secondaryGray
          : theme.colors.ui.primaryPurple,
      }}
      fullWidth
      // disabled={practiceButtonDisabled}
      onClick={() => {
        if (!buttonDisabled) {
          onActionsButtonClicked(actionType);
        }
      }}>
      <span
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}>
        {actionButtonText(blockstarId, actionType, action)}
      </span>
    </Button>
  );

  if (buttonDisabled) {
    return (
      <Tooltip placement='right' title={tooltip}>
        {button}
      </Tooltip>
    );
  }
  return button;
};

const FireButton = (props: {
  blockstarId: number;
  buttonDisabledText?: string;
  onFireButtonClicked: () => void;
}) => {
  const { blockstarId, buttonDisabledText, onFireButtonClicked } = props;
  const theme = useTheme() as ThemeType;

  const tooltip = (
    <Paper>
      <TooltipContainer>
        <span>{buttonDisabledText}</span>
      </TooltipContainer>
    </Paper>
  );

  const button = (
    <Button
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        paddingLeft: 16,
        cursor: buttonDisabledText ? 'help' : 'pointer',
        color: buttonDisabledText
          ? theme.colors.text.secondaryGray
          : theme.colors.ui.primaryPurple,
      }}
      fullWidth
      // disabled={practiceButtonDisabled}
      onClick={() => {
        if (!buttonDisabledText) {
          onFireButtonClicked();
        }
      }}>
      <span
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}>
        Fire
      </span>
    </Button>
  );

  if (buttonDisabledText) {
    return (
      <Tooltip placement='right' title={tooltip}>
        {button}
      </Tooltip>
    );
  }

  return button;
};

export default BlockstarsTableActionButton;
