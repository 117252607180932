import { Dialog, Paper, Tooltip } from '@mui/material';
import { Band, GenreData, SubgenreInfo } from '@shared-data';
import { Subgenre } from '@shared-generated/generated-genres';
import React, { useEffect, useState } from 'react';
import { DialogClose } from 'src/components/library/dialog-header';
import {
  Dropdown,
  DropdownComponentData,
} from 'src/components/library/dropdown';
import { InfoBody } from 'src/styles/global-style';
import analytics from 'src/utils/analytics';
import {
  BodyContainer,
  ConfirmButton,
  GenreSelectionContainer,
  GenreSelectionInfoHeader,
  GenreType,
  HeaderContainer,
  Link,
  SubgenreText,
  SubgenreTooltipContainer,
} from './style';

interface UsedGenre {
  [key: string]: Subgenre[];
}
const GenreSelectionDialog = (props: {
  bands?: Band[];
  genreData: GenreData;
  open: boolean;
  onClose: () => void;
  onGenreSelected: (
    selectedGenreId: string,
    selectedSubGenreId: string,
  ) => void;
}) => {
  const genreKeys = Object.keys(props.genreData).sort();
  const getSubGenreKeys = (genre: string) => {
    const subGenres = props.genreData[genre].subgenres;
    return Object.keys(subGenres).sort();
  };
  const [selectedGenre, setSelectedGenre] = useState<number>(-1);
  const [selectedSubGenre, setSelectedSubGenre] = useState<number>(-1);
  const [subGenreKeys, setSubGenreKeys] = useState<string[]>([]);

  useEffect(() => {
    if (props.open) {
      analytics.logEvent('ViewScreenSelectGenre');
    }
  }, [props.open]);

  const genreSelected = (index: number) => {
    setSelectedGenre(index);
    if (index < 0) {
      return;
    }

    const genre = genreKeys[index];
    const subGenres = props.genreData[genre];
    const subKeys = getSubGenreKeys(genre);
    setSubGenreKeys(subKeys);
  };

  const onSubmit = () => {
    const genreId = genreKeys[selectedGenre];
    const subGenreId = subGenreKeys[selectedSubGenre];
    analytics.logEvent('ClickSelectGenreNext', {
      genre: genreId,
      subgenre: subGenreId,
    });

    props.onGenreSelected(genreId, subGenreId);
  };

  const usedGenre: UsedGenre = {};
  props.bands?.forEach((band) => {
    const existing = usedGenre[band.genre];
    if (existing) usedGenre[band.genre] = [...existing, band.subgenre];
    else usedGenre[band.genre] = [band.subgenre];
  });

  const subgenreDisabled = (genreKey: string, subGenreKey: string) => {
    return usedGenre[genreKey]
      ? usedGenre[genreKey].findIndex(
          (subgenre) => subgenre.toString() === subGenreKey,
        ) >= 0
      : false;
  };

  const subgenreDisabledTooltip = (subgenre: string): JSX.Element => {
    return (
      <Paper>
        <SubgenreTooltipContainer>
          <p>{`You already have a ${subgenre} band.`}</p>
        </SubgenreTooltipContainer>
      </Paper>
    );
  };

  const subgenreMinMaxTooltip = (subgenreInfo: SubgenreInfo): JSX.Element => {
    return (
      <Paper style={{ width: '200px' }}>
        <SubgenreTooltipContainer>
          <p
            style={{
              fontWeight: 'bold',
            }}>{`Min ${subgenreInfo.requirements.minMembers} / Max ${subgenreInfo.requirements.maxMembers}`}</p>
          {subgenreInfo.requirements.musicalSkills.map((skill, index) => (
            <p key={index} style={{ marginLeft: 8 }}>{`• ${skill.name}`}</p>
          ))}
        </SubgenreTooltipContainer>
      </Paper>
    );
  };

  const subgenreComponent = (
    subgenreInfo: SubgenreInfo,
    genreKey: string,
    subGenreKey: string,
  ) => {
    if (subgenreDisabled(genreKey, subGenreKey)) {
      return <p>{subgenreInfo.displayName}</p>;
    }

    return (
      <Tooltip placement={'right'} title={subgenreMinMaxTooltip(subgenreInfo)}>
        <SubgenreText>{subgenreInfo.displayName}</SubgenreText>
      </Tooltip>
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      sx={{ minWidth: '650px', minHeight: '600px' }}>
      <GenreSelectionContainer>
        <DialogClose onClose={props.onClose} />
        <HeaderContainer>
          <GenreSelectionInfoHeader>Start a band</GenreSelectionInfoHeader>
          <InfoBody style={{ width: '90%' }}>
            Each subgenre has a unique set of musical requirements. Find the
            right subgenres for your Blockstars.{' '}
            <Link
              href='https://docs.google.com/spreadsheets/d/1qUXMYJ9lordaAkfYRimFkaXRFElQtrdPKvnPPqz_EVw/edit?usp=sharing'
              target={'_blank'}>
              View them all here.
            </Link>
          </InfoBody>
        </HeaderContainer>
        <BodyContainer>
          <Dropdown
            style={{
              width: '459px',
              height: '50px',
            }}
            placeHolder={<GenreType>Choose a genre</GenreType>}
            components={[
              ...genreKeys.map((genre, index) => {
                return {
                  component: <p>{props.genreData[genre].displayName}</p>,
                };
              }),
            ]}
            defaultSelected={selectedGenre}
            value={selectedGenre}
            onChange={(index) => {
              genreSelected(index);
            }}
          />
          <Dropdown
            style={{
              width: '459px',
              height: '50px',
            }}
            placeHolder={<GenreType>Choose a subgenre</GenreType>}
            components={[
              ...subGenreKeys.map((subGenreKey, index) => {
                const genreKey = genreKeys[selectedGenre];
                const subgenreInfo =
                  props.genreData[genreKey].subgenres[subGenreKey];
                return {
                  disabledTooltip: {
                    placement: 'right',
                    title: subgenreDisabledTooltip(subgenreInfo.displayName),
                  },
                  component: subgenreComponent(
                    subgenreInfo,
                    genreKey,
                    subGenreKey,
                  ),
                  disabled: subgenreDisabled(genreKey, subGenreKey),
                } as DropdownComponentData;
              }),
            ]}
            defaultSelected={selectedSubGenre}
            value={selectedSubGenre}
            onChange={(index) => {
              setSelectedSubGenre(index);
            }}
            disabled={subGenreKeys.length <= 0}
          />
        </BodyContainer>
        <ConfirmButton
          onClick={onSubmit}
          disabled={selectedGenre < 0 || selectedSubGenre < 0}>
          Next
        </ConfirmButton>
      </GenreSelectionContainer>
    </Dialog>
  );
};

export default GenreSelectionDialog;
