import { styled } from '@mui/material/styles';
import { ButtonNoBG } from 'src/components/library/style';

export const DetailContainer = styled('div')``;

export const ContentsContainer = styled('div')`
  width: 100%;
  height: ${window.innerHeight - 128}px;
  overflow: auto;
`;

export const BodyContainer = styled('div')`
  display: flex;
  width: 60%;
  margin: auto;
  min-width: 700px;
  align-items: center;
  flex-direction: column;
  row-gap: 48px;
  @media (max-width: 1364px) {
    width: 632px;
  }
`;

// Top - Image
export const ImageContainer = styled('div')`
  width: 100%;
  height: 410px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.text.primaryGray};
`;

//Middle - Alert
export const MiddleAlertContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  border: 2px solid ${(props) => props.theme.colors.text.secondaryGray};
  border-radius: 10px;
  padding-left: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
`;
export const MiddleAlertIconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MiddleAlertDescriptionContainer = styled('div')``;

export const MiddleAlertDescriptionTitle = styled('p')`
  font-size: 20px;
`;

export const MiddleAlertDescriptionButton = styled(ButtonNoBG)`
  padding: 0px;
  color: ${(props) => props.theme.colors.text.error};
`;

// Middle - Info
export const MiddleInfoContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 48px;
`;

export const MiddleInfoTopContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const MiddleInfoTopLeftContainer = styled('div')`
  width: 80%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const BandNameContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

export const BandName = styled('h1')`
  text-align: left;
`;

export const StarContainer = styled('div')`
  height: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const ManagedBy = styled('p')`
  text-align: left;
`;

export const MiddleInfoTopRightContainer = styled('div')`
  width: 20%;
  height: 48px;
  position: relative;
`;

export const MiddleInfoBottomContainer = styled('div')`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 68px;
  padding-left: 24px;
  @media (max-width: 1500px) {
    column-gap: 12px;
  }
`;

export const MiddleInfoBottomItemContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;

export const MiddleInfoBottomItemLeftContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MiddleInfoBottomItemRightContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 6px;
`;

export const MiddleInfoBottomItemRightTop = styled('p')`
  color: ${(props) => props.theme.colors.text.secondaryGray};
  text-align: left;
`;

export const MiddleInfoBottomItemRightBottom = styled('h4')`
  white-space: nowrap;
  min-width: 155px;
  text-align: left;
`;

// Middle - Members
export const MiddleMembersContainer = styled('div')`
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const MiddleMembersTopContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 18px;
`;

export const MiddleMembersMembersText = styled('p')`
  font-size: 24px;
`;

export const MiddleMembersMembersCount = styled('p')`
  color: ${(props) => props.theme.colors.text.secondaryGray};
  font-size: 12px;
`;

export const MiddleMembersBottomContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'columnCount',
})<{ columnCount: number }>`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.columnCount ?? 8},
    minmax(96px, 128px)
  );
  grid-gap: 6px;
`;

export const MiddleMemberContainer = styled('div')`
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MiddleMemberPictureContainer = styled('div')`
  position: relative;
  max-width: 100%;
  border: none;
  &:hover:enabled {
    cursor: pointer;
  }
`;

export const MiddleMemberPictureButton = styled('button')`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  &:hover:enabled {
    cursor: pointer;
  }
`;

export const MiddleMemberPicture = styled('img')`
  max-width: 100%;
  border-radius: 6px;
`;

export const MiddleMemberText = styled('div')`
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// Bottom - Disband
export const BottomDisbandButton = styled(ButtonNoBG)`
  margin-bottom: 48px;
`;
