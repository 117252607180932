import { Band } from '@shared-data';
import * as React from 'react';
import Lottie from 'react-lottie';
import * as confetti from 'src/assets/lottie/confetti.json';
import { getBandCollegeColumnRowCount } from 'src/utils/utils';
import { CollageFrame, CollageImage } from '../../style';

// eventually we want to replace this with the unity 3d rendered band
export const BandRender = (props: { band: Band; confetti?: boolean }) => {
  const collageColumnRowCount = getBandCollegeColumnRowCount(
    props.band.memberIds.length,
  );
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        position: 'relative',
        width: '100%',
        height: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          padding: 60,
          width: '100%',
          height: '100%',
        }}>
        <CollageFrame
          style={{ width: '350px', height: '350px' }}
          collageColumnRowCount={collageColumnRowCount}>
          {props.band.memberIds.map((memberId, index) => {
            return (
              <CollageImage
                key={index}
                src={`${process.env.ASSET_BASE_URL}/${memberId}.png`}
              />
            );
          })}
        </CollageFrame>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: -120,
        }}>
        {props.confetti && (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: confetti,
            }}
          />
        )}
      </div>
    </div>
  );
};
