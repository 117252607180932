import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import * as React from 'react';
import { Maxed } from './style';

export const MaxReached = () => {
  return (
    <Maxed>
      <LocalFireDepartmentIcon fontSize='inherit' />
      MAX REACHED
    </Maxed>
  );
};
