import { useConnection } from '@solana/wallet-adapter-react';
import React, { useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import analytics from '../../utils/analytics';
import { getOriginPage, isBrave } from '../../utils/utils';
import { Wallet } from '../solana-wallet';
import {
  BGImage,
  BGImageMobile,
  ButtonContainer,
  Container,
  LogoButton,
  MainContainer,
} from './style';

const HomePage = () => {
  const location = useLocation();
  const { connection } = useConnection();

  useEffect(() => {
    analytics.logEvent('ViewScreenHome', {
      originPage: getOriginPage(location.state),
    });
  }, []);

  if (isMobile || isTablet) {
    return (
      <MainContainer>
        <BGImageMobile />
        <p
          style={{
            font: 'Roboto',
            fontSize: 32,
            margin: '10%',
            marginTop: '40%',
            height: '100%',
          }}>
          <span>
            This app is currently only available on desktop.
            <br />
            <br />
            Open in a desktop browser 🤘
          </span>
        </p>
      </MainContainer>
    );
  }

  if (isBrave) {
    return (
      <MainContainer>
        <BGImageMobile />
        <p
          style={{
            font: 'Roboto',
            fontSize: 32,
            margin: '10%',
            marginTop: '40%',
            height: '100%',
          }}>
          <span>
            This app is not supported on the Brave web browser.
            <br />
            <br />
            Open in another desktop browser 🤘
          </span>
        </p>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <BGImage />
      <Container>
        <video
          autoPlay
          loop
          muted
          playsInline
          src='https://assets.blockstars.gg/videos/abbieRoad.mp4'
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
        />
        <a href='https://blockstars.gg'>
          <LogoButton />
        </a>
        <ButtonContainer>
          <Wallet />
        </ButtonContainer>
      </Container>
    </MainContainer>
  );
};

export default HomePage;
