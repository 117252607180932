import React, { useEffect, useState } from 'react';
import { BarBG, PotentialBar } from './style';

export const ProgressBar = (props: {
  currentLevel: number;
  nextLevel?: number;
  loops?: number;
  color: string;
  autoFill?: boolean;
  delayAnimation?: boolean;
}) => {
  if (props.autoFill) {
    const [level, setLevel] = useState(props.currentLevel);
    const [transition, setTransition] = useState<string>();

    useEffect(() => {
      if (!props.delayAnimation) {
        setTransition(undefined);
        setLevel(props.currentLevel);
        if (props.nextLevel !== undefined) {
          setTimeout(() => {
            if (props.loops) {
              let totalTime = 1.5;
              if (props.loops > 3) {
                totalTime = 3;
              }
              const totalGain =
                100 - props.currentLevel + (props.loops - 1) * 100; // total distance bar travels
              const fps = totalGain / totalTime; // bar travel per second
              const firstDuration = (100 - props.currentLevel) / fps;
              setTransition(`flex ${firstDuration}s linear`);
              setLevel(100);
              let totalDuration = firstDuration;
              for (let loops = 1; loops < props.loops; loops++) {
                // do any additional full loops if they leveled more than once
                setTimeout(() => {
                  setTransition(undefined);
                  setLevel(0);
                  setTimeout(() => {
                    setTransition(`flex ${100 / fps}s linear`);
                    setLevel(100);
                  }, 100); // without this delay, it switches the transition back before going back to empty bar
                }, totalDuration * 1000);
                totalDuration += 100 / fps + 0.1; //0.1 for the 100ms delay
              }
              setTimeout(() => {
                setTransition(undefined);
                setLevel(0);
                setTimeout(() => {
                  const secondDuration = props.nextLevel! / fps;
                  setTransition(`flex ${secondDuration}s linear`);
                  setLevel(props.nextLevel!);
                }, 100); // without this delay, it switches the transition back before going back to empty bar
              }, totalDuration * 1000);
            } else {
              setTransition(`flex 1s linear`);
              setLevel(props.nextLevel!);
            }
          }, 500);
        }
      }
    }, [props.delayAnimation]);

    return (
      <BarBG style={{ margin: 0 }}>
        <PotentialBar
          style={{
            flex: level,
            backgroundColor: props.color,
            transition: transition,
          }}
        />
        <div
          style={{
            display: 'flex',
            flex: 100 - level,
            transition: transition,
          }}
        />
      </BarBG>
    );
  } else {
    return (
      <BarBG style={{ margin: 0 }}>
        <PotentialBar
          style={{
            flex: props.currentLevel,
            backgroundColor: props.color,
          }}
        />
        <div
          style={{
            display: 'flex',
            flex: 100 - props.currentLevel,
          }}
        />
      </BarBG>
    );
  }
};
