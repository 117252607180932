import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateState = <T,>(
  key: string,
  initialValue?: T,
): [T, (t: T) => void] => {
  const navigate = useNavigate();
  const location = useLocation();

  const [localState, setLocalState] = useState<T>(() => {
    const value = (location.state as any)?.[key];
    return value ?? initialValue ?? undefined;
  });

  function setState(value: T) {
    navigate(location.pathname, {
      replace: true,
      state: {
        originPage: location.pathname,
        ...(location.state as any),
        [key]: value,
      },
    });

    setLocalState(value);
  }
  return [localState, setState];
};
