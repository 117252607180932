import { Blockstar } from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import rest, {
  BlockstarsRequests,
  NGMIRequests,
} from '../actions/network/rest';
import { useNFTs } from './wallet/nfts';

export const useBlockstar = (id?: number) => {
  const { data, error, mutate } = useSWR<Blockstar>(
    `${process.env.WORKER_URL}/${BlockstarsRequests.GetBlockstar}/${id}`,
    (url: string) =>
      rest.get(url).then((res: any) => {
        if (res?.message) {
          toast.info(res.message);
        }
        return res.blockstar;
      }),
  );
  return { blockstar: data, mutate };
};

export const useBlockstars = () => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { nfts: allTokens } = useNFTs(connection, publicKey);

  useEffect(() => {
    mutate();
  }, [allTokens]);

  const { data, error, mutate } = useSWR<Blockstar[]>(
    `${process.env.WORKER_URL}/${BlockstarsRequests.GetAllBlockstars}`,
    async (url: string) => {
      const blockstarIds: number[] = [];
      if (allTokens) {
        allTokens.map((token) => {
          const numberId = token.name.split('#')[1];
          if (token.name.includes('Blockstar')) {
            blockstarIds.push(parseInt(numberId));
          }
        });

        const result = await rest
          .post(url, { blockstarIds: blockstarIds })
          .then((res: any) => {
            if (res?.message) {
              toast.info(res.message);
            }
            return res;
          });

        return result;
      }
    },
  );

  return { blockstars: data, mutateBlockstars: mutate };
};

export const useBlockstarsByIds = (blockstarIds?: number[]) => {
  // use key as `useBlockstarsByIds/${blockstarIds}`.
  const { data, error, mutate } = useSWR<Blockstar[]>(
    `useBlockstarsByIds/${blockstarIds}`,
    async (url: string) => {
      const result = !blockstarIds
        ? []
        : await rest
            .post(
              `${process.env.WORKER_URL}/${BlockstarsRequests.GetAllBlockstars}`,
              { blockstarIds: blockstarIds },
            )
            .then((res: any) => {
              if (res?.message) {
                toast.info(res.message);
              }
              return res;
            });

      return result;
    },
  );

  return { blockstars: data, mutateBlockstars: mutate };
};

export const useCrates = () => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { nfts: allTokens } = useNFTs(connection, publicKey);

  useEffect(() => {
    mutate();
  }, [allTokens]);

  const { data, error, mutate } = useSWR<any[]>(
    `${process.env.WORKER_URL}/${NGMIRequests.IOU}`,
    async (url: string) => {
      const crateItems: any[] = [];
      if (allTokens) {
        await Promise.all(
          allTokens.map(async (token) => {
            const numberId = token.name.split('#')[1];
            if (!token.name.includes('Blockstar')) {
              try {
                const response = await rest.get<any>(`${url}/${numberId}`);
                crateItems.push(response);
              } catch (e) {
                console.error(
                  `Failed fetching crate ${numberId}... skipping...`,
                );
              }
            }
          }),
        );

        crateItems.sort((a, b) => {
          const crateA = parseInt(a.name.split('#')[1]);
          const crateB = parseInt(b.name.split('#')[1]);
          // eslint-disable-next-line no-nested-ternary
          return crateA < crateB ? -1 : crateA > crateB ? 1 : 0;
        });
      }
      return crateItems;
    },
  );

  return { crates: data, mutateCrates: mutate };
};
