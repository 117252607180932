import {
  Blockstar,
  NGMISkillOption,
  SkillType,
  TransferInfo,
} from '@shared-data';
import React from 'react';
import { BlockstarInfo } from '../library/dialog-header';
import { NewBadge } from '../library/new';
import { SkillEntryType } from '../library/types';
import {
  SummaryBody,
  SummaryContainer,
  SummaryEntry,
  TransferEntries,
  TransferIcon,
  TransferLabel,
} from './style';
import { TransferEntry } from './transfer-entry';

interface SummaryData {
  transferData?: TransferInfo[];
  newSkill?: string;
  overwriteSkill?: string;
}

export const Summary = ({
  blockstar,
  targetBlockstar,
  summaryData,
}: {
  blockstar: Blockstar;
  targetBlockstar: Blockstar;
  summaryData: SummaryData;
}) => {
  const imgSrc = `${process.env.ASSET_BASE_URL || ''}/${blockstar.number}.png`;
  const targetImgSrc = `${process.env.ASSET_BASE_URL || ''}/${
    targetBlockstar.number
  }.png`;
  return (
    <SummaryContainer>
      <span style={{ padding: '0px 48px', fontSize: 32, textAlign: 'center' }}>
        Do you want to complete the transfer?
      </span>
      <SummaryBody>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            padding: '0px 48px',
          }}>
          <BlockstarInfo
            basic
            showBorders
            height={88}
            blockstar={blockstar}
            padding='16px 0px'
            margin='0px'
          />
          <TransferLabel style={{ margin: '' }}>
            <TransferIcon style={{ marginRight: 12 }} /> Transfer to
          </TransferLabel>
          <BlockstarInfo
            basic
            showBorders
            height={88}
            blockstar={targetBlockstar}
            padding='16px 0px'
            margin='0px'
          />
        </div>
        <TransferEntries
          style={{
            height: 160,
            padding: '0px 16px 0px 48px',
            marginRight: 48,
          }}>
          {targetBlockstar.skills.map((s, idx) => {
            const transferExp = summaryData.transferData?.find(
              (t) => t.type === s.type && t.name === s.name,
            );
            let data: any = {
              title: s.name,
              exp: transferExp?.exp,
              ability: s.currentAbilities,
              levels: transferExp?.levels,
              maxed: transferExp?.maxed,
            };
            if (s.type === SkillType.Instrumental) {
              data = {
                ...data,
                type: SkillEntryType.MUSICAL,
              };
            } else {
              const type = s.name.split(' ')[0].toUpperCase();
              data = {
                ...data,
                type: SkillEntryType[type as keyof typeof SkillEntryType],
              };
            }
            const showOutline =
              summaryData.overwriteSkill !== NGMISkillOption.NONE &&
              summaryData.overwriteSkill === NGMISkillOption.LOCKED
                ? targetBlockstar.skills.findIndex(
                    (s, idx) =>
                      idx > 0 &&
                      s.type === SkillType.Instrumental &&
                      !s.unlocked,
                  ) === idx
                : targetBlockstar.skills.findIndex(
                    (s) => s.name === summaryData.overwriteSkill,
                  ) === idx;
            const outline = showOutline
              ? {
                  borderRadius: 4,
                  border: '1px solid #FF24FF',
                  background: 'rgba(255, 36, 255, 0.05)',
                }
              : undefined;
            if (showOutline) {
              const skill = blockstar.skills[0];
              data = {
                type: SkillEntryType.MUSICAL,
                title: skill.name,
                ability: 1,
                hideExp: true,
                showAbility: true,
              };
            }
            return (
              <SummaryEntry
                key={`summary-${s.name}}`}
                style={{
                  ...outline,
                }}>
                <TransferEntry key={`transfer-${s.name}`} showGains {...data} />
                {showOutline && (
                  <div
                    style={{
                      position: 'absolute',
                      right: 12,
                      top: -6,
                    }}>
                    <NewBadge />
                  </div>
                )}
              </SummaryEntry>
            );
          })}
        </TransferEntries>
      </SummaryBody>
    </SummaryContainer>
  );
};
