import { Paper, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  ActionTargetType,
  Blockstar,
  GenreInfo,
  NameChangeInfo,
  rolToPayForSignature,
  SubgenreInfo,
  ThemeType,
} from '@shared-data';
import { Subgenre } from '@shared-generated/generated-genres';
import React, { useEffect, useState } from 'react';
import { ButtonRolPayText } from 'src/components/library/button-rol-pay-text';
import { InfoNumber } from 'src/components/library/style';
import { NameEditButton } from 'src/components/name-change/name-edit-button';
import Stars from 'src/components/star-rating';
import { RolSvgStyled } from 'src/components/tokens/nft/style';
import { DividerVertical } from 'src/styles/global-style';
import {
  bandMembers,
  bandSalary,
  bandSalaryCutMember,
  getBlockstarName,
} from 'src/utils/utils';
import { StarRatingInfo } from '.';
import blank_optional from '../../../assets/images/blank_optional.png';
import blank_required from '../../../assets/images/blank_required.png';
import {
  EditorBodyElementsContainerTop,
  EditorCancelButton,
  EditorSubmitButton,
  EditorTopMemberContainer,
  EditorTopMemberPicture,
  EditorTopMemberPictureContainer,
  EditorTopMemberRemoveButton,
  EditorTopMemberRemoveIcon,
  EditorTopMembersContainer,
  EditorTopMemberText,
  EditorTopTitleButtonContainer,
  EditorTopTitleContainer,
  EditorTopTitleInfo,
  EditorTopTitleInfoBottomMiscContainer,
  EditorTopTitleInfoBottomStarContainer,
  EditorTopTitleInfoContainer,
  EditorTopTitleInfoTopMemberInfo,
  EditorTopTitleInfoTopTitle,
  GenreIcon,
  GenreText,
  SalaryText,
} from './style';

const BandEditorTop = (props: {
  blockstars?: Blockstar[];
  genreInfo: GenreInfo;
  subGenreInfo: SubgenreInfo;
  subGenreId: Subgenre;
  memberIds: number[];
  starRatingInfo: StarRatingInfo;
  isEdit?: boolean;
  isSaving?: boolean;
  disableSubmit: boolean;
  canEditNameInfo: NameChangeInfo;
  onNameChangeClicked: () => void;
  newName?: string;
  onMemberRemoved: (
    memberId: number,
    slotIndex?: number,
    isMusical?: boolean,
  ) => void;
  onCancel: () => void;
  onSubmit: () => void;
}) => {
  const { blockstars } = props;

  const theme = useTheme() as ThemeType;

  const onMemberRemove = (removedBlockstar: Blockstar) => {
    props.onMemberRemoved(removedBlockstar.number);
  };

  const onCancelClicked = () => {
    props.onCancel();
  };

  const onSubmitClicked = () => {
    if (props.starRatingInfo.completed && !props.disableSubmit) {
      props.onSubmit();
    }
  };

  const [members, setMembers] = useState<Blockstar[]>();

  useEffect(() => {
    const members = bandMembers(props.memberIds, blockstars);
    setMembers(members);
  }, [props.memberIds]);

  if (!members) {
    return <div />;
  }

  let salaryCutToDisplay = bandSalaryCutMember(members);
  const totalSalary = bandSalary(members);

  const starsTooltip = !props.starRatingInfo.completed ? (
    <Paper style={{ padding: '12px', fontSize: '16px' }}>
      Complete your band to see its star rating.
    </Paper>
  ) : (
    <div />
  );

  const editButtonDisabledTooltip = props.disableSubmit ? (
    <Paper style={{ padding: '12px', fontSize: '16px' }}>
      Assign Blockstars to all of the remaining skills slots to continue
    </Paper>
  ) : (
    <div />
  );

  const editButtonStyle = props.disableSubmit
    ? { backgroundColor: theme.colors.ui.mediumGray, cursor: 'not-allowed' }
    : {};

  return (
    <EditorBodyElementsContainerTop>
      <EditorTopTitleContainer>
        <EditorTopTitleInfoContainer>
          <EditorTopTitleInfo>
            <EditorTopTitleInfoTopTitle>
              {props.newName ?? `${props.subGenreInfo.displayName} Band`}
            </EditorTopTitleInfoTopTitle>
            <NameEditButton
              type={ActionTargetType.Band}
              canEditNameInfo={props.canEditNameInfo}
              onNameChangeClicked={props.onNameChangeClicked}
            />
          </EditorTopTitleInfo>
          <EditorTopTitleInfo>
            <Tooltip placement={'right'} title={starsTooltip}>
              <EditorTopTitleInfoBottomStarContainer>
                <Stars
                  currentStars={props.starRatingInfo.currentStarRating}
                  potentialStars={props.starRatingInfo.potentialStarRating}
                  starSize={24}
                  preview={!props.starRatingInfo.completed}
                />
              </EditorTopTitleInfoBottomStarContainer>
            </Tooltip>
            <DividerVertical />
            <EditorTopTitleInfoBottomMiscContainer>
              <RolSvgStyled />
              <SalaryText>
                {totalSalary
                  ? `${totalSalary.toLocaleString()} / ${Math.floor(
                      (salaryCutToDisplay?.wage.percentCut ?? 0) * 100,
                    )}% ${salaryCutToDisplay?.wage.bucket}`
                  : '-'}
              </SalaryText>
            </EditorTopTitleInfoBottomMiscContainer>
            <DividerVertical />
            <EditorTopTitleInfoBottomMiscContainer>
              <GenreIcon />
              <GenreText>{`${props.genreInfo.displayName} / ${props.subGenreInfo.displayName}`}</GenreText>
            </EditorTopTitleInfoBottomMiscContainer>
          </EditorTopTitleInfo>
          <EditorTopTitleInfo>
            <EditorTopTitleInfoTopMemberInfo>
              {`min ${props.subGenreInfo.requirements.minMembers} / max ${props.subGenreInfo.requirements.maxMembers}`}
            </EditorTopTitleInfoTopMemberInfo>
          </EditorTopTitleInfo>
        </EditorTopTitleInfoContainer>
        <EditorTopTitleButtonContainer>
          <EditorCancelButton onClick={onCancelClicked}>
            Cancel
          </EditorCancelButton>
          <Tooltip placement={'bottom'} title={editButtonDisabledTooltip}>
            <EditorSubmitButton
              onClick={onSubmitClicked}
              loading={props.isSaving}
              style={editButtonStyle}>
              <ButtonRolPayText
                title={props.isEdit ? 'Update' : 'Create band'}
                rolAmount={rolToPayForSignature}
                disabled={props.disableSubmit}
              />
            </EditorSubmitButton>
          </Tooltip>
        </EditorTopTitleButtonContainer>
      </EditorTopTitleContainer>
      <EditorTopMembersContainer>
        {[...Array(props.subGenreInfo.requirements.maxMembers)].map(
          (item, index) => {
            const required = index < props.subGenreInfo.requirements.minMembers;
            return (
              <EditorTopMemberContainer
                key={index}
                doAnimation={members.length > 0 && members.length > index}>
                <EditorTopMemberPictureContainer>
                  <EditorTopMemberPicture
                    src={
                      members.length > 0 && members.length > index
                        ? `${process.env.ASSET_BASE_URL || ''}/${
                            members[index].number
                          }.png`
                        : required
                        ? blank_required
                        : blank_optional
                    }
                  />
                  {members.length > 0 && members.length > index && (
                    <EditorTopMemberRemoveButton
                      onClick={() => onMemberRemove(members[index])}>
                      <EditorTopMemberRemoveIcon />
                    </EditorTopMemberRemoveButton>
                  )}
                </EditorTopMemberPictureContainer>
                {members.length > 0 && members.length > index && (
                  <div>
                    <EditorTopMemberText>
                      {getBlockstarName(members[index])}
                    </EditorTopMemberText>
                    <InfoNumber>{`#${members[index].number}`}</InfoNumber>
                  </div>
                )}
              </EditorTopMemberContainer>
            );
          },
        )}
      </EditorTopMembersContainer>
    </EditorBodyElementsContainerTop>
  );
};

export default BandEditorTop;
