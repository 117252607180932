import amplitude from 'amplitude-js';
import _ from 'lodash';

type PrimitiveValue = string | number | boolean | undefined;
type PropertyValue = PrimitiveValue | PrimitiveValue[] | IEventProperties;
interface IEventProperties {
  [index: string]: PropertyValue;
}

class Analytics {
  private amplitude = amplitude.getInstance();
  private cachedDeviceId?: string;
  public get deviceId() {
    return this.cachedDeviceId;
  }

  public constructor() {
    // do nothing.
  }

  public init() {
    this.amplitude.init(process.env.REACT_APP_AMPLITUDE!, undefined, {
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
    });
  }

  public setVersion = (version: string) => {
    this.amplitude.setVersionName(version);
  };

  public setUserId = (walletPublicKey: string) => {
    this.amplitude.setUserId(walletPublicKey);
    if (process.env.AMPLITUDE_LOGS) {
      console.log('amplitude: setUserId: ', walletPublicKey);
    }
  };

  public setUserProperties = (properties: any) => {
    this.amplitude.setUserProperties(properties);
    if (process.env.AMPLITUDE_LOGS) {
      console.log('amplitude: setUserProperties: ', properties);
    }
  };

  public clearUserProperties = () => {
    this.amplitude.clearUserProperties();
  };

  public logEvent = (
    name: string,
    properties?: IEventProperties,
    callback?: amplitude.Callback,
  ) => {
    this.amplitude.logEvent(
      name,
      this.cloneAndStripFunctions(properties),
      callback,
    );
    if (process.env.AMPLITUDE_LOGS) {
      console.log('amplitude: logEvent: ', name, properties);
    }
  };

  // Log gtag data for e.g. Dysrupt -cojo
  public gtag = (...args: any[]) => {
    (window as any).gtag(...args);
  };

  protected cloneAndStripFunctions = (
    obj?: IEventProperties,
  ): IEventProperties =>
    _.transform(
      obj ?? {},
      (result, value, key) =>
        _.isUndefined(value)
          ? null
          : _.isObject(value)
          ? (result[key] = this.cloneAndStripFunctions(
              value as IEventProperties,
            ))
          : (result[key] = value),
      {} as IEventProperties,
    );
}

const analytics = new Analytics();
export default analytics;
