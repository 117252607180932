import { Checkbox } from '@mui/material';
import React, { Dispatch, SetStateAction, useContext } from 'react';

import TableContext from './table-context';

interface IProps {
  toggleheaderChecked: Dispatch<SetStateAction<boolean>>;
}

const TableListToggle = ({ toggleheaderChecked }: IProps) => {
  const { rowsChecked, selectAllDisabled } = useContext(TableContext);

  return (
    <Checkbox
      style={{ width: '42px' }}
      disabled={selectAllDisabled}
      checked={
        rowsChecked &&
        rowsChecked.length > 0 &&
        rowsChecked.reduce((prev, curr) => prev && curr, true) &&
        !selectAllDisabled
      }
      indeterminate={
        rowsChecked &&
        !rowsChecked.reduce((prev, curr) => prev && curr, true) &&
        rowsChecked.reduce((prev, curr) => prev || curr, false)
      }
      onChange={(e) => {
        toggleheaderChecked(e.target.checked);
      }}
    />
  );
};

export default TableListToggle;
