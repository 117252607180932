import { makeAutoObservable } from 'mobx';

class UIStore {
  error: { [key: string]: string };

  constructor() {
    makeAutoObservable(this);
    this.error = {};
  }

  setError(key: string, error: string) {
    this.error[key] = error;
  }

  clearError(key: string) {
    if (this.error[key]) {
      delete this.error[key];
    }
  }
}

const uiStore = new UIStore();
export default uiStore;
