import { Band, Blockstar } from '@shared-data';
import React, { useEffect } from 'react';
import {
  IBandActionControl,
  useBandActionControlContext,
} from 'src/hooks/band-action-control';
import { KeyedMutator } from 'swr';
import { BandsGridView } from './grid-view';

export const BandsView = (props: {
  bands: Band[];
  mutateBands: KeyedMutator<Band[]>;
  blockstars: Blockstar[];
}) => {
  const { actionResult } = useBandActionControlContext() as IBandActionControl;

  useEffect(() => {
    if (actionResult) {
      props.mutateBands();
    }
  }, [actionResult]);
  return <BandsGridView bands={props.bands} blockstars={props.blockstars} />;
};
