// Structural

import React, { ComponentType, ReactNode, useContext } from 'react';
import TableContext from 'src/components/library/list-table/table-context';
import { ListTableHead, ListTableHeadRow, TableCellHeader } from './style';
type TableProps = {
  children: ReactNode;
  Header: ComponentType<any>;
};

const TableHeader = ({ children, Header }: TableProps) => {
  const { listToggle } = useContext(TableContext);

  return (
    <ListTableHead>
      <ListTableHeadRow style={{}}>
        {/* Prepended header columns */}
        {children}
        {/* Passed in header to render */}
        <Header />
        {/* Appended blank header */}
        {listToggle && (
          <TableCellHeader
            style={{
              width: '190px',
              transformOrigin: 'bottom left',
              transform: 'skew(-50deg)',
              WebkitTransform: 'skew(-50deg)',
              overflow: 'hidden',
            }}
            padding={'none'}></TableCellHeader>
        )}
        {!listToggle && (
          <TableCellHeader
            padding={'none'}
            style={{
              width: '7%',
              minWidth: '60px',
              transformOrigin: 'bottom left',
              transform: 'skew(-50deg)',
              WebkitTransform: 'skew(-50deg)',
              overflow: 'hidden',
            }}></TableCellHeader>
        )}
      </ListTableHeadRow>
    </ListTableHead>
  );
};

export default TableHeader;
