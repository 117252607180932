import { useConnection } from '@solana/wallet-adapter-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BandActionProgress from 'src/components/actions/action-progress/band-action-progress';
import BlockstarActionProgress from 'src/components/actions/action-progress/blockstar-action-progress';
import BlockstarActionSelect from 'src/components/actions/action-select/blockstar-action-select';
import ListTable from 'src/components/library/list-table';
import BlockstarsListToggles, {
  ListLayout,
} from 'src/components/library/list-table/blockstars/blockstars-list-toggles';
import BlockstarsTableHeader from 'src/components/library/list-table/blockstars/blockstars-table-header';
import BlockstarsTableRows from 'src/components/library/list-table/blockstars/blockstars-table-rows';
import { BlockstarsTableContainer } from 'src/components/library/list-table/style';
import TableContext, {
  ColumnState,
  SelectedColumn,
} from 'src/components/library/list-table/table-context';
import { BuskResults } from 'src/components/library/results/busk';
import { PracticeResults } from 'src/components/library/results/practice';
import { BandActionControlProvider } from 'src/hooks/band-action-control';
import { useBands } from 'src/hooks/bands';
import { BlockstarActionControlProvider } from 'src/hooks/blockstar-action-control';
import { useBlockstars, useCrates } from 'src/hooks/blockstars';
import rest, { BlockstarsRequests } from '../../../actions/network/rest';
import { useActionOptions } from '../../../hooks/action-options';
import { useWindowSize } from '../../../hooks/window-size';
import uiStore from '../../../stores/ui/ui';
import playerStore from '../../../stores/user/player';
import { BodyContainer } from '../../../styles/global-style';
import analytics from '../../../utils/analytics';
import {
  getLocalStorage,
  getOriginPage,
  StorageType,
} from '../../../utils/utils';
import Header from '../../header';
import { headerHeight } from '../../header/style';
import { BandPracticeResults } from '../../library/results/band-practice';
import { Loading } from '../../loading';
import { NFTList, NFTListLayout } from './nft-list';
import NoNftInfo from './no-nft-info';
import { Container } from './style';

const NFTs = () => {
  const { connection } = useConnection();
  const location = useLocation();
  const { height } = useWindowSize();
  const { blockstars, mutateBlockstars } = useBlockstars();
  const { bands, mutateBands } = useBands();
  const { crates, mutateCrates } = useCrates();
  const { durations } = useActionOptions(); // preload for actions

  useEffect(() => {
    const layout = getLocalStorage(StorageType.LIST_LAYOUT, 0);
    analytics.logEvent('ViewScreenMyBlockstars', {
      originPage: getOriginPage(location.state),
      layout: layout ?? NFTListLayout.GRID,
    });
    uiStore.clearError('wallet');
  }, []);

  const updateBlockstar = async (id: number) => {
    const response = await rest.get<any>(
      `${process.env.WORKER_URL}/${BlockstarsRequests.GetBlockstar}/${id}`,
    );
    playerStore.updateBlockstar(response?.blockstar);
    mutateBlockstars();
    mutateCrates();
  };

  const retryGetNFTInfo = () => {
    uiStore.clearError('wallet');
    mutateBlockstars();
    mutateBands();
    mutateCrates();
  };

  // Layout controls
  const [layout, setLayout] = useState<ListLayout>(() => {
    const savedLayout = getLocalStorage(StorageType.LIST_LAYOUT, 0);
    return savedLayout ? (savedLayout as ListLayout) : ListLayout.GRID;
  });

  // Checkbox controls
  const [rowsChecked, setRowsChecked] = useState<boolean[]>(
    Array(blockstars?.length).fill(false),
  );
  const [selectAllDisabled, setSelectAllDisabled] = useState(false);
  const checkAll = () => {
    const allChecked = rowsChecked.reduce((prev, curr) => prev && curr, true);
    setRowsChecked(rowsChecked.map(() => !allChecked));
  };

  // Sort Controls
  const [selectedColumn, setSelectedColumn] = useState<SelectedColumn>({
    id: 'default',
    state: ColumnState.ascending,
  });

  return (
    <Container>
      <BlockstarActionControlProvider>
        <BandActionControlProvider>
          <Header />
          <BodyContainer
            id='scroll'
            style={{
              height: `${height - headerHeight}px`,
            }}>
            {blockstars &&
            blockstars.length <= 0 &&
            crates &&
            crates.length <= 0 ? (
              <NoNftInfo />
            ) : blockstars && crates ? (
              <div>
                <TableContext.Provider
                  value={{
                    layout,
                    setLayout,
                    // Checkbox
                    listToggle: true,
                    rowsChecked,
                    setRowsChecked,
                    selectAllDisabled,
                    setSelectAllDisabled,
                    // Sort
                    selectedColumn,
                    setSelectedColumn,
                  }}>
                  <BlockstarsListToggles blockstars={blockstars} />

                  {layout === ListLayout.LIST && (
                    <BlockstarsTableContainer>
                      <ListTable
                        label='BLOCKSTAR'
                        Header={BlockstarsTableHeader}
                        Body={BlockstarsTableRows}
                        componentProps={{
                          blockstars,
                          bands,
                          crates,
                          clickableBlockstar: true,
                          updateBlockstar,
                        }}
                      />
                    </BlockstarsTableContainer>
                  )}

                  {layout === ListLayout.GRID && (
                    <NFTList
                      blockstars={blockstars}
                      crates={crates}
                      bands={bands}
                      updateBlockstar={updateBlockstar}
                    />
                  )}
                </TableContext.Provider>
              </div>
            ) : (
              <Loading
                height='300px'
                iconSize='50px'
                error={uiStore.error.wallet}
                onRetry={retryGetNFTInfo}
              />
            )}
          </BodyContainer>
          <BandActionProgress />
          <BandPracticeResults />
        </BandActionControlProvider>
        <BlockstarActionSelect />
        <BlockstarActionProgress />
        <PracticeResults />
        <BuskResults />
      </BlockstarActionControlProvider>
    </Container>
  );
};

export default observer(NFTs);
