import { Accordion } from '@mui/material';
import { styled } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import rollupLvlup from '../../assets/images/rollup_lvlup.svg';
import rollupRol from '../../assets/images/rollup_rol.svg';
import rollupStar from '../../assets/images/rollup_star.svg';
import rollupXp from '../../assets/images/rollup_xp.svg';

const RollupIconBase = styled('div')`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 125px;
`;

export const RollupXp = styled(RollupIconBase)`
  background-image: url(${rollupXp});
`;

export const RollupLvlup = styled(RollupIconBase)`
  background-image: url(${rollupLvlup});
`;

export const RollupStar = styled(RollupIconBase)`
  background-image: url(${rollupStar});
`;

export const RollupRol = styled(RollupIconBase)`
  background-image: url(${rollupRol});
`;

export const RollupInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding: 20px;
  height: 550px;
`;

export const RewardCarouselContainer = styled('div')`
  width: 100%;
  height: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RewardCarousel = styled(Carousel)`
  padding: 60px;
  padding-bottom: 0px;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  overflow: unset;
`;

export const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 555px;
  height: 590px;
  justify-content: center;
  align-items: center;
`;

export const TopContainer = styled('div')`
  display: flex;
  height: 108px;
  width: 100%;
  flex-direction: row;
  padding-left: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.ui.cellGray};
`;

export const TabContainer = styled('div')`
  display: flex;
  justify-content: center;
  height: 42px;
  width: 100%;
  color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const MidContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 300px;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
`;

export const BottomContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
`;

export const InfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 84px;
  padding-left: 16px;
`;

export const InfoItem = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const DropdownItem = styled('div')`
  display: flex;
  width: 420px;
  flex-direction: row;
  align-items: center;
`;

export const CloseContainer = styled('div')`
  display: flex;
  width: 40px;
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const StartButton = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`;

export const ButtonContent = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextContainer = styled('div')`
  display: flex;
  width: 50%;
  flex-direction: column;
  row-gap: 8px;
`;

export const TitleText = styled('span')`
  font-size: 24px;
  font-weight: bold;
`;

export const SubText = styled('span')`
  font-size: 16px;
`;

export const InfoIconContainer = styled('div')`
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.secondaryGray};
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: 8px;
`;

export const TooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 275px;
  padding: 16px;
  font-size: 16px;
`;

export const CloseButtonRow = styled('div')`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const DropdownSkillXPContainer = styled('div')`
  display: flex;
  flex: 2;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-end;
`;

export const DropdownSkillXPText = styled('div')`
  display: flex;
  height: 15px;
  font-size: 12px;
`;

export const DropdownSkillXPBar = styled('div')`
  display: flex;
  height: 15px;
  width: 100%;
  align-items: center;
  padding-left: 8px;
`;

export const DropdownLocImg = styled('div')`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: gray;
  overflow: hidden;
`;

export const DropdownLocInfo = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 91%;
  align-items: center;
`;

export const DropdownCost = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const DropdownDurInfo = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  align-items: center;
`;

export const ViewActionContainer = styled('div')`
  width: 620px;
  height: 720px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

export const ViewActionBlockstarImg = styled('img')`
  object-fit: cover;
  position: absolute;
  right: -50px;
  top: 20px;
  z-index: 1;
`;

export const ViewActionLocation = styled('div')`
  width: 100%;
  height: 275px;
  background-color: gray;
`;

export const ViewActionContent = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 40px;
`;

export const ViewActionText = styled('div')`
  width: 60%;
  font-size: 40px;
  font-weight: bold;
  height: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ViewActionInfo = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ViewActionInfoItem = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
`;

export const ProgressTextContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const ProgressText = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
`;

export const StopActionContainer = styled('div')`
  display: flex;
  width: 525px;
  height: 375px;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
`;

export const RollupInfoLevelup = styled('div')`
  margin: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

export const RollupContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  padding-top: 32px;
  align-items: center;
`;

export const RollupCloseContainer = styled('div')`
  height: 50px;
  width: 100%;
  position: absolute;
  top: 64px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
`;

export const RollupContents = styled('div')`
  display: flex;
  flex-direction: row;
  width: 1200px;
  height: 600px;
`;

export const RollupContentsInfo = styled('div')`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  /* scrollbar width */
  &::-webkit-scrollbar {
    width: 6px;
  }
  /* scrollbar Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* scrollbar Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.ui.unfilledGray};
    border-radius: 2px;
  }
  /* scrollbar Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.text.primaryGray};
  }
`;

export const XpInfoSkill = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
`;

export const XpInfoText = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  margin-bottom: 8px;
`;

export const Maxed = styled('div')`
  display: flex;
  flex-direction: row;
  width: 115px;
  height: 22px;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 40px;
  background: ${(props) => props.theme.colors.text.musicalAqua};
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
`;

export const AccordionBreakDown = styled(Accordion)`
  background-color: transparent;
  width: 100%;
`;

export const BreakDownTitle = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
`;

export const BreakDownMessageContainer = styled('div')`
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const BreakDownMessage = styled('div')`
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
