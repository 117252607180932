import {
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
  EndActionBuskingResponse,
  MemoTypes,
  RoutePath,
} from '@shared-data';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import bs58 from 'bs58';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';
import playerStore from '../stores/user/player';
import analytics from '../utils/analytics';
import { sleep } from '../utils/utils';
import rest, { ActionRequests, Web3Request } from './network/rest';
import {
  createSignedTransaction,
  createSignedTransactions,
  sendSignedTransaction,
} from './wallet';

export interface ActionOptions {
  actionType: BlockstarActionType;
  actionSkillType: string;
  durationMs: number;
  location: { id: number };
}

const getTransactionPrep = async (body: any) => {
  try {
    // get transaction data from server.
    const transactionPrep: any = await rest.post(
      `${process.env.WORKER_URL}/${Web3Request.prepROLTransaction}`,
      body,
    );
    return transactionPrep;
  } catch (e) {
    const error = `Transaction prep failed for Blockstar #${body.blockstarId}.`;
    toast.error(error);
    analytics.logEvent('TransactionComplete', {
      blockstarId: body.blockstarId,
      action: body.actionType,
      state: error,
    });
    throw new Error(error);
  }
};

export const onStartAction = async (
  publicKey: PublicKey | null,
  blockstar: Blockstar,
  connection: Connection,
  rolBalance: number,
  signTransaction: (t: Transaction) => Promise<Transaction>,
  onSuccess: (action: BlockstarActionResponse) => void,
  options: ActionOptions,
) => {
  if (!publicKey) throw new WalletNotConnectedError();

  if (!process.env.ROL_MINT_ADDRESS || !process.env.ROL_PAYMENT_ADDRESS) {
    throw new Error('Env not ready');
  }
  const body = {
    walletId: publicKey.toString(),
    blockstarId: blockstar.number,
    ...options,
  };

  const transactionPrep = await getTransactionPrep(body);
  if (!transactionPrep) {
    throw new Error('Transaction prep failed');
  }

  if (transactionPrep.signedTransactionInfo) {
    await resumePaymentOnLoad(
      transactionPrep,
      publicKey,
      connection,
      blockstar,
      onSuccess,
    );
    return;
  }
  if (transactionPrep.rolToPay > rolBalance) {
    const error = `Current $ROL balance (${rolBalance.toLocaleString()}) insufficient!`;
    toast.error(error);
    analytics.logEvent('TransactionComplete', {
      blockstarId: blockstar.number,
      action: options.actionType,
      state: error,
      cost: transactionPrep.rolToPay,
    });
    return;
  }

  // create signed transaction.
  const signedTxn = await createSignedTransaction(
    connection,
    publicKey,
    new PublicKey(process.env.ROL_PAYMENT_ADDRESS),
    new PublicKey(process.env.ROL_MINT_ADDRESS),
    signTransaction,
    transactionPrep.rolToPay,
    transactionPrep.memo,
  );

  if (!signedTxn) {
    const error = `Wallet failed to sign Solana transaction.`;
    toast.error(error);
    analytics.logEvent('TransactionComplete', {
      blockstarId: blockstar.number,
      action: options.actionType,
      state: error,
      cost: transactionPrep.rolToPay,
    });
    return;
  }

  const txnFee = (await connection.getFeeForMessage(signedTxn.compileMessage()))
    .value;
  const solBalance = await connection.getBalance(publicKey);
  if (solBalance < txnFee) {
    const error = `Insufficient $SOL balance to cover transaction fee.`;
    toast.error(error);
    analytics.logEvent('TransactionComplete', {
      blockstarId: blockstar.number,
      action: options.actionType,
      state: error,
      cost: transactionPrep.rolToPay,
    });
    return;
  }

  // setSignedTransaction(signedTxn);
  const savedTxn = await saveTransaction(
    signedTxn,
    publicKey,
    blockstar.number,
    options,
    transactionPrep.rolToPay,
  );
  if (!savedTxn) {
    // error caught in saveTransaction
    return;
  }

  // Actually send ROL via Solana.
  const verifiedTxn = await sendSavedTransaction(
    savedTxn,
    publicKey,
    connection,
  );

  return verifiedTxn;
};

interface SavedTxnData {
  transaction: Transaction;
  blockstarId: number;
  options: ActionOptions;
  rolToPay: number;
}

export const onStartActions = async (
  publicKey: PublicKey | null,
  blockstarIds: number[],
  connection: Connection,
  rolBalance: number,
  signTransaction: (t: Transaction) => Promise<Transaction>,
  signAllTransactions: (t: Transaction[]) => Promise<Transaction[]>,
  options: ActionOptions[],
) => {
  if (!publicKey) throw new WalletNotConnectedError();

  if (!process.env.ROL_MINT_ADDRESS || !process.env.ROL_PAYMENT_ADDRESS) {
    throw new Error('Env not ready');
  }

  interface TransactionPrepData {
    transactionPrep: any;
    blockstarId: number;
    options: ActionOptions;
  }

  const transactionPrepDatas: TransactionPrepData[] = [];
  const transactionPrepPromises: Promise<any>[] = [];
  for (let i = 0; i < blockstarIds.length; i++) {
    const body = {
      walletId: publicKey.toString(),
      blockstarId: blockstarIds[i],
      ...options[i],
    };

    transactionPrepPromises.push(getTransactionPrep(body));
  }
  await Promise.allSettled(transactionPrepPromises).then((results) =>
    results.forEach((r, idx) => {
      if (r.status === 'fulfilled')
        transactionPrepDatas.push({
          transactionPrep: r.value,
          blockstarId: blockstarIds[idx],
          options: options[idx],
        });
    }),
  );

  if (transactionPrepDatas.length === 0) {
    throw new Error('Transaction preps all failed');
  }

  const totalRolToPay = transactionPrepDatas.reduce(
    (prev, curr) => prev + curr.transactionPrep.rolToPay,
    0,
  );
  if (totalRolToPay > rolBalance) {
    const error = `Current $ROL balance (${rolBalance.toLocaleString()}) insufficient!`;
    toast.error(error);
    analytics.logEvent('TransactionSetComplete', {
      blockstarIds: blockstarIds,
      state: error,
      cost: totalRolToPay,
    });
    return;
  }

  // create signed transaction.
  const signedTxns = await createSignedTransactions(
    connection,
    publicKey,
    new PublicKey(process.env.ROL_PAYMENT_ADDRESS),
    new PublicKey(process.env.ROL_MINT_ADDRESS),
    signTransaction,
    signAllTransactions,
    transactionPrepDatas.map((t) => t.transactionPrep.rolToPay),
    transactionPrepDatas.map((t) => t.transactionPrep.memo),
  );

  if (!signedTxns || signedTxns.length === 0) {
    const error = `Wallet failed to sign Solana transactions.`;
    toast.error(error);
    analytics.logEvent('TransactionSetComplete', {
      blockstarIds: blockstarIds,
      state: error,
      cost: totalRolToPay,
    });
    return;
  }

  const feePromises: Promise<any>[] = [];
  for (let i = 0; i < signedTxns.length; i++) {
    if (signedTxns[i]) {
      feePromises.push(
        connection.getFeeForMessage(signedTxns[i]!.compileMessage()),
      );
    }
  }
  const totalTxnFee = (await Promise.allSettled(feePromises)).reduce(
    (prev, curr) => {
      if (curr.status === 'fulfilled') return prev + curr.value.value;
      else return prev;
    },
    0,
  );
  const solBalance = await connection.getBalance(publicKey);
  if (solBalance < totalTxnFee) {
    const error = `Insufficient $SOL balance to cover transaction fee.`;
    toast.error(error);
    analytics.logEvent('TransactionSetComplete', {
      blockstarIds: blockstarIds,
      state: error,
      cost: totalRolToPay,
      sol: totalTxnFee,
    });
    return;
  }

  const savedTxns: SavedTxnData[] = [];
  const saveTxnPromises: any[] = [];
  for (let i = 0; i < signedTxns.length; i++) {
    if (signedTxns[i]) {
      saveTxnPromises.push(
        saveTransaction(
          signedTxns[i]!,
          publicKey,
          transactionPrepDatas[i].blockstarId,
          transactionPrepDatas[i].options,
          transactionPrepDatas[i].transactionPrep.rolToPay,
        ),
      );
    } else {
      // undefined means a failure in the signed transaction creation step
      analytics.logEvent('TransactionComplete', {
        blockstarId: transactionPrepDatas[i].blockstarId,
        action: transactionPrepDatas[i].options.actionType,
        state: 'Failed to create signed transaction',
        cost: transactionPrepDatas[i].transactionPrep.rolToPay,
      });
    }
  }
  await Promise.allSettled<SavedTxnData>(saveTxnPromises).then((results) =>
    results.forEach((r) => {
      if (r.status === 'fulfilled') savedTxns.push(r.value);
    }),
  );

  // Actually send ROL via Solana.
  const txnPromises = [];
  for (let i = 0; i < savedTxns.length; i++) {
    txnPromises.push(sendSavedTransaction(savedTxns[i], publicKey, connection));
  }
  const verifiedTxns: any[] = [];
  await Promise.allSettled(txnPromises).then((results) =>
    results.forEach((r) => {
      if (r.status === 'fulfilled') verifiedTxns.push(r.value);
    }),
  );
  analytics.logEvent('TransactionSetComplete', {
    blockstarIds: blockstarIds,
    state: 'success',
    cost: totalRolToPay,
  });
  return verifiedTxns;
};

const saveTransaction = async (
  signedTxn: Transaction,
  publicKey: PublicKey,
  blockstarId: number,
  options: ActionOptions,
  rolToPay: number,
) => {
  const signatureString = bs58.encode(signedTxn.signature!);
  try {
    const body = {
      walletId: publicKey.toString(),
      blockstarId: blockstarId,
      ...options,
    };
    await rest.post(
      `${process.env.WORKER_URL}/${Web3Request.saveSignedTransaction}`,
      {
        ...body,
        signedTransaction: signedTxn.serialize(),
        signature: signatureString,
      },
    );
    return {
      transaction: signedTxn,
      blockstarId: blockstarId,
      options: options,
      rolToPay: rolToPay,
    };
  } catch (e: any) {
    const signError = `Failed to save signed Solana transaction: ${e.toString()}`;
    toast.error(signError);
    analytics.logEvent('TransactionComplete', {
      blockstarId: blockstarId,
      action: options.actionType,
      state: signError,
      cost: rolToPay,
    });
    throw new Error(signError);
  }
};

const sendSavedTransaction = async (
  savedTxnData: SavedTxnData,
  publicKey: PublicKey,
  connection: Connection,
) => {
  const { transaction, blockstarId, rolToPay, options } = savedTxnData;
  const body = {
    walletId: publicKey.toString(),
    blockstarId: blockstarId,
    ...options,
  };
  const txnToast = toast.info('Sending transactions to Solana...', {
    autoClose: 120000,
  });
  try {
    const signature = await sendSignedTransaction(transaction, connection);

    toast.dismiss(txnToast);
    toast.success(`Transaction recorded on Solana successfully!`);
    const validateToast = toast.info(
      'Validating transaction with our server...',
    );

    await sleep(1000);
    try {
      // validate signature from server.
      const verified: any = await rest.post(
        `${process.env.WORKER_URL}/${Web3Request.verifyROLTransactionSignature}`,
        { ...body, signature: signature },
      );
      if (verified.verified) {
        toast.dismiss(validateToast);
        toast.success(
          `Transaction valid! ${rolToPay} $ROL deducted; Blockstar #${blockstarId}'s action has begun.`,
        );

        analytics.logEvent('TransactionComplete', {
          blockstarId: blockstarId,
          action: options.actionType,
          state: 'success',
          cost: rolToPay,
        });
        return verified;
      } else {
        analytics.logEvent('TransactionComplete', {
          blockstarId: blockstarId,
          action: options.actionType,
          state: 'failed',
          cost: rolToPay,
        });
        toast.dismiss(validateToast);
        const error = `Transaction failed validation checks`;
        toast.error('Transaction failed ');
      }
    } catch (e: any) {
      toast.dismiss(validateToast);
      const error = `Transaction failed validation checks: ${e.toString()}`;
      toast.error(error);
      analytics.logEvent('TransactionComplete', {
        blockstarId: blockstarId,
        action: options.actionType,
        state: error,
        cost: rolToPay,
        signature: signature,
      });
      throw new Error(error);
    }
  } catch (e: any) {
    // sending transaction failed. Try again.
    toast.dismiss(txnToast);
    const error = `Transaction failed to process on Solana: ${e.toString()}`;
    toast.error(error);

    analytics.logEvent('TransactionComplete', {
      blockstarId: blockstarId,
      action: options.actionType,
      state: error,
      cost: rolToPay,
    });
    throw new Error(error);
  }
};

export const onStopAction = async (
  publicKey: PublicKey | null,
  blockstar: Blockstar,
) => {
  if (!publicKey) throw new WalletNotConnectedError();
  const body = {
    walletId: publicKey.toString(),
    blockstarId: blockstar.number,
    // actionSkillType: props.action.skills[0].name,
  };
  try {
    const result: BlockstarActionResponse = await rest.post(
      `${process.env.WORKER_URL}/${ActionRequests.EndAction}`,
      body,
    );

    if (result.actionType === BlockstarActionType.Busking) {
      const endActionBuskingResponse = result as EndActionBuskingResponse;
      if (endActionBuskingResponse.earningBreakDown) {
        analytics.logEvent('TransactionReward', {
          action: result.actionType,
          blockstarId: blockstar.number,
          grossEarn: endActionBuskingResponse.earningBreakDown.total,
          netEarn: endActionBuskingResponse.netBreakDown.total,
        });
      }
    }
    if (result?.blockstar) {
      playerStore.updateBlockstar(result.blockstar);
    }

    return result;
  } catch (e: any) {
    toast.error(`Stopping Action failed. Please try again.`);
    return { status: e.status ?? 500 };
  }
};

export const resumePaymentOnLoad = async (
  action: BlockstarActionResponse,
  publicKey: PublicKey | null,
  connection: Connection,
  blockstar: Blockstar,
  onSuccess: (action: BlockstarActionResponse) => void,
) => {
  if (
    action.signedTransactionInfo &&
    action.signedTransactionInfo.timestamp > Date.now() - 120000
  ) {
    const newAction = await resumePayment(
      publicKey,
      connection,
      action,
      blockstar,
    );
    if (newAction) {
      onSuccess(newAction);
    }
  }
};

export const resumePayment = async (
  publicKey: PublicKey | null,
  connection: Connection,
  action: BlockstarActionResponse,
  blockstar: Blockstar,
) => {
  if (
    !publicKey ||
    !action.selectedSkill ||
    !action.durationMs ||
    !action.location
  ) {
    toast.error(`Transaction retry failed`);
    return;
  }

  const signedTransaction = Transaction.from(
    (action.signedTransactionInfo?.signedTransaction as any).data,
  );

  // send money.
  const body = {
    walletId: publicKey.toString(),
    blockstarId: blockstar.number,
    actionType: action.actionType,
    actionSkillType: blockstar.skills.find(
      (item) => item.name === action.selectedSkill,
    )?.name,
    durationMs: action.durationMs,
    location: action.location,
  };
  const txnToast = toast.info('Retrying transaction on Solana...', {
    autoClose: 120000,
  });
  try {
    const signature = await sendSignedTransaction(
      signedTransaction,
      connection,
    );

    toast.dismiss(txnToast);
    toast.success(`Transaction resent to Solana successfully!`);
    const validateToast = toast.info(
      'Validating transaction with our server...',
    );

    await sleep(1000);
    try {
      // validate signature from server.
      const verified: any = await rest.post(
        `${process.env.WORKER_URL}/${Web3Request.verifyROLTransactionSignature}`,
        { ...body, signature: signature },
      );

      if (verified.verified) {
        toast.dismiss(validateToast);
        toast.success(
          `Retried transaction valid! ${action.rolToPay} $ROL deducted; Blockstar #${blockstar.number}'s action has begun.`,
        );

        return verified;
      } else {
        toast.dismiss(validateToast);
        toast.error(`Retried transaction failed validation checks.`);
      }
    } catch (e: any) {
      toast.dismiss(validateToast);
      toast.error(
        `Retried transaction failed validation checks: ${e.toString()}`,
      );
    }
  } catch (e: any) {
    // sending transaction failed. Try again.
    toast.dismiss(txnToast);
    toast.error(
      `Retried transaction failed to process on Solana: ${e.toString()}`,
    );
  }
};

export const onViewButtonClicked = (
  blockstarId: number,
  navigate: NavigateFunction,
) => {
  navigate(`${RoutePath.Blockstars}/${blockstarId}`, {
    state: { originPage: location.pathname },
  });
};

export const sendBlockstarClickActionEvent = (
  blockstar: Blockstar,
  actionType: BlockstarActionType,
) => {
  analytics.logEvent('ClickAction', {
    action: actionType,
    blockstarId: blockstar.number,
  });
};

export const payForSignature = async (
  signedTxn: Transaction,
  publicKey: PublicKey,
  connection: Connection,
  currentRolBalance: number,
  rolToPay: number,
  signTransaction: (t: Transaction) => Promise<Transaction>,
  memoType: MemoTypes,
) => {
  // Actually send ROL via Solana.
  const verifiedTxn = await sendTransaction(
    signedTxn,
    publicKey,
    connection,
    rolToPay,
    memoType,
  );
  return {
    signedTxn,
    verifiedTxn,
  };
};

const sendTransaction = async (
  signedTxn: Transaction,
  publicKey: PublicKey,
  connection: Connection,
  rolToPay: number,
  type: MemoTypes,
) => {
  const txnToast = toast.info('Sending transactions to Solana...', {
    autoClose: 120000,
  });
  try {
    const signature = await sendSignedTransaction(signedTxn, connection);
    toast.dismiss(txnToast);
    toast.success(`Transaction recorded on Solana successfully!`);
    const validateToast = toast.info(
      'Validating transaction with our server...',
    );
    return signature;
  } catch (e: any) {
    // sending transaction failed. Try again.
    toast.dismiss(txnToast);
    const error = `Transaction failed to process on Solana: ${e.toString()}`;
    toast.error(error);

    analytics.logEvent('TransactionComplete', {
      action: type,
      state: error,
      cost: rolToPay,
    });
    throw new Error(error);
  }
};
