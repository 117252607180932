import { Button, styled, TextField } from '@mui/material';
import { ButtonDefault } from '../library/style';

export const NameChangeDialogContainer = styled('div')`
  width: 433px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled('div')`
  width: 90%;
`;

export const Title = styled('h1')`
  width: 100%;
  text-align: center;
`;

export const BodyContainer = styled('div')`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

export const MessageContainer = styled('div')`
  width: 100%;
`;

export const Message = styled('p')`
  width: 100%;
  text-align: left;
  font-size: 16px;
  white-space: pre-line;
`;

export const ErrorMessage = styled('p')`
  width: 85%;
  height: 24px;
  text-align: left;
  font-size: 13px;
  font-style: 'italic';
  color: ${(props) => props.theme.colors.text.error}; ;
`;

export const NameChangeForm = styled('form')`
  display: flex;
  width: 433px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextFieldContainer = styled('div')`
  padding: 15px;
  width: 433px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
`;

export const NameTextField = styled(TextField)`
  width: 85%;

  & .MuiFilledInput-root.Mui-focused {
    background-color: white;
  }
  & .MuiFilledInput-root {
    background-color: white;
  }
  & .MuiFilledInput-root:hover {
    background-color: white;
  }
  & .MuiFilledInput-input {
    height: 25px;
    background-color: white;
    padding-left: 3px;
  }
  & .MuiFilledInput-input:focus {
    height: 25px;
    background-color: white;
    padding-left: 3px;
  }
`;

export const TooltipContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 275px;
  padding: 16px;
  font-size: 16px;
`;

export const EditIconButton = styled(Button)`
  height: 22px;
  min-width: 40px;

  :hover {
    background-color: white;
  }
`;

export const SubmitButton = styled(ButtonDefault)`
  width: 150px;
  height: 60px;
  border-radius: 38px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SubmitButtonTextContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
