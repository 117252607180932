import CakeIcon from '@mui/icons-material/Cake';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useTheme } from '@mui/material/styles';
import {
  ActionTargetType,
  Band,
  BandActionResponse,
  Blockstar,
  BlockstarActionType,
  NameChangeInfo,
  RoutePath,
  ThemeType,
} from '@shared-data';
import { useWallet } from '@solana/wallet-adapter-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WalletText } from 'src/components/details/style';
import { default as HoverComponent } from 'src/components/hover-component/hover-component';
import { AccordionDirection } from 'src/components/library/accordion-button-group';
import { DisabledBadge } from 'src/components/library/disabled-badge';
import { infoIcon } from 'src/components/library/info-icon';
import { InfoNumber } from 'src/components/library/style';
import { StarRatingTooltipComponent } from 'src/components/library/tooltip-components';
import { NameEditButton } from 'src/components/name-change/name-edit-button';
import Stars from 'src/components/star-rating';
import { BlockstarActions } from 'src/hooks/blockstar-action';
import { DividerVertical, RolIcon } from 'src/styles/global-style';
import analytics from 'src/utils/analytics';
import {
  bandSalary,
  bandSalaryCutMember,
  genreData,
  getAccountLink,
  getBandName,
  getDateTimeString,
  scanEnvString,
  truncateString,
} from 'src/utils/utils';
import { GenreIcon } from '../main/grid-view/style';
import { ActionAccordion } from '../others/action-accordion';
import { generateNavigateState } from '../others/helper';
import {
  BandName,
  BandNameContainer,
  ManagedBy,
  MiddleInfoBottomContainer,
  MiddleInfoBottomItemContainer,
  MiddleInfoBottomItemLeftContainer,
  MiddleInfoBottomItemRightBottom,
  MiddleInfoBottomItemRightContainer,
  MiddleInfoBottomItemRightTop,
  MiddleInfoContainer,
  MiddleInfoTopContainer,
  MiddleInfoTopLeftContainer,
  MiddleInfoTopRightContainer,
  StarContainer,
} from './style';

export const MiddleInfo = (props: {
  band: Band;
  members: Blockstar[];
  bandAction?: BandActionResponse;
  blockstarActions?: BlockstarActions;
  canEditNameInfo: NameChangeInfo;
  onNameChangeClicked: () => void;
}) => {
  const salaryCutInfo = bandSalaryCutMember(props.members);
  const salary = !props.band.notValid ? bandSalary(props.members) : 0;
  const genre = genreData[props.band.genre];
  const genreText = genre.displayName;
  const subgenre = genre.subgenres[props.band.subgenre.toString()];
  const subgenreText = subgenre.displayName;

  const theme = useTheme() as ThemeType;
  const navigate = useNavigate();
  const { publicKey } = useWallet();

  const onActionButtonClicked = (actionType: BlockstarActionType) => {
    switch (actionType) {
      case BlockstarActionType.View:
        navigate(`${RoutePath.Bands}/${props.band.id}`, {
          state: { originPage: location.pathname },
        });
        break;
      case BlockstarActionType.Edit:
        const musicalSlots = props.band.musicalMembers.map((member) => {
          if (!member?.blockstarId === undefined) {
            return undefined;
          }
          return {
            blockstarId: member?.blockstarId,
            skill: {
              name: member?.blockstarSkill.name,
              familyName: member?.blockstarSkill.familyName,
            },
          };
        });
        const otherSlots = props.band.generalMembers.map((member) => {
          if (!member?.blockstarId === undefined) {
            return undefined;
          }
          return {
            blockstarId: member?.blockstarId,
            skill: {
              name: member?.skill,
            },
          };
        });

        navigate(RoutePath.BandsEdit, {
          state: {
            originPage: location.pathname,
            isEdit: true,
            ...generateNavigateState(props.band),
          },
        });
        break;
      case BlockstarActionType.BandPracticing:
        // handled in accordion, do nothing here.
        break;
      case BlockstarActionType.BandGigging:
        // handled in accordion, do nothing here.
        break;
    }
  };

  return (
    <MiddleInfoContainer>
      <MiddleInfoTopContainer>
        <MiddleInfoTopLeftContainer>
          {/* name */}
          <BandNameContainer>
            <BandName>{getBandName(props.band)}</BandName>
            {!props.band.disbandedAt ? (
              <NameEditButton
                type={ActionTargetType.Band}
                canEditNameInfo={props.canEditNameInfo}
                onNameChangeClicked={props.onNameChangeClicked}
              />
            ) : (
              <DisabledBadge title={'Disbanded'} showIcon={false} />
            )}
            {!props.band.disbandedAt && props.band.notValid && (
              <DisabledBadge title={'Inactive'} showIcon={false} />
            )}
          </BandNameContainer>
          {/* number */}
          <InfoNumber>
            <PeopleAltIcon style={{ fontSize: '16px' }} />
            {`#${props.band.id}`}
          </InfoNumber>
          <StarContainer>
            {/* star */}
            <Stars
              currentStars={props.band.starRating?.currentStarRating ?? 0}
              potentialStars={props.band.starRating?.potentialStarRating ?? 5}
            />
            <HoverComponent
              hoverComponent={infoIcon}
              tooltipComponent={
                <StarRatingTooltipComponent target={ActionTargetType.Band} />
              }
            />
          </StarContainer>
          <ManagedBy>
            Managed by{' '}
            <WalletText
              style={{ textDecoration: 'none' }}
              href={getAccountLink(props.band.walletId, scanEnvString)}
              onClick={() =>
                analytics.logEvent('OpenTransactionHistory', {
                  id: `${props.band.walletId}${scanEnvString}`,
                  service: process.env.TRANSACTION_LINK_SERVICE,
                })
              }
              target='_blank'
              rel='noreferrer'>
              {truncateString(props.band.walletId)}
            </WalletText>
          </ManagedBy>
          {/* </InfoContainer> */}
        </MiddleInfoTopLeftContainer>
        <MiddleInfoTopRightContainer>
          {!props.band.disbandedAt &&
            props.band.walletId === publicKey?.toString() && (
              <ActionAccordion
                band={props.band}
                style={{ width: '148px', right: '0px' }}
                bandAction={props.bandAction?.action}
                blockstarActions={props.blockstarActions}
                accordionDirection={AccordionDirection.DOWN}
                onActionButtonClicked={onActionButtonClicked}></ActionAccordion>
            )}
        </MiddleInfoTopRightContainer>
      </MiddleInfoTopContainer>
      <MiddleInfoBottomContainer>
        {/* Creation day */}
        <GetMiddleInfoBottomItem
          icon={<CakeIcon style={{ color: theme.colors.text.secondaryGray }} />}
          title={'Formed'}
          message={getDateTimeString(new Date(props.band.createdAt), false)}
        />
        <DividerVertical style={{ height: '80%' }} />
        {/* Salary */}
        <GetMiddleInfoBottomItem
          icon={<RolIcon />}
          title={'Salary'}
          message={`${salary.toLocaleString()} | ${
            !props.band.notValid ? salaryCutInfo?.wage.bucket : ''
          } (${Math.floor(
            (!props.band.notValid ? salaryCutInfo?.wage.percentCut ?? 0 : 0) *
              100,
          )}%)`}
        />
        <DividerVertical style={{ height: '80%' }} />
        {/* Genre */}
        <GetMiddleInfoBottomItem
          icon={
            <GenreIcon style={{ color: theme.colors.text.secondaryGray }} />
          }
          title={'Genre'}
          message={`${genreText} / ${subgenreText}`}
        />
      </MiddleInfoBottomContainer>
    </MiddleInfoContainer>
  );
};

const GetMiddleInfoBottomItem = (props: {
  icon: any;
  title: string;
  message: string;
}) => {
  return (
    <MiddleInfoBottomItemContainer>
      <MiddleInfoBottomItemLeftContainer>
        {props.icon}
      </MiddleInfoBottomItemLeftContainer>
      <MiddleInfoBottomItemRightContainer>
        <MiddleInfoBottomItemRightTop>
          {props.title}
        </MiddleInfoBottomItemRightTop>
        <MiddleInfoBottomItemRightBottom>
          {props.message}
        </MiddleInfoBottomItemRightBottom>
      </MiddleInfoBottomItemRightContainer>
    </MiddleInfoBottomItemContainer>
  );
};
