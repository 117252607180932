import LockIcon from '@mui/icons-material/Lock';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import completeLvlup from '../../../assets/images/complete_lvlup.svg';
import completeStar from '../../../assets/images/complete_star.svg';
import completeXp from '../../../assets/images/complete_xp.svg';
import { InfoContainer, RolSvg } from '../../../styles/global-style';

const CompleteIconBase = styled('div')`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
`;

export const CompleteXp = styled(CompleteIconBase)`
  background-image: url(${completeXp});
`;

export const CompleteLvlup = styled(CompleteIconBase)`
  background-image: url(${completeLvlup});
`;

export const CompleteStar = styled(CompleteIconBase)`
  background-image: url(${completeStar});
`;

export const Container = styled('div')`
  background-color: white;
`;

export const NftInfoContainer = styled(InfoContainer)`
  width: 1364px;
  @media (max-width: 1364px) {
    width: 632px;
  }
`;

export const NftListToggles = styled('div')`
  width: 1364px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  align-items: center;
  @media (max-width: 1364px) {
    width: 632px;
  }
`;

export const NFTListGrid = styled('div')`
  width: 1364px;
  margin: auto;
  display: grid;
  grid-template-columns: 632px 632px;
  grid-gap: 100px;
  grid-row-gap: 100px;
  align-items: center;
  margin-bottom: 100px;
  @media (max-width: 1364px) {
    width: 632px;
    grid-template-columns: 632px;
    grid-row-gap: 50px;
  }
`;

export const NFTListList = styled('div')`
  width: 1364px;
  margin: auto;
  @media (max-width: 1364px) {
    width: 850px;
  }
`;

export const NftListItemContainer = styled('div')`
  width: 100%;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NftListItemImage = styled('img')`
  width: 270px;
  height: 100%;
  border: none;
  border-radius: 15px;
`;

export const NftListItemActionStatus = styled('div')`
  width: 246px;
  height: 60px;
  position: absolute;
  border: none;
  border-radius: 8px;
  bottom: 15px;
  left: 12px;
  background-color: white;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

export const NftListItemInfoContainer = styled('div')`
  width: 330px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const NftListItemInfoTitle = styled('h2')`
  width: 100%;
  height: 33px;
  text-align: left;
  color: ${(props) => props.theme.colors.text.primaryGray};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemInfoNumberContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
  justify-content: flex-start;
`;

export const NftListItemInfoNumber = styled('h5')`
  align-self: flex-start;
  text-align: left;
  color: ${(props) => props.theme.colors.text.secondaryGray};
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  border-radius: 4px;
  border-color: ${(props) => props.theme.colors.ui.cellGray};
  padding: 0px 3px 0px 3px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NftListItemInfoBaseContainer = styled('div')`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NftListItemInfoStars = styled('div')`
  width: 120px;
  height: 100%;
`;

export const NftListItemInfoSalary = styled('div')`
  width: 168px;
  height: 100%;
  text-align: left;
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.musicalAqua};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const NftListItemInfoInstrumentsContainer = styled('div')`
  width: 100%;
  height: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-bottom: 16px;
`;

export const NftListItemInfoInstrument = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isEnabled',
})<{
  isEnabled?: boolean;
}>`
  width: 100%;
  height: 25px;
  text-align: left;
  font-size: 16px;
  color: ${(props) =>
    props.isEnabled
      ? props.theme.colors.text.primaryGray
      : props.theme.colors.text.secondaryGray};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const NftListItemInfoActionButton = styled('button')`
  width: 100%;
  height: 50px;
  background-color: white;
  border: solid;
  border-color: ${(props) => props.theme.colors.ui.primaryPurple};
  border-width: 1px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.ui.primaryPurple};
  font-size: 15px;
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.ui.primaryPurple};
    color: white;
  }
`;

export const RolSvgStyled = styled(RolSvg)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const MusicNoteSvg = styled(MusicNoteIcon)`
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.colors.ui.primaryPurple};
  margin-right: 14px;
`;

export const LockSvg = styled(LockIcon)`
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.colors.text.secondaryGray};
  margin-right: 14px;
`;

export const ListRowMainSkillHeader = styled('div')`
  width: 99px;
  height: 100%;
  white-space: nowrap;
`;

export const ListRowMainSkillHeaderRotator = styled('div')`
  width: 155px;
  height: 70px;
  transform: translate(0px, 9px) rotate(315deg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
`;

export const ListRowOtherSkillHeader = styled('div')`
  width: 74px;
  height: 100%;
  white-space: nowrap;
`;

export const ListRowOtherSkillHeaderRotator = styled('div')`
  width: 155px;
  height: 52px;
  transform: translate(-10px, 4px) rotate(315deg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ListRowMainSkillHeaderText = styled('span')`
  padding: 0px 60px;
`;

export const ListRowOtherSkillHeaderText = styled('span')`
  padding: 0px 50px;
  white-space: nowrap;
`;

export const ListRowSkill = styled('div')`
  display: flex;
  justify-content: center;
`;

export const ListRowMusical = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const ListRowNew = styled('div')`
  display: flex;
  border: 1px solid #ff24ff;
  background-color: rgba(255, 36, 255, 0.05);
  border-radius: 6px;
  position: relative;
  height: 64px;
  align-items: center;
  justify-content: center;
`;

export const ListRowBlockstar = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0px 12px;
  width: 125px;
  text-align: center;
`;

export const ClickableBlockstar = styled('div')`
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

export const ListRowContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 76px;
`;

export const InfoNumber = styled('h5')`
  align-self: flex-start;
  text-align: center;
  color: ${(props) => props.theme.colors.text.secondaryGray};
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  border-radius: 4px;
  border-color: ${(props) => props.theme.colors.ui.cellGray};
  padding: 0px 3px 0px 3px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ListTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  position: relative;
  table-layout: fixed;
`;

export const ListTableBodyTable = styled(Table)`
  width: auto;
`;

export const ListTableHead = styled(TableHead)`
  position: sticky;
  top: 0px;
  z-index: 1;
`;

export const ListTableHeadRow = styled(TableRow)`
  background: linear-gradient(
    to top,
    ${(props) => props.theme.colors.ui.cellGray} 20%,
    white,
    white
  );
  height: 75px;
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

export const TableCellHeader = styled(TableCell)`
  border: 0px;
  position: sticky;
  top: 0px;
  overflow-y: clip;
`;

export const HidableTableCellHeader = styled(TableCellHeader, {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{
  maxWidth?: string;
}>`
  @media (max-width: 1364px) {
    display: none;
  }
`;

export const TableCellDefault = styled(TableCell)`
  /* border-top: 1px solid ${(props) => props.theme.colors.ui.lineGray}; */
  border-left: 1px solid ${(props) => props.theme.colors.ui.lineGray};
  border-collapse: collapse;
  padding-left: 3px;
  padding-right: 3px;
`;

export const HidableTableCell = styled(TableCellDefault, {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{
  maxWidth?: string;
}>`
  overflow-y: clip;
  @media (max-width: 1364px) {
    display: none;
  }
`;
