import {
  ActionStatus,
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
} from '@shared-data';
import React from 'react';
import {
  AccordionActionButton,
  AccordionButtonGroup,
  AccordionToolTip,
} from 'src/components/library/accordion-button-group';
import {
  IBlockstarActionControl,
  useBlockstarActionControlContext,
} from 'src/hooks/blockstar-action-control';
import analytics from 'src/utils/analytics';
import {
  canRunAction,
  capitalizeFirstLetter,
  isInAction,
} from '../../../utils/utils';

export const ExpandingActionButton = (props: {
  style?: React.CSSProperties;
  action?: BlockstarActionResponse;
  expanded?: boolean;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  onActionsButtonClicked: (type: BlockstarActionType) => void;
  elevation?: number;
  blockstar: Blockstar;
  onFireButtonClicked: () => void;
  fireButtonDisabledText?: string;
}) => {
  const blockstarId = props.blockstar.number;
  const {
    actionType,
    setActionType,
    setBlockstar,
    setOpenActionSelectDialog,
    setOpenActionProgressDialog,
  } = useBlockstarActionControlContext() as IBlockstarActionControl;

  const onActionButtonClicked = (type: BlockstarActionType) => {
    switch (type) {
      case BlockstarActionType.BandPracticing:
      case BlockstarActionType.BandGigging:
      case BlockstarActionType.View:
        props.onActionsButtonClicked(type);
        break;
      default:
        setBlockstar(props.blockstar);
        setActionType(type);
        if (
          props.action?.actionStatus &&
          props.action.actionStatus > ActionStatus.None
        ) {
          switch (props.action.actionStatus) {
            case ActionStatus.InAction:
              setOpenActionProgressDialog(true);
              break;
            case ActionStatus.Completed:
              setOpenActionProgressDialog(true);
              break;
          }
        } else {
          setOpenActionSelectDialog(true);
        }
        break;
    }

    analytics.logEvent('ClickAction', {
      action: type,
      blockstarId: blockstarId,
    });
  };

  const buttons = [
    <AccordionActionButton
      key={BlockstarActionType.View}
      title={'View Blockstar'}
      actionType={BlockstarActionType.View}
      onButtonClicked={() =>
        props.onActionsButtonClicked(BlockstarActionType.View)
      }
    />,
    <AccordionActionButton
      key={BlockstarActionType.Practicing}
      title={actionButtonText(
        blockstarId,
        BlockstarActionType.Practicing,
        props.action,
      )}
      actionType={BlockstarActionType.Practicing}
      onButtonClicked={() =>
        onActionButtonClicked(
          props.action?.actionType ?? BlockstarActionType.Practicing,
        )
      }
      disabled={actionButtonDisabled(
        blockstarId,
        BlockstarActionType.Practicing,
        props.action,
      )}
      tooltip={AccordionToolTip(tooltipText(blockstarId))}
    />,
    <AccordionActionButton
      key={BlockstarActionType.Busking}
      title={actionButtonText(
        blockstarId,
        BlockstarActionType.Busking,
        props.action,
      )}
      actionType={BlockstarActionType.Busking}
      onButtonClicked={() => onActionButtonClicked(BlockstarActionType.Busking)}
      disabled={actionButtonDisabled(
        blockstarId,
        BlockstarActionType.Busking,
        props.action,
      )}
      tooltip={AccordionToolTip(tooltipText(blockstarId))}
    />,
    <AccordionActionButton
      key={BlockstarActionType.Fire}
      title={'Fire'}
      actionType={BlockstarActionType.Fire}
      onButtonClicked={() => props.onFireButtonClicked()}
      disabled={!!props.fireButtonDisabledText}
      tooltip={AccordionToolTip(props.fireButtonDisabledText ?? '')}
    />,
  ];

  return (
    <AccordionButtonGroup
      style={props.style}
      expanded={props.expanded}
      onChange={props.onChange}
      elevation={props.elevation}
      buttons={buttons}
    />
  );
};

const actionButtonDisabled = (
  blockstarId: number,
  actionType: BlockstarActionType,
  action?: BlockstarActionResponse,
) => {
  let actionTypeNotMatched = action?.actionType !== actionType;
  switch (action?.actionType) {
    case BlockstarActionType.BandPracticing:
      actionTypeNotMatched = actionType !== BlockstarActionType.Practicing;
  }

  return (
    action != undefined &&
    actionTypeNotMatched &&
    isInAction(action.actionStatus)
  );
};

const actionButtonText = (
  blockstarId: number,
  actionType: BlockstarActionType,
  action?: BlockstarActionResponse,
) => {
  let result = 'Practice';
  if (actionType === BlockstarActionType.Busking) {
    result = 'Busk';
  }

  if (!actionButtonDisabled(blockstarId, actionType, action)) {
    if (action) {
      switch (action.actionStatus) {
        case ActionStatus.InAction:
          result = `${capitalizeFirstLetter(actionType)} - Check progress`;
          break;
        case ActionStatus.Completed:
          result = `${capitalizeFirstLetter(
            actionType,
          )} complete - View details`;
          break;
      }
    }
  }
  return result;
};

const tooltipText = (blockstarId: number) => {
  if (!canRunAction(blockstarId)) {
    return 'Only Origin Series of blockstar can use this action.';
  }
  return 'You can start a new action when the Blockstar is idle.';
};
