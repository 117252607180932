import { BlockstarActionType, LocationInfo } from '@shared-data';
import useSWRImmutable from 'swr/immutable';
import rest, {
  MiscRequests,
  StaticDataRequestPath,
} from '../actions/network/rest';

export const useActionOptions = (actionType?: BlockstarActionType) => {
  const {
    data: locationsData,
    error: locationError,
    mutate,
  } = useSWRImmutable<{ locations: LocationInfo[] }>(
    `${process.env.WORKER_URL}/${StaticDataRequestPath.GetLocations}`,
    rest.get,
  );

  let filteredLocation = locationsData?.locations;
  if (actionType && filteredLocation) {
    filteredLocation = locationsData!.locations?.filter((item) => {
      return item.locationTypes.includes(actionType);
    });
  }

  const { data: durations, error: durationError } = useSWRImmutable<number[]>(
    MiscRequests.ActionDurations,
    rest.get,
  );

  return {
    locations: filteredLocation ?? [],
    durations: durations ?? [],
    mutateActionOptions: mutate,
  };
};
