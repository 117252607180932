import { Connection, PublicKey } from '@solana/web3.js';
import useSWR from 'swr';
import { getNFTBaseMetas } from '../../actions/wallet';
import analytics from '../../utils/analytics';

export const useNFTs = (
  connection: Connection,
  publicKey: PublicKey | null,
) => {
  const { data, error, mutate } = useSWR(
    publicKey ? ['nfts', publicKey.toString()] : null,
    () => getNFTBaseMetas(connection, publicKey!),
  );
  if (data) {
    analytics.setUserProperties({ blockstars: data.length });
  }
  return { nfts: data, mutateNFTs: mutate };
};
