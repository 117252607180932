import { RoutePath } from '@shared-data';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import rolIcon from '../../assets/images/icon_rol.svg';
import { RolPrice, RolPriceButton } from './style';

const HeaderRolInfo = (props: { rolBalance: number | null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onRollButtonClicked = () => {
    navigate(RoutePath.Tokens, { state: { originPage: location.pathname } });
  };
  return (
    <RolPriceButton onClick={() => onRollButtonClicked()}>
      <RolPrice>
        <img
          src={rolIcon}
          className='ROL-sign'
          alt='ROL'
          width={25}
          height={25}
          style={{
            border: 'none',
            borderRadius: '50%',
            marginRight: '10px',
          }}
        />
        {(props.rolBalance ?? 0).toLocaleString()}
      </RolPrice>
    </RolPriceButton>
  );
};

export default HeaderRolInfo;
