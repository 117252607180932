import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Blockstar,
  BlockstarActionResponse,
  BlockstarActionType,
  EarningBreakDown,
  EndActionBuskingResponse,
  ErrorResponse,
  NetBreakDown,
  SpentBreakDown,
  ThemeType,
} from '@shared-data';
import * as React from 'react';
import {
  AccordionBreakDown,
  BreakDownMessage,
  BreakDownMessageContainer,
  BreakDownTitle,
  RollupContentsInfo,
  RollupInfoContainer,
  RollupRol,
} from '../../../actions/style';
import { Divider } from '../../style';

export const RolTable = (props: {
  blockstar: Blockstar;
  action: BlockstarActionResponse | EndActionBuskingResponse | ErrorResponse;
}) => {
  const theme = useTheme() as ThemeType;

  const breakDownTitle = (title: string, total: number) => {
    return (
      <BreakDownTitle>
        <div>{`${title}`}</div>
        <div>{`${total > 0 ? '+' : ''}${total.toLocaleString(undefined, {
          minimumFractionDigits: 6,
          maximumFractionDigits: parseInt(process.env.ROL_DECIMAL!),
        })} $ROL`}</div>
      </BreakDownTitle>
    );
  };
  const breakDownMessage = (title: string, amount: number) => {
    return (
      <BreakDownMessage>
        <div>{`${title}`}</div>
        <div>{`${amount > 0 ? '+' : ''}${amount.toLocaleString(undefined, {
          minimumFractionDigits: 6,
          maximumFractionDigits: parseInt(process.env.ROL_DECIMAL!),
        })} $ROL`}</div>
      </BreakDownMessage>
    );
  };

  const spentBreakDown = (breakDown: SpentBreakDown) => {
    if (!breakDown) {
      return <div />;
    }
    return (
      <AccordionBreakDown
        style={{ backgroundColor: 'transparent', color: 'white' }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon htmlColor={theme.colors.text.secondaryGray} />
          }>
          {breakDownTitle('Spent', -1 * breakDown.total)}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '370px' }} />
          <BreakDownMessageContainer>
            {breakDownMessage('Salary', -1 * breakDown.salary)}
            {breakDownMessage(
              'Location: Transportation',
              (breakDown.locationDeposit > 0 ? -1 : 1) *
                breakDown.locationDeposit,
            )}
            {breakDownMessage(
              'Location: Rental',
              (breakDown.locationDaily > 0 ? -1 : 1) * breakDown.locationDaily,
            )}
          </BreakDownMessageContainer>
        </AccordionDetails>
      </AccordionBreakDown>
    );
  };

  const earningBreakDown = (breakDown: EarningBreakDown) => {
    if (!breakDown) {
      return <div />;
    }
    return (
      <AccordionBreakDown
        style={{ backgroundColor: 'transparent', color: 'white' }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon htmlColor={theme.colors.text.secondaryGray} />
          }>
          {breakDownTitle('Total Earnings', breakDown.total)}
        </AccordionSummary>
        <AccordionDetails>
          <BreakDownMessageContainer>
            {breakDownMessage('Gross', breakDown.total + breakDown.extra)}
          </BreakDownMessageContainer>
          <BreakDownMessageContainer>
            {breakDownMessage(
              `%cut (${(props.blockstar.wage.percentCut * 100).toFixed(1)}%)`,
              (breakDown.percentCut > 0 ? -1 : 1) * breakDown.percentCut,
            )}
          </BreakDownMessageContainer>
          <BreakDownMessageContainer>
            {breakDownMessage('Salary Refund', breakDown.originSalary)}
          </BreakDownMessageContainer>
        </AccordionDetails>
      </AccordionBreakDown>
    );
  };

  const netBreakDown = (breakDown: NetBreakDown) => {
    if (!breakDown) {
      return <div />;
    }
    return (
      <AccordionBreakDown
        style={{ backgroundColor: 'transparent', color: 'white' }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon htmlColor={theme.colors.text.secondaryGray} />
          }>
          {breakDownTitle('Net', breakDown.total)}
        </AccordionSummary>
        <AccordionDetails>
          <BreakDownMessageContainer>
            {breakDownMessage('Total earnings', breakDown.earned)}
            {breakDownMessage('Total spent', -1 * breakDown.spent)}
          </BreakDownMessageContainer>
        </AccordionDetails>
      </AccordionBreakDown>
    );
  };

  // You earned +${buskingResponse.earningBreakDown?.total}
  // const buskingResponse = props.action as EndActionBuskingResponse;

  const action = props.action as EndActionBuskingResponse;
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
      }}>
      <RollupInfoContainer key='rolRollUp'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
          <RollupRol />
          <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 4 }}>
            {action.actionType === BlockstarActionType.Practicing
              ? 'PRACTICE'
              : 'BUSK'}{' '}
            COMPLETE
          </div>
          <div
            style={{
              fontSize: 40,
              fontWeight: 600,
            }}>
            $ROL Earned!
          </div>
        </div>
        <RollupContentsInfo style={{ marginTop: '48px' }}>
          {spentBreakDown(action.spentBreakDown)}
          <Divider />
          {earningBreakDown(action.earningBreakDown)}
          {/* {divider} */}
          {/* we are not showing net this time */}
          {/* {netBreakDown(buskingResponse.netBreakDown)} */}
        </RollupContentsInfo>
      </RollupInfoContainer>
    </div>
  );
};
