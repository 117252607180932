import {
  Blockstar,
  BlockstarSkillType,
  SkillInfo,
  SkillType,
} from '@shared-data';
import { PerformanceSkillFamily } from '@shared-generated/generated-instruments';
import React from 'react';
import { SkillIcon } from 'src/components/library/skill-icon';
import { SkillBar } from 'src/components/library/skill-usage-bar';
import { InfoItem, InfoNumber } from 'src/components/library/style';
import { ErrorText } from 'src/styles/global-style';
import { getBlockstarName } from 'src/utils/utils';
import { BandPreviewInfo } from '.';
import {
  ClearIcon,
  DropdownAbilityInfoAbilityNumber,
  DropdownAbilityInfoContainer,
  DropdownAbilityInfoName,
  DropdownIconImage,
  DropdownItemContainer,
  DropdownItemContainerHeader,
  DropdownItemImageContainer,
  DropdownItemImageContainerClear,
  DropdownName,
  DropdownNameBlockstar,
} from './style';

const SelectionDropDownItem = (props: {
  blockstar: Blockstar;
  targetSkill: SkillInfo;
  previewInfo?: BandPreviewInfo;
}) => {
  const imgSrc = `${process.env.ASSET_BASE_URL || ''}/${
    props.blockstar.number
  }.png`;
  return (
    <DropdownItemContainer>
      <DropdownItemImageContainer>
        <DropdownIconImage src={imgSrc} />
      </DropdownItemImageContainer>
      <DropdownNameBlockstar>
        <InfoItem style={{ height: '16px', fontSize: 16, textAlign: 'left' }}>
          {getBlockstarName(props.blockstar)}
        </InfoItem>
        <InfoNumber
          style={{
            padding: '0px 0px 0px 0px',
            width: '50px',
            height: '16px',
            backgroundColor: 'transparent',
          }}>{`#${props.blockstar.number}`}</InfoNumber>
      </DropdownNameBlockstar>
      <DropdownAbilityInfoContainer>
        <DropdownAbilityInfoName>
          {props.targetSkill.name}
        </DropdownAbilityInfoName>
        <SkillBar
          musical={props.targetSkill.type === SkillType.Instrumental}
          targetLevel={
            props.previewInfo?.ability ?? props.targetSkill.currentAbilities
          }
          currentLevel={props.targetSkill.currentAbilities}
          targetMaxLevel={100}
          width={150}></SkillBar>
        <DropdownAbilityInfoAbilityNumber>
          {props.targetSkill.currentAbilities}
        </DropdownAbilityInfoAbilityNumber>
      </DropdownAbilityInfoContainer>
    </DropdownItemContainer>
  );
};

export default SelectionDropDownItem;

export const ClearComponent = () => {
  return (
    <DropdownItemContainer>
      <DropdownItemImageContainerClear>
        <ClearIcon />
      </DropdownItemImageContainerClear>
      <DropdownName>Clear Selection</DropdownName>
    </DropdownItemContainer>
  );
};

export const SubHeader = (props: { title: string; errorMessage?: string }) => {
  return (
    <DropdownItemContainerHeader>
      <DropdownName>{props.title}</DropdownName>
      {props.errorMessage && <ErrorText> {props.errorMessage}</ErrorText>}
    </DropdownItemContainerHeader>
  );
};

export const NoneAvailable = () => {
  return (
    <DropdownItemContainer>
      <DropdownItemImageContainer />
      <DropdownName>None available</DropdownName>
    </DropdownItemContainer>
  );
};

export const SelectBlockstar = (props: {
  skill: PerformanceSkillFamily | BlockstarSkillType;
  isMusical?: boolean;
}) => {
  return (
    <DropdownItemContainer style={{ marginLeft: '-6px' }}>
      <SkillIcon
        style={{ marginRight: '0px' }}
        skill={props.skill}
        isMusical={props.isMusical}
        includeFrame={true}
      />
      <DropdownName>Choose a Blockstar</DropdownName>
    </DropdownItemContainer>
  );
};
