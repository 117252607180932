import { useWallet } from '@solana/wallet-adapter-react';
import React from 'react';
import analytics from '../../utils/analytics';
import useIsWindowFocused from '../../utils/use-is-window-focused';
import { ConnectButtonContainer, WalletButton } from './style';

let connectedWalletType: string;

const ConnectButton = () => {
  const isWindowFocused = useIsWindowFocused();
  const { wallet, connected, select, publicKey, signMessage } = useWallet();

  if (wallet?.adapter.name) {
    analytics.setUserProperties({ wallet: wallet?.adapter.name });
    if (wallet.adapter.name !== connectedWalletType) {
      analytics.logEvent('ConnectDifferentWallet', {
        previousWallet: connectedWalletType,
        wallet: wallet.adapter.name,
      });
    }
    connectedWalletType = wallet.adapter.name;
  }

  const walletButtonOnClick = () => {
    analytics.logEvent('ConnectWallet');
  };

  return (
    <ConnectButtonContainer>
      <WalletButton onClick={() => walletButtonOnClick()} />
    </ConnectButtonContainer>
  );
};

export default ConnectButton;
