import { styled } from '@mui/material';

export const DropdownItem = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;

export const DropdownCost = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const DropdownLocImg = styled('div')`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: gray;
  overflow: hidden;
`;

export const DropdownLocInfo = styled('div')`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

export const DropdownDurInfo = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  align-items: center;
`;

export const FooterTooltipTitleContainer = styled('span')`
  font-weight: bold;
  display: flex;
  flex-direction: row;
`;

export const FooterTooltipBodyContainer = styled('span')`
  display: flex;
  flex-direction: row;
`;

export const FooterTooltipBodyLeftText = styled('p')`
  width: 130px;
`;

export const FooterTooltipBodyRightText = styled('p')`
  width: 120px;
  text-align: right;
`;
