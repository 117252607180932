import { Band, BandActionEndResponse, BlockstarActionType } from '@shared-data';
import React, { useContext, useState } from 'react';

export interface IBandActionControl {
  openActionSelectDialog: boolean;
  setOpenActionSelectDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openActionProgressDialog: boolean;
  setOpenActionProgressDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openActionResultDialog: boolean;
  setOpenActionResultDialog: React.Dispatch<React.SetStateAction<boolean>>;
  actionResult?: BandActionEndResponse;
  setActionResult: React.Dispatch<
    React.SetStateAction<BandActionEndResponse | undefined>
  >;
  actionResultError?: string;
  setActionResultError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  actionButtonLoading: boolean;
  setActionButtonLoading: React.Dispatch<React.SetStateAction<boolean>>;
  actionType: BlockstarActionType;
  setActionType: React.Dispatch<React.SetStateAction<BlockstarActionType>>;
  band?: Band;
  setBand: React.Dispatch<React.SetStateAction<Band | undefined>>;
}
// our context
const BandActionControlContext = React.createContext<
  IBandActionControl | undefined
>(undefined);

// a custom hook to access our context
export const useBandActionControlContext = () =>
  useContext(BandActionControlContext);

// the context provider component
export const BandActionControlProvider = (props: { children: any }) => {
  const [band, setBand] = useState<Band | undefined>();
  const [actionType, setActionType] = useState(BlockstarActionType.Practicing);
  const [openActionSelectDialog, setOpenActionSelectDialog] = useState(false);
  const [openActionProgressDialog, setOpenActionProgressDialog] =
    useState(false);
  const [openActionResultDialog, setOpenActionResultDialog] = useState(false);
  const [actionButtonLoading, setActionButtonLoading] = useState(false);
  const [actionResult, setActionResult] = useState<
    BandActionEndResponse | undefined
  >();
  const [actionResultError, setActionResultError] = useState<
    string | undefined
  >();

  const value = React.useMemo(() => {
    if (openActionResultDialog) {
      setOpenActionSelectDialog(false);
      setOpenActionProgressDialog(false);
    } else if (openActionProgressDialog) {
      setOpenActionSelectDialog(false);
      setOpenActionResultDialog(false);
    } else if (openActionSelectDialog) {
      setOpenActionProgressDialog(false);
      setOpenActionResultDialog(false);
    }

    return {
      openActionSelectDialog,
      setOpenActionSelectDialog,
      openActionProgressDialog,
      setOpenActionProgressDialog,
      openActionResultDialog,
      setOpenActionResultDialog,
      actionResult,
      setActionResult,
      actionResultError,
      setActionResultError,
      actionButtonLoading,
      setActionButtonLoading,
      actionType,
      setActionType,
      band,
      setBand,
    };
  }, [
    openActionSelectDialog,
    openActionProgressDialog,
    openActionResultDialog,
    actionResult,
    actionResultError,
    actionButtonLoading,
    actionType,
    band,
  ]);

  return (
    <BandActionControlContext.Provider value={value}>
      {props.children}
    </BandActionControlContext.Provider>
  );
};
