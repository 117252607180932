import {
  Blockstar,
  CrateRarityNames,
  SkillType,
  TransferInfo,
} from '@shared-data';
import React from 'react';
import { ResultsData } from 'src/components/ngmi';
import { IOUBody, IOUText } from 'src/components/ngmi/style';
import { getBlockstarName } from 'src/utils/utils';
import Results, { ResultsPage } from '.';
import { SkillEntryType } from '../types';
import { StarIncrease } from './left/star-increase';
import { XpEntryProps } from './left/xp-entry';
import { XpTable } from './left/xp-table';
import { BlockstarRender } from './right/blockstar-render';

// This is used within the old ngmi dialog to replace the results steps at the end. Utilizes the newer
// system but not an entirely separate dialog like practice/busk results are.

export const NgmiResults = (props: {
  target: Blockstar;
  onClose: () => void;
  data: ResultsData;
}) => {
  const pages: ResultsPage[] = [];

  const newSkill = props.data.blockstar.skills.find(
    (s) => s.type === SkillType.Instrumental && s.new,
  );

  const xpEntryProps = getTransferData(props.data, props.target);
  pages.push({
    left: (
      <XpTable
        headerText='FIRE COMPLETE'
        mainText={
          newSkill
            ? [
                `${getBlockstarName(props.target, true)} learned`,
                `${newSkill.name}!`,
              ]
            : ['']
        }
        xpEntryProps={xpEntryProps}
        allLevelUps={props.data.levelUpInfo}
        groupGeneral
      />
    ),
    right: <BlockstarRender key='xp' blockstarId={props.target.number} />,
  });

  if (props.data.starUpInfo) {
    const { previousStars, currentStars } = props.data.starUpInfo;
    pages.push({
      left: (
        <StarIncrease
          previousStars={previousStars}
          currentStars={currentStars}
          potentialStars={props.target.starRatingInfo.maxStarRating}
        />
      ),
      right: (
        <BlockstarRender
          key='starincrease'
          blockstarId={props.target.number}
          confetti
        />
      ),
    });
  }

  pages.push({
    left: (
      <IOUBody>
        <IOUText>
          <span style={{ fontSize: 40, paddingTop: 8 }}>You got a</span>
          <span style={{ fontSize: 40, paddingBottom: 48 }}>
            {props.data.iouRarity !== undefined
              ? CrateRarityNames[props.data.iouRarity]
              : ''}{' '}
            Crate!
          </span>
          <span style={{ fontSize: 24, fontWeight: 400, textAlign: 'center' }}>
            This crate is an IOU for a future Blockstar. You will be able to
            unlock the crate when the next collection is released.
          </span>
        </IOUText>
      </IOUBody>
    ),
    right: (
      <IOUBody>
        <video
          autoPlay
          loop
          playsInline
          muted
          src={`https://assets.blockstars.gg/static/crates/${
            props.data.iouRarity ?? 0
          }.mp4`}
          style={{
            width: 400,
            height: '100%',
            border: 'none',
            borderRadius: 15,
            objectFit: 'contain',
          }}
        />
      </IOUBody>
    ),
  });

  return <Results pages={pages} onClose={props.onClose} />;
};

const getTransferData = (
  resultsData: ResultsData,
  targetBlockstar: Blockstar,
) => {
  const { transferData, blockstar, levelUpInfo } = resultsData;
  const newSkill = blockstar.skills.find((s) => s.new);

  const primarySkill = blockstar.skills[0];
  const primaryTransferData = transferData.find(
    (t) => t.type === SkillType.Instrumental,
  )!;
  const primaryLevelUpInfo = levelUpInfo.find(
    (l) => l.skill === primarySkill.name,
  );

  const data: XpEntryProps[] = [];
  data.push({
    ...primarySkill,
    ability: primarySkill.currentAbilities,
    targetExp: primarySkill.targetExp!,
    finalExp: primaryLevelUpInfo?.currentExp ?? primarySkill.currentExp ?? 0,
    gainedExp: primaryTransferData.exp,
    previousExp: targetBlockstar.skills[0].currentExp ?? 0,
    type: SkillEntryType.MUSICAL,
    levelUpInfo: primaryLevelUpInfo,
  });

  if (newSkill) {
    data.push({
      ...newSkill,
      ability: newSkill.currentAbilities,
      targetExp: newSkill.targetExp!,
      finalExp: newSkill.currentExp!,
      gainedExp: 0,
      previousExp: 0,
      type: SkillEntryType.MUSICAL,
      new: true,
    });
  }

  data.push(
    ...blockstar.skills
      .filter((s) => s.type === SkillType.General)
      .map((s, idx) => {
        const t = s.name.split(' ')[0].toUpperCase();
        const type = SkillEntryType[t as keyof typeof SkillEntryType];
        const transferInfo: TransferInfo = transferData.find(
          (t) => t.name === s.name && t.type === s.type,
        ) ?? { ...s, exp: 0 };
        const levelUp = levelUpInfo.find((l) => l.skill === s.name);
        return {
          ...s,
          ability: s.currentAbilities,
          targetExp: s.targetExp!,
          finalExp: s.currentExp!,
          gainedExp: transferInfo.exp,
          previousExp: targetBlockstar.skills[idx].currentExp ?? 0,
          type,
          levelUpInfo: levelUp,
        };
      }),
  );
  return data;
};
