import { useTheme } from '@mui/material';
import { ThemeType } from '@shared-data';
import React from 'react';
import { RolSvg } from 'src/styles/global-style';

export const ButtonRolPayText = (props: {
  title?: string;
  rolAmount: number;
  disabled?: boolean;
}) => {
  const theme = useTheme() as ThemeType;
  const color = props.disabled ? theme.colors.text.secondaryGray : 'white';
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <p
        style={{
          color: color,
        }}>
        {props.title ?? ''}
      </p>
      <RolSvg
        style={{
          width: '20px',
          height: '20px',
          marginLeft: '10px',
          marginRight: '2px',
          backgroundColor: color,
          transform: 'translateY(-5%)',
        }}
      />
      <p
        style={{
          color: color,
        }}>
        {props.rolAmount}
      </p>
    </div>
  );
};
