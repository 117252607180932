import { kebabCase } from 'lodash';
import { sleep } from './utils';

let sendMessage: (
  gameObjectName: string,
  methodName: string,
  parameter?: string,
) => void;
let unityElement: HTMLElement | null;
export const UNITY_CLASS_NAME = 'blockstar-unity';

interface UnityMethod {
  gameObject: string;
  method: string;
}
interface UnityMethods {
  [id: string]: UnityMethod;
}

export const unityMethods: UnityMethods = {
  loadSingleCharacter: {
    gameObject: 'CharacterContainer',
    method: 'LoadCharacter',
  },
  cleanSingleCharacter: {
    gameObject: 'CharacterContainer',
    method: 'CleanCharacter',
  },
  loadScene: {
    gameObject: 'SceneLoader',
    method: 'LoadScene',
  },
  enableUnityInput: {
    gameObject: 'InputManager',
    method: 'EnableUnityKeyboardInput',
  },
  disableUnityInput: {
    gameObject: 'InputManager',
    method: 'DisableUnityKeyboardInput',
  },
};

export const setUnityElement = () => {
  if (!unityElement) {
    const elements = document.getElementsByClassName(UNITY_CLASS_NAME);
    if (elements.length === 1) {
      unityElement = elements.item(0) as HTMLElement;
    }
  }
  return unityElement;
};

export const styleUnityElement = async (style: React.CSSProperties) => {
  if (!unityElement) {
    // Unity3D starts on the app start up. There's chance that the unity is not ready when this function is called.
    // In such case, wait a bit, and try to assign again.
    await sleep(500);
    styleUnityElement(style);
    return;
  }

  const stylesString = Object.keys(style).reduce((accumulator, key) => {
    // transform the key from camelCase to kebab-case
    const cssKey = kebabCase(key);
    // remove ' in value
    const cssValue = (style as any)[key].replace("'", '');
    // build the result
    // you can break the line, add indent for it if you need
    return `${accumulator}${cssKey}:${cssValue};`;
  }, '');
  const stylesSplit = stylesString.split(';');

  for (const cssStyle of stylesSplit) {
    const styleSplit = cssStyle.split(':');
    unityElement.style.setProperty(styleSplit[0], styleSplit[1]);
  }
  unityElement.style.visibility = 'visible';
};

export const hideUnityElement = async () => {
  if (!unityElement) {
    await sleep(500);
    hideUnityElement();
    return;
  }
  unityElement.style.visibility = 'hidden';
};

// this will simple unhide the view.
export const showUnityElement = () => {
  if (!unityElement) {
    console.error('Unity is not registered.');
    return;
  }
  unityElement.style.visibility = 'visible';
};

export const registerUnitySendMessage = (
  unitySendMessage: (
    gameObjectName: string,
    methodName: string,
    parameter?: string,
  ) => void,
) => {
  sendMessage = unitySendMessage;
};

export const unitySendMessage = async (
  unityMethod: UnityMethod,
  parameter?: string,
) => {
  if (!sendMessage || !unityElement) {
    // Unity3D starts on the app start up. There's chance that the unity is not ready when this function is called.
    // In such case, wait a bit, and try to assign again.
    await sleep(500);
    unitySendMessage(unityMethod, parameter);
    return;
  }

  sendMessage(unityMethod.gameObject, unityMethod.method, parameter);
};

export const showUnity = async (
  style: React.CSSProperties,
  unityMethod: UnityMethod,
  parameter?: string,
) => {
  unitySendMessage(unityMethod, parameter);
  styleUnityElement(style);
};

export const enableUnityInput = () => {
  unitySendMessage(unityMethods.enableUnityInput);
};

export const disableUnityInput = () => {
  unitySendMessage(unityMethods.disableUnityInput);
};
