import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { styled } from '@mui/material';

export const PopupHeaderContainer = styled('div')`
  width: 100%;
  /* height: 120px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;

export const PopupHeaderCollegeContainer = styled('div')`
  width: 84px;
  height: 84px;
`;

export const PopupHeaderInfoContainer = styled('div')`
  height: 84px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PopupHeaderInfoTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`;

export const PopupHeaderInfoTitle = styled('h3')`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const PopupHeaderInfoTag = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopupHeaderInfoRatingContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
`;
export const PopupHeaderInfoStars = styled('div')``;
export const PopupHeaderInfoSalary = styled('div')`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.colors.text.musicalAqua};
`;

export const PopupHeaderInfoGenreContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 6px;
`;

export const GenreIcon = styled(LibraryMusicIcon)`
  color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const GenreText = styled('p')``;
