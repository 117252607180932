import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton, useTheme } from '@mui/material';
import { ThemeType } from '@shared-data';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CloseContainer, ResultsContainer, ResultsContents } from './style';

interface IProps {
  pages: ResultsPage[];
  onClose: () => void;
}

export interface ResultsPage {
  left: any;
  right: any;
}

const Results = (props: IProps) => {
  const theme = useTheme() as ThemeType;
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
  }, []);

  const onClick = (back?: boolean) => {
    if (back) {
      setCurrentPage(currentPage - 1);
    } else if (currentPage === props.pages.length - 1) {
      props.onClose();
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const page = props.pages[currentPage];
  const lastPage = currentPage === props.pages.length - 1;

  return (
    <ResultsContainer>
      <CloseContainer>
        <div style={{ display: 'flex', color: 'white', marginRight: 12 }}>
          <IconButton
            style={{ width: 64, height: 64, fontSize: 48 }}
            color='inherit'
            onClick={props.onClose}>
            <CloseIcon fontSize='inherit' />
          </IconButton>
        </div>
      </CloseContainer>
      <ResultsContents>
        <div style={{ display: 'flex', flex: 1 }}>{page.left}</div>
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}>
          {page.right}
        </div>
      </ResultsContents>
      <div style={{ display: 'flex', height: 128 }} />
      <div
        style={{
          bottom: 32,
          display: 'flex',
          flexDirection: 'row',
          gap: 24,
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
        }}>
        {props.pages.length > 1 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 64,
              width: 64,
            }}>
            <IconButton
              onClick={() => {
                onClick(true);
              }}
              disabled={currentPage === 0}
              style={{
                width: 64,
                height: 64,
                borderRadius: 50,
                color: 'white',
                backgroundColor: '#131313',
                opacity: currentPage === 0 ? 0.5 : 1,
              }}>
              <ArrowBackIcon fontSize='inherit' />
            </IconButton>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 64,
            width: 64,
          }}>
          <IconButton
            onClick={() => {
              onClick();
            }}
            style={{
              width: 64,
              height: 64,
              borderRadius: 50,
              color: 'white',
              backgroundColor:
                currentPage === props.pages.length - 1
                  ? theme.colors.ui.primaryPurple
                  : '#131313',
            }}>
            {lastPage ? (
              <DoneIcon fontSize='inherit' />
            ) : (
              <ArrowForwardIcon fontSize='inherit' />
            )}
          </IconButton>
        </div>
      </div>
    </ResultsContainer>
  );
};

export default Results;
