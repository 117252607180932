import React from 'react';
import {
  PotentialBar,
  SkillBarBG,
  SkillBarMusical,
  SkillBarMusicalNotMatched,
  SkillBarOther,
} from './style';

export const SkillBar = (props: {
  musical: boolean;
  currentLevel: number;
  targetLevel: number;
  targetMaxLevel: number;
  width?: number;
}) => {
  const CurrentAbilityBar = props.musical
    ? props.currentLevel === props.targetLevel
      ? SkillBarMusical
      : SkillBarMusicalNotMatched
    : SkillBarOther;

  return (
    <SkillBarBG style={{ minWidth: `${props.width}px` }}>
      {props.currentLevel !== props.targetLevel && (
        <PotentialBar
          style={{
            position: 'absolute',
            left: '0px',
            height: '100%',
            width: `${(props.currentLevel / props.targetMaxLevel) * 100}%`,
          }}></PotentialBar>
      )}
      <CurrentAbilityBar
        style={{
          position: 'absolute',
          left: '0px',
          height: '100%',
          width: `${(props.targetLevel / props.targetMaxLevel) * 100}%`,
        }}
      />
    </SkillBarBG>
  );
};
