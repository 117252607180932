import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';
import loading from '../../assets/images/loading.svg';
import { CloseContainer } from '../../styles/global-style';
import { MarketplaceButton } from '../tokens/ft/style';

interface ILoadingProps {
  width?: number | string;
  height?: number | string;
  iconSize?: number | string;
  onRetry?: () => void;
  retryText?: string;
  error?: string;
  message?: string;
  messageStyle?: React.CSSProperties;
  onClose?: () => void;
  closeButtonShowTimerMs?: number;
}

const countdownRenderer = ({
  completed,
  days,
  hours,
  minutes,
  seconds,
}: CountdownRenderProps) => {
  if (minutes === 0 && seconds <= 0) {
    return '';
  }
  return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
};

export const Loading = (props: ILoadingProps) => {
  const {
    message,
    messageStyle,
    width,
    height,
    iconSize,
    error,
    retryText,
    closeButtonShowTimerMs,
    onRetry,
    onClose,
  } = props;

  const [endDate, setEndDate] = useState(
    Date.now() + (closeButtonShowTimerMs ?? 0),
  );
  const [showCloseButton, setShowCloseButton] = useState(false);
  useEffect(() => {
    setShowCloseButton(false);
    setEndDate(Date.now() + (closeButtonShowTimerMs ?? 0));
    if (onClose && closeButtonShowTimerMs === undefined) {
      setShowCloseButton(true);
    }
  }, [closeButtonShowTimerMs]);

  const countdownDone = () => {
    setShowCloseButton(true);
  };

  return (
    <div
      style={{
        width: width ?? '100%',
        height: height ?? '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {showCloseButton && (
        <CloseContainer>
          <div style={{ display: 'flex', color: 'white', marginRight: 12 }}>
            <IconButton
              style={{ width: 64, height: 64, fontSize: 48 }}
              color={'inherit'}
              onClick={onClose}>
              <CloseIcon fontSize={'inherit'} />
            </IconButton>
          </div>
        </CloseContainer>
      )}
      <object
        type='image/svg+xml'
        data={loading}
        style={{
          width: iconSize ?? '50%',
        }}>
        loading
      </object>
      {message && (
        <div>
          <p style={{ ...messageStyle, marginBottom: 8 }}>{message}</p>
        </div>
      )}
      {closeButtonShowTimerMs && (
        <div style={{ ...messageStyle, marginBottom: 8 }}>
          <Countdown
            date={endDate}
            renderer={countdownRenderer}
            onComplete={countdownDone}
          />
        </div>
      )}
      {error && onRetry && (
        <div>
          <p style={{ marginBottom: 8 }}>{error}</p>
          <MarketplaceButton onClick={onRetry}>
            {retryText ?? `Retry`}
          </MarketplaceButton>
        </div>
      )}
    </div>
  );
};
