import { ListLayout } from 'src/components/library/list-table/blockstars/blockstars-list-toggles';
import { createContext, Dispatch, SetStateAction } from 'react';

export enum ColumnState {
  ascending,
  descending,
}

export interface SelectedColumn {
  id: string;
  state: ColumnState;
}

export interface ITableContext {
  listToggle: boolean;
  selectedColumn: SelectedColumn;
  setSelectedColumn: Dispatch<SetStateAction<SelectedColumn>>;

  layout?: ListLayout;
  setLayout?: Dispatch<React.SetStateAction<ListLayout>>;

  rowsChecked?: boolean[];
  setRowsChecked?: Dispatch<SetStateAction<boolean[]>>;

  selectAllDisabled?: boolean;
  setSelectAllDisabled?: Dispatch<SetStateAction<boolean>>;
}

const TableContext = createContext<ITableContext>({
  listToggle: false,
  selectedColumn: {
    id: 'default',
    state: ColumnState.descending,
  },
  setSelectedColumn: () => {},

  layout: ListLayout.LIST,
  setLayout: () => {},

  rowsChecked: [],
  setRowsChecked: () => {},

  selectAllDisabled: false,
  setSelectAllDisabled: () => {},
});

export default TableContext;
