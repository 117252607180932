import { styled } from '@mui/material';
import completeLvlup from '../../../assets/images/complete_lvlup.svg';
import completeStar from '../../../assets/images/complete_star.svg';
import completeXp from '../../../assets/images/complete_xp.svg';

export const ActionStatusContainer = styled('div')`
  width: 90%;
  height: 60px;
  position: absolute;
  border: none;
  border-radius: 8px;
  bottom: 5%;
  left: 5%;
  background-color: white;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const ActionStatusSimpleContainer = styled(ActionStatusContainer)`
  height: 36px;
  bottom: 14px;
  left: 8%;
  width: 84%;
  padding: calc(4% + 0.1vw);
  :hover {
    cursor: pointer;
  }
`;

const CompleteIconBase = styled('div')`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
`;

export const CompleteXp = styled(CompleteIconBase)`
  background-image: url(${completeXp});
`;

export const CompleteLvlup = styled(CompleteIconBase)`
  background-image: url(${completeLvlup});
`;

export const CompleteStar = styled(CompleteIconBase)`
  background-image: url(${completeStar});
`;

export const CloseContainer = styled('div')`
  display: flex;
  width: 40px;
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const CompleteText = styled('p')`
  font-size: 14px;
`;
