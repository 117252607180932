import DiamondIcon from '@mui/icons-material/Diamond';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useTheme } from '@mui/material/styles';
import {
  ActionTargetType,
  NameChangeInfo,
  NameChangeNotAvailableReason,
  PhysicalAppearance,
  PhysicalAppearanceCategory,
  RoutePath,
  SkillType,
  ThemeType,
} from '@shared-data';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { getNFTOwner } from 'src/actions/wallet';
import { useBand } from 'src/hooks/bands';
import { useBlockstar } from 'src/hooks/blockstars';
import { DividerVertical, RolIcon } from 'src/styles/global-style';
import { useAttributeCounts } from '../../hooks/attribute-counts';
import { useWindowSize } from '../../hooks/window-size';
import analytics from '../../utils/analytics';
import {
  canBlockstarChangeName,
  genreData,
  getBlockstarName,
  getOriginPage,
  getStringFromWage,
} from '../../utils/utils';
import Header from '../header';
import HoverComponent from '../hover-component/hover-component';
import { DisabledBadge } from '../library/disabled-badge';
import { infoIcon } from '../library/info-icon';
import { SkillSummary } from '../library/skill-details';
import { ISkillEntryProps } from '../library/skill-details/skill-entry';
import { Blur, Opaque } from '../library/style';
import { SkillEntryType } from '../library/types';
import { Loading } from '../loading';
import NameChangeDialog from '../name-change';
import { AttributeEntry } from './attribute-entry';
import {
  AboutContainer,
  AboutItemContainer,
  AboutItemLeftContainer,
  AboutItemRightBottom,
  AboutItemRightContainer,
  AboutItemRightTop,
  AttributeBody,
  AttributeContainer,
  AttributeHeader,
  BackButton,
  BandButton,
  BgImage,
  Container,
  ImageContainer,
  InfoContainer,
  InfoTitle,
  MainContainer,
  SelectedImage,
  SelectedImageContainer,
  ShareContainer,
  Thumbnail,
  ThumbnailContainer,
} from './style';
import { Summary } from './summary';

interface IAttributesData {
  title: string;
  attributes: PhysicalAppearance[];
}

const Details = () => {
  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { blockstar, mutate } = useBlockstar(parseInt(id ?? '0'));
  const { bandData, mutateBand } = useBand(blockstar?.bandId);
  const attributeCounts = useAttributeCounts();
  const theme = useTheme() as ThemeType;
  const windowSize = useWindowSize();
  const bodyHeight = (windowSize.height ?? 0) - 128;

  const [invalid, setInvalidState] = useState<boolean>(false);
  const [selectedImg, setSelectedImgState] = useState(0);
  const [owner, setOwnerState] = useState();
  const [canEditNameInfo, setCanEditNameInfo] = useState<NameChangeInfo>({
    canEdit: false,
    reason: NameChangeNotAvailableReason.None,
  });
  const [nameChangeOpen, setNameChangeOpen] = useState(false);

  useEffect(() => {
    analytics.logEvent('ViewScreenBlockstar', {
      blockstarId: id,
      originPage: getOriginPage(location.state),
    });
  }, []);

  useEffect(() => {
    const nameEditInfo = canBlockstarChangeName(blockstar, publicKey, owner);
    setCanEditNameInfo(nameEditInfo);

    if (blockstar) {
      getNFTOwner(connection, new PublicKey(blockstar.mintAddress)).then(
        (owner) => {
          setOwnerState(owner?.owner);
        },
      );
      setInvalidState(false);
      mutateBand();
    } else {
      const timer = setTimeout(() => setInvalidState(true), 1000);
      return () => clearTimeout(timer);
    }
  }, [blockstar, owner, publicKey]);

  if (!blockstar && !invalid) {
    return <Loading height='300px' iconSize='50px' />;
  }

  const nameChangeStatusChanged = () => {
    setNameChangeOpen(!nameChangeOpen);
  };

  const attributesTooltip = (
    <div style={{ color: theme.colors.text.primaryGray }}>
      <p
        style={{
          marginBottom: 8,
          fontSize: 16,
        }}>
        {"What's this?"}
      </p>
      <p style={{ fontSize: 12, lineHeight: 1.5 }}>
        {
          'Physical attributes are immutable cosmetic characteristics that Blockstars are minted with at birth. They have varying degrees of rarity. The number shown next to each attribute shows the number of times that attribute occurs within the collection.'
        }
        <br />
        <br />
        <div
          style={{
            fontSize: 16,
            margin: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 8,
            color: theme.colors.ui.primaryPurple,
            float: 'left',
          }}>
          <DiamondIcon fontSize={'inherit'} />
        </div>
        {
          'Physical attributes that show up fewer than 111 times across all Blockstars in their collection.'
        }
      </p>
      <div style={{ display: 'flex', flexDirection: 'row' }}></div>
    </div>
  );

  const baseUrl = process.env.ASSET_BASE_URL || '';
  const imageSources = [
    `${baseUrl}/${blockstar?.number}.png`,
    `${baseUrl}/${blockstar?.number}_back.png`,
    `${baseUrl}/${blockstar?.number}_portrait.png`,
    `${baseUrl}/${blockstar?.number}.gif`,
  ];
  const imageStatsName = ['front', 'back', 'portrait', 'gif'];

  const onThumbnailClick = (idx: number, isGif: boolean, src: string) => {
    if (idx === selectedImg) return;
    setSelectedImgState(idx);
    if (isGif) {
      const img = document.getElementById('gif') as HTMLImageElement;
      img.src = '';
      img.src = src;
    }
    analytics.logEvent('ClickBlockstarImage', {
      blockstarId: id,
      image: imageStatsName[idx],
      currentPage: location.pathname,
    });
  };

  const skills: ISkillEntryProps[] = [];
  const attributes: IAttributesData[] = [];
  if (blockstar) {
    blockstar.skills
      .filter((s) => s.type === SkillType.Instrumental)
      .forEach((s) => {
        skills.push({
          type: SkillEntryType.MUSICAL,
          title: s.name,
          currentLevel: s.currentAbilities,
          minPotential: s.potentialLow,
          maxPotential: s.potentialHigh,
          currentExp: s.currentExp ?? 0,
          targetExp: s.targetExp ?? 1,
          maxed: s.maxed,
        });
      });
    blockstar.skills
      .filter((s) => s.type === SkillType.General)
      .forEach((s) => {
        const type = s.name.split(' ')[0].toUpperCase();
        skills.push({
          type: SkillEntryType[type as keyof typeof SkillEntryType],
          title: s.name,
          currentLevel: s.currentAbilities,
          minPotential: s.potentialLow,
          maxPotential: s.potentialHigh,
          currentExp: s.currentExp ?? 0,
          targetExp: s.targetExp ?? 1,
          maxed: s.maxed,
        });
      });

    attributes.push({
      title: PhysicalAppearanceCategory.Musical,
      attributes: blockstar.physicalAttributes.filter(
        (a) => a.category === PhysicalAppearanceCategory.Musical,
      ),
    });
    attributes.push({
      title: PhysicalAppearanceCategory.Head,
      attributes: blockstar.physicalAttributes.filter(
        (a) => a.category === PhysicalAppearanceCategory.Head,
      ),
    });
    attributes.push({
      title: PhysicalAppearanceCategory.Top,
      attributes: blockstar.physicalAttributes.filter(
        (a) => a.category === PhysicalAppearanceCategory.Top,
      ),
    });
    attributes.push({
      title: PhysicalAppearanceCategory.Bottom,
      attributes: blockstar.physicalAttributes.filter(
        (a) => a.category === PhysicalAppearanceCategory.Bottom,
      ),
    });
    attributes.push({
      title: PhysicalAppearanceCategory.Other,
      attributes: blockstar.physicalAttributes.filter(
        (a) => a.category === PhysicalAppearanceCategory.Other,
      ),
    });
  }

  return (
    <div>
      <Header>
        <BackButton
          onClick={() =>
            navigate(RoutePath.Blockstars, {
              state: { originPage: location.pathname },
            })
          }>
          {'<      Back to My Blockstars'}
        </BackButton>
        <ShareContainer>
          <span
            style={{
              fontSize: 16,
              textAlign: 'center',
              marginRight: 12,
              color: theme.colors.text.primaryGray,
            }}>
            {'Share on:'}
          </span>
          <TwitterShareButton
            style={{ display: 'flex', marginRight: 12 }}
            url={`https://app.blockstars.gg${location.pathname}`}
            title={
              'Check out this awesome #Blockstar! 🤘🎸 Rockstars on the #Blockchain!'
            }
            hashtags={['play', 'games', 'solana', 'playandearn']}
            beforeOnClick={() =>
              analytics.logEvent('ClickShareButton', {
                target: 'twitter',
                currentPage: location.pathname,
              })
            }>
            <TwitterIcon size={36} borderRadius={8} />
          </TwitterShareButton>
          <FacebookShareButton
            style={{ display: 'flex' }}
            url={`https://app.blockstars.gg${location.pathname}`}
            quote={
              'Check out this awesome #Blockstar! Rockstars on the #Blockchain! #play #games #solana #playandearn'
            }
            beforeOnClick={() =>
              analytics.logEvent('ClickShareButton', {
                target: 'facebook',
                currentPage: location.pathname,
              })
            }>
            <FacebookIcon size={36} borderRadius={8} />
          </FacebookShareButton>
        </ShareContainer>
      </Header>
      <MainContainer style={{ height: bodyHeight }}>
        {invalid ? (
          <div style={{ margin: 48 }}>Invalid Blockstar ID</div>
        ) : blockstar ? (
          <Container>
            <ImageContainer>
              <BgImage src={imageSources[0]} />
              <Blur />
              <SelectedImageContainer>
                <SelectedImage src={imageSources[selectedImg]} />
                {blockstar.fired && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 12,
                      right: 12,
                      zIndex: 1,
                    }}>
                    <DisabledBadge title={'FIRED'} showIcon={true} />
                  </div>
                )}
              </SelectedImageContainer>
              <ThumbnailContainer>
                {imageSources.map((val, idx) => {
                  const style: React.CSSProperties = {
                    boxShadow: '0px 0px 0px 2px white',
                  };
                  const isGif = val.includes('.gif');
                  return (
                    <Thumbnail
                      key={idx}
                      id={isGif ? 'gif' : undefined}
                      src={val}
                      onClick={() => onThumbnailClick(idx, isGif, val)}
                      style={idx === selectedImg ? style : undefined}
                    />
                  );
                })}
              </ThumbnailContainer>
              {blockstar.fired && <Opaque />}
            </ImageContainer>
            <InfoContainer>
              <Summary
                name={getBlockstarName(blockstar)}
                wallet={owner ?? ''}
                number={blockstar.number}
                currentStars={+blockstar.starRatingInfo.currentStarRating}
                potentialStars={+blockstar.starRatingInfo.maxStarRating}
                fired={blockstar.fired}
                canEditNameInfo={canEditNameInfo}
                bandId={blockstar.bandId}
                onNameChangeClicked={nameChangeStatusChanged}
              />
              <div>
                <InfoTitle>About</InfoTitle>
                <AboutContainer>
                  {/* Salary */}
                  <GetAboutItem
                    icon={<RolIcon />}
                    title={'Salary'}
                    message={`${getStringFromWage(blockstar.wage)}`}
                  />
                  <DividerVertical style={{ height: '80%' }} />
                  {/* Band */}
                  <GetAboutItem
                    icon={
                      <PeopleAltIcon
                        style={{ color: theme.colors.text.secondaryGray }}
                      />
                    }
                    title={'Band'}
                    message={
                      bandData ? (
                        <BandButton
                          onClick={() =>
                            navigate(`${RoutePath.Bands}/${bandData.band.id}`, {
                              state: { originPage: location.pathname },
                            })
                          }>{`${
                          bandData.band.customName?.name ??
                          genreData[bandData.band.genre].subgenres[
                            bandData.band.subgenre.toString()
                          ].displayName
                        }`}</BandButton>
                      ) : (
                        'Not in a band'
                      )
                    }
                    messageStyle={{ color: theme.colors.text.secondaryGray }}
                  />
                </AboutContainer>
              </div>
              <SkillSummary skills={skills} />
              <div>
                <InfoTitle>
                  <span style={{ marginRight: 12 }}>Physical Attributes</span>
                  <HoverComponent
                    hoverComponent={infoIcon}
                    tooltipComponent={attributesTooltip}
                  />
                </InfoTitle>
                {attributes.map((val, idx) => {
                  return (
                    <AttributeContainer key={idx}>
                      <AttributeHeader>{val.title}</AttributeHeader>
                      <AttributeBody>
                        {val.attributes.map((a, idx) => {
                          const count =
                            attributeCounts?.[a.trait_type]?.[a.value];
                          return (
                            <AttributeEntry
                              key={idx}
                              type={a.trait_type}
                              value={a.value}
                              rarity={count}
                            />
                          );
                        })}
                      </AttributeBody>
                    </AttributeContainer>
                  );
                })}
              </div>
            </InfoContainer>
          </Container>
        ) : (
          <Loading height='300px' iconSize='50px' />
        )}
      </MainContainer>
      <NameChangeDialog
        open={nameChangeOpen}
        target={blockstar!}
        nameChangeType={ActionTargetType.Blockstar}
        updateTarget={() => mutate()}
        onClose={nameChangeStatusChanged}
      />
    </div>
  );
};

const GetAboutItem = (props: {
  icon: any;
  title: string;
  message: string | JSX.Element;
  messageStyle?: React.CSSProperties;
}) => {
  return (
    <AboutItemContainer>
      <AboutItemLeftContainer>{props.icon}</AboutItemLeftContainer>
      <AboutItemRightContainer>
        <AboutItemRightTop>{props.title}</AboutItemRightTop>
        <AboutItemRightBottom style={{ ...props.messageStyle }}>
          {props.message}
        </AboutItemRightBottom>
      </AboutItemRightContainer>
    </AboutItemContainer>
  );
};

export default Details;
