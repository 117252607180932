import { styled } from '@mui/material';
import { IconBase } from '../style';

export const SkillTitle = styled('div')`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 24px;
  color: ${(props) => props.theme.colors.text.primaryGray};
  align-items: center;
`;

export const InfoLabelContainer = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 16px;
  padding-right: 24px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const BarBG = styled('div')`
  display: flex;
  flex: 1;
  margin-left: 24px;
  margin-right: 24px;
  height: 8px;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  border-radius: 20px;
`;

export const CurrentBarMusical = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.ui.musicalCyan};
`;

export const PotentialBar = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.text.secondaryGray};
`;

// skill-entry.tsx
export const SkillAbilityContainer = styled('div')`
  display: flex;
  width: 80px;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const SkillEntryContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const CurrentAbility = styled(SkillAbilityContainer)`
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const PotentialAbility = styled(SkillAbilityContainer)`
  color: ${(props) => props.theme.colors.text.primaryGray};
  margin-left: 24px;
`;

export const XpInfo = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0px 24px;
`;

export const XpText = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 8px;
`;

export const XpBar = styled('div')`
  display: flex;
  width: 100%;
  height: 8px;
`;

// skill.tsx
export const LockedText = styled('div')`
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const MusicalLabel = styled('div')`
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 14px;
  color: ${(props) => props.theme.colors.text.musicalAqua};
`;

export const SkillTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
`;

export const BlockstarImageContainer = styled(IconBase)`
  position: relative;
`;
export const BlockstarImage = styled('img')`
  width: 36px;
  height: 36px;
  border-radius: 6px;
`;
