import { Blockstar, Players, TutorialStatus } from '@shared-data';
import { makeAutoObservable } from 'mobx';
import analytics from '../../utils/analytics';

class PlayerStore {
  loginChecked: boolean;

  player?: Players | null;

  blockstars?: Blockstar[];

  constructor() {
    this.loginChecked = false;

    makeAutoObservable(this);
  }

  setLoginChecked(loginChecked: boolean) {
    this.loginChecked = loginChecked;
  }

  setPlayer(player?: Players) {
    let result = player;
    if (result) {
      const { tutorialStatus, ...playerOnly } = result;
      const tutorialStat: TutorialStatus = JSON.parse(tutorialStatus as string);
      result = { ...playerOnly, tutorialStatus: tutorialStat };
    }
    this.player = result;
    if (this.player) {
      analytics.setUserId(this.player.publicKey);
    }
  }

  setBlockstars(blockstars?: Blockstar[]) {
    blockstars?.sort((a, b) => {
      return a.number - b.number;
    });

    this.blockstars = blockstars;
  }

  updateBlockstar(blockstar: Blockstar) {
    if (this.blockstars) {
      const idx = this.blockstars.findIndex(
        (b) => b.number === blockstar.number,
      );
      this.blockstars[idx] = blockstar;
    }
  }
}

const playerStore = new PlayerStore();
export default playerStore;
