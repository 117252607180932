import { styled } from '@mui/material/styles';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
`;

export const ConnectButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
`;

export const WalletButton = styled(WalletMultiButton)`
  width: 400px;
  height: 80px;
  background-image: linear-gradient(
    ${(props) => props.theme.colors.legacy.blue},
    ${(props) => props.theme.colors.ui.primaryPurple}
  );
  border: none;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
`;
