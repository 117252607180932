import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomMenuItem } from './style';

export interface DropdownComponentData {
  disabledTooltip?: {
    placement?:
      | 'bottom-end'
      | 'bottom-start'
      | 'bottom'
      | 'left-end'
      | 'left-start'
      | 'left'
      | 'right-end'
      | 'right-start'
      | 'right'
      | 'top-end'
      | 'top-start'
      | 'top';
    title: JSX.Element;
  };
  component: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  disableOpacityChange?: boolean;
  disabled?: boolean | undefined;
  isSubHeader?: boolean | undefined;
}

export const Dropdown = (props: {
  placeHolder?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  components: DropdownComponentData[];
  onChange: (idx: number) => void;
  label?: string;
  id?: string;
  style?: React.CSSProperties;
  defaultSelected?: number;
  value?: number;
  disabled?: boolean;
  error?: boolean;
  arrowColor?: string;
}) => {
  const [selectedValue, setSelectedValue] = useState<any>(
    props.defaultSelected,
  );
  const onChange = (event: SelectChangeEvent<number>) => {
    setSelectedValue(event.target.value);
    props.onChange(event.target.value as number);
  };

  useEffect(() => {
    if (props.value && props.value < 0) {
      setSelectedValue(undefined);
    }
  }, [props.value]);

  const { width: _, ...selectStyle } = props.style ?? {};
  return (
    <FormControl
      disabled={props.disabled}
      error={props.error}
      style={{ width: props.style?.width }}>
      {props.label && props.id && (
        <InputLabel htmlFor={`${props.id}`}>{props.label}</InputLabel>
      )}
      <Select
        sx={{
          backgroundColor: 'white',
          height: 70,
          '& .MuiSelect-select': { pt: 0, pb: 0 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: props.arrowColor ?? '#783BFF',
          },
        }}
        style={selectStyle}
        id={props.label ? props.id : undefined}
        label={props.label}
        defaultValue={props.defaultSelected ?? 0}
        value={props.value}
        renderValue={
          selectedValue !== undefined
            ? undefined
            : props.placeHolder
            ? () => props.placeHolder
            : () => props.components[props.value ?? 0].component
        }
        IconComponent={(selectProps) => {
          // somehow this doesn't take theme.color.ui.primaryPurple as color.
          if (props.components.length > 1) {
            return (
              <ArrowDropDownIcon
                {...selectProps}
                style={{ color: props.arrowColor ?? '#783BFF' }}
              />
            );
          }
          return <div />;
        }}
        onChange={onChange}>
        {props.components.map((val, idx) => {
          if (!val.isSubHeader) {
            return val.disabled && val.disabledTooltip ? (
              <Tooltip
                key={idx}
                placement={val.disabledTooltip.placement}
                title={val.disabledTooltip.title}>
                <div style={{ width: '96%' }}>
                  <CustomMenuItem
                    disableOpacityChange={val.disableOpacityChange}
                    disabled={val.disabled}
                    key={idx}
                    value={idx}
                    style={{ display: 'flex' }}>
                    {val.component}
                  </CustomMenuItem>
                </div>
              </Tooltip>
            ) : (
              <CustomMenuItem
                disableOpacityChange={val.disableOpacityChange}
                disabled={val.disabled}
                key={idx}
                value={idx}
                style={{ display: 'flex' }}>
                {val.component}
              </CustomMenuItem>
            );
          }
          return (
            <ListSubheader key={idx} style={{ padding: '0px' }}>
              {val.component}
            </ListSubheader>
          );
        })}
      </Select>
    </FormControl>
  );
};
