import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
} from '@mui/material';
import { Blockstar, LevelUpInfo, ThemeType } from '@shared-data';
import * as React from 'react';
import { useState } from 'react';
import { calculateProgress, getSkillName } from 'src/utils/utils';
import { MaxReached } from '../../max-reached';
import { NewBadge } from '../../new';
import { ProgressBar } from '../../progress-bar';
import { Skill } from '../../skill-details/skill';
import { XpInfoSkill, XpInfoText } from '../../style';
import { SkillEntryType } from '../../types';

export interface XpEntryProps {
  name: string;
  type: SkillEntryType;
  ability: number;
  targetExp: number;
  finalExp: number;
  gainedExp: number;
  previousExp: number;
  new?: boolean;
  levelUpInfo?: LevelUpInfo;
  blockstar?: Blockstar;
}

export const XpEntry = (props: XpEntryProps) => {
  const theme = useTheme() as ThemeType;
  const color = theme.colors.ui.specialPink;
  const [animate, setAnimate] = useState(false);
  const startingProgress = calculateProgress(
    props.previousExp,
    props.targetExp,
    true,
  );
  let progress = calculateProgress(
    props.finalExp,
    props.levelUpInfo?.targetExp ?? props.targetExp,
    true,
  );
  if (props.levelUpInfo?.maxed) {
    progress = 100;
  }
  const name = getSkillName(props.name, props.type);
  const levels = props.levelUpInfo
    ? props.levelUpInfo.currentAbility - props.levelUpInfo.previousAbility
    : undefined;

  const onClick = () => {
    setAnimate(true);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Accordion
        onChange={onClick}
        style={{ background: 'none', color: 'white' }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon htmlColor={theme.colors.text.secondaryGray} />
          }>
          <XpInfoSkill style={{ marginBottom: 0 }}>
            <Skill
              title={name}
              type={props.type}
              width={100}
              skillTextColor='white'
              blockstar={props.blockstar}
            />
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              {props.levelUpInfo?.maxed && (
                <span style={{ marginRight: 8 }}>
                  <MaxReached />
                </span>
              )}
              {props.new && (
                <span style={{ marginRight: 8 }}>
                  <NewBadge />
                </span>
              )}
              {levels && (
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 8,
                    color: theme.colors.ui.primaryPurple,
                  }}>
                  +{levels}
                </span>
              )}
              {`Ability ${props.ability}`}
            </span>
          </XpInfoSkill>
        </AccordionSummary>
        <AccordionDetails>
          {props.levelUpInfo?.maxed ? (
            <XpInfoText style={{ marginTop: 12 }}>
              <span />
              <span>
                <span
                  style={{
                    color,
                    marginRight: 8,
                  }}>{`+${Math.floor(props.gainedExp)}`}</span>
                Full potential reached!
              </span>
            </XpInfoText>
          ) : (
            <XpInfoText style={{ marginTop: 12 }}>
              <span>To next ability</span>
              <span>
                <span
                  style={{
                    color,
                    marginRight: 8,
                  }}>{`+${Math.floor(props.gainedExp).toLocaleString()}`}</span>
                {`${Math.floor(props.finalExp).toLocaleString()}/${Math.floor(
                  props.levelUpInfo?.targetExp ?? props.targetExp,
                ).toLocaleString()} XP`}
              </span>
            </XpInfoText>
          )}
          <div style={{ display: 'flex', width: '100%' }}>
            <ProgressBar
              currentLevel={props.new ? 0 : startingProgress}
              nextLevel={props.new ? 0 : progress}
              color={color}
              loops={props.new ? undefined : levels}
              delayAnimation={!animate}
              autoFill
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
