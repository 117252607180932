import { Dialog } from '@mui/material';
import React from 'react';
import {
  ButtonContainer,
  CancelButton,
  ConfirmButton,
  SimpleDialogContainer,
  SimpleDialogMessage,
  SimpleDialogTitle,
  TextContainer,
} from './style';

export const SimpleDialog = (props: {
  open: boolean;
  title: string | JSX.Element;
  message?: string | JSX.Element;
  okTitle?: string | JSX.Element;
  cancelTitle?: string;
  okButtonLoading?: boolean;
  onOKButtonClicked?: () => void;
  onCancelButtonClicked?: () => void;
}) => {
  return (
    <Dialog
      open={props.open}
      sx={{ minWidth: '650px', minHeight: '600px' }}
      onClose={props.onCancelButtonClicked}>
      <SimpleDialogContainer>
        {/* <DialogClose onClose={props.onCancelButtonClicked} /> */}
        <TextContainer>
          <SimpleDialogTitle>{props.title}</SimpleDialogTitle>
          <SimpleDialogMessage>{props.message}</SimpleDialogMessage>
        </TextContainer>
        <ButtonContainer>
          <ConfirmButton
            onClick={props.onOKButtonClicked}
            loading={props.okButtonLoading}>
            {props.okTitle ?? 'Ok'}
          </ConfirmButton>
          <CancelButton onClick={props.onCancelButtonClicked}>
            {props.cancelTitle ?? 'Cancel'}
          </CancelButton>
        </ButtonContainer>
      </SimpleDialogContainer>
    </Dialog>
  );
};
