import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParsedTransactions } from '../../../hooks/wallet/parsed-transactions';
import { useRolBalance } from '../../../hooks/wallet/rol-balance';
import { useWindowSize } from '../../../hooks/window-size';
import uiStore from '../../../stores/ui/ui';
import {
  BodyContainer,
  InfoBody,
  InfoContainer,
  InfoHeader,
  RolSvg,
} from '../../../styles/global-style';
import analytics from '../../../utils/analytics';
import { getOriginPage, TransactionLinkType } from '../../../utils/utils';
import Header from '../../header';
import { headerHeight } from '../../header/style';
import { Loading } from '../../loading';
import NoRolInfo from './no-rol-info';
import RolActivity from './rol-activity';
import RolChart from './rol-chart';
import { Container, InfoPrice, InfoPriceTag, RolChartContainer } from './style';

const FungibleTokenSummary = () => {
  const { connection } = useConnection();
  const location = useLocation();
  const { height } = useWindowSize();
  const { publicKey } = useWallet();
  const { rolBalance, mutateBalance } = useRolBalance(connection, publicKey);
  const { parsedTransactions: transactions, mutateParsedTransactions } =
    useParsedTransactions(connection, publicKey);

  useEffect(() => {
    analytics.logEvent('ViewScreenFinance', {
      originPage: getOriginPage(location.state),
    });
    uiStore.clearError('wallet');
  }, []);

  const retryGetRolInfo = () => {
    uiStore.clearError('wallet');
    mutateBalance();
    mutateParsedTransactions();
  };

  return (
    <Container>
      <Header />
      <BodyContainer
        style={{
          height: `${height - headerHeight}px`,
        }}>
        {transactions && transactions.length <= 0 && !rolBalance ? (
          <NoRolInfo />
        ) : (
          <div>
            <InfoContainer style={{ marginBottom: '30px' }}>
              <InfoHeader>$ROL Summary</InfoHeader>
              <InfoBody style={{ marginBottom: '30px' }}>
                Here&apos;s a summary of your recent transactions.
              </InfoBody>
              <InfoPriceTag>Total $ROL</InfoPriceTag>
              <InfoPrice>
                <RolSvg />
                {rolBalance.toLocaleString()}
              </InfoPrice>
            </InfoContainer>
            {transactions ? (
              <RolChartContainer>
                <ParentSize>
                  {({ width, height }) => (
                    <RolChart
                      width={width}
                      height={height}
                      transactions={transactions.flat()}
                      totalBalance={rolBalance}
                    />
                  )}
                </ParentSize>
              </RolChartContainer>
            ) : (
              <Loading
                height='300px'
                iconSize='50px'
                error={uiStore.error['wallet']}
                onRetry={retryGetRolInfo}
              />
            )}
            <InfoContainer style={{ marginTop: '50px' }}>
              <InfoHeader>$ROL Activity</InfoHeader>
              <InfoBody>
                {`View transaction details on ${
                  process.env.TRANSACTION_LINK_SERVICE?.replace('-', ' ') ??
                  TransactionLinkType.SOLSCAN
                } by clicking on the
                Transaction ID.`}
              </InfoBody>
            </InfoContainer>
            {transactions ? (
              <RolActivity transactions={transactions.flat()} />
            ) : (
              <Loading
                height='300px'
                iconSize='50px'
                error={uiStore.error['wallet']}
                onRetry={retryGetRolInfo}
              />
            )}
          </div>
        )}
      </BodyContainer>
    </Container>
  );
};

export default observer(FungibleTokenSummary);
