import React, { useEffect, useState } from 'react';

export const FadeIn = (props: {
  style?: React.CSSProperties;
  children: any;
}) => {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    setOpacity(1);
  }, []);

  const defaultStyle: React.CSSProperties = {
    transition: 'opacity 1s linear',
  };

  return (
    <div style={{ ...defaultStyle, ...props.style, opacity: opacity }}>
      {props.children}
    </div>
  );
};
