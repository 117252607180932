import React from 'react';
import {
  CollageFrame,
  CollageImage,
  Opaque,
} from 'src/components/library/style';
import { getBandCollegeColumnRowCount } from 'src/utils/utils';
import { ImageContainer } from './style';

export const TopImage = (props: {
  memberIds: number[];
  disbanded: boolean;
  inactive?: boolean;
}) => {
  const collageColumnRowCount = getBandCollegeColumnRowCount(
    props.memberIds.length,
  );

  return (
    <ImageContainer>
      {(props.disbanded || props.inactive) && <Opaque />}
      <CollageFrame
        style={{ width: '350px', height: '350px' }}
        collageColumnRowCount={collageColumnRowCount}>
        {props.memberIds.map((memberId, index) => {
          return (
            <CollageImage
              key={index}
              src={`${process.env.ASSET_BASE_URL}/${memberId}.png`}
            />
          );
        })}
      </CollageFrame>
    </ImageContainer>
  );
};
