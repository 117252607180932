import CheckIcon from '@mui/icons-material/Check';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { LoadingButton } from '@mui/lab';
import { Button, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import firedIcon from 'src/assets/images/fired.svg';
import { RolSvg } from '../../styles/global-style';

export const IconBase = styled('div')`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 16px;
  font-size: 16px;
  color: white;
`;

export const AboutIconBG = styled(IconBase)`
  position: relative;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
`;

export const AboutRolFill = styled('div')`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  background: ${(props) => props.theme.colors.text.primaryGray};
`;

export const AboutRolIcon = styled(RolSvg)`
  width: 16px;
  height: 16px;
  margin-right: 0px;
  background: ${(props) => props.theme.colors.ui.cellGray};
`;

// color baked from musicalAqua with 15% opacity over white bg
export const MusicalIconBG = styled(IconBase)`
  background: radial-gradient(#ffffff, #d9f1f7);
  color: ${(props) => props.theme.colors.text.musicalAqua};
`;

// color baked from primaryPurple with 15% opacity over white bg
export const GeneralIconBG = styled(IconBase)`
  background-color: #ebe2ff;
  color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const GrayIconBG = styled(IconBase)`
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const AboutLabel = styled('span')`
  font-size: 12px;
  color: ${(props) => props.theme.colors.text.primaryGray};
`;

export const BarBG = styled('div')`
  display: flex;
  flex: 1;
  margin-left: 24px;
  margin-right: 24px;
  height: 8px;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  border-radius: 20px;
  position: relative;
`;

export const CurrentBar = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const CurrentBarMusical = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.ui.musicalCyan};
`;

export const CurrentBarMusicalNotMatched = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.ui.notMatched};
`;

export const PotentialBar = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const XpGainBar = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.ui.specialPink};
`;

export const CloseButtonRow = styled('div')`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CloseContainer = styled('div')`
  display: flex;
  width: 40px;
  color: ${(props) => props.theme.colors.text.secondaryGray};
`;

export const InfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 84px; */
  padding-left: 16px;
  row-gap: 4px;
`;

export const InfoItem = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const InfoNumber = styled('h5')`
  align-self: flex-start;
  text-align: left;
  color: ${(props) => props.theme.colors.text.secondaryGray};
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  border-radius: 4px;
  border-color: ${(props) => props.theme.colors.ui.cellGray};
  padding: 0px 3px 0px 3px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TopContainer = styled('div')`
  display: flex;
  height: 84px;
  width: 100%;
  flex-direction: row;
  padding-left: 24px;
  margin-bottom: 24px;
`;

export const FiredIcon = styled('div')`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  background-image: url(${firedIcon});
`;

export const Maxed = styled('div')`
  display: flex;
  flex-direction: row;
  width: 115px;
  height: 22px;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 40px;
  background: ${(props) => props.theme.colors.text.musicalAqua};
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled('div')`
  width: 100%;
  border-bottom: 1px solid gray;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const RollupInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding: 20px;
  height: 550px;
`;

export const RollupInfoLevelup = styled('div')`
  margin: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

export const RollupContentsInfo = styled('div')`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  /* scrollbar width */
  &::-webkit-scrollbar {
    width: 6px;
  }
  /* scrollbar Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* scrollbar Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.ui.unfilledGray};
    border-radius: 2px;
  }
  /* scrollbar Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.text.primaryGray};
  }
`;

export const XpInfoSkill = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
`;

export const XpInfoText = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  margin-bottom: 8px;
`;

export const EditIconButton = styled(Button)`
  height: 22px;
  min-width: 40px;

  :hover {
    background-color: white;
  }
`;

export const SkillBarBG = styled('div')`
  position: relative;
  height: 8px;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
  border-radius: 20px;
`;

export const SkillBarOther = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple};
`;

export const SkillBarMusical = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.ui.musicalCyan};
`;

export const SkillBarMusicalNotMatched = styled('div')`
  display: flex;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.ui.notMatched};
`;

export const CustomMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'disableOpacityChange',
})<{
  disableOpacityChange?: boolean;
}>`
  &.Mui-disabled {
    opacity: ${(props) => (props.disableOpacityChange ? 1 : 0.38)};
  }
`;

export const PulsingDotContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height',
})<{
  width: number;
  height: number;
}>`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const PulsingDotCircle = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'width' && prop !== 'height' && prop !== 'color',
})<{
  width: number;
  height: number;
  color: string;
}>`
  width: ${(props) => props.width / 2.25}px;
  height: ${(props) => props.height / 2.25}px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const pulsate = keyframes`
  0% {
    transform: scale(0.5, 0.5); opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1.1, 1.1); opacity: 0.0;
  }
`;

export const PulsingDotRing = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'width' && prop !== 'height' && prop !== 'color',
})<{
  width: number;
  height: number;
  color: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  animation: ${pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.2;
`;

export const CollageFrame = styled('div', {
  shouldForwardProp: (prop) => prop !== 'collageColumnRowCount',
})<{ collageColumnRowCount: number }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.collageColumnRowCount}, 1fr);
  grid-template-rows: repeat(${(props) => props.collageColumnRowCount}, 1fr);
  grid-gap: 0px;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.ui.cellGray};
`;

export const CollageImage = styled('img')`
  max-width: 100%;
`;

export const Blur = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
`;

export const Opaque = styled('div')`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.5;
  pointer-events: none;
  z-index: 2;
`;

export const ButtonDefault = styled(LoadingButton)`
  width: 100%;
  height: 50px;
  background-color: ${(props) => props.theme.colors.ui.primaryPurple};
  border: none;
  border-radius: 50px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.ui.primaryPurple};
    color: white;
  }
  :disabled {
    background-color: ${(props) => props.theme.colors.ui.cellGray};
  }
`;

export const ButtonNoBG = styled(LoadingButton)`
  color: ${(props) => props.theme.colors.text.secondaryGray};
  display: flex;
  column-gap: 6px;
  box-shadow: none;
  &.MuiLoadingButton-root {
    background-color: transparent;
    box-shadow: none;
  }
  :hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

export const IconCircleContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width' && prop != 'height',
})<{ width?: number | string; height?: number | string }>`
  position: relative;
  width: ${(props) =>
    props.width
      ? typeof props.width === 'number'
        ? `${props.width}px`
        : props.width
      : '32px'};
  height: ${(props) =>
    props.height
      ? typeof props.height === 'number'
        ? `${props.height}px`
        : props.height
      : '32px'};
`;

export const IconCircleOuter = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 50%;
  background-color: ${(props) => props.color ?? props.theme.colors.text.error};
`;

export const IconCircleInner = styled(IconCircleOuter, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>`
  width: 62%;
  height: 62%;
  background-color: ${(props) =>
    props.color ?? props.theme.colors.text.darkError};
`;

export const ExclamationMarkIcon = styled(PriorityHighIcon)`
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

export const CheckMarkIcon = styled(CheckIcon)`
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

export const InfoIconContainer = styled('div')`
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.secondaryGray};
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: 8px;
`;

export const SkillMarkIcon = styled(MusicNoteIcon)`
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

export const AbilityUpIcon = styled(TrendingUpIcon)`
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

export const StarUpIcon = styled(StarRoundedIcon)`
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;
