import { Button } from '@mui/material';
import React from 'react';
import {
  NftListItemContainer,
  NftListItemInfoContainer,
  NftListItemInfoTitle,
} from './style';

const baseUrl = process.env.ASSET_BASE_URL || '';

export const NFTCrateItem = (props: { crate: any }) => {
  return (
    <NftListItemContainer>
      <video
        autoPlay
        loop
        playsInline
        muted
        src={props.crate.animation_url}
        style={{
          width: 270,
          height: '100%',
          border: 'none',
          borderRadius: 15,
        }}
      />
      <NftListItemInfoContainer>
        <NftListItemInfoTitle>{props.crate.name}</NftListItemInfoTitle>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 12,
          }}>
          <p style={{ textAlign: 'left' }}>
            This crate represents an IOU for a Blockstar to be released in a
            future collection. It contains the attributes and instrument of a
            potential star.
          </p>
          <p style={{ textAlign: 'left' }}>
            The unlock date is yet to be announced.
          </p>
        </div>
        <Button
          style={{
            borderRadius: 8,
            height: 50,
            position: 'absolute',
            bottom: 0,
          }}
          variant={'outlined'}
          disabled
          fullWidth>
          Open crate
        </Button>
      </NftListItemInfoContainer>
    </NftListItemContainer>
  );
};
