import { Band, Blockstar } from '@shared-data';
import * as React from 'react';
import StarRating from 'src/components/star-rating';
import { getBandName, getBlockstarName } from 'src/utils/utils';
import { FadeIn } from '../../fade-in';
import { ResultsInfoContainer, ResultsStar } from '../style';

interface IProps {
  currentStars: number;
  previousStars: number;
  potentialStars: number;
  blockstar?: Blockstar;
  band?: Band;
}

export const StarIncrease = (props: IProps) => {
  let name = 'You';
  if (props.blockstar) {
    name = getBlockstarName(props.blockstar, true);
  } else if (props.band) {
    name = getBandName(props.band);
  }
  return (
    <ResultsInfoContainer
      style={{ height: '100%', padding: 0, justifyContent: 'center' }}>
      <ResultsStar />
      <div
        style={{
          fontSize: 40,
          fontWeight: 600,
        }}>{`${name} leveled up!`}</div>
      <div style={{ margin: 12 }} />
      <div style={{ position: 'relative' }}>
        <StarRating
          currentStars={props.previousStars ?? 0}
          potentialStars={props.potentialStars}
          starSize={36}
        />
        <FadeIn style={{ position: 'absolute', top: 0 }}>
          <StarRating
            currentStars={props.currentStars ?? 0}
            potentialStars={props.potentialStars}
            starSize={36}
          />
        </FadeIn>
      </div>
    </ResultsInfoContainer>
  );
};
